<template>
  <div class="sidenav-divider" />
</template>

<script>

export default {
  name: 'SidenavDivider'
};
</script>
