export default {
    authorizationsLevels: {
        admin: ['basic', 'admin'],
        superadmin: ['basic', 'admin', 'superadmin'],
        megaadmin: ['brokerdealer', 'basic', 'admin', 'superadmin', 'megaadmin'],
        brokerdealer: ['brokerdealer'],
    },
    tokensAuthentication: {
        accessToken: '',
        accessTokenExpiredAt: '',
        refreshToken: '',
        refreshTokenExpiredAt: '',
    },
    googleIsLoading: false,
};
