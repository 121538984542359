import {
  getAllOpportunities,
  createOpportunity,
  getIssuerOriginalOpportunities,
  deleteOpportunity,
  getOpportunityById,
  updateOpportunity,
  updateOpportunitiesOrder,
  getAllTags,
} from '@/utilities/external-api/apis/brokerDealerCpApi';
import { infoApi } from '@/utilities/mainApiClient';

const { getInfo } = infoApi;

export default {
  getAllBrokerDealerOpportunities({ commit }, filters) {
    return getAllOpportunities(filters)
      .then(({ data }) => {
        commit('SET_BROKER_DEALER_OPPORTUNITIES', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Broker dealer get opportunities error', err);
      });
  },
  createBrokerDealerOpportunity({ commit }, opportunity) {
    return createOpportunity(opportunity)
      .then(() => {
        commit('global/CALL_TOASTER', 'Opportunity created successfully', { root: true });
      })
      .catch((err) => {
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while saving the opportunity');
        this.$log.error('Broker dealer get opportunities error', err);
      });
  },
  getIssuerTokens(ctx, issuerId) {
    return getInfo(issuerId).then(({ data }) => data.tokens)
      .catch((err) => {
        this.$log.error('Opportunity list error:', err);
        throw err;
      });
  },
  getIssuerOriginalOpportunities(ctx, { issuerId, tokenId }) {
    return getIssuerOriginalOpportunities({ issuerId, tokenId })
      .then(({ data }) => data.data)
      .catch((err) => {
        this.$log.error('Broker dealer get issuer opportunities error', err);
        throw err;
      });
  },
  deleteBrokerDealerOpportunity({ commit }, opportunityId) {
    return deleteOpportunity(opportunityId)
      .then((data) => {
        commit('global/CALL_TOASTER', 'Opportunity deleted successfully', { root: true });
        return data;
      })
      .catch((err) => {
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while deleting the opportunity');
        this.$log.error('Broker dealer delete opportunities error', err);
      });
  },
  getBrokerDealerOpportunityById(ctx, opportunityId) {
    return getOpportunityById(opportunityId)
      .catch((err) => {
        this.$log.error('Broker dealer get opportunity by id error', err);
      });
  },
  updateBrokerDealerOpportunity({ commit }, { opportunityId, opportunity }) {
    return updateOpportunity(opportunityId, opportunity)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity updated successfully', { root: true });
        return data;
      })
      .catch((err) => {
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while saving the opportunity');
        this.$log.error('Broker dealer update opportunities error', err);
      });
  },

  updateBrokerDealerOpportunitiesOrder({ commit }, opportunities) {
    return updateOpportunitiesOrder(opportunities)
      .then(() => {
        commit('global/CALL_TOASTER', 'Opportunities order updated successfully', { root: true });
      })
      .catch((err) => {
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating order');
        this.$log.error('Broker dealer update opportunities error', err);
      });
  },
  getAvailableTags() {
    return getAllTags()
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Broker dealer get tags error', err);
      });
  },
};
