/* eslint-disable import/prefer-default-export */
import axios from '@/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class AbstractionLayerCpApi {
  constructor() {
    this.configuration = {
      apiKey: '',
      basePath: '/api/v2',
    };

    this.authorizationHeader = () => {
      // const _store = operatorStore;
      // this.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = {};
      // localVarHeaderParams.authorization = `Bearer ${this.configuration.apiKey}`;

      return localVarHeaderParams;
    };

    this.getAbstractionLayerMultisigWalletsByDeploymentId = (deploymentId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/multisig-wallet/deployments/${deploymentId}/multisig-wallets/`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAbstractionLayerMultisigWalletsByDeploymentIdAndWalletId = (deploymentId, walletId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/multisig-wallet/deployments/${deploymentId}/multisig-wallets/${walletId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAbstractionLayerMultisigWalletTransactionsStatusList = (deploymentId, clientTransactionIds, includeTransactionData) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/multisig-wallet/deployments/${deploymentId}/multisig-wallets/transactions/statuses`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
        params: {
          clientTransactionIds: encodeURI(clientTransactionIds),
          includeTransactionData,
        },
      });
    };

    this.getAbstractionLayerMultisigWalletTransactionsStatus = (deploymentId, clientTransactionId, includeTransactionData) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/multisig-wallet/deployments/${deploymentId}/multisig-wallets/transactions/status/${clientTransactionId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
        params: { includeTransactionData },
      });
    };

    this.getAbstractionLayerNetworkInfo = (providerName) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/providers/${providerName}/info`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAbstractionLayerTokenProvider = (tokenId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/providers/tokens/${tokenId}/network`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getOperatorHSMKeys = (operatorId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/operators/${operatorId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.createHSMOperator = (operatorId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/operators/${operatorId}`;
      return axios.post(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.createHSMOperatorKey = (operatorId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/operators/${operatorId}/key-pair`;
      return axios.post(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.removeHSMOperatorKey = (operatorId, hsmAddress) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/operators/${operatorId}/key-pair/${hsmAddress}`;
      return axios.delete(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.toggleHSMOperatorStatus = (operatorId) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/operators/${operatorId}`;
      return axios.put(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getOperatorTransactionSignature = (hsmAddress, unsignedTx, options) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/address/${hsmAddress}/signatures/transaction`;
      return axios.post(localVarPath, { unsignedTx, ...options }, {
        headers: this.authorizationHeader(),
      });
    };

    this.getOperatorMessageSignature = (hsmAddress, unsignedMessage) => {
      const localVarPath = `${this.configuration.basePath}/abstraction-layer/hsm/address/${hsmAddress}/signatures/message`;
      return axios.post(localVarPath, { unsignedMessage }, {
        headers: this.authorizationHeader(),
      });
    };
  }
}

export const {
  getAbstractionLayerMultisigWalletsByDeploymentId,
  getAbstractionLayerMultisigWalletsByDeploymentIdAndWalletId,
  getAbstractionLayerMultisigWalletTransactionsStatus,
  getAbstractionLayerMultisigWalletTransactionsStatusList,
  getAbstractionLayerNetworkInfo,
  getAbstractionLayerTokenProvider,
  getOperatorHSMKeys,
  removeHSMOperatorKey,
  createHSMOperator,
  createHSMOperatorKey,
  toggleHSMOperatorStatus,
  getOperatorTransactionSignature,
  getOperatorMessageSignature,
} = new AbstractionLayerCpApi();
