export default {
  SET_ACTIVITIES(state, list) {
    state.activities = list;
  },
  SET_TOTAL_LOGS(state, count) {
    state.totalLogs = count;
  },
  SET_FUNDING_STATISTICS(state, data) {
    state.fundingStatistics = data;
  },
  SET_ONBOARDING_STATISTICS(state, data) {
    state.onboardingStatistics = data;
  },
  SET_ONBOARDING_BY_COUNTRY_SUMMARY(state, data) {
    state.onboardingByCountrySummary = data;
  },
  SET_ONBOARDING_REGISTERED_SUMMARY(state, data) {
    state.onboardingRegisteredSummary = data;
  },
  SET_ONBOARDING_AUTHORIZED_SUMMARY(state, data) {
    state.onboardingAuthorizedSummary = data;
  },
  SET_FUNDING_BY_COUNTRY_SUMMARY(state, data) {
    state.fudingByCountrySummary = data;
  },
  SET_FUNDING_BY_PLEDGE_SUMMARY(state, data) {
    state.fundingByPledgeSummary = data;
  },
  SET_FUNDING_FUNDED_SUMMARY(state, data) {
    state.fundingFundedSummary = data;
  },
  SET_HOLDERS_STATISTICS(state, data) {
    state.holdersStatistics = data;
  },
  SET_HOLDERS_BY_COUNTRY_SUMMARY(state, data) {
    state.holdersByCountrySummary = data;
  },
  SET_HOLDERS_BY_TYPE_SUMMARY(state, data) {
    state.holdersByTypeSummary = data;
  },
  SET_HOLDERS_TOKEN_STAKES(state, data) {
    state.holdersTokenStakes = data;
  },
};
