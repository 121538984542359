import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';
import issuerStore from '@/store/modules/issuer-list';

export default async () => Frame(
    'markets_overview',
    'Markets Overview',
    './MarketsOverviewApp',
    process.env.VUE_APP_MARKETS_OVERVIEW_MFE_ENTRY_POINT,
    () => {
        const {
            state: { idUUID: operatorId },
        } = currentOperatorStore;

        const {
            state: { locale },
        } = globalStore;

        const {
            state: { current },
        } = issuerStore;

        return { operatorId, locale, issuerId: current.id };
    },
);
