export default {
  SET_AUDIT_LOG_OPERATOR_LIST: (state, list) => {
    state.operatorList = list;
  },
  SET_AUDIT_LOG_ISSUER_LIST: (state, list) => {
    state.issuerList = list;
  },
  SET_AUDIT_LOG_TOKEN_LIST: (state, list) => {
    state.tokenList = list;
  },
};
