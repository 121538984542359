export default {
  component: {
    cpDualListBox: {
      label: {
        instances: 'インスタンス',
        selectedInstances: '選択中インスタンス',
        showingAll: 'すべて表示({0})',
        emptyList: '空のリスト',
        moveAll: '全て移動',
        moveSelected: '選択中を移動',
        removeAll: 'すべて削除',
        removeSelected: '選択中を削除',
        noResultsFound: '検索結果が存在しません',
        clearSearch: '検索をクリア',
      },
      input: {
        search: {
          placeholder: '検索...',
        },
      },
    },
    cpDateRange: {
      to: '〜',
    },
    cpHtmlTextarea: {
      button: {
        preview: 'プレビュー',
        view: 'ソースを見る',
      },
    },
    cpSelect: {
      label: {
        defaultText: 'オプションを選択して下さい',
      },
    },
    cpTextArea: {
      label: {
        placeholder: 'テキストをここに入力',
      },
    },
    cpMultiSelect: {
      button: {
        clear: '{0}クリア',
      },
    },
  },
};
