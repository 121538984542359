export default [
  'totalInvestorsLimit',
  'minUSTokens',
  'minEUTokens',
  'usInvestorsLimit',
  'usAccreditedInvestorsLimit',
  'nonAccreditedInvestorsLimit',
  'maxUSInvestorsPercentage',
  'blockFlowbackEndTime',
  'nonUSLockPeriod',
  'minimumTotalInvestors',
  'minimumHoldingsPerInvestor',
  'maximumHoldingsPerInvestor',
  'euRetailInvestorsLimit',
  'authorizedSecurities',
  'usLockPeriod',
  'jpInvestorsLimit',
  'forceFullTransfer',
  'worldWideForceFullTransfer',
  'forceAccredited',
  'forceAccreditedUS',
  'disallowBackDating',
];
