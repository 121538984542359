export default {
  component: {
    filterBooleanField: {
      label: {
        show: '{0}フィルタ',
      },
      options: {
        true: '払い出しウォレットあり',
        false: '払い出しウォレットなし',
      },
    },
  },
};
