<template>
  <div id="app">
    <div
      v-if="hasLayoutWrapper"
      class="layout-wrapper layout-2 default-style"
    >
      <div class="layout-inner">
        <cp-layout-sidenav />
        <div class="layout-container">
          <cp-layout-navbar />
          <div class="layout-content">
            <div
              id="scroll-container"
              class="router-transitions container-fluid flex-grow-1 inner-layout-scroll"
              :class="contentClasses"
            >
              <router-view />
            </div>
            <cp-layout-footer />
          </div>
        </div>
      </div>
      <div
        class="layout-overlay"
        @click="closeSidenav"
      />
      <zendesk v-if="isZendeskAvailable" />
    </div>
    <router-view v-else />
  </div>
</template>

<script>

import { mapMutations } from 'vuex';
import CpLayoutNavbar from '~/components/layout/navbar';
import CpLayoutSidenav from '~/components/layout/sidenav';
import CpLayoutFooter from '~/components/layout/footer';
import Zendesk from '@/components/layout/zendesk';

export default {
  name: 'App',
  components: {
    CpLayoutNavbar,
    CpLayoutSidenav,
    CpLayoutFooter,
    Zendesk,
  },
  computed: {
    contentClasses() {
      return this.$route.name === 'idIssuer-dashboard' ? 'p-0' : 'container-p-y';
    },
    hasLayoutWrapper() {
      return !['/authorize', '/authorize/', '/', '/register', '/signup', '/two-factor-auth'].includes(this.$route.path);
    },
    isZendeskAvailable() {
      return Boolean(process.env.VUE_APP_ZENDESK_KEY);
    },
  },
  watch: {
    $route(from) {
      const issuerId = from.params.idIssuer;
      if (issuerId) {
        this.SET_ISSUER_ID(issuerId);
      }
    },
  },
  mounted() {
    if (!!this.$route.params.idIssuer && this.$route.params.idIssuer !== 'undefined') {
      this.SET_ISSUER_ID(this.$route.params.idIssuer);
    }
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },
  beforeDestroy() {
    this.layoutHelpers.destroy();
  },
  methods: {
    ...mapMutations('issuersInfo', ['SET_ISSUER_ID']),
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
  },
};
</script>
<style scoped>
  .layout-container {
    height: 100vh;
  }
  .inner-layout-scroll{
    overflow-x: hidden;
    overflow-y:auto;
  }
  .layout-inner {
    max-height: 100vh;
  }
</style>
