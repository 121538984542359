import {
  getAuditLogs,
  getAuditLogOperators,
  getAuditLogIssuers,
  getAuditLogTokens,
} from '~/utilities/external-api/apis/auditLogCpApi';
import {
  getAuditLogTokensByIssuerId,
} from '~/utilities/external-api/apis/auditLogIssuerCpApi';

export default {
  getAuditLogsList(ctx, { params }) {
    return getAuditLogs(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Audit Logs get error:', err);
      });
  },

  getOperatorList({ commit }) {
    return getAuditLogOperators()
      .then(({ data }) => {
        commit('SET_AUDIT_LOG_OPERATOR_LIST', data);
      })
      .catch((err) => {
        this.$log.error('Audit Logs get operators error:', err);
      });
  },

  getIssuerList({ commit }) {
    return getAuditLogIssuers()
      .then(({ data }) => {
        commit('SET_AUDIT_LOG_ISSUER_LIST', data);
      })
      .catch((err) => {
        this.$log.error('Audit Logs get issuers error:', err);
      });
  },

  getTokenList({ commit }) {
    return getAuditLogTokens()
      .then(({ data }) => {
        commit('SET_AUDIT_LOG_TOKEN_LIST', data);
      })
      .catch((err) => {
        this.$log.error('Audit Logs get tokens error:', err);
      });
  },
  getTokenListByIssuerId({ commit }, { issuerId }) {
    return getAuditLogTokensByIssuerId(issuerId)
        .then(({ data }) => {
          commit('SET_AUDIT_LOG_TOKEN_LIST', data);
        })
        .catch((err) => {
          this.$log.error('Audit Logs get tokens by issuer id error:', err);
        });
  },
};
