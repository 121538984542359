export default {
  SET_CONTACT_LIST(state, data) {
    state.previewContactList = data;
  },
  SET_TOTAL_ITEMS(state, data) {
    state.totalItems = data;
  },
  SET_SAVED_CONTACT_LISTS(state, data) {
    const savedContactLists = data.map(item => ({
      filters: item.filters,
      name: item.name,
      id: item.id,
    }));
    state.savedContactLists = savedContactLists;
  },
  CLEAR_SAVED_CONTACT_LISTS(state) {
    state.savedContactLists = [];
  },
  SET_NEW_SAVED_LIST(state, { id, name, filters }) {
    state.savedContactLists = [{ id, name, filters }, ...state.savedContactLists];
  },
  REMOVE_CONTACT_LIST(state, listId) {
    const index = state.savedContactLists.findIndex(list => list.id === listId);
    state.savedContactLists.splice(index, 1);
  },
  SET_FILTER_LIST_STATE: (state, { filterData }) => {
    state.investorsOutreachList = { ...filterData };
  },
  ADD_CUSTOM_EMAIL: (state, { email }) => {
    const index = state.customEmails.indexOf(email);
    if (index === -1) {
      state.customEmails.push(email);
    }
  },
  SET_CUSTOM_EMAIL: (state, { email }) => {
    const index = state.customEmails.indexOf(email);
    state.customEmails.splice(index, 1);
  },
  SET_DOMAIN: (state, { domain }) => {
    state.domain = domain;
  },
  SET_IS_AUTHENTICATED: (state, { isAuthenticated }) => {
    state.isAuthenticated = isAuthenticated;
  },
  SET_EMAIL_ACTIVITIES_CHART(state, data) {
    state.emailActivityChart = data;
  },
  SET_EMAIL_ACTIVITIES(state, data) {
    state.emailActivity = data;
  },
  SET_EMAIL_ACTIVITIES_TOTAL(state, data) {
    state.emailActivityTotal = data;
  },
  SET_SELECTED_ID_DETAIL_DATA(state, data) {
    state.selectedIdDetailsData = data;
  },
  CHANGES_FEATURED_STATUS(state, data) {
    state.outreachIsEnabled = data;
  },
};
