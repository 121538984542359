export default {
  snapshotRecordGetter: state => state.snapshotRecord,
  snapshotsRecordsFiltered: (state, getters) => {
    const filterData = [];
    getters.snapshotRecordGetter.forEach((dataElement) => {
      const obj = {
        name: dataElement.investor.name,
        wallet: dataElement.wallet.address,
        tokens: dataElement.tokens,
      };
      filterData.push(obj);
    });
    return filterData;
  },
};
