import { campaignsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addCampaign,
  deleteCampaignById,
  getCampaignById,
  getCampaigns,
  updateCampaignById,
} = campaignsApi;
const affiliateCampaignsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  affiliateId: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ commit }, { issuerId, campaign: { id, ...rest } }) {
    return updateCampaignById(issuerId, id, rest).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliateCampaigns.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, campaignId }) {
    return deleteCampaignById(issuerId, campaignId).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliateCampaigns.delete'), { root: true });
    });
  },

  create({ commit }, { issuerId, campaign }) {
    return addCampaign(issuerId, campaign).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliateCampaigns.create'), { root: true });
    });
  },

  getById({ commit }, { issuerId, campaignId }) {
    return getCampaignById(issuerId, campaignId).then(({ data }) => {
      commit('SET_ISSUER_AFFILIATE_CAMPAIGN', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Affiliate Campaign get error:', err);
    });
  },
  getAffiliateCampaigns(ctx, { params }) {
    const args = getArgumentsForApi(affiliateCampaignsArguments, params);
    return getCampaigns(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Affiliate Campaigns get error:', err);
        throw err;
      });
  },

};
