import axios from '~/utilities/axios';

class SnapshotsCpApi {
    constructor() {
        const that = this;
        that.configuration = { apiKey: '', basePath: '/api/v2' };

        that.authorizationHeader = function authorizationHeader() {
            const localVarHeaderParams = { };
            return localVarHeaderParams;
        };

        this.getSnapshots = function getSnapshots(params) {
            const { issuerId, tokenId } = params;
            delete params.issuerId;
            delete params.tokenId;
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/snapshots`;
            return axios.get(localVarPath, {
                headers: that.authorizationHeader(),
                params,
            });
        };
    }
}

// eslint-disable-next-line import/prefer-default-export
export const { getSnapshots } = new SnapshotsCpApi();
