<template>
  <div
    class="date-block cp-time-date-picker"
    :class="{'half-column': columnsLayout}"
    @click="$emit('focus')"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
      <small>
        {{ previewDate }}
      </small>
    </label>
    <div class="input-group">
      <datepicker
        ref="cpRangeDatePicker"
        v-click-outside="hide"
        typeable
        calendar-button
        input-class="d-none"
        bootstrap-styling
        calendar-button-icon="ion ion-md-calendar"
        name="cp-datepicker"
        :initial-view="initialView"
        :open-date="openDate"
        :disabled-dates="disabledDates"
        :disabled="disabled"
        @selected="onDateChange"
      />
      <b-input
        v-validate.initial="validation"
        class="cp-border-r-off cp-b-input"
        :class="[ inputClass, { 'border border-danger': error || controlledError }]"
        :value="currentValue"
        :disabled="disabled"
        :readonly="readOnly"
        :name="name"
        :data-vv-as="name.split(/(?=[A-Z])/).join(' ').toLowerCase()"
        @input="onInput"
        @focus="onFocus"
        @blur="inFocus = false"
      />
      <vue-time-picker
        v-model="timeValue"
        :input-class="['form-control','cp-display-time']"
        placeholder=" "
        hide-clear-button
        lazy
        :disabled="disabled"
        @input="onTimeChange"
      />
      <span
        v-if="error || controlledError"
        class="invalid-feedback d-block"
      >
        {{ error || controlledError }}
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import ClickOutside from 'vue-click-outside';
import VueTimePicker from 'vue2-timepicker';
import {
  checkDateInput, formatDate, isAfter, isBefore,
} from '~/utilities/date-utils';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  name: 'CpTimeDatePicker',
  components: {
    Datepicker,
    VueTimePicker,
  },
  directives: {
    ClickOutside,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    columnsLayout: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Object,
      default: () => ({}),
    },
    openDate: {
      type: Date,
      default: () => (new Date()),
    },
    initialView: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: '',
    },
    calendarOnRight: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'll',
    },
    serverFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
  },
  data() {
    return {
      inFocus: false,
      userValue: this.value,
      controlledError: '',
      controlledValue: this.value,
      timeValue: this.value ? formatDate(this.value, 'HH:mm') : '',
      dateValue: '',
    };
  },
  computed: {
    previewDate() {
      const isValid = checkDateInput(this.currentValue);
      return isValid ? this.formatToll(this.currentValue) : '';
    },
    validation() {
      return this.validate ? `any_date|${this.validate}` : 'any_date';
    },
    currentValue() {
      return this.inFocus ? this.userValue : this.formatToll(this.value || this.controlledValue);
    },
  },
  watch: {
    value(val) { // sync values on clear filter
      this.timeValue = formatDate(val, 'HH:mm');
      if (val === '') this.userValue = val;
    },
  },
  mounted() {
    if (this.calendarOnRight) {
      this.$refs.cpRangeDatePicker.$children[1].$el.style.right = 0;
    }
  },
  methods: {
    onFocus() {
      this.userValue = this.currentValue;
      this.inFocus = true;
    },
    onInput(val) {
      this.$emit('dateIsChanged', val);
      this.userValue = val;
      this.handleInput(val);
    },
    onTimeChange(val) {
      const timeArr = val.split(':');
      timeArr.forEach((timeItem, index) => {
        if (Number.isNaN(Number(timeItem))) {
          timeArr[index] = '00';
        }
      });
      this.timeValue = timeArr.join(':');
      if (this.value) {
        const date = this.formatTimeForServer(this.value || '', 'YYYY-MM-DD');
        this.handleInput(this.formatTimeForServer(`${date} ${this.timeValue}`));
      }
    },
    onDateChange(val) {
      if (!this.timeValue) this.timeValue = '00:00';
      val = this.formatTimeForServer(val, 'YYYY-MM-DD');
      val = this.formatTimeForServer(`${val} ${this.timeValue}`);
      this.handleInput(val);
    },
    handleInput(val) {
      const isValid = checkDateInput(val);
      if (!isValid) {
        this.$emit('input', val);
        return;
      }
      if (Object.keys(this.disabledDates).length) {
        const isBeforeDisabled = this.disabledDates.from && isBefore(val, this.disabledDates.from);
        if (isBeforeDisabled) {
          this.$emit('input', this.formatTimeForServer(this.disabledDates.from));
          this.$nextTick(() => {
            this.timeValue = formatDate(this.disabledDates.from, 'HH:mm');
          });
          return;
        }
        const isAfterDisabled = this.disabledDates.to && isAfter(val, this.disabledDates.to);
        if (isAfterDisabled) {
          this.$emit('input', this.formatTimeForServer(this.disabledDates.to));
          this.$nextTick(() => {
            this.timeValue = formatDate(this.disabledDates.to, 'HH:mm');
          });
          return;
        }
      }
      this.$emit('input', this.formatTimeForServer(val));
    },
    isCpInput() {
      return true;
    },
    hide() {
      this.$refs.cpRangeDatePicker.close();
    },
    formatToll(date) {
      const isValid = checkDateInput(date);
      return isValid ? formatDate(date, this.dateFormat) : date;
    },
    formatTimeForServer(date, dateFormat = 'YYYY-MM-DD HH:mm') {
      return formatDate(date, dateFormat);
    },
  },
};
</script>

<style lang="scss">
    .cp-border-r-off {
        border-radius: 0 !important;
    }

    .vue__time-picker {
        width: auto;
    }

    .vdp-datepicker {
        .input-group {
            height: 100% !important;
        }

        .vdp-datepicker__calendar-button {
            .input-group-text {
                border-radius: 0;
                background-color: rgba(10, 23, 39, 0.06) !important;
            }
        }
    }

    .cp-time-date-picker.half-column {
        .cp-b-input {
            min-width: 100px;
            max-width: 114px;
            text-align: center;
        }

        input.cp-display-time {
            border: 1px solid rgba(10, 23, 39, 0.1);
            border-radius: 0;
            width: 3.8em;
        }
    }

    .cp-time-date-picker {
        .dropdown ul li:not([disabled]).active,
        .dropdown ul li:not([disabled]).active:focus,
        .dropdown ul li:not([disabled]).active:hover {
            background-color: #04bec4;
        }

        .dropdown ul li:not(.hint):not([disabled]):focus,
        .dropdown ul li:not(.hint):not([disabled]):hover {
            background-color: transparent;
            border: 1px solid #4bd;
        }

        .dropdown ul li:not(.hint):not([disabled]):hover {
            padding: calc(0.3em - 1px);
            color: #627d8e;
        }

        .dropdown ul li.hint:hover {
            padding: 0.3em;
        }

        .dropdown ul li {
            color: #627d8e;
        }
    }
</style>
