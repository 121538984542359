import Frame from '@/router/mfe/frame';
import globalStore from '@/store/modules/global';
import currentOperatorStore from '@/store/modules/current-operator';

export default async () => {
  const { state: { authorizations } } = currentOperatorStore;
  if (authorizations.includes('new-cp-ui')) {
    return Frame(
      'affiliate_management',
      'Affiliate Management',
      './AffiliateManagementApp',
      process.env.VUE_APP_AFFILIATE_MANAGEMENT_MFE_ENTRY_POINT,
      () => {
        const {
          state: { locale },
        } = globalStore;

        return { locale };
      },
    );
  }

  return import('@/pages/_idIssuer/transfer-agent/affiliate-management/index');
};
