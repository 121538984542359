import { emailTemplatesApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  deleteEmailTemplateByName,
  getEmailTemplates,
  getEmailTemplateByName,
  updateEmailTemplateByName,
  enableAllIssuerEmailTemplates,
  disableAllIssuerEmailTemplates,
} = emailTemplatesApi;
const emailTemplatesArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ commit }, { issuerId, template: { name, ...rest } }) {
    return updateEmailTemplateByName(issuerId, name, rest).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerEmailTemplates.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, name }) {
    return deleteEmailTemplateByName(issuerId, name).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerEmailTemplates.delete'), { root: true });
    });
  },

  getByName({ commit }, { issuerId, name }) {
    return getEmailTemplateByName(issuerId, name).then(({ data }) => {
      commit('SET_TEMPLATE', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Email template get error:', err);
    });
  },
  getIssuerEmailTemplates(ctx, { params }) {
    const args = getArgumentsForApi(emailTemplatesArguments, params);
    return getEmailTemplates(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Email Templates get error:', err);
        return err;
      });
  },
  enableAll({ commit }, { issuerId }) {
    return enableAllIssuerEmailTemplates(issuerId, { data: {}, headers: { 'Content-Type': 'application/json' } }).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerEmailTemplates.update'), { root: true });
    }).catch((err) => {
      this.$log.error('Enable Issuer Email templates error:', err);
    });
  },
  disableAll({ commit }, { issuerId }) {
    return disableAllIssuerEmailTemplates(issuerId, { data: {}, headers: { 'Content-Type': 'application/json' } }).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerEmailTemplates.update'), { root: true });
    }).catch((err) => {
      this.$log.error('Disable Issuer Email templates error:', err);
    });
  },
};
