import axiosV2 from '~/utilities/axiosV2';

class IssuerLabelsCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2', basePathNewGW: '/isr/api' };

    this.authorizationHeader = () => ({});

    this.getLabels = function getGeneralLabels(startsWith) {
      const localVarPath = `${that.configuration.basePathNewGW}/v1/issuer-labels/${startsWith ? `?startsWith=${startsWith}` : ''}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axiosV2.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };

    this.createLabel = function createIssuerLabel(createDto) {
      const localVarPath = `${that.configuration.basePathNewGW}/v1/issuer-labels`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axiosV2.post(localVarPath, createDto, {
        headers: localVarHeadersParams,
      });
    };

    this.deleteLabel = function deleteLabel(label) {
      const localVarPath = `${that.configuration.basePathNewGW}/v1/issuer-labels/${label}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axiosV2.delete(localVarPath, {
        headers: localVarHeadersParams,
      });
    };

    this.getIssuerLabels = function getLabelsForIssuer(issuerId) {
      const localVarPath = `${that.configuration.basePathNewGW}/v1/issuers/${issuerId}/labels`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axiosV2.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };

    this.updateIssuerLabels = function updateLabelsForIssuer(issuerId, updateDto) {
      const localVarPath = `${that.configuration.basePathNewGW}/v1/issuers/${issuerId}/labels`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axiosV2.put(localVarPath, updateDto, {
        headers: localVarHeadersParams,
      });
    };
  }
}

// eslint-disable-next-line import/prefer-default-export
export const {
  getLabels,
  createLabel,
  deleteLabel,
  getIssuerLabels,
  updateIssuerLabels,
} = new IssuerLabelsCpApi();
