<template>
  <b-nav-item-dropdown
    no-caret
    right
    class="demo-navbar-notifications mr-lg-3"
  >
    <!--Notifications icon-->
    <template slot="button-content">
      <i class="ion ion-md-notifications-outline navbar-icon align-middle" />
      <span
        v-if="alertsCount"
        class="badge badge-pill badge-info indicator"
      >
        {{ alertsCount }}
      </span>
    </template>
    <!--Notifications icon-->
    <div class="bg-primary text-center text-white font-weight-bold p-3">
      {{ alertsSubtitle }}
    </div>
    <b-list-group
      v-if="alertsCount"
      flush
    >
      <b-list-group-item
        v-if="getAlertsCount('pending-manual-review-for-kyc')"
        href="javascript:void(0)"
        class="media d-flex align-items-center"
        @click.prevent="goToOnboarding"
      >
        <div class="ui-icon ui-icon-sm ion ion-md-ribbon bg-warning border-0 text-dark" />
        <div class="media-body line-height-condenced ml-3">
          <i18n
            path="component.notifications.message.investorsPendingManualReview"
            tag="div"
            class="text-dark"
          >
            <strong>{{ getAlertsCount('pending-manual-review-for-kyc') }}</strong>
          </i18n>
          <div class="text-light small mt-1">
            {{ $t('component.notifications.message.clickHere') }}
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item
        v-if="getAlertsCount('pending-approval-to-register-a-wallet')"
        href="javascript:void(0)"
        class="media d-flex align-items-center"
        @click.prevent="goToWalletOnboarding"
      >
        <div class="ui-icon ui-icon-sm ion ion-md-wallet bg-warning border-0 text-dark" />
        <div class="media-body line-height-condenced ml-3">
          <i18n
            path="component.notifications.message.investorsPendingApproval"
            tag="div"
            class="text-dark"
          >
            <strong>{{ getAlertsCount('pending-approval-to-register-a-wallet') }}</strong>
          </i18n>
          <div class="text-light small mt-1">
            {{ $t('component.notifications.message.clickHere') }}
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item
        v-if="getAlertsCount('transactions-pending-sign')"
        href="javascript:void(0)"
        class="media d-flex align-items-center"
        @click.prevent="redirectToPage('signatures')"
      >
        <div class="ui-icon ui-icon-sm ion ion-md-done-all bg-warning border-0 text-dark" />
        <div class="media-body line-height-condenced ml-3">
          <i18n
            path="component.notifications.message.transactionsRequireSigning"
            tag="div"
            class="text-dark"
          >
            <strong>{{ getAlertsCount('transactions-pending-sign') }}</strong>
          </i18n>
          <div class="text-light small mt-1">
            {{ $t('component.notifications.message.clickHere') }}
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-nav-item-dropdown>
</template>

<script>
import {
  mapState, mapGetters, mapMutations,
} from 'vuex';
import routeNames from '~/utilities/routes';

export default {
  name: 'CpNotifications',
  data() {
    return {
      updateInterval: undefined,
    };
  },
  computed: {
    ...mapGetters({
      alertsCount: 'currentOperator/alertsCount',
      notifications: 'currentOperator/notifications',
      currentIssuer: 'issuerList/currentIssuer',
    }),
    ...mapState('issuersInfo', ['currentToken']),
    alertsSubtitle() {
      return this.alertsCount
        ? this.$t('component.notifications.label.newNotification', [this.alertsCount])
        : this.$t('component.notifications.label.noNotifications');
    },
  },
  watch: {
    currentIssuer() {
    },
  },
  beforeMount() {
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    ...mapMutations('global', ['SET_FILTER_STATE']),
    goToOnboarding() {
      this.SET_FILTER_STATE({
        name: 'investorsOnboarding',
        filterData: { 'kyc-status': 'manual-review' },
      });
      this.redirectToPage('onboarding');
    },
    goToWalletOnboarding() {
      this.SET_FILTER_STATE({
        name: 'investorsOnboarding',
        filterData: {
          'accredited-status': 'no',
          'wallet-registration-status': 'pending',
        },
      });
      this.redirectToPage('onboarding');
    },
    getAlertsCount(type) {
      if (!this.notifications.length) return 0;

      const alert = this.notifications.find(item => item.type === type);
      return alert ? alert.count : 0;
    },
    redirectToPage(pageName) {
      this.$router.push(routeNames[pageName](this.$route.params.idIssuer, this.currentToken.id));
    },
  },
};
</script>
