import Frame from '@/router/mfe/frame';
import globalStore from '@/store/modules/global';
import currentOperatorStore from '@/store/modules/current-operator';

export default async () => {
  const {
    state: {
      id,
      idUUID,
      name,
      email,
      authorizationLevel,
      externalId,
      authorizations,
      picture,
    },
  } = currentOperatorStore;

  return Frame(
    'msf',
    'MSF',
    './MsfApp',
    process.env.VUE_APP_MSF_MFE_ENTRY_POINT,
    () => {
      const {
        state: { locale },
      } = globalStore;

      return {
        locale,
        operatorInfo: {
          operatorId: id,
          idUUID,
          name,
          email,
          authorizationLevel,
          externalId,
          authorizations,
          picture,
        },
      };
    },
  );
};
