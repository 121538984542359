import { textsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const { getTexts, updateTextById } = textsApi;
const textsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};
export default {
  update(ctx, { issuerId, text: { name, ...data } }) {
    return updateTextById(issuerId, name, data).then(() => {
      ctx.commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerTexts.update'), { root: true });
    });
  },
  getIssuerTexts(ctx, { params }) {
    const args = getArgumentsForApi(textsArguments, params);
    return getTexts(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Texts get error:', err);
        return err;
      });
  },
};
