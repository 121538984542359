export default {
  outreach: {
    prependTitle: 'Investor',
    title: 'Engagement',
    loading: 'Loading Engagement Data...',
    resetDomainButton: 'Domain Reset',
    upgradeMessage: 'Maximize investor Engagement!',
    upgradeText: 'Connect directly to your investors with the Securitize investor engagement module',
    upgradeInfo: 'The investor engagement module allows issuers to easily send and track email communications to their investors directly from the control panel.',
    upgradeButton: 'Contact Sales',
    label: {
      tab: {
        dashboard: 'Dashboard',
        contacts: 'Contacts',
        sendEmail: 'Send Email',
        tooltipEmail: 'Your domain must be authenticated to send emails',
      },
      table: {
        investorName: 'Name',
        emailAddress: 'Email',
        country: 'Country',
        investorType: 'Type',
        investorStatus: 'Investor Status',
        tokenAmount: 'Amount',
        percentage: 'Percentage',
        kycStatus: 'KYC Status',
        walletRegistrationStatus: 'Wallet Status',
        accreditationStatus: 'SA Status',
        investedStatus: 'Investor Status',
        subscriptionAgreementStatus: 'SA Status',
        rounds: 'Rounds',
        labels: 'Labels',
        fundingStatus: 'Funding Status',
        subject: 'Subject',
        date: 'Date',
        recipients: 'Recipients',
        delivered: 'Delivered',
        bounce: 'Bounced',
        open: 'Opened',
        click: 'Clicked',
        unsubscribe: 'Unsubcribed',
        spamreport: 'Spam Report',
      },
      filter: {
        country: 'Country',
        all: 'All investors',
        investorType: 'Investor Type',
        kycStatus: 'KYC Status',
        accreditationStatus: 'Accreditation Status',
        walletStatus: 'Wallet Status',
        fundingStatus: 'Funding Status',
        percentage: 'Token Amount',
        investorStatus: 'Investor Status',
        subscriptionAgreementStatus: 'SA Status',
        rounds: 'Rounds',
        labels: 'Labels',
      },
    },
    status: {
      kyc: {
        none: 'none',
        pending: 'pending',
        processing: 'processing',
        manualReview: 'manual review',
        processingError: 'processing error',
        passed: 'passed',
        rejected: 'rejected',
      },
      walletRegistration: {
        none: 'none',
        pending: 'pending',
        syncing: 'syncing',
        ready: 'ready',
        rejected: 'rejected',
        cancelled: 'cancelled',
      },
      investor: {
        ready: 'ready for issuance',
        pendingKyc: 'pending kyc',
        pendingAccreditation: 'pending accreditation',
        pendingInvestorSignature: 'pending investor signature',
      },
    },
    option: {
      fundingStatus: {
        commited: 'Pledged',
        funded: 'Funded',
      },
      percentage: {
        ten: '10%',
        quarter: '25%',
        half: '50%',
        threeQuarter: '75%',
      },
      kycStatus: {
        processing: 'Processing',
        processing_error: 'Processing Error',
        pending: 'Pending',
        pending_aml: 'Pending AML',
        manual_review: 'Manual Review',
        passed: 'Passed',
        rejected: 'Rejected',
      },
      investorStatus: {
        pendingKyc: 'Pending KYC',
        pendingQualification: 'pending-investor-signature',
        pendingAccreditation: 'Pending Accreditation',
        pendingInvestorSignature: 'Pending Investor Signature',
        pendingIssuerSignature: 'Pending Issuer Signature',
        pendingFunding: 'Pending Funding',
        ready: 'Ready',
      },
    },
    dashboard: {
      message: {
        causeDescription: `Before sending email communications to investors, your domain must be
        authenticated by adding DNS records to your domain host provider, such
        as Amazon or Godaddy.`,
        actionDescription: `Fill out the information below to generate and send the required DNS
        records to an appropriate technical person in your organization.`,
        reset: 'Your domain has been reset',
        confirmation: 'Alert: Email services and activity will be disabled until your new domain is authenticated',
        confirmationButton: 'Reset Domain',
        footerText: 'View video instructions',
      },
      label: {
        submitButton: 'Submit',
        domain: 'Domain*',
        toEmail: 'To Email*',
        ccedEmail: 'CCed Email',
        toolToEmail: 'This email recipient will receive the DNS records that need to be added to your domain host provider. (Required)',
        toolDomain: 'Enter the domain you want to send your emails from, such as example.com. (Required)',
        toolCcedEmail: 'This email recipient will be added to the email that is sent with the required DNS records.  (Optional)',
      },
      pendingStatus: {
        pendingTitle: 'Domain Authentication Pending',
        pendingMessage: 'You will be notified via email once the DNS records have been added to your domain host provider.',
      },
      chart: {
        sent: 'Emails Sent',
        opened: 'Emails Opened',
        click: 'Email Clicks',
        days: 'Last 30 Days',
      },
      emailActivity: {
        title: 'Email Activity',
        details: 'Details',
        back: 'Back',
        emailSubject: 'Email Subject:',
        dateSent: 'Date Sent:',
      },
    },
    contact: {
      investmentTitle: 'Investment Stage',
      createContactTitle: 'Create A Contact List',
      savedContactListTitle: 'Saved Contact List',
      newList: 'New List Name',
      save: 'Save List',
    },
    email: {
      form: {
        fromAddress: 'From Email Address',
        fromName: 'From Name',
        replyToAddress: 'Reply to Email Address',
        replyToName: 'Reply to Name',
        cc: 'CCed Email Address',
        bcc: 'Bcc Email Address',
        subject: 'Subject Line',
        body: 'Your Message',
        required: 'Required',
        optional: 'Optional',
        send: 'Send',
        test: 'Test',
        example: 'example@example.com',
        confirmation: 'Are you sure you want to send?',
      },
      additionalContacts: {
        title: 'Additional Contacts',
        add: 'Add Contact',
      },
      index: {
        messageTry: 'Your test email has been sent.',
        messageSend: 'Your email has been sent.',
      },
      messages: {
        duplicates: 'Please Delete Duplicate Emails Address',
        noSelected: 'No Address Selected',
        processing: 'Processing Request',
      },
      toaster: {
        sent: 'Email sent',
        listCreated: 'Contact list created',
        listRemoved: 'Contact list removed',
      },
    },
  },
};
