import qs from 'qs';
import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class AuditLogCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2' };

    that.authorizationHeader = function authorizationHeader() {
      // const _store = operatorStore;
      // that.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = { };
      // localVarHeaderParams.authorization = `Bearer ${that.configuration.apiKey}`;

      return localVarHeaderParams;
    };

    this.getAuditLogs = function getAuditLogs(params) {
      const localVarPath = `${that.configuration.basePath}/audit-logs`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
        params,
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'brackets' }),
      });
    };

    this.getAuditLogOperators = function getOperators() {
      const localVarPath = `${that.configuration.basePath}/audit-logs/operators`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
      });
    };

    this.getAuditLogIssuers = function getIssuers() {
      const localVarPath = `${that.configuration.basePath}/audit-logs/issuers`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
      });
    };

    this.getAuditLogTokens = function getTokens() {
      const localVarPath = `${that.configuration.basePath}/audit-logs/tokens`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
      });
    };
  }
}

export const { getAuditLogs, getAuditLogOperators, getAuditLogIssuers, getAuditLogTokens } = new AuditLogCpApi();
