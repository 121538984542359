import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class OpportunityCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2' };

    that.authorizationHeader = function authorizationHeader() {
      // const _store = operatorStore;
      // that.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = { };
      // localVarHeaderParams.authorization = `Bearer ${that.configuration.apiKey}`;

      return localVarHeaderParams;
    };
    /** ***************************************************************************
     *                           OPPORTUNITIES
     ****************************************************************************** */


    this.createOpportunity = function createOpportunity(issuerId, opportunity, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, opportunity, {
        headers: localVarHeaderParams,
      });
    };
    this.getOpportunityById = function getOpportunityById(issuerId, opportunityId, includeEmptyFields, additionalFields, language, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      const params = {};
      if (includeEmptyFields) {
        params.includeEmptyFields = true;
      }
      if (additionalFields) {
        params.additionalFields = additionalFields;
      }
      if (language) {
        params.language = language;
      }
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
        params,
      });
    };
    this.getLanguages = function getLanguages(issuerId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/languages`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getOpportunities = function getOpportunities(issuerId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getOpportunitiesByToken = function getOpportunities(issuerId, tokenId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities?tokenId=${encodeURIComponent(tokenId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteOpportunity = function deleteOpportunity(issuerId, opportunityId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.publishOpportunity = function publishOpportunity(issuerId, opportunityId, opportunity, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/publish/${encodeURIComponent(opportunityId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, opportunity, {
        headers: localVarHeaderParams,
      });
    };
    this.changeOpportunityOrder = function changeOpportunityOrder(issuerId, opportunityId, opportunity, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/order/${encodeURIComponent(opportunityId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, opportunity, {
        headers: localVarHeaderParams,
      });
    };
    this.updateOpportunity = function updateOpportunity(issuerId, opportunityId, opportunity, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, opportunity, {
        headers: localVarHeaderParams,
      });
    };
    this.createLanguageContent = function createLanguageContent(issuerId, opportunityId, language, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/contents/${language}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.createLanguageSummary = function createLanguageSummary(issuerId, opportunityId, summary, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/summaries`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, summary, {
        headers: localVarHeaderParams,
      });
    };
    this.updateLanguageSummary = function updateLanguageSummary(issuerId, opportunityId, summary, language, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/summaries/${language}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, summary, {
        headers: localVarHeaderParams,
      });
    };
    /** ***************************************************************************
     *                           OPPORTUNITY CONTENT
     ****************************************************************************** */
    this.addOpportunityContent = function addOpportunityContent(issuerId, opportunityId, opportunityContent, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/content`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, opportunityContent, {
        headers: localVarHeaderParams,
      });
    };
    this.updateOpportunityContent = function updateOpportunityContent(issuerId, opportunityId, opportunityContentId, opportunityContent, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/content/${encodeURIComponent(opportunityContentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, opportunityContent, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteOpportunityContent = function deleteOpportunityContent(issuerId, opportunityId, opportunityContentId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/content/${encodeURIComponent(opportunityContentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.getVimeoThumbnail = function getVimeoThumbnail(videoId) {
      return axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
    };
    this.updatePublishContent = function updatePublishContent(issuerId, opportunityId, published, language, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/contents/${language}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, { published }, {
        headers: localVarHeaderParams,
      });
    };
    /** ***************************************************************************
     *                           OPPORTUNITY DOCUMENT
     ****************************************************************************** */
    this.addOpportunityDocument = function addOpportunityDocument(issuerId, opportunityId, opportunityDocument, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.post(localVarPath, opportunityDocument, {
        headers: localVarHeaderParams,
      });
    };
    this.getOpportunityDocument = function getOpportunityDocument(issuerId, opportunityId, opportunityDocumentId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/documents/${encodeURIComponent(opportunityDocumentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
    this.updateOpportunityDocument = function updateOpportunityDocument(issuerId, opportunityId, opportunityDocument, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/documents`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.patch(localVarPath, opportunityDocument, {
        headers: localVarHeaderParams,
      });
    };
    this.deleteOpportunityDocument = function deleteOpportunityDocument(issuerId, opportunityId, opportunityDocumentId, headers) {
      const localVarPath = `${that.configuration.basePath}/issuer/${encodeURIComponent(issuerId)}/opportunities/${encodeURIComponent(opportunityId)}/documents/${encodeURIComponent(opportunityDocumentId)}`;
      const localVarHeaderParams = { ...that.authorizationHeader(), ...headers };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
      });
    };
  }
}

export const {
  createOpportunity,
  getOpportunityById,
  getOpportunities,
  getOpportunitiesByToken,
  deleteOpportunity,
  publishOpportunity,
  updateOpportunity,
  addOpportunityContent,
  updateOpportunityContent,
  deleteOpportunityContent,
  addOpportunityDocument,
  updateOpportunityDocument,
  deleteOpportunityDocument,
  getOpportunityDocument,
  changeOpportunityOrder,
  getVimeoThumbnail,
  getLanguages,
  updatePublishContent,
  createLanguageContent,
  createLanguageSummary,
  updateLanguageSummary,
} = new OpportunityCpApi();
