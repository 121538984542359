<!-- TODO: Deprecated feature self service issuer -->
<template>
  <div
    class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
  >
    <div
      class="ui-bg-overlay bg-dark ui-bg-cover login-bg"
      :style="{
        'background-image': `url(${getImagePath(
          'login-background.jpg',
          'theme'
        )}`
      }"
    />
    <div class="authentication-inner py-5">
      <b-card no-body>
        <div class="authentication-inner py-5">
          <div class="card">
            <div class="card-body">
              <!-- Logo -->
              <div
                class="d-flex justify-content-center align-items-center pb-2 mb-4"
              >
                <div class="ui-w-60">
                  <div class="w-100 position-relative">
                    <img
                      class="d-block w-100"
                      :src="getImagePath('securitize.png', 'logo')"
                      alt="Securitize"
                    >
                  </div>
                </div>
              </div>
              <!-- / Logo -->

              <h5 class="text-center text-muted font-weight-normal mb-4">
                Register a Securitize Account
              </h5>

              <!-- Warning Message -->
              <div class="alert alert-warning my-3">
                <small>For a better experience we suggest using
                  <a
                    href="https://www.google.com/intl/en/chrome/"
                    target="_blank"
                  >Google Chrome.</a></small>
              </div>
              <!-- / Warning Message -->
              <cp-input-container
                ref="cpInputContainer"
              >
                <div class="form-group">
                  <cp-input
                    v-model.trim="operatorName"
                    name="operatorName"
                    validate="required|min:3"
                    label="Operator name"
                    type="text"
                    placeholder="e.g. John Doe"
                    :class="{ 'is-invalid': errorField === 'operator-name' }"
                  />
                </div>
                <div class="form-group">
                  <cp-input
                    id="issuer-name"
                    v-model.trim="issuerName"
                    label="Issuer name"
                    name="issuerName"
                    type="text"
                    validate="required|min:3"
                    placeholder="e.g. Securitize"
                    :class="{ 'is-invalid': errorField === 'issuer-name' }"
                  />
                </div>
                <div class="form-group">
                  <label
                    class="form-label"
                    for="token-name"
                  />
                  <cp-input
                    id="token-name"
                    v-model.trim="issuerTokenName"
                    label="Token name"
                    name="issuerTokenName"
                    type="text"
                    validate="required|min:3"
                    placeholder="e.g. SCT"
                    :class="{ 'is-invalid': errorField === 'token-name' }"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label" />
                  <cp-select
                    id="blockchain"
                    v-model="blockchain"
                    label="Blockchain"
                    name="blockchain"
                    :options="blockchainOptions"
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-google btn-block blue"
                  @click="onConfirm({ operatorName, issuerName, issuerTokenName, blockchain })"
                >
                  <span>
                    <img :src="getImagePath('logo-google.png', 'logo')">
                  </span>
                  REGISTER WITH YOUR GOOGLE-ID
                </button>
              </cp-input-container>
              <!-- Error Message -->
              <div
                v-if="registerError"
                class="alert alert-danger mt-3 mb-0"
              >
                <small>{{ errorMessage }}</small>
              </div>
              <!-- / Error Message -->
            </div>

            <div class="card-footer text-center">
              You already have an account?
              <router-link to="/">
                Login now!
              </router-link>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="initLoader"
      class="background-overlay"
    />
    <Spinner
      v-if="initLoader"
      name="wave"
      color="#04bec4"
      class="cp-button-spinner"
      fade-in="quarter"
    />
  </div>
</template>

<script>
// TODO: Deprecated feature self service issuer
import Spinner from 'vue-spinkit';
import { mapActions } from 'vuex';
// import { performGoogleRegister } from '~/utilities/googleAuth';// TODO: deprecated by ISR2-1413
// import routeNames from '~/utilities/routes'; // TODO: deprecated by ISR2-1413
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import { CpInput } from '~/components/common/standalone-components/inputs';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import cpInputContainer from '~/components/common/cpInputContainer';

export default {
  metaInfo: {
    title: 'registration form',
    name: 'registrationForm',
  },
  components: {
    Spinner,
    CpInput,
    cpInputContainer,
    CpSelect,
  },
  mixins: [staticPathGenerationMixin],
  data() {
    return {
      registerError: false,
      operatorName: '',
      issuerName: '',
      issuerTokenName: '',
      errorMessage: '',
      errorField: '',
      initLoader: false,
      formData: {},
      blockchain: 'ethereum',
    };
  },
  computed: {
    blockchainOptions() {
      return [
        { text: 'Ethereum', value: 'ethereum' },
        { text: 'Tezos', value: 'tezos' },
        { text: 'Algorand', value: 'algorand' },
      ];
    },
  },
  methods: {
    ...mapActions('currentOperator', ['register']),
    ...mapActions('global', ['fetchIssuersList']),
    onConfirm({ ...formData }) {
      this.$refs.cpInputContainer.validateForm().then(() => {
        if (this.$refs.cpInputContainer.isValid()) {
          this.singIn(formData);
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    singIn(formData) {
      this.registerError = false;
      //  TODO: deprecated by ISR2-1413
      // performGoogleRegister()
      //   .then((operator) => {
      //     const { access_token: accessToken } = operator.getAuthResponse();
      //     formData.accessToken = accessToken;
      //     this.initLoader = true;
      //     this.register(formData)
      //       .then((result) => {
      //         if (result instanceof Error) {
      //           this.registerErrorMessage(result.response.data || result.response);
      //           this.initLoader = false;
      //           this.registerError = true;
      //         } else {
      //           this.$router.push(routeNames.generalConfiguration(result.issuerId));
      //         }
      //       })
      //       .catch((error) => {
      //         this.registerErrorMessage(error.response.error);
      //         this.initLoader = false;
      //         this.registerError = true;
      //       });
      //   })
      //   .catch((error) => {
      //     this.initLoader = false;
      //     this.registerErrorMessage(error);
      //   });
    },
    notEmptyValidate(fieldValue) {
      if (!fieldValue) {
        return 'Field is Mandatory';
      }
    },
    registerErrorMessage({ error = '' }) {
      switch (error) {
        case 'OPERATOR_ALREADY_EXISTS':
          this.errorField = 'operator-name';
          error = 'Operator name already exists';
          break;
        case 'ISSUER_NAME_NOT_UNIQUE':
          this.errorField = 'issuer-name';
          error = 'Issuer name already exists';
          break;
        case 'TOKEN_NAME_NOT_UNIQUE':
          this.errorField = 'token-name';
          error = 'Token name already exists';
          break;
        case 'popup_closed_by_user':
          this.registerError = false;
          error = '';
          break;
        default:
          error = 'Something went wrong! please contact customer support for assistance';
      }
      this.errorMessage = error;
    },
  },
};
</script>

<style src="~/vendor/styles/pages/authentication.scss" lang="scss"></style>
<style lang="scss">
.card {
  position: relative;
}
.cp-button-spinner {
  position: absolute;
  top: calc(50% - 20px);
  right: calc(50% - 25px);
}
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000099;
  width: 100vw;
  height: 100%;
}
</style>
