import { sanitize } from 'dompurify';
import {
  tokenConfigurationsApi,
} from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';
import axiosV2 from '~/utilities/axiosV2';

const { getFundraiseTokenConfiguration, updateFundraiseTokenConfiguration } = tokenConfigurationsApi;

export default {
  getFundraiseTokenConfiguration({ commit }, { issuerId, tokenId }) {
    return getFundraiseTokenConfiguration(issuerId, tokenId).then(({ data }) => {
      data.wireTransferInstructions = sanitize(data.wireTransferInstructions);
      commit('SET_FUNDRAISE_CONFIGURATION', data);
    }).catch((err) => {
      this.$log.error('Get fundraise configuration error:', err);
    });
  },

  updateFundraiseTokenConfiguration({ commit }, { body, issuerId, tokenId }) {
    return updateFundraiseTokenConfiguration(issuerId, tokenId, body).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.fundraise.update'), { root: true });
    });
  },

  getIssuerInvestmentRounds(ctx, { params }) {
    const { issuerId, tokenId, q, page, limit, 'order-direction': orderDirection, 'order-field': orderField, isEvergreenRound } = params;
    const url = `/isr/api/v1/issuers/${issuerId}/tokens/${tokenId}/investment-rounds`;
    const filterParams = { q, page, limit, 'order-direction': orderDirection, 'order-field': orderField, 'is-evergreen-round': isEvergreenRound };
    return axiosV2.get(url, { params: filterParams, headers: { 'Content-Type': 'application/json' } })
      .then(data => data)
      .catch((err) => {
        this.$log.error('Investment Rounds get error:', err);
        return err;
      });
  },
};
