import Vue from 'vue';// eslint-disable-line import/no-extraneous-dependencies
import { getCountryName } from '~/utilities/i18n-util';
import { i18n } from '~/i18n';

export default {
  SET_GLOBAL_COUNTRIES: (state, list) => {
    state.countriesIsReady = true;
    state.countriesErrors = [];
    state.countries = list.map(item => ({
      countryCode: item,
      name: getCountryName(item, state.locale),
    }));
  },
  UPDATE_GLOBAL_COUNTRIES: (state, locale) => {
    state.countries = state
      .countries
      .filter(({ stateAndRegionId }) => !stateAndRegionId)
      .map(({ countryCode }) => ({ countryCode, name: getCountryName(countryCode, locale) }));
  },
  ISSUER_MAIN_CURRENCY: (state, { mainCurrency }) => {
    state.issuerMainCurrencyIdentifier = mainCurrency;
  },
  SET_GLOBAL_COUNTRIES_ERROR: (state, err) => {
    state.countriesIsReady = false;
    state.countriesErrors = [];
    state.countriesErrors = (err.response.body.message || '').split(/,/g);
  },
  SET_GLOBAL_CURRENCIES: (state, list) => {
    state.currencies = list;
    state.currenciesErrors = [];
    state.currenciesIsReady = true;
  },
  SET_GLOBAL_STATES_AND_REGIONS: (state, data) => {
    state.statesAndRegions = data;
  },
  SET_GLOBAL_CURRENCIES_ERROR: (state, err) => {
    state.currenciesErrors = [];
    state.currenciesIsReady = false;
    state.currenciesErrors = (err.response.body.message || '').split(/,/g);
  },
  SET_GLOBAL_ISSUERS: (state, list) => {
    state.issuers = list;
  },
  UPDATE_GLOBAL_ISSUER: (state, { issuerId, labels }) => {
    const issuerToUpdate = state.issuers?.find(({ id }) => id === issuerId);
    if (issuerToUpdate) {
      issuerToUpdate.labels = labels;
    }
  },
  SET_GLOBAL_ISSUERS_NAV_LIST: (state, list) => {
    state.issuersNavList = list;
  },
  SET_CURRENT_OPERATOR: (state, user) => {
    state.user = JSON.parse(JSON.stringify(user));
  },
  CALL_TOASTER: (state, message) => {
    Vue.$toast.open({
      message: message || i18n.t('store.toaster.common.success'),
      type: 'success',
      duration: 5000,
    });
  },
  CALL_ERROR_TOASTER: (state, message) => {
    Vue.$toast.open({
      message: message || i18n.t('store.toaster.common.error'),
      type: 'error',
      duration: 5000,
    });
  },
  SET_FILTER_STATE: (state, { name, filterData }) => {
    state.filterState[name] = { ...filterData };
  },
  CLEAR_FILTER_STATE: (state, { name }) => {
    state.filterState[name] = {};
  },
  SET_LOCALE: (state, locale) => {
    state.locale = locale;
  },
};
