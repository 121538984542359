import { affiliatesApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addAffiliate,
  deleteAffiliateById,
  getAffiliateById,
  getAffiliates,
  updateAffiliateById,
} = affiliatesApi;
const affiliatesArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  getIssuerAffiliates({ commit }, { params }) {
    const args = getArgumentsForApi(affiliatesArguments, params);
    return getAffiliates(...args).then((res) => {
      commit('SET_ISSUER_AFFILIATES', res.data);
      return res;
    }).catch((err) => {
      this.$log.error('Issuer Affiliates get error:', err);
      throw err;
    });
  },

  update({ commit }, { issuerId, affiliate: { name, id } }) {
    return updateAffiliateById(issuerId, id, { name }).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliates.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, affiliateId }) {
    return deleteAffiliateById(issuerId, affiliateId).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliates.delete'), { root: true });
    });
  },

  create({ commit }, { issuerId, affiliate }) {
    return addAffiliate(issuerId, affiliate).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAffiliates.create'), { root: true });
    });
  },

  getById({ commit }, { issuerId, affiliateId }) {
    return getAffiliateById(issuerId, affiliateId).then(({ data }) => {
      commit('SET_ISSUER_AFFILIATE', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Affiliate get error:', err);
    });
  },
};
