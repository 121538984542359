<template>
  <b-modal
    id="generalModal"
    ref="generalModal"
    :hide-footer="hideFooter"
    :hide-header="hideHeader"
    :hide-header-close="isLoading"
    :ok-title="okText || $t('common.button.ok')"
    :cancel-title="cancelText || $t('common.button.cancel')"
    :ok-disabled="okDisabled"
    :size="size"
    :scrollable="scrollable"
    ok-variant="primary"
    cancel-variant="default"
    @shown="$emit('shown')"
    @ok.prevent="onConfirm"
    @hide="hideEventHandler('onCancel', $event)"
  >
    <template slot="modal-title">
      <i :class="`ion ion-${titleIcon}`" />
      {{ title }}
    </template>
    <slot
      slot="modal-header"
      name="modal-header"
    />
    <p
      v-if="message"
      class="my-4"
    >
      {{ message }}
    </p>
    <cp-input-container
      ref="cpInputContainer"
      v-model="formData"
      :default-value="defaultValue"
      @edited="onChange"
    >
      <slot />
    </cp-input-container>
  </b-modal>
</template>

<script>
import CpInputContainer from '~/components/common/cpInputContainer';


export default {
  name: 'GeneralModal',
  components: {
    CpInputContainer,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: true,
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    formGroupValue: {
      type: Object,
      default: () => {},
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      isOpen: false,
    };
  },
  watch: {
    // What is formGroup value? its default value?
    formGroupValue(val) {
      this.formData = { ...val };
    },
    defaultValue(val) {
      this.formData = { ...val };
    },
  },
  methods: {
    show() {
      this.isOpen = true;
      this.formData = {};
      this.$refs.generalModal.show();
    },
    hide(forceHide = true) {
      if (forceHide) {
        this.$refs.generalModal.hide();
      }
      window.setTimeout(() => {
        this.isOpen = false;
      }, 300);
    },
    hideEventHandler(eventType, event) {
      if (event.trigger && event.trigger !== 'ok') {
        this.$emit(eventType, event);
        this.hide(false);
      }
    },
    onConfirm() {
      const joinData = { ...this.defaultValue, ...this.formData };
      this.$refs.cpInputContainer.validateForm().then(() => {
        if (this.$refs.cpInputContainer.isValid()) {
          this.$emit('onOk', joinData);
        } else {
          this.$emit('formInvalid', true);
        }
      });
    },
    onChange() {
      this.$emit('onInput', this.formData);
    },
  },
};
</script>
