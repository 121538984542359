export default {
  signup: {
    button: {
      register: {
        local: '登録',
        google: 'Googleで登録',
      },
    },
    input: {
      password: 'パスワード',
    },
    message: {
      error: '問題が発生しました。コンタクトサポートにご連絡ください。',
      expire: '登録リンクの有効期限が切れているか、無効になっています。',
      account: 'アカウントをお持ちですか？{0}',
      loginNow: 'ここからログイン',
    },
    label: {
      requirements: {
        upperCase: '１つの大文字',
        lowerCase: '１つの小文字',
        number: '１つの数字',
        chars: '最低８文字',
      },
    },
  },
};
