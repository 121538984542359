export default {
  distributions: {
    title: 'Distributions',
    label: {
      description: `Generate snapshots of the token holders at specific times to be able to
        generate distribution files to send payouts to investors.`,
      table: {
        name: 'Name / Entity',
        date: 'Date',
        status: 'Status',
      },
    },
    status: {
      distributed: 'distributed',
      none: 'none',
    },
    button: {
      snapshot: {
        create: ' Create Snapshot',
        edit: 'Edit',
        view: 'View',
      },
      csv: {
        download: 'Download',
      },
      distribution: {
        generate: 'Generate Distribution',
        confirm: 'Confirm Distribution',
      },
    },
    snapshotModal: {
      title: {
        common: '{0} Snapshot',
        edit: 'Edit',
        generate: 'Generate',
      },
      input: {
        name: {
          text: 'Name',
          defaultValue: 'Snapshot at {0}',
        },
        dateTime: 'Date & Time',
      },
    },
    generateDistributionModal: {
      title: 'Generate Distribution',
      input: {
        distributedAmount: '{distributionModel} distributed to holders ({currencySymbol})',
        distributionModel: 'Distribution model',
        distributionMethod: 'Distribution Method',
      },
      button: {
        ok: 'Save & Download Distribution',
        loading: 'Loading...',
      },
      option: {
        distributionModel: {
          total: 'Total amount to distribute',
          perToken: 'Amount per Token',
        },
        distributionMethod: {
          bankAccount: 'Bank Account',
          directToWallet: 'Direct To Wallet',
        },
      },
    },
  },
};
