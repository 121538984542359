import { snapshotsApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const { addDistribution, confirmDistribution } = snapshotsApi;

export default {
  postDistribution({ commit }, {
    issuerId, id, snapshotName, body,
  }) {
    return addDistribution(issuerId, id, body)
      .then((res) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshotsDistributions.postDistribution'), { root: true });
        return { snapshotName, res };
      }).catch((err) => {
        this.$log.error('Snapshot update error:', err);
      });
  },
  confirmDistribution({ commit }, { issuerId, id, tokenId }) {
    return confirmDistribution(issuerId, id, { tokenId })
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshotsDistributions.confirmDistribution'), { root: true });
      }).catch((err) => {
        this.$log.error('confirmDistribution confirm error:', err);
      });
  },
};
