import { getArgumentsForApi, privacyControl } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  getPrivacyControlOperators,
  addPrivacyControlOperator,
  deletePrivacyControlOperator,
} = privacyControl;

const privacyControlArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  getPrivacyControlOperators({ commit }, { params }) {
    const args = getArgumentsForApi(privacyControlArguments, params);
    return getPrivacyControlOperators(...args)
      .then((data) => {
        commit('SET_PRIVACY_CONTROL_OPERATORS', data.data);
        return data;
      }).catch((err) => {
        this.$log.error('privacy control operators GET error:', err);
      });
  },

  addPrivacyControlOperator({ commit, rootState }, { issuerId, body }) {
    return addPrivacyControlOperator(issuerId, body)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.privacyControl.success.addOperator'), { root: true });
        const currentOperatorEmail = rootState.currentOperator.email;
        if (data.email === currentOperatorEmail) {
          commit('issuersInfo/SET_CAN_OPERATOR_VIEW_EDIT_INVESTOR_INFO', true, { root: true });
        }
      }).catch((err) => {
        this.$log.error('privacy control operator POST error:', err);
        throw err;
      });
  },

  deletePrivacyControlOperator({ commit }, { issuerId, operatorId }) {
    return deletePrivacyControlOperator(issuerId, operatorId)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.privacyControl.success.deleteOperator'), { root: true });
      });
  },
};
