import { systemTextsApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const { getSystemTexts, updateSystemTextById } = systemTextsApi;
const systemTextsArguments = {
  page: undefined,
  limit: undefined,
  q: undefined,
  orderDirection: undefined,
  orderField: undefined,
};
export default {
  updateSystemTexts({ commit }, { textId, body }) {
    return updateSystemTextById(textId, body)
      .then((res) => {
        commit('global/CALL_TOASTER', 'System Text was edited', { root: true });
        return res.totalItems;
      }).catch((err) => {
        this.$log.error('updateSystemTexts patch error:', err);
      });
  },

  getTexts(ctx, { params }) {
    const args = getArgumentsForApi(systemTextsArguments, params);
    return getSystemTexts(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('System Texts get error:', err);
        return err;
      });
  },
};
