import moment from 'moment';

export const parserDateFromServer = date => moment(date).format('ll');

export const parseDateForServer = (date) => {
  try {
    return new Date(date).toISOString();
  } catch (e) {
    return null;
  }
};

export const parserDate = () => moment().format('MM-DD-YYYY HH-mm');
