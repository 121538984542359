export default {
  home: {
    button: {
      signIn: 'ログイン',
      google: 'Googleでログイン',
    },
    input: {
      email: 'Eメール',
      password: 'パスワード',
    },
    message: {
      error: 'ログインに失敗しました。もう一度試すか、サポートにお問い合わせ下さい。',
      wrongEmailOrPassword: 'Eメールもしくはパスワードが間違っています。',
      loginprocessFailed: 'The login process has failed. Please try again', // TODO: translate jp
      lock: 'このアカウントはロックされました。10分後に再度サインインしてください。',
      noAccount: 'まだアカウントをお持ちではないですか？',
    },
    link: {
      register: 'ここから登録',
    },
  },
};
