export default {
  ISSUER_INFO(state, issuerInfo) {
    state.issuerInfo = issuerInfo;
  },
  PERMISSIONS_PER_ISSUER(state, permissionsPerIssuer) {
    state.permissionsPerIssuer = permissionsPerIssuer;
  },
  SET_CAN_OPERATOR_VIEW_EDIT_INVESTOR_INFO(state, canOperatorViewEditInvestorInfo) {
    state.permissionsPerIssuer.canOperatorViewEditInvestorInfo = canOperatorViewEditInvestorInfo;
  },
  SET_ISSUER_ID(state, issuerId) {
    state.issuerId = issuerId;
  },
  SET_ISSUER_WALLET_MANAGERS(state, managers) {
    state.issuerWalletManagers = managers;
  },
  SET_CURRENT_TOKEN(state, token) {
    state.currentToken = token;
  },
};
