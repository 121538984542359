<template>
  <div class="sidenav-block">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SidenavBlock'
};
</script>
