export default {
  distributionDetail: {
    label: {
      table: {
        name: '投資家',
        email: 'Eメール',
        tokens: 'トークン',
        taxForms: '税務書類',
        payoutMethod: '支払い方法',
      },
    },
    status: {
      wallet: {
        wallet: 'ウォレット',
        bonus: 'ボーナス',
        treasury: 'トレジャリー',
      },
    },
    button: {
      back: 'リストに戻る',
      download: 'ダウンロード',
      downloading: 'Downloading',
      generate: '分配の作成',
    },
    loading: '分配データを読み込み中...',
  },
};
