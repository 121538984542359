import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import WAValidator from 'wallet-address-validator';
import { isValidAddress } from 'algosdk';
import { checkDateInput } from './date-utils';

Vue.use(VeeValidate, {
  errorBagName: 'validationErrors',
  fieldsBagName: 'validationFields',
});

const dictionary = {
  en: {
    messages: {
      numeric: () => 'Must be a positive number or 0',
    },
    attributes: {
      countryCode: 'country',
      taxCountryCode1: 'tax country',
      countryOfBirth: 'country of birth',
      entityType: 'entity type',
      brokerDealerGroupId: 'b/d group',
      issuerIds: 'selected instances',
      issuersData: 'selected instances',
      'disclaimer.issuerAgreementId': 'initial agreement',
      'qualification.issuerAgreementId': 'accredited agreements',
      regionCode: 'region',
      stateCode: 'state',
    },
  },
};

Validator.localize(dictionary);

Validator.extend('sid-email', {
  validate: (value) => {
    // Regex from Yup https://github.com/jquense/yup/blob/master/src/string.ts#L21
    // eslint-disable-next-line
    const rEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    let isValid = rEmail.test(value);
    if (isValid && value.length > 256) {
      isValid = false;
    }
    return isValid;
  },
  getMessage: () => 'Please enter a valid email',
});

Validator.extend('max_list_length', {
  validate: (value, params = []) => {
    const allowedCount = +params[0];
    return value.length <= allowedCount;
  },
  getMessage: () => 'Multiple entries not allowed',
});

Validator.extend('wallet_address', {
  validate: (value, crypto = ['ETH', 'BTC', 'BCH', 'BTG', 'ETC', 'ETZ']) => crypto.find((currency) => {
    if (currency === 'tezos') {
      const regex = RegExp('^(?:tz[123]|KT1)[a-zA-Z0-9]{33}');
      return !!(value.length === 36 && regex.test(value));
    }
    if (currency === 'algorand') {
      return isValidAddress(value);
    }
    if (currency === 'quorum') {
      return WAValidator.validate(value, 'ethereum');
    }
    return WAValidator.validate(value, currency);
  }) || false,
  getMessage: () => 'Invalid Wallet Address.',
});

Validator.extend('cannot_be_selected_without_another_deposit_method', {
  validate: () => false,
  getMessage: () => 'USDC cannot be selected without another deposit method',
});

Validator.extend('larger_than_zero', {
  validate: value => +value > 0,
  getMessage: () => 'Must be larger than 0.',
});

Validator.extend('any_date', {
  validate: value => checkDateInput(value),
  getMessage: () => 'Must be a valid date.',
});
Validator.extend('excluded_value', {
  validate: (value, excludedValue) => !value.includes(excludedValue),
  getMessage: () => 'Value is not not allowed',
});
Validator.extend('is', {
  validate: (value, compareTo) => value === compareTo.toString(),
  getMessage: () => 'Values do not match.',
});

Validator.extend('date_range', {
  validate: (value) => {
    if (value[0] && value[1]) return +new Date(value[1]) > +new Date(value[0]);
    return true;
  },
  getMessage: () => 'Start date must be less than end date.',
});

Validator.extend('tx_hash', {
  validate: (value, crypto = ['ethereum', 'tezos', 'algorand']) => crypto.find((currency) => {
    switch (currency) {
      case 'ethereum':
        return /^0x([A-Fa-f0-9]{64})$/.test(value);
      case 'tezos':
        return /^(?:op|on|oo)[A-Za-z0-9]{49}$/.test(value);
      case 'algorand':
        return /^[A-Z0-9]{52}$/.test(value);
      default:
        return false;
    }
  }) || false,
  getMessage: () => 'Invalid transaction hash.',
});

Validator.extend('phone_code', {
  validate: (value => /^\+[0-9]{1,4}$/.test(value)),
  getMessage: () => 'Invalid phone code',
});

Validator.extend('phone_number', {
  validate: (value => /^[0-9]{5,14}$/.test(value)),
  getMessage: () => 'Invalid phone number',
});

Validator.extend('phone', {
  validate: (value => /^[+]?\d{5,}$/.test(value)),
  getMessage: () => 'Invalid phone number',
});

VeeValidate.Validator.extend('json', {
  getMessage: () => 'Must be a valid JSON',
  validate: (value) => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  },
});

VeeValidate.Validator.extend('domain', {
  validate: (value => /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/.test(value)),
  getMessage: () => 'Must be a valid domain',
});
VeeValidate.Validator.extend('clientConfigName', {
  validate: (value => (!(/[^a-z0-9\-_]/.test(value)))),
  getMessage: () => 'Must be a valid configuration name',
});
VeeValidate.Validator.extend('multiple_email', {
  validate: (value => /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/.test(value)),
  getMessage: () => 'Must contain valid email addresses separated by comma',
});
VeeValidate.Validator.extend('alphanumeric', {
  validate: (value => /^[a-zA-Z0-9]+$/.test(value)),
  getMessage: () => 'Must be a valid alphanumeric value',
});
VeeValidate.Validator.extend('website_url', {
  validate: (value => /^[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,25}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/.test(value)),
  getMessage: () => 'Please enter a valid website URL',
});
