import USStates from '~/utilities/us-states-list';

export default {
  countries: state => state.countries,
  countryByCode: ({ countries }) => code => countries.find(item => item.countryCode === code),
  stateByCode: () => code => USStates[code] || code,
  currencies: state => state.currencies,
  isCurrenciesIsReady: state => state.currenciesIsReady,
  currenciesErrors: state => state.currenciesErrors,
  isCountriesIsReady: state => state.countriesIsReady,
  currencyByIdentifier: ({ currencies }) => identifier => currencies.find(item => item.identifier === String(identifier)),
  countriesErrors: state => state.countriesErrors,
  currencyById: ({ currencies }) => id => currencies.find(item => item.id === Number(id)),
  issuers: state => state.issuers,
  issuersNavList: state => state.issuersNavList,
  issuerById: ({ issuers }) => id => issuers.find(item => item.id === id),
  currentUser: state => state.user,
  getFilterState: ({ filterState }) => name => filterState[name],
  locale: state => state.locale,
  statesAndRegions: state => state.statesAndRegions,
};
