import { brokerDealerGroupsApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const {
  createBrokerDealerGroup,
  disableBrokerDealerGroupById,
  enableBrokerDealerGroupById,
  getBrokerDealerGroupById,
  getBrokerDealerGroups,
  updateBrokerDealerGroup,
} = brokerDealerGroupsApi;
const brokerDealerGroupsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  orderDirection: undefined,
  orderField: undefined,
  q: undefined,
};

export default {
  addBrokerDealerGroup({ commit }, body) {
    return createBrokerDealerGroup(body).then(({ data }) => {
      commit('global/CALL_TOASTER', 'B/D Group is added', { root: true });
      return data;
    });
  },
  updateBrokerDealerGroup({ commit }, { body, brokerDealerGroupId }) {
    return updateBrokerDealerGroup(brokerDealerGroupId, body).then(() => {
      commit('global/CALL_TOASTER', 'B/D Group is updated', { root: true });
    });
  },
  getBrokerDealerGroupById(ctx, brokerDealerGroupId) {
    return getBrokerDealerGroupById(brokerDealerGroupId).then(({ data }) => data);
  },
  activateBrokerDealerGroup({ commit }, brokerDealerGroupId) {
    return enableBrokerDealerGroupById(brokerDealerGroupId, { data: {}, headers: { 'Content-Type': 'application/json' } }).then(() => {
      commit('global/CALL_TOASTER', 'B/D Group is activated', { root: true });
    });
  },
  deactivateBrokerDealerGroup({ commit }, brokerDealerGroupId) {
    return disableBrokerDealerGroupById(brokerDealerGroupId, { data: {}, headers: { 'Content-Type': 'application/json' } }).then(() => {
      commit('global/CALL_TOASTER', 'B/D Group is deactivated', { root: true });
    });
  },
  getBrokerDealerGroups(ctx, { params }) {
    const args = getArgumentsForApi(brokerDealerGroupsArguments, params);
    return getBrokerDealerGroups(...args).then(data => data).catch((err) => {
      this.$log.error('B/D Groups get error:', err);
      return err;
    });
  },
  getBrokerDealerGroupListDropdown({ commit }) {
    const args = getArgumentsForApi(brokerDealerGroupsArguments, { limit: 1000 });
    return getBrokerDealerGroups(...args)
      .then(({ data: { data } }) => commit('SET_BROKER_DEALER_GROUPS_DROPDOWN', data.map(({ id, name }) => ({ value: id, text: name }))))
      .catch(error => this.$log.error('Error get B/D Groups list:', error));
  },
};
