export default {
  issuerAgreements: {
    title: 'Issuer Agreements',
    button: {
      agreements: {
        add: 'Add agreement',
      },
    },
    label: {
      table: {
        name: 'Name',
        title: 'Title',
      },
    },
    // modal
    agreementModal: {
      title: '{0} Agreement',
      input: {
        name: 'Name',
        title: 'Title',
        subtitle: 'Subtitle',
        type: 'Type',
        yesText: 'Yes Text',
        noText: 'No Text',
        documentUrl: 'Document Url',
        checkOptions: 'Check options',
        text: 'Agreement Text',
      },
      status: {
        yes: 'yes',
        yesNo: 'yes_no',
      },
    },
  },
};
