import * as _ from 'lodash';
import {
  configurationsApi,
  documentsApi,
  getArgumentsForApi,
} from '~/utilities/mainApiClient';
import DeploymentsManagementService from '~/utilities/deploymentsManagementService';
import { i18n } from '~/i18n';
import { getRowFields } from '@/pages/_idIssuer/configuration/fundraise-token/manage-token/components/common/helpers';
import ComplianceRulesVars from '../../../../pages/_idIssuer/configuration/fundraise-token/manage-token/components/common/compliance-rules-variables';
import { fromWeiToGWei, fromGWeiToWei } from './constants';
import SignaturesService from '@/utilities/signaturesService';
import {
    createHistoricalTokenValue,
    deleteHistoricalTokenValue,
    getHistoricalTokenValueById,
    getHistoricalTokenValues, updateHistoricalTokenValue,
} from '@/utilities/external-api/apis/historicalTokenValueCpApi';

const {
  disableLifecycleState,
  enableFundraiseState,
  disableFundraiseState,
  enableLifecycleState,
} = configurationsApi;
const {
  addTokenDocument,
  deleteTokenDocumentById,
  getTokenDocumentById,
  getTokenDocuments,
  updateTokenDocumentById,
} = documentsApi;
const documentsArguments = {
  issuerId: undefined,
  tokenId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

function callToaster({ dispatch, commit }, { issuerId, tokenId, message }) {
  dispatch(
    'configuration/getTokenConfiguration',
    { issuerId, tokenId },
    { root: true },
  );
  commit(
    'global/CALL_TOASTER',
    message,
    { root: true },
  );
}
const getSortedCountryCompliances = (data) => {
  const sortedCountryTransactions = _(data).orderBy(['createdAt'], ['desc'])
    .map(tx => (_.has(tx, 'countryCompliance') ? JSON.parse(tx.countryCompliance) : null))
    .value();
    // eslint-disable-next-line prefer-spread
  return _([].concat.apply([], sortedCountryTransactions))
    .uniqBy('countryName')
    .without(null)
    .value();
};
const getSortedComplianceRules = (data) => {
  const sortedRulesTransactions = _(data).orderBy(['createdAt'], ['asc'])
    .map(tx => (_.has(tx, 'complianceRules') ? tx.complianceRules : null))
    .value();
    // eslint-disable-next-line prefer-spread
  const cleanedRulesTransactions = _([].concat.apply([], sortedRulesTransactions))
    .uniq()
    .without(null)
    .value();

  const organized = Object.assign({}, ...cleanedRulesTransactions);
  return getRowFields(organized);
};

export default {
  enableTokenFundraise({ dispatch, commit }, { issuerId, tokenId }) {
    return enableFundraiseState(issuerId, tokenId, { data: {}, headers: { 'Content-Type': 'application/json' } })
      .then(() => {
        const message = i18n.t('store.toaster.configToken.updateTokenFundraise');
        callToaster({ dispatch, commit }, { issuerId, tokenId, message });
      })
      .catch((err) => {
        this.$log.error('Update configuration token fundraise error:', err);
      });
  },
  disableTokenFundraise({ dispatch, commit }, { issuerId, tokenId }) {
    return disableFundraiseState(issuerId, tokenId)
      .then(() => {
        const message = i18n.t('store.toaster.configToken.updateTokenFundraise');
        callToaster({ dispatch, commit }, { issuerId, tokenId, message });
      })
      .catch((err) => {
        this.$log.error('Disable configuration token fundraise error:', err);
      });
  },
  enableTokenLifecycle({ dispatch, commit }, { issuerId, tokenId }) {
    return enableLifecycleState(issuerId, tokenId, { data: {}, headers: { 'Content-Type': 'application/json' } })
      .then(() => {
        const message = i18n.t('store.toaster.configToken.updateTokenLifecycle');
        callToaster({ dispatch, commit }, { issuerId, tokenId, message });
      })
      .catch((err) => {
        this.$log.error('Update configuration token lifecycle error:', err);
      });
  },
  disableTokenLifecycle({ dispatch, commit }, { issuerId, tokenId }) {
    return disableLifecycleState(issuerId, tokenId)
      .then(() => {
        const message = i18n.t('store.toaster.configToken.updateTokenLifecycle');
        callToaster({ dispatch, commit }, { issuerId, tokenId, message });
      })
      .catch((err) => {
        this.$log.error('Disable configuration token lifecycle error:', err);
      });
  },
  getDocuments(ctx, { params }) {
    const args = getArgumentsForApi(documentsArguments, params);
    return getTokenDocuments(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Documents get error:', err);
        return err;
      });
  },
  getTokenDeploymentBasicInfo({ commit }, { issuerId, tokenId }) {
    return DeploymentsManagementService.getIssuerDeploymentBasicInfo(issuerId, tokenId)
      .then((data) => {
        // clean previous status
        commit('SET_DEPLOYMENT_EXISTS', false);
        commit('SET_DEPLOYMENT_TOKEN', { status: 'initial' });
        if (data) {
          // Set here the token feature flags with the following code snippet

          // clientConfigs.forEach((clientConfig) => {
            // if (clientConfig.name === 'show-hsm-syncer' && clientConfig.value) {
            //   data.showHSMSyncer = clientConfig.value === 'true';
            // }
          // });

          // (Do not forget to set the feature flag in the state initialization as well)
          commit('SET_DEPLOYMENT_EXISTS', true);
        }
        const existingOrNewTokenDeployment = data || {
          status: 'initial',
        };
        commit('SET_DEPLOYMENT_TOKEN', existingOrNewTokenDeployment);
        return existingOrNewTokenDeployment;
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          commit('SET_DEPLOYMENT_TOKEN', { status: 'initial' });
          return { status: 'initial' };
        }
        this.$log.error('Get Deployments error:', err);
        return err;
      });
  },

  getTokenDeployment({ commit }, { issuerId, tokenId }) {
    // let clientConfigs = [];
    // DeploymentsManagementService.getIssuerClientConfigurations(issuerId).then((data) => {
    //   clientConfigs = data.data;
    // });
    return DeploymentsManagementService.getIssuerDeployment(issuerId, tokenId)
      .then((data) => {
        // clean previous status
        commit('SET_DEPLOYMENT_EXISTS', false);
        commit('SET_DEPLOYMENT_TOKEN', { status: 'initial' });
        if (data) {
          // Set here the token feature flags with the following code snippet

          // clientConfigs.forEach((clientConfig) => {
            // if (clientConfig.name === 'show-hsm-syncer' && clientConfig.value) {
            //   data.showHSMSyncer = clientConfig.value === 'true';
            // }
          // });

          // (Do not forget to set the feature flag in the state initialization as well)
          commit('SET_DEPLOYMENT_EXISTS', true);
        }
        const existingOrNewTokenDeployment = data || {
          status: 'initial',
        };
        commit('SET_DEPLOYMENT_TOKEN', existingOrNewTokenDeployment);
        return existingOrNewTokenDeployment;
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          commit('SET_DEPLOYMENT_TOKEN', { status: 'initial' });
          return { status: 'initial' };
        }
        this.$log.error('Get Deployments error:', err);
        return err;
      });
  },
  addTokenDeployment({ commit }, { issuerId, params }) {
    return DeploymentsManagementService.addDeploymentForIssuer(issuerId, params)
      .then((deploymentId) => {
        commit('SET_DEPLOYMENT_EXISTS', true);
        commit('SET_DEPLOYMENT_ID', deploymentId);
        commit('SET_DEPLOYMENT_DESCRIPTION', params);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.tokenDescriptionAdded'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Add Deployment error:', err);
        throw err;
      });
  },
  updateTokenDeployment({ commit }, { tokenId, params }) {
    return DeploymentsManagementService.updateDeploymentDescription(
      tokenId,
      params,
    )
      .then(() => {
        commit('SET_DEPLOYMENT_DESCRIPTION', params);
        commit(
          'global/CALL_TOASTER',
          i18n.t(
            'store.toaster.configToken.manageToken.tokenDescriptionUpdated',
          ),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Update Deployment error:', err);
        throw err;
      });
  },
  updateComplianceType({ commit }, { deploymentId, complianceConfigParams }) {
    return DeploymentsManagementService.updateComplianceType(deploymentId, complianceConfigParams)
      .then(() => {
        commit('SET_DEPLOYMENT_COMPLIANCE_TYPE', complianceConfigParams.complianceType);
      })
      .catch((err) => {
        this.$log.error('Update complianceType error', err);
        throw err;
      });
  },
  addComplianceConfiguration({ commit }, { deploymentId, complianceConfigParams }) {
    return DeploymentsManagementService.addComplianceConfiguration(
      deploymentId,
      complianceConfigParams,
    )
      .then(() => {
        commit('SET_DEPLOYMENT_COMPLIANCE', complianceConfigParams.complianceRules);
        commit('SET_COUNTRIES_COMPLIANCE', complianceConfigParams.countriesComplianceStatuses);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.tokenComplianceAdded'),
          { root: true },
        );
      }).catch((err) => {
        this.$log.error('Add Compliance error:', err);
        throw err;
      });
  },
  updateComplianceConfiguration({ commit }, { deploymentId, complianceConfigParams }) {
    return DeploymentsManagementService.updateComplianceConfiguration(
      deploymentId,
      complianceConfigParams,
    )
      .then(() => {
        commit('SET_DEPLOYMENT_COMPLIANCE', complianceConfigParams.complianceRules);
        commit('SET_COUNTRIES_COMPLIANCE', complianceConfigParams.countriesComplianceStatuses);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.tokenComplianceAdded'),
          { root: true },
        );
      }).catch((err) => {
        this.$log.error('Update Compliance error:', err);
        throw err;
      });
  },
  addPermissions({ commit }, { deploymentId, params }) {
    return DeploymentsManagementService.addPermissions(deploymentId, params)
      .then(() => {
        commit('SET_ROLES', params.roles);
        commit('SET_OWNERS', {
            tokenOwner: params.owners.tokenOwner || params.owners.masterWalletAddress,
            walletRegistrarOwner: params.hsmUsed ? '' : params.owners.walletRegistrarOwner || params.owners.registrarOwnerAddress,
            walletRegistrarPrivateKey: params.hsmUsed ? '' : params.owners.walletRegistrarPrivateKey || params.owners.registrarOwnerPk,
            omnibusTBEAddress: params.owners.omnibusTBEAddress,
            redemptionAddress: params.owners.redemptionAddress,
        });
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.tokenPermissionsAdded'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Add Permissions error:', err);
        throw err;
      });
  },
  updatePermissions({ commit, state }, { deploymentId, params }) {
    const oldRoles = state.deploymentToken.roles;
    oldRoles.forEach((oldRole) => {
      const paramRoleInd = params.roles
        .map(r => r.address)
        .indexOf(oldRole.address);
      if (paramRoleInd === -1) {
        params.roles.push({ ...oldRole, role: 'none' });
      } else {
        params.roles.splice(paramRoleInd, 1);
      }
    });
    if (params.roles.length === 0) {
      return;
    }
    return DeploymentsManagementService.updatePermissions(deploymentId, params)
      .then(() => {
        commit('SET_ROLES', params.roles);
        commit(
          'global/CALL_TOASTER',
          i18n.t(
            'store.toaster.configToken.manageToken.tokenPermissionsUpdated',
          ),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Update Permissions error:', err);
        throw err;
      });
  },
  deployToken({ commit }, { deploymentId, gasPriceInGwei }) {
    const gasPrice = fromGWeiToWei(gasPriceInGwei);
    return DeploymentsManagementService.deployToken(deploymentId, gasPrice)
      .then(() => {
        commit('SET_DEPLOYMENT_STATUS', 'pending');
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.deployingToken'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Deploy Token error:', err);
        throw err;
      });
  },
  retryDeploy({ commit }, deploymentId) {
    return DeploymentsManagementService.retryDeploy(deploymentId)
      .then(() => {
        commit('SET_DEPLOYMENT_STATUS', 'pending');
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.configToken.manageToken.deployingToken'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Deploy Token error:', err);
        throw err;
      });
  },
  getDeploymentGasCostEstimationInGwei({ state }, deploymentId) {
    if (state.deploymentToken.status !== 'initial' && state.deploymentToken.status !== 'failure') {
      return {
        gasPriceInGwei: '0',
        estimatedDeploymentGas: '0',
      };
    }
    return DeploymentsManagementService.getDeploymentGasCostEstimation(deploymentId, state.deploymentToken.complianceType)
      .then((result) => {
        const deploymentGasCostEstimation = {
          gasPriceInGwei: fromWeiToGWei(result.gasPrice),
          estimatedDeploymentGas: result.estimatedDeploymentGas,
        };
        return deploymentGasCostEstimation;
      })
      .catch((err) => {
        this.$log.error('Could not get deployment gas cost: ', err);
        throw err;
      });
  },

  getDeploymentTransactions({ commit }, deploymentId) {
    return DeploymentsManagementService.getTransactions(deploymentId)
      .then((res) => {
        const complianceRules = res.data.filter(t => 'complianceRules'.includes(t.type)).map(value => ({ createdAt: value.createdAt, blockNumber: value.blockNumber, complianceRules: value.data, status: value.status }));
        const countryCompliance = res.data.filter(t => 'countryComplianceStatus'.includes(t.type)).map(value => ({ createdAt: value.createdAt, blockNumber: value.blockNumber, countryCompliance: value.data, status: value.status }));

        const complianceRulesMap = _.groupBy(complianceRules, 'createdAt');
        const countryComplianceMap = _.groupBy(countryCompliance, 'createdAt');

        // merge countries to json single property
        const compliance = Object.keys(countryComplianceMap).map(key => countryComplianceMap[key].reduce((rules, rule) => {
          rules = ({ ...rule, countryCompliance: JSON.stringify((countryComplianceMap[key]).map(x => x.countryCompliance), null, ' ') });
          return rules;
        }, []));

        // grouping
        const countriesComplianceGrouped = _.groupBy(compliance, 'createdAt');

        // merging both objects
        const merged = _.merge(complianceRulesMap, countriesComplianceGrouped);

        const sortedTransactions = Object.keys(merged).map(key => merged[key]).flat();
        const transactionsOrderedByDate = _.orderBy(sortedTransactions, ['createdAt'], ['desc']);


        const lastTransaction = _.maxBy(transactionsOrderedByDate, 'createdAt');
        let isLocked;
        if (lastTransaction) {
          isLocked = lastTransaction && ['pending', 'sent'].includes(lastTransaction.status);
        }
        transactionsOrderedByDate.map((tx) => {
          if (['success'].includes(tx.status)) {
              tx.status = 'overriden';
          }
          return tx;
        });
        const pendingTransactions = transactionsOrderedByDate.filter(tx => tx.status === 'pending');
        const overriddenTransactions = transactionsOrderedByDate.filter(tx => tx.status === 'overriden');
        const initialDeploymentTransactions = transactionsOrderedByDate.filter(tx => tx.status === 'initialDeployment');
        const overriddenOrderedTransactions = _.orderBy(overriddenTransactions, ['blockNumber'], ['desc']);
        if (overriddenOrderedTransactions?.length) {
            overriddenOrderedTransactions[0].status = 'success';
        }
        const transactionsHistory = pendingTransactions
            .concat(overriddenOrderedTransactions)
            .concat(initialDeploymentTransactions);
        commit('SET_DEPLOYMENT_TRANSACTIONS', transactionsHistory);
        commit('SET_DEPLOYMENT_LOCK', isLocked);
      })
      .catch((err) => {
        this.$log.error('Could not retrieve compliance history', err);
      });
  },
  getLatestPendingTransaction({ getters }) {
    const { data } = getters.getTransactions;
    const { isLocked } = getters.getDeploymentLockStatus;
    const countriesCompliance = getSortedCountryCompliances(data);
    const complianceRules = getSortedComplianceRules(data);

    return { countriesCompliance, complianceRules, isLocked };
  },
  getComplianceHistory({ getters }) {
    return { ...getters.getTransactions };
  },
  getComplianceCounters({ state }, { params }) {
    if (state.deploymentToken.status !== 'success') {
      return { data: [] };
    }
    return DeploymentsManagementService.getComplianceCounters(params.deploymentId)
      .then((res) => {
        const complianceCounters = res.data;
        return { data: [complianceCounters] };
      })
      .catch((err) => {
        this.$log.error('Could not retrieve compliance holders counters', err);
      });
  },
  getCountriesSnapshot({ getters }, { params }) {
    const transactionDate = params.createdAt;
    const { data } = { ...getters.getTransactions };

    const transactions = _(data).map((tx) => {
      if (transactionDate >= tx.createdAt) return tx;
      return null;
    })
      .without(null)
      .value();

    const countriesComplianceStatuses = getSortedCountryCompliances(transactions);
    const rowFields = getRowFields({ countriesCompliance: { value: JSON.stringify(countriesComplianceStatuses, null, ' ') } });

    return { data: rowFields, totalItems: rowFields.length };
  },
  getRulesSnapshot({ getters }, { params }) {
    const transactionDate = params.createdAt;
    const { data } = { ...getters.getTransactions };

    const transactions = _(data).map((tx) => {
      if (transactionDate >= tx.createdAt) return tx;
      return null;
    })
      .without(null)
      .value();

    const sortedComplianceRules = getSortedComplianceRules(transactions);
    const complianceRules = _.sortBy(getRowFields(sortedComplianceRules), row => ComplianceRulesVars.indexOf(row.variable));

    return { data: complianceRules, totalItems: complianceRules.length };
  },
  updateTokenDocument(
    { commit },
    { issuerId, tokenId, data: { id, ...docData } },
  ) {
    return updateTokenDocumentById(issuerId, tokenId, id, docData).then(() => {
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.issuerPlatformDocuments.update'),
        { root: true },
      );
    });
  },
  deleteTokenDocument({ commit }, { issuerId, tokenId, id }) {
    return deleteTokenDocumentById(issuerId, tokenId, id).then(() => {
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.issuerPlatformDocuments.delete'),
        { root: true },
      );
    });
  },

  createTokenDocument({ commit }, { issuerId, tokenId, data }) {
    return addTokenDocument(issuerId, tokenId, data).then(() => {
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.issuerPlatformDocuments.create'),
        { root: true },
      );
    });
  },
  getTokenDocument(ctx, { issuerId, tokenId, id }) {
    return getTokenDocumentById(issuerId, tokenId, id)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Issuer Platform Document get error:', err);
      });
  },
    // Historical Token Values
    getHistoricalTokenValues(ctx, { params }) {
        return getHistoricalTokenValues({ ...params })
            .then(data => data)
            .catch((err) => {
                this.$log.error('Issuer Historical Token Values get list error:', err);
                return err;
            });
    },
    getHistoricalTokenValue(ctx, { issuerId, tokenId, id }) {
        return getHistoricalTokenValueById({ issuerId, tokenId, id })
            .then(({ data }) => data)
            .catch((err) => {
                this.$log.error('Issuer Historical Token Value get by id error:', err);
            });
    },
    deleteHistoricalTokenValue(ctx, { issuerId, tokenId, id }) {
        return deleteHistoricalTokenValue({ issuerId, tokenId, id })
            .then(({ data }) => data)
            .catch((err) => {
                this.$log.error('Issuer Historical Token Value delete error:', err);
            });
    },
    createHistoricalTokenValue({ commit }, { issuerId, tokenId, tokenValue, tokenValueDate }) {
        return createHistoricalTokenValue({ issuerId, tokenId, tokenValue, tokenValueDate }).then(() => {
            commit(
                'global/CALL_TOASTER',
                i18n.t('store.toaster.configToken.historicalTokenValue.created'),
                { root: true },
            );
        });
    },
    updateHistoricalTokenValue({ commit }, { issuerId, tokenId, id, tokenValue, tokenValueDate }) {
        return updateHistoricalTokenValue({ issuerId, tokenId, id, tokenValue, tokenValueDate }).then(() => {
            commit(
                'global/CALL_TOASTER',
                i18n.t('store.toaster.configToken.historicalTokenValue.updated'),
                { root: true },
            );
        });
    },
  getWalletRegistrarOwnerBalance({ commit }, { deploymentId, address }) {
    return DeploymentsManagementService.getWalletRegistrarOwnerBalance(deploymentId, address)
      .then(({ data }) => {
        commit('SET_DEPLOYMENT_REGISTRAR_WALLET_BALANCE', { address, balance: data.balance });
      })
      .catch((err) => {
        this.$log.error('Error fetching registrar owner balance', err);
      });
  },
  getAvailableNetworks({ commit }) {
    return DeploymentsManagementService.getAvailableNetworks()
      .then(({ data }) => {
        commit('SET_DEPLOYMENT_AVAILABLE_NETWORKS', data);
      }).catch((err) => {
        this.$log.error('Error retrieving available networks', err);
      });
  },
  addMultiSigWallet({ commit }, { deploymentId, wallet }) {
    if (wallet.id === '') {
      return DeploymentsManagementService.addMultiSigWallet(deploymentId, { ...wallet }).then(({ data }) => {
        commit('global/CALL_TOASTER',
          i18n.t('store.toaster.multiSigWallet.create'), { root: true });
        return data.id;
      });
    }

    return DeploymentsManagementService.updateMultiSigWallet(deploymentId, { ...wallet }).then(({ data }) => {
      commit('global/CALL_TOASTER',
        i18n.t('store.toaster.multiSigWallet.update'), { root: true });
      return data.id;
    });
  },
  getAllMultiSigWallets({ commit }, { deploymentId }) {
    return DeploymentsManagementService.getAllMultiSigWallets(deploymentId).then(({ data }) => {
      commit('SET_DEPLOYMENT_MULTISIG_WALLETS', data);
    });
  },
  postDeploymentMultiSigWallets({ commit }, { deploymentId }) {
    return DeploymentsManagementService.postDeployMultiSigWallets(deploymentId).then(() => {
      commit('global/CALL_TOASTER',
        i18n.t('store.toaster.multiSigWallet.postDeploy'), { root: true });
    });
  },
  sendTransactionHash({ commit }, { deploymentId, signatureTxId, transactionProviderId }) {
    return SignaturesService.sendTransactionHash(deploymentId, signatureTxId, transactionProviderId).then(() => {
      commit('global/CALL_TOASTER',
        i18n.t('store.toaster.signatures.transactionSent'), { root: true });
    });
  },
  updateBulkDsProtocolTransactionStatus({ commit }, { issuerId, tokenId, bulkDsProtocolTransactionId, body }) {
        return SignaturesService.updateBulkDsProtocolTransactionStatus(issuerId, tokenId, bulkDsProtocolTransactionId, body).then(() => {
            commit('global/CALL_TOASTER',
                i18n.t('store.toaster.signatures.transactionSent'), { root: true });
        });
  },

  setSubmitButtonDisabled({ commit }, value) {
    commit('SET_SUBMITBUTTONDISABLED', value);
  },
  setProcessingActivate({ commit }, value) {
    commit('SET_PROCESSINGACTIVATE', value);
  },
};
