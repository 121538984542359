export default [
  {
    /* "advanced-affiliates" */
    path: 'issuer-affiliates',
    name: 'issuerAffiliates',
    component: () => import('../pages/_idIssuer/advanced/issuer-affiliates'),
  }, {
    /* "advanced-affiliate-campaigns" */
    path: 'issuer-affiliate-campaigns',
    name: 'issuerAffiliateCampaigns',
    component: () => import('../pages/_idIssuer/advanced/issuer-affiliate-campaigns'),
  }, {
    /* webpackChunkName: "advanced-agreements" */
    path: 'issuer-agreements',
    name: 'issuerAgreements',
    component: () => import('../pages/_idIssuer/advanced/issuer-agreements'),
  }, {
    /* webpackChunkName: "advanced-bank-accounts" */
    path: 'issuer-bank-accounts',
    name: 'issuerBank',
    component: () => import('../pages/_idIssuer/advanced/issuer-bank-accounts'),
  }, {
    /* webpackChunkName: "advanced-configuration" */
    path: 'issuer-configuration-variables',
    name: 'issuerConfiguration',
    component: () => import('../pages/_idIssuer/advanced/issuer-configuration-variables'),
  }, {
    /* webpackChunkName: "advanced-currencies" */
    path: 'issuer-currencies',
    name: 'issuerCurrencies',
    component: () => import('../pages/_idIssuer/advanced/issuer-currencies'),
  }, {
    /* webpackChunkName: "advanced-email-templates" */
    path: 'issuer-email-templates',
    name: 'issuerEmailTemplates',
    component: () => import('../pages/_idIssuer/advanced/issuer-email-templates'),
  }, {
    /* webpackChunkName: "advanced-platform-documents" */
    path: 'issuer-platform-documents',
    name: 'issuerPlatformDocuments',
    component: () => import('../pages/_idIssuer/advanced/issuer-platform-documents'),
  }, {
    /* webpackChunkName: "advanced-prerendered-addresses" */
    path: 'issuer-prerendered-addresses',
    name: 'issuerPrerenderedAddresses',
    component: () => import('../pages/_idIssuer/advanced/issuer-prerendered-addresses'),
  }, {
    /* webpackChunkName: "advanced-questions" */
    path: 'issuer-questions',
    name: 'issuerQuestions',
    component: () => import('../pages/_idIssuer/advanced/issuer-questions'),
  }, {
    /* webpackChunkName: "advanced-texts" */
    path: 'issuer-texts',
    name: 'issuerTexts',
    component: () => import('../pages/_idIssuer/advanced/issuer-texts'),
  },
];
