import axios from 'axios';
import Vue from 'vue';
import { sentenceCase } from 'change-case';

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.defaults.baseURL = process.env.VUE_APP_SERVER_PROXY_NEW_GW;

axiosInstance.interceptors.response.use(
  res => res,
  (err) => {
    Vue.$log.error(err);
    const hideRegisterSyncSecIdInvestorsServerError = err.config.method === 'post'
        && err.config.url.includes('register-sec-id-investors')
        && err.response.status === 400;
      const hideBulkRunIssuanceError = err.config.method === 'post'
          && err.config.url.includes('run-issuance')
          && (err.response.status === 408 || err.response.status === 504);

    const hideDeploymentServerError = ((err.config.method === 'get')
        && err.config.url.includes('issuers') && err.config.url.includes('deployments')
        && (err.response.data.code === 'RESOURCE_NOT_FOUND'));

    // tmp(hhh) crutch to avoid broken details
    const hideServerError = (err.config.method === 'get') && (
      (
        err.config.url.includes('investors') && err.config.url.includes('document')
      )
          || (
            err.config.url.includes('ethereum-transactions') && err.config.url.includes('sign')
          )
          || (
            err.config.url.includes('investors') && err.config.url.includes('payout-wallet')
          ));

    let hideToast = hideServerError;
    if (!err.response) {
      Vue.$toast.open({
        message: 'Failed to receive response from the server.',
        type: 'error',
        duration: 5000,
      });
      err.response = {};
      throw err;
    }
    if (err.response.status >= 500 && !hideServerError) {
      // error({ statusCode: 500, message: err.response.data.message })
    }

    // Because we are unable to hide error toast per endpoint example in /oauth endpoint handelError()
    const { data: resData } = err.response;
    if (resData && resData.data && resData.data.hideToast) hideToast = true;


    if (err.response.status >= 400
        && !hideToast
        && err.response.data.error !== 'NO_AUTHORIZATION_HEADER'
        && !hideDeploymentServerError
        && !hideBulkRunIssuanceError
        && !hideRegisterSyncSecIdInvestorsServerError) {
    const message = sentenceCase(err.response.data.message) === 'Issuer not found'
          ? 'Access denied or Issuer not found'
          : sentenceCase(err.response.data.message);

      Vue.$toast.open({
        message,
        type: 'error',
        duration: 5000,
      });
    }

    if (err.response.status !== 0) {
      throw err;
    }
  },
);


export default axiosInstance;
