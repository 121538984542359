<template>
  <div class="d-flex mb-4">
    <b-input
      v-for="(item, index) in digits"
      :key="index"
      ref="digits"
      v-model="digits[index]"
      class="text-center py-4 mx-1"
      type="text"
      :autofocus="focusIndex===index"
      :formatter="formatter"
      @focus="onFocus(index)"
      @paste="onPaste"
    />
  </div>
</template>

<script>
export default {
  name: 'DigitCodeInputs',
  data() {
    return {
      isPaste: false,
      focusIndex: NaN,
      pasteFlag: false,
      digits: ['', '', '', '', '', ''],
    };
  },
  computed: {},
  watch: {
    digits(val) {
      this.$emit('input', val.join(''));
    },
  },
  mounted() {
    this.focusIndex = 0;
  },
  methods: {
    formatter(value) {
      if (this.isPaste || value.length > 2) {
        this.isPaste = false;
        this.$refs.digits[this.focusIndex].blur();
        return this.digits[this.focusIndex];
      }

      if (!value || !/\d/.test(value)) {
        return '';
      }

      if (this.focusIndex < 5) {
        this.$refs.digits[this.focusIndex + 1].focus();
      } else {
        this.$refs.digits[5].blur();
        this.focusIndex = null;
      }

      return String(value).slice(-1);
    },
    onFocus(index) {
      this.focusIndex = index;
    },
    onPaste({ clipboardData }) {
      this.isPaste = true;
      const values = clipboardData.getData('Text').split('').slice(0, 6);
      const formattedValues = values.map(value => (/\d/.test(value) ? value : ''));
      for (let i = 0; i < Math.min(formattedValues.length, this.digits.length); i += 1) {
        this.digits[i] = formattedValues[i];
      }
    },
  },
};
</script>
