import moment from 'moment';

const supportedFormats = [
  'M D YYYY',
  'M/D/YYYY',
  'M-D-YYYY',
  'M.D.YYYY',
  'YYYY-MM-DD',
  'll',
  'MMM D, YYYY',
  'MMM D, YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm',
  'YYYY-MM-DD HH:mm:ss',
  moment.ISO_8601,
];

export const checkDateInput = date => moment(date, supportedFormats, true).isValid();

export const formatDate = (date, format = 'YYYY-MM-DD HH:mm') => moment(date, supportedFormats, true).format(format);

export const formatDatePayout = (date, format = 'DD MMM YYYY') => moment(date, supportedFormats, true).format(format);

export const formatDateUTC = (date, format = 'YYYY-MM-DD HH:mm') => moment(date, supportedFormats, true).utc().format(format);

export const isBefore = (date, beforeDate) => moment(beforeDate, supportedFormats, true).isBefore(moment(date, supportedFormats, true).utc());

export const isAfter = (date, afterDate) => moment(afterDate, supportedFormats, true).isAfter(moment(date, supportedFormats, true).utc());
