import { getLabels, createLabel, deleteLabel, getIssuerLabels, updateIssuerLabels } from '../../../utilities/external-api/apis/issuerLabelsCpApi';

export default {
  fetchIssuerLabels({ commit }, startsWith) {
    return getLabels(startsWith)
      .then(({ data }) => {
        commit('SET_LABELS', data);
      })
      .catch(error => this.$log.error('Fetch labels error:', error));
  },
  // to be removed in next iteration, will be present in issuer
  fetchLabelsForIssuer({ commit }, issuerId) {
    return getIssuerLabels(issuerId)
    .then(({ data }) => {
      commit('SET_ISSUER_LABELS', data);
    })
    .catch(error => this.$log.error('Fetch labels error:', error));
  },
  createLabelForIssuer({ dispatch }, body) {
    return createLabel(body)
      .then(() => {
        dispatch('fetchIssuerLabels');
        dispatch('fetchLabelsForIssuer', body.issuerId);
      })
      .catch((error) => {
        this.$log.error('Fetch labels error:', error);
        throw error;
      });
  },
  deleteLabel({ commit }, body) {
    return deleteLabel(body.label)
      .then(() => {
        commit('DELETE_LABEL', body.label);
        commit('DELETE_ISSUER_LABEL', body.label);
      })
      .catch(error => this.$log.error('Fetch labels error:', error));
  },
  updateIssuerLabels({ commit }, updateDto) {
    return updateIssuerLabels(updateDto.issuerId, updateDto.body)
      .then(({ data }) => {
        commit('SET_ISSUER_LABELS', data);
        return data;
      })
      .catch(error => this.$log.error('Fetch labels error:', error));
  },
};
