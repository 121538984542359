<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Zendesk',
  computed: {
    ...mapGetters('currentOperator', ['operatorData']),
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapGetters('global', ['locale']),
    identifiedUser() {
      const { name, email } = this.operatorData;
      return {
        name,
        email,
        organization: this.currentIssuer.name,
      };
    },
  },
  watch: {
    locale: {
      immediate: true,
      handler() {
        window.zE('webWidget', 'setLocale', this.locale);
      },
    },
    identifiedUser: {
      immediate: true,
      handler() {
        const { name, email, organization } = this.identifiedUser;
        window.zE('webWidget', 'identify', ({ name, email, organization }));
        window.zE('webWidget', 'prefill', {
          name: {
            value: name,
            readOnly: true,
          },
          email: {
            value: email,
            readOnly: true,
          },
        });
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            contactForm: {
              fields: [
                { id: 360046084854, prefill: { '*': organization } },
              ],
            },
          },
        });
      },
    },
  },
  mounted() {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: {
          horizontal: '-5px',
          vertical: '0px',
        },
      },
    });
    window.zE('webWidget', 'show');
  },
  beforeDestroy() {
    window.zE('webWidget', 'hide');
  },
  render: () => null,
};
</script>
