export default {
  dashboard: {
    chart: {
      title: {
        countries: 'Countries',
        type: 'Type',
      },
      legend: {
        pledged: 'Pledged',
        funded: 'Funded',
        registered: 'Registered',
        authorized: 'Authorized',
        rest: 'Rest',
      },
    },
    activities: {
      title: 'Activity',
      label: {
        newInvestorRegistered: 'New investor registered:',
        newInvestorFrom: 'New investor from {from} : {investor}',
        openFinance: 'OpenFinance',
        fundsReceivedFrom: 'Funds received from {from} : {amount}',
        subscriptionSignedBy: 'Subscription signed by {by} : {amount}',
        newSignatureRequired: {
          path: 'New {0}',
          for: 'Signature required',
        },
        walletRegisteredBy: 'Wallet registered by {0}',
        investorRequiringManualReview: 'Investor requiring manual review:',
      },
      button: {
        showMore: 'SHOW MORE',
      },
    },
    fundraiseChartCard: {
      title: 'Fundraise',
      label: {
        status: 'Status',
        amountPledged: 'Amount Pledged',
        amountRaised: 'Amount Raised',
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
      },
      loading: 'Loading Fundraising Data...',
    },
    holdersChartCard: {
      title: 'Holders',
      label: {
        usHolders: 'US holders',
        euHolders: 'EU holders',
        worldwideHolders: 'Worldwide Holders',
        maximumStakes: 'Top 3 maximum stakes',
        tokens: 'Tokens',
      },
      loading: 'Loading Holders Data...',
    },
    onboardingChartCard: {
      title: 'Onboarding',
      label: {
        totalRegistered: 'Total Registered',
        totalPledged: 'Total Pledged',
        totalFunded: 'Total Funded',
      },
      loading: 'Loading Onboarding Data...',
    },
  },
};
