import { currenciesApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const { getCurrencies, updateCurrencyById } = currenciesApi;
const currenciesArguments = {
  issuerId: undefined,
  q: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ dispatch, commit }, { issuerId, currency }) {
    const { id, active } = currency;
    return updateCurrencyById(issuerId, id, { active }).then(() => {
      dispatch('global/fetchCurrencies', issuerId, { root: true });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerCurrencies.update'), { root: true });
    }).catch((err) => {
      this.$log.error('Update issuer currency get error:', err);
    });
  },

  getIssuerCurrencies(ctx, { params }) {
    const args = getArgumentsForApi(currenciesArguments, params);
    return getCurrencies(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Currencies get error:', err);
        return err;
      });
  },
  getIssuerCurrenciesList({ commit }, issuerId) {
    return getCurrencies(issuerId)
      .then(({ data }) => { commit('SET_CURRENCIES', data.data); })
      .catch((err) => {
        this.$log.error('Issuer Currencies get error:', err);
        return err;
      });
  },
};
