<template>
  <div>
    <label
      v-if="showLabel"
      class="form-label"
    >
      {{ label }}
    </label>
    <b-input-group :append="append">
      <b-input
        v-validate.initial="validate"
        :class="[ inputClass, {'password-input': inputType==='password'}, { 'border border-danger': error || controlledError, 'readonly': isReadonly }]"
        :disabled="disabled"
        :value="currentValue"
        :placeholder="displayedPlaceholder"
        :name="name"
        v-bind="htmlProps"
        :type="convertedType"
        :data-vv-as="validationFieldName"
        :readonly="isReadonly"
        :maxlength="maxLength"
        @input="onChangeInput"
        @paste="$emit('paste',$event)"
      />
      <b-input-group-prepend
        v-if="inputType==='password'"
        @click="showPassword()"
      >
        <span class="input-group-text">
          <i :class="['ion', isShow ? 'ion-md-eye' : 'ion-md-eye-off' ]" />
        </span>
      </b-input-group-prepend>
    </b-input-group>
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'CpInput',
  props: {
    error: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    htmlProps: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'form-group',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonlyPlaceholder: {
      type: String,
      default: '-',
    },
    validate: {
      type: [String, Object],
      default: '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    append: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 524288,
    },
  },
  data() {
    return {
      controlledError: '',
      controlledValue: this.value,
      touched: false,
      isShow: false,
    };
  },
  computed: {
    displayedPlaceholder() {
      return this.isReadonly ? this.readonlyPlaceholder : this.placeholder;
    },
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    validationFieldName() {
      return this.label.toLowerCase() || this.name.split(/(?=[A-Z])/).join(' ').toLowerCase();
    },
    showLabel() {
      return this.label && !this.hideLabel;
    },
    convertedType() {
      return this.isShow && this.inputType === 'password' ? 'text' : this.inputType;
    },
  },
  methods: {
    onChangeInput(val) {
      if (this.inputType === 'number') {
        val = (this.touched || val !== '') ? Number(val) : null;
        this.touched = true;
      }
      this.$emit('input', val);
    },
    isCpInput() {
      return true;
    },
    showPassword() {
      this.isShow = !this.isShow;
    },
    clear() {
      this.$emit('input', '');
    },
  },
};
</script>

<style scoped>
.readonly {
  background-color: rgba(10,23,39,.025);
  border-color: rgba(10,23,39,.025);
}
.input-group-text {
  border-left: none;
}
.password-input:not(:focus) {
  border-right: none;
}
</style>
