export default {
  auditLog: {
    title: 'Audit Log',
    label: {
      filter: {
        operator: 'Operator',
        issuer: 'Issuer',
        token: 'Token',
        dateRange: 'Date Range',
      },
    },
  },
};
