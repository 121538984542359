import { logsApi, dashboardApi } from '~/utilities/mainApiClient';

const {
  getDashboardFundraiseInformation,
  getDashboardHoldersInformation,
  getDashboardOnboardingInformation,
} = dashboardApi;
const { getLogs } = logsApi;


export default {
  getLogs({ commit }, { issuerId, page, limit }) {
    return getLogs(issuerId, page, limit).then(({ data }) => {
      commit('SET_ACTIVITIES', data.data);
      commit('SET_TOTAL_LOGS', data.totalCount || data.totalItems);
    })
      .catch((err) => {
        this.$log.error('Logs get error:', err);
      });
  },

  getDashboardOnboardingInfo({ commit }, { issuerId, timezone, roundId }) {
    return getDashboardOnboardingInformation(issuerId, timezone, roundId)
      .then(({ data }) => {
        const {
          countryDistribution,
          registeredData,
          authorizedData,
          ...stats
        } = data;
        commit('SET_ONBOARDING_BY_COUNTRY_SUMMARY', countryDistribution);
        commit('SET_ONBOARDING_REGISTERED_SUMMARY', registeredData);
        commit('SET_ONBOARDING_AUTHORIZED_SUMMARY', authorizedData);
        commit('SET_ONBOARDING_STATISTICS', stats);
      })
      .catch((err) => {
        this.$log.error('Dashboard General Info get error:', err);
      });
  },

  getDashboardFundraiseInfo({ commit }, { issuerId, tokenId, timezone, roundId }) {
    return getDashboardFundraiseInformation(issuerId, tokenId, timezone, roundId).then(({ data }) => {
      const {
        countryDistribution,
        pledgeData,
        fundData,
        ...stats
      } = data;
      commit('SET_FUNDING_STATISTICS', stats);
      commit('SET_FUNDING_BY_COUNTRY_SUMMARY', countryDistribution);
      commit('SET_FUNDING_BY_PLEDGE_SUMMARY', pledgeData);
      commit('SET_FUNDING_FUNDED_SUMMARY', fundData);
    })
      .catch((err) => {
        this.$log.error('Dashboard Fundraise Info get error:', err);
      });
  },

  getDashboardHoldersInfo({ commit }, { issuerId, tokenId }) {
    return getDashboardHoldersInformation(issuerId, tokenId).then(({ data }) => {
      const {
        countryDistribution,
        individualVsInstitutionalData,
        topThreeMaximumTokenStakes,
        ...stats
      } = data;
      commit('SET_HOLDERS_STATISTICS', stats);
      commit('SET_HOLDERS_BY_COUNTRY_SUMMARY', countryDistribution);
      commit('SET_HOLDERS_BY_TYPE_SUMMARY', individualVsInstitutionalData);
      commit('SET_HOLDERS_TOKEN_STAKES', topThreeMaximumTokenStakes);
    })
      .catch((err) => {
        this.$log.error('Dashboard Holders Info get error:', err);
      });
  },
};
