export default {
  restrictedJurisdictions: [],
  restrictedJurisdictionsState: [],
  disclaimersAndDefinitions: {},
  perCountryDisclaimersAndDefinitions: null,
  jurisdictionsCountries: [],
  editableCountry: {
    disclaimer: {},
    qualification: {},
  },
  totalCountriesCount: null,
  totalStatesAndRegionsCount: null,
  jurisdictionsStatesAndRegions: [],
};
