<template>
  <div class="bg-white token-selector-container px-4 border-top">
    <b-dd
      no-caret
      variant="link"
      menu-class="sidenav-dd-menu"
      class="token-selector-dropdown"
    >
      <template
        slot="button-content"
      >
        <div class="d-flex button-content-container">
          <cp-image-with-blockchain-name
            :src-main-logo="getImagePath(currentToken.iconUrl, 'logo')"
            :src-blockchain-logo="currentToken.blockchainNetworkIconUrl"
            :default-main-logo-src="fallbackTokenIcon"
            :default-blockchain-logo-src="fallbackBlockchainIcon"
            :should-hide-blockchain-default-img-if-fail="true"
            title="Current Token"
          />
          <span
            id="current-token-name"
            class="font-weight-normal ml-0 dropdown-toggle dropdown-toggle-text dflex"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <b-tooltip
              v-if="shouldShowTooltip(currentToken, 126)"
              :target="'current-token-name'"
              triggers="hover"
            >
              {{ currentToken.symbol || currentToken.name }}
            </b-tooltip>
            <div class="dropdown-toggle-text-display">
              {{ currentToken.symbol || currentToken.name }}
            </div>
          </span>
        </div>
      </template>
      <b-dd-item
        v-for="token in issuerTokens"
        :key="token.id"
        @click="selectToken(token)"
      >
        <div
          class="image-row"
        >
          <cp-image-with-blockchain-name
            :src-main-logo="getImagePath(token.iconUrl, 'logo')"
            :src-blockchain-logo="token.blockchainNetworkIconUrl"
            :default-main-logo-src="fallbackTokenIcon"
            :default-blockchain-logo-src="fallbackBlockchainIcon"
            :should-hide-blockchain-default-img-if-fail="true"
            title="Current Token"
          />
          <b-tooltip
            v-if="shouldShowTooltip(token, 126)"
            :target="`issuer-token-${token.id}`"
            triggers="hover"
          >
            {{ token.symbol || token.name }}
          </b-tooltip>
          <span
            :id="`issuer-token-${token.id}`"
            class="menu-item-wrapper menu-item-wrapper--text "
          >
            {{ token.symbol || token.name }}
          </span>
        </div>
      </b-dd-item>
    </b-dd>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import CpImageWithBlockchainName from '~/components/common/standalone-components/cp-image-with-blockchain-name';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import checkHtmlElementsOverflowMixin from '~/mixins/check-html-elements-overflow';

export default {
  components: {
    CpImageWithBlockchainName,
  },
  mixins: [
    staticPathGenerationMixin,
    checkHtmlElementsOverflowMixin,
  ],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    ...mapGetters('issuersInfo', ['issuerTokens']),
    DEFAULT_BLOCKCHAIN_LOGO() {
      return 'default_bc_logo.png';
    },
    fallbackTokenIcon() {
      return this.getImagePath('logo.png', 'logo');
    },
    fallbackBlockchainIcon() {
      return this.getImagePath(this.DEFAULT_BLOCKCHAIN_LOGO, 'logo');
    },
  },
  watch: {
    $route({ params: { tokenId: newTokenId } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        const token = this.issuerTokens.find(item => item.id === newTokenId);
        this.SET_CURRENT_TOKEN(token);
      }
    },
  },
  beforeMount() {
    if (!Object.keys(this.currentToken).length) {
      this.selectToken(this.issuerTokens.find(token => token.id === this.$route.params.tokenId) || this.issuerTokens[0]);
    }
  },
  methods: {
    ...mapMutations('issuersInfo', ['SET_CURRENT_TOKEN']),
    selectToken(token) {
      this.SET_CURRENT_TOKEN(token);
      const { params, query } = this.$route;
      this.$router.push({ params: { ...params, tokenId: token.id }, query });
    },
    shouldShowTooltip(item, maxWidth) {
      return this.doesTextExceedContainer((item.symbol || item.name), maxWidth);
    },
  },
};
</script>

<style scoped>
.token-logo {
  width: 16px;
  height: 16px;
}
</style>

<style>
.token-selector-container{
  padding-top: 12px;
  padding-bottom: 12px;
}
.token-selector-dropdown ul.dropdown-menu.sidenav-dd-menu.show{
  left: -25px !important;
  width: 252px;
  padding-top: 8px;
}
.token-selector-dropdown .dropdown-menu {
  max-height: 80vh;
  overflow-y: auto;
}
.token-selector-dropdown .dropdown-toggle{
  padding: 0;
  padding-left: 1px;
}

.token-selector-dropdown .button-content-container {
  height: 30px;
}
.token-selector-dropdown .dropdown-toggle-text {
  display: flex;
  align-items: center;
}
.token-selector-dropdown .dropdown-toggle-text .dropdown-toggle-text-display,
.token-selector-dropdown .menu-item-wrapper.menu-item-wrapper--text {
  max-width: 126px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.token-selector-dropdown .dropdown-toggle-text,
.token-selector-dropdown .menu-item-wrapper {
  align-content: center;
}
.token-selector-dropdown .image-row {
  display: flex;
  height: 30px;
  margin-left: 9px;
}
.token-selector-dropdown ul li .dropdown-item {
  padding: 2px 16px;
}
</style>
