export default {
  isConnected: false,
  isConnecting: false,
  isFetchingLogs: false,
  hasFetchedLogs: false,
  isReceivingEvents: false,
  logMessages: {},
  socketError: false,
  socketErrorMessage: '',
};
