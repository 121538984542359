import { bankAccountsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addBankAccount,
  deleteBankAccountById,
  getBankAccounts,
  getBankAccountById,
  updateBankAccountById,
} = bankAccountsApi;
const bankAccountsArguments = {
  issuerId: undefined,
  q: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ commit }, { issuerId, bank: { id, ...rest } }) {
    return updateBankAccountById(issuerId, id, rest).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.bankAccountById.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, id }) {
    return deleteBankAccountById(issuerId, id).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.bankAccountById.delete'), { root: true });
    });
  },

  create({ commit }, { issuerId, bank }) {
    return addBankAccount(issuerId, bank).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.bankAccountById.create'), { root: true });
    });
  },

  getById({ commit }, { issuerId, id }) {
    return getBankAccountById(issuerId, id).then(({ data }) => {
      commit('SET_ACCOUNT', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Bank Account get error:', err);
    });
  },

  getIssuerBankAccounts(ctx, { params }) {
    const args = getArgumentsForApi(bankAccountsArguments, params);
    return getBankAccounts(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Bank accounts get error:', err);
        throw err;
      });
  },
};
