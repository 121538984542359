export default {
  operators: {
    head: {
      title: 'Panel Administration',
      disabledTooltip: 'You must have an Issuer to add an Operator',
    },
    tabs: {
      operators: 'Operators',
      brokerDealerGroups: 'B/D Groups',
      organizations: 'Organizations',
    },
    button: {
      operators: {
        add: 'Add Operators',
        reset: 'Reset Auth',
        hsm: 'HSM',
      },
    },
    label: {
      never: 'never',
      table: {
        name: 'Name',
        email: 'Email',
        lastLoginAt: 'Last login',
        lastLoginIp: 'IP',
        authorizationLevel: 'Authorization level',
        createdAt: 'Created',
        enabled: 'Status',
        issuers: 'Issuers',
        organization: 'Organization',
        operators: 'Operators',
      },
    },
    message: {
      titleTooltip: 'You must have an Issuer to add an Operator',
    },
    dropdown: {
      title: 'Add',
      operator: 'Operator',
      brokerDealerGroup: 'B/D Group',
      organization: 'Organization',
    },
    filter: {
      title: 'Filter',
      label: {
        issuers: 'Issuers',
        authorizations: 'Authorizations',
        authorizationLevel: 'Authorization Level',
        clearFilter: 'Clear Filter',
      },
    },
    option: {
      authorizationLevel: {
        basic: 'Basic',
        admin: 'Admin',
        superadmin: 'Super Admin',
        megaadmin: 'Mega Admin',
        brokerdealer: 'Broker Dealer',
      },
    },
    confirmModal: {
      title: 'Change status for {type}',
      message: 'Are you sure you want to {status} {name}?',
      status: {
        activate: 'activate',
        deactivate: 'deactivate',
      },
    },
    resetConfirmModal: {
      title: 'Reset authentication',
      message: 'Are you sure you want to reset authentication setting?',
    },
    operatorModal: {
      title: {
        add: 'Add Operator',
        edit: 'Edit Operator',
      },
      label: {
        name: 'Name',
        email: 'Email',
        authorizationLevel: 'Authorization level',
        authorizations: 'Authorizations',
        instances: 'Instances',
        selectedInstances: 'Selected Instances',
        brokerDealerGroup: 'B/D Group',
        organization: 'Organization',
        createdBy: 'Created By',
      },
      warning: {
        emptyBrokerDealerGroupList: 'Please add at least one B/D group',
      },
    },
    brokerDealerGroupModal: {
      title: {
        add: 'Add B/D Group',
        edit: 'Edit B/D Group',
      },
      label: {
        name: 'Name',
        email: 'Email',
        instances: 'Instances',
        selectedInstances: 'Selected Instances',
        authorizations: 'Authorizations',
      },
      button: {
        add: 'Add Group',
        edit: 'Save Changes',
      },
      settings: {
        label: 'Settings for: {issuerName}',
        noIssuerSelected: 'no issuer selected',
        userCanBeEditedByIssuer: 'B/D\'s investor details can be edited by the issuer',
        canIssueTokens: 'B/D can issue tokens',
        canAccessToIssuerConfiguration: 'B/D can access Configuration menu',
        canAccessToIssuerSignatures: 'B/D can access Signatures menu',
      },
    },
    organizationModal: {
      title: {
        add: 'Add Organization',
        edit: 'Edit Organization',
      },
      label: {
        name: 'Name',
        ipRange: 'IP Range',
        fromIp: 'From',
        toIp: 'To',
      },
      button: {
        add: 'Add Organization',
        edit: 'Save Changes',
      },
    },
    hsm: {
      backToPage: 'Back',
      pageTitle: 'HSM Wallet Info',
      toggleLabel: 'HSM Wallet Enabled',
      walletLabel: 'HSM Wallet',
      error: {
        register: 'Error while trying to register a new HSM Operator.',
        createKey: 'Error while trying to create Operator HSM Keys.',
        removeOperator: 'Error while trying to remove Operator HSM Keys',
        toggleStatus: 'Error while trying to change Operator HSM status.',
      },
    },
  },
};
