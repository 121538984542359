import { custodiansApi } from '~/utilities/mainApiClient';
import { i18n } from '@/i18n';

const {
  getCustodians,
  addCustodian,
  updateCustodian,
} = custodiansApi;

export default {
  getCustodians({ commit }, { params: { issuerId, q, page, limit } }) {
    return getCustodians(issuerId, page, q, limit)
      .then((data) => {
        commit('SET_CUSTODIANS', data.data);
        return data;
      }).catch((err) => {
        this.$log.error('Custodians - custodians get error:', err);
      });
  },
  addCustodian({ commit }, { issuerId, body }) {
    return addCustodian(issuerId, { ...body })
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.custodian.custodianAdded'), { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('Custodians - custodians get error:', err);
      });
  },
  updateCustodian({ commit }, { issuerId, body: { id }, body }) {
    return updateCustodian(issuerId, id, { ...body })
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.custodian.custodianUpdated'), { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('Custodians - custodians update error:', err);
      });
  },
};
