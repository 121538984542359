<template>
  <b-nav-item-dropdown
    no-caret
    right
    class="ml-2 lang-navbar"
  >
    <!--Selected lang-->
    <template slot="button-content">
      <div class="lang-code">
        {{ locale.toUpperCase() }}
      </div>
      <i class="ion ion-ios-arrow-down align-middle mx-1" />
    </template>

    <!--Lang list-->
    <b-list-group flush>
      <b-list-group-item
        v-for="(value, index) in localeList"
        :key="index"
        href="javascript:void(0)"
        @click="changeLocale(value)"
      >
        <b-form-radio
          :checked="locale"
          :value="value"
          plain
        >
          {{ $t(`component.languageSelector.label.${value}`) }}
        </b-form-radio>
      </b-list-group-item>
    </b-list-group>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { localeList } from '~/i18n';
import { ISR_MFE_ROUTES } from '../../utilities/route-helpers';

export default {
  name: 'CpLanguageSelector',
  data() {
    return {
      localeList,
    };
  },
  computed: {
    ...mapGetters('global', ['locale']),
  },
  methods: {
    ...mapActions('global', ['setLocale']),
    async changeLocale(selectedLocale) {
      await this.setLocale(selectedLocale);
      const routeName = this.$route.name;

      if (ISR_MFE_ROUTES.includes(routeName)) {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
  .lang-navbar {
    .lang-code {
      min-width: 20px;
      display: inline-block;
    }

    .dropdown-menu {
      padding: 0;
      border: 0;
      width: 200px;
    }

    .form-check {
      padding-left: 0;

      .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:checked ~ .form-check-label::after {
          border-color: #04bec4;
          background-color: #04bec4;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
        }
      }

      .form-check-label {
        width: 100%;

        &::after {
          background-color: #fff;
          position: absolute;
          top: 0.0625rem;
          right: 0;
          border: 1px solid rgba(10, 23, 39, 0.1);
          width: 1.125rem;
          height: 1.125rem;
          content: "";
          background-position: center center;
          background-repeat: no-repeat;
          transition: all .2s;
          border-radius: 50%;
          background-size: 0.5rem;
        }
      }
    }
  }
</style>
