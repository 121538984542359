import {
  createOpportunity,
  getOpportunities,
  getOpportunitiesByToken,
  deleteOpportunity,
  publishOpportunity,
  getOpportunityById,
  updateOpportunity,
  addOpportunityContent,
  updateOpportunityContent,
  deleteOpportunityContent,
  updateOpportunityDocument,
  addOpportunityDocument,
  deleteOpportunityDocument,
  getOpportunityDocument,
  changeOpportunityOrder,
  getVimeoThumbnail,
  getLanguages,
  updatePublishContent,
  createLanguageContent,
  createLanguageSummary,
  updateLanguageSummary,
} from '~/utilities/external-api/apis/opportunityCpApi';

export default {
  createOpportunity({ commit }, { issuerId, opportunity }) {
    return createOpportunity(issuerId, opportunity)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity is created', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Opportunity add error:', err);
        throw err;
      });
  },
  updateOpportunity({ commit }, { issuerId, opportunityId, opportunity }) {
    return updateOpportunity(issuerId, opportunityId, opportunity)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Opportunity update error:', err);
        throw err;
      });
  },
  getOpportunities(ctx, { issuerId }) {
    return getOpportunities(issuerId)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Opportunity list error:', err);
        throw err;
      });
  },
  getOpportunitiesByToken(ctx, { issuerId, tokenId }) {
    return getOpportunitiesByToken(issuerId, tokenId)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Opportunity by token error:', err);
        throw err;
      });
  },
  getOpportunityById({ commit }, { issuerId, opportunityId, includeEmptyFields, additionalFields, language }) {
    return getOpportunityById(issuerId, opportunityId, includeEmptyFields, additionalFields, language)
      .then(({ data }) => {
        commit('SET_CURRENT_OPPORTUNITY', data.data);
        return data;
      }).catch((err) => {
        this.$log.error('get Opportunity by id:', err);
        throw err;
      });
  },
  deleteOpportunity({ commit }, { issuerId, opportunityId }) {
    return deleteOpportunity(issuerId, opportunityId)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity is deleted', { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('delete Opportunity:', err);
        throw err;
      });
  },
  publishOpportunity(ctx, { issuerId, opportunityId, opportunity }) {
    return publishOpportunity(issuerId, opportunityId, opportunity)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('publish Opportunity:', err);
        throw err;
      });
  },
  changeOpportunityOrder(ctx, { issuerId, opportunityId, opportunity }) {
    return changeOpportunityOrder(issuerId, opportunityId, opportunity)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('change order Opportunity error:', err);
        throw err;
      });
  },
  addOpportunityContent({ commit }, { issuerId, opportunityId, opportunityContent }) {
    return addOpportunityContent(issuerId, opportunityId, opportunityContent)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Content is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('update Opportunity Content error:', err);
        throw err;
      });
  },
  updateOpportunityContent({ commit }, { issuerId, opportunityId, opportunityContentId, opportunityContent }) {
    return updateOpportunityContent(issuerId, opportunityId, opportunityContentId, opportunityContent)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Content is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('update Opportunity Content error:', err);
        throw err;
      });
  },
  deleteOpportunityContent({ commit }, { issuerId, opportunityId, opportunityContentId }) {
    return deleteOpportunityContent(issuerId, opportunityId, opportunityContentId)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Content is deleted', { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('delete Opportunity Content error:', err);
        throw err;
      });
  },
  addOpportunityDocument({ commit }, { issuerId, opportunityId, opportunityDocument }) {
    return addOpportunityDocument(issuerId, opportunityId, opportunityDocument)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Document is added', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('add Opportunity Document error:', err);
        throw err;
      });
  },
  updateOpportunityDocument({ commit }, { issuerId, opportunityId, opportunityDocument }) {
    return updateOpportunityDocument(issuerId, opportunityId, opportunityDocument)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Document is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('update Opportunity Document error:', err);
        throw err;
      });
  },
  deleteOpportunityDocument({ commit }, { issuerId, opportunityId, opportunityDocumentId }) {
    return deleteOpportunityDocument(issuerId, opportunityId, opportunityDocumentId)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Document is deleted', { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('delete Opportunity Document error:', err);
        throw err;
      });
  },
  getOpportunityDocument(ctx, { issuerId, opportunityId, opportunityDocumentId }) {
    return getOpportunityDocument(issuerId, opportunityId, opportunityDocumentId)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('get Opportunity Document error:', err);
        throw err;
      });
  },
  getVimeoVideoThumbnail(ctx, { videoId }) {
    return getVimeoThumbnail(videoId);
  },
  getLanguages({ commit }, { issuerId }) {
    return getLanguages(issuerId)
      .then(({ data }) => {
        commit('SET_LANGUAGES', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Languages list error:', err);
        throw err;
      });
  },
  updatePublishContent({ commit }, { issuerId, opportunityId, published, language }) {
    return updatePublishContent(issuerId, opportunityId, published, language)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Content is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Error trying to update content', err);
        throw err;
      });
  },
  createLanguageContent({ commit }, { issuerId, opportunityId, language }) {
    return createLanguageContent(issuerId, opportunityId, language)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Content is created', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Error trying to create content', err);
        throw err;
      });
  },
  createLanguageSummary({ commit }, { issuerId, opportunityId, summaryPayload }) {
    return createLanguageSummary(issuerId, opportunityId, summaryPayload)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity Summary is created', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Error trying to create summary', err);
        throw err;
      });
  },
  updateLanguageSummary({ commit }, { issuerId, opportunityId, summaryPayload, language }) {
    return updateLanguageSummary(issuerId, opportunityId, summaryPayload, language)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Opportunity summary is updated', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Error trying to update summary', err);
        throw err;
      });
  },
};
