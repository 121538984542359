export default {
  msf: {
    title: 'Master Securityholder File (MSF)',
    label: {
      holdersOfRecord: 'Holders of Record',
      beneficialOwners: 'Beneficial Owners',
      table: {
        id: 'Investor ID',
        beneficialOwners: 'Ben. Owner Name/Entity',
        recordHolder: 'Record Holder',
        countryResidence: 'Country of residence',
        stateCode: 'US State',
        email: 'Email',
        walletAddress: 'Wallet address',
        numberOfSecurities: 'Number of Securities',
        principalAmount: 'Principal Amount, {0}',
      },
    },
    filter: {
      countryCode: 'Country',
      investorType: 'Individual/Entity',
      fromTotalTokens: 'Token Owned',
    },
    button: {
      list: {
        export: 'Export List',
      },
    },
  },
};
