export default {
  component: {
    cpFilter: {
      title: 'Filter',
      button: {
        filter: {
          clear: 'Clear Filter',
        },
      },
    },
    cpFileUploader: {
      error: 'This document is required',
    },
  },
};
