function setCommasToNumber(val, limitDecimal) {
  val = (val || '').toString();
  return Number(val).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: +limitDecimal || 20,
  });
}

export default function exponentialToDecimal([exponentialNumber, limitDecimal]) {
  const str = (exponentialNumber || '').toString();
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 10);
    return setCommasToNumber(exponentialNumber.toFixed(exponent), limitDecimal);
  }
  return setCommasToNumber(exponentialNumber, limitDecimal);
}
