<template>
  <div class="form-group">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <b-textarea
      v-validate.initial="validate"
      :class="[ inputClass, { 'border border-danger': error || controlledError }]"
      :disabled="disabled"
      :value="currentValue"
      :placeholder="displayedPlaceholder"
      :name="name"
      :type="inputType"
      :rows="rows"
      :readonly="isReadonly"
      :maxlength="maxLength"
      @input="onChangeInput"
    />
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CpTextArea',
  props: {
    rows: {
      type: Number,
      default: null,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'html-content form-group',
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    readOnlyPlaceholder: {
      type: String,
      default: '-',
    },
    maxLength: {
      type: Number,
      default: 524288,
    },
  },
  data() {
    return {
      controlledValue: this.value,
      controlledError: '',
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    displayedPlaceholder() {
      return this.isReadonly ? this.readOnlyPlaceholder : this.placeholder || this.$t('component.cpTextArea.label.placeholder');
    },
  },
  methods: {
    onChangeInput(val) {
      if (this.inputType === 'number') {
        val = Number(val);
      }
      this.$emit('input', val);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
