export default {
    wizard: { // TODO: translate to jp
        step: {
            title: 'Step',
            status: {
                pending: 'Pending',
                inProgress: 'In Progress',
                completed: 'Completed',
            },
        },
        buttons: {
            continue: 'Continue',
            goBack: 'Go Back',
            finish: 'Finish',
        },
    },
};
