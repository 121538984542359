import { render, staticRenderFns } from "./cp-side-nav-menu.vue?vue&type=template&id=e2bf6c64&scoped=true&"
import script from "./cp-side-nav-menu.vue?vue&type=script&lang=js&"
export * from "./cp-side-nav-menu.vue?vue&type=script&lang=js&"
import style0 from "./cp-side-nav-menu.vue?vue&type=style&index=0&id=e2bf6c64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2bf6c64",
  null
  
)

export default component.exports