import {
  getControlBookRecords, controlBookInfo, getTokenTransactions,
} from '~/utilities/external-api/apis/tranceCpApi';

export default {
  controlBookRecords({ commit }, { issuerId, tokenId, queryParams }) {
    return getControlBookRecords(issuerId, tokenId, queryParams)
      .then(({ data }) => {
        commit('SET_CONTROL_BOOK_RECORDS', data.data);
        commit('SET_CONTROL_BOOK_RECORDS_TOTAL', data.totalItems);
      })
      .catch((err) => {
        this.$log.error('Error fetching activity:', err);
        throw err;
      });
  },
  controlBookInfo({ commit }, { issuerId, tokenId }) {
    return controlBookInfo(issuerId, tokenId)
      .then(({ data }) => {
        commit('SET_CONTROL_BOOK_INFO', data);
      })
      .catch((err) => {
        this.$log.error('Error fetching activity detail:', err);
        throw err;
      });
  },
  tokenTransactions({ commit }, { issuerId, tokenId, queryParams, acceptType = 'application/json' }) {
    return getTokenTransactions(issuerId, tokenId, queryParams, acceptType)
      .then(({ data }) => {
        commit('SET_TOKEN_TRANSACTIONS', data.data);
        commit('SET_TOKEN_TRANSACTIONS_TOTAL', data.totalItems);
      })
      .catch((err) => {
        this.$log.error('Error fetching activity:', err);
        throw err;
      });
  },
};
