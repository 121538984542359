export default {
  issuerCurrencies: {
    title: 'Issuer Currencies',
    label: {
      table: {
        name: 'Name',
        identifier: 'Identifier',
        type: 'Type',
        decimals: 'Decimals',
        active: 'Active',
      },
    },
    status: {
      fiat: 'fiat',
      crypto: 'crypto',
    },
    // modal
    currencyModal: {
      title: 'Edit Currency',
      label: {
        active: 'Active',
      },
      message: {
        alert: 'At least one currency must remain active',
      },
    },
  },
};
