export default {
  component: {
    cpTableControls: {
      label: {
        show: 'Show {0} entries',
      },
    },
    cpTablePagination: {
      label: {
        entries: 'Showing {shownItems} of {totalItems} entries',
        previous: 'Previous',
        next: 'Next',
      },
    },
    cpTableDeleteModal: {
      message: 'Are you sure you want to delete\n"{0}"?',
    },
  },
};
