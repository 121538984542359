<template>
  <b-nav-item-dropdown
    no-caret
    right
    class="demo-navbar-notifications mr-lg-3"
  >
    <!--Notifications icon-->
    <template slot="button-content">
      <i class="ion ion-md-notifications-outline navbar-icon align-middle" />
      <span
        v-if="alertsCount"
        class="badge badge-pill badge-info indicator"
      >
        {{ alertsCount }}
      </span>
    </template>
    <!--Notifications icon-->
    <div class="bg-primary text-center text-white font-weight-bold p-3">
      {{ alertsSubtitle }}
    </div>
    <b-list-group
      v-if="alertsCount"
      flush
    >
      <b-list-group-item
        v-if="getAlertsCount('securitize-id-manual-review')"
        href="javascript:void(0)"
        class="media d-flex align-items-center"
        @click.prevent="goToSecuritizeIdManualReviewInvestors"
      >
        <div class="ui-icon ui-icon-sm ion ion-md-ribbon bg-warning border-0 text-dark" />
        <div class="media-body line-height-condenced ml-3">
          <div class="text-dark">
            <strong>{{ getAlertsCount('securitize-id-manual-review') }}</strong>
            SecuritizeId investors are pending manual review for KYC
          </div>
          <div class="text-light small mt-1">
            Click here to manually review the SecuritizeId investors.
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import routeNames from '~/utilities/routes';

export default {
  name: 'CpNotifications',
  data() {
    return { updateInterval: undefined };
  },
  computed: {
    ...mapGetters({
      alertsCount: 'currentOperator/securitizeIdAlertsCount',
      notifications: 'currentOperator/securitizeIdNotifications',
    }),
    alertsSubtitle() {
      return this.alertsCount
        ? `${this.alertsCount} new notification(s)`
        : 'No notifications';
    },
  },
  beforeMount() {
    this.getSecuritizeIdNotifications();
    this.updateInterval = setInterval(() => this.getSecuritizeIdNotifications(), 30000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    ...mapActions('currentOperator', ['getSecuritizeIdNotifications']),
    ...mapMutations('global', ['SET_FILTER_STATE']),
    goToSecuritizeIdManualReviewInvestors() {
      this.SET_FILTER_STATE({
        name: 'securitizeIdInvestors',
        filterData: { 'kyc-status': 'manual-review' },
      });
      this.redirectToPage('securitizeId');
    },
    getAlertsCount(type) {
      if (!this.notifications.length) return 0;

      const alert = this.notifications.find(item => item.type === type);
      return alert ? alert.count : 0;
    },
    redirectToPage(pageName) {
      this.$router.push(routeNames[pageName]());
    },
  },
};
</script>
