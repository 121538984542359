import axios from '~/utilities/axios';

const basePath = '/api/v2';

class SessionCpApi {
  constructor() {
    this.login = function login(email, password, recaptchaToken, tfaToken) {
      const url = `${basePath}/session/login`;
      const obj = { email, password, recaptchaToken, tfaToken };
      return axios.post(url, obj);
    };
    this.logout = function logout() {
      const url = `${basePath}/session/logout`;
      return axios.post(url);
    };
    this.refresh = function refresh(refreshToken) {
      const url = `${basePath}/session/refresh`;
      const obj = { refreshToken };
      return axios.post(url, obj);
    };
    this.googleLogin = function googleLogin(googleAccessToken) {
      const url = `${basePath}/session/google-login`;
      const obj = { googleAccessToken };
      return axios.post(url, obj);
    };
    this.updatePassword = function updatePassword(password, recaptchaToken, registerToken) {
      const url = `${basePath}/session/password`;
      const obj = { password, recaptchaToken };
      const config = { headers: { authorization: `Bearer ${registerToken}` } };
      return axios.put(url, obj, config);
    };
    this.getTwoFactorAuth = function getTwoFactorAuth(registerToken) {
      const url = `${basePath}/session/two-factor-auth`;
      const config = { headers: { authorization: `Bearer ${registerToken}` } };
      return axios.get(url, config);
    };
    this.verifyTwoFactorAuth = async function verifyTwoFactorAuth(digits, registerToken = undefined) {
      const config = registerToken ? { headers: { authorization: `Bearer ${registerToken}` } } : undefined;
      const url = `${basePath}/session/two-factor-auth/verify`;
      const obj = { token: digits };
      const res = await axios.post(url, obj, config);
      return res.data;
    };
    this.verifyRegisterToken = function verifyRegisterToken(registerToken) {
      const url = `${basePath}/session/register-token/verify`;
      const config = { headers: { authorization: `Bearer ${registerToken}` } };
      return axios.get(url, config);
    };
  }
}

export const {
  login,
  refresh,
  googleLogin,
  updatePassword,
  getTwoFactorAuth,
  verifyTwoFactorAuth,
  verifyRegisterToken,
  logout,
} = new SessionCpApi();
