export default {
  affiliateManagement: {
    title: '発行体関係者管理',
    label: {
      table: {
        fullName: '氏名',
        affiliateStatus: '関係者',
        lastChange: '最終変更日',
        comment: 'コメント',
      },
    },
    status: {
      yes: 'yes', // TODO
      no: 'no', // TODO
      pending: 'pending', // TODO
    },
    message: {
      setAffiliateToolTip: 'Investor cannot be set as an affiliate when Pending', // TODO
    },
    deleteModal: {
      title: {
        activate: '関係者として登録',
        deactivate: '登録解除',
      },
      message: {
        activate: 'Please note that you will need to sign a signature per token in order to mark this investor as affiliate.', // TODO
        deactivate: 'Please note that you will need to sign a signature per token in order to mark this investor as non affiliate.', // TODO
      },
      input: {
        comment: 'コメント:',
        documentId: 'ドキュメントID:',
      },
      label: {
        table: {
          createdAt: '最終変更日',
          status: 'ステータス',
          comment: 'コメント',
        },
      },
    },
  },
};
