export default {
  SET_BROKER_DEALER_INVESTOR_INFO(state, generalInfo) {
    state.brokerDealerInvestor = generalInfo;
  },
  SET_BROKER_DEALER_LEGAL_SIGNERS_LIST(state, data) {
    state.legalSignerList = data;
  },
  SET_BROKER_DEALER_LEGAL_SIGNER_BY_ID(state, data) {
    state.legalSignerByIdInfo = data;
  },
};
