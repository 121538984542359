import { configurationVariablesApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const {
  getConfigurationVariables,
  getConfigurationVariableById,
  updateConfigurationVariableById,
} = configurationVariablesApi;
const configurqationVariablesArguments = {
  issuerId: undefined,
  q: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ commit }, { issuerId, configVariable: { id, ...data } }) {
    return updateConfigurationVariableById(issuerId, id, data).then(() => {
      commit('global/CALL_TOASTER', 'Issuer Configuration variable is updated', { root: true });
    });
  },
  getById({ commit }, { issuerId, id }) {
    return getConfigurationVariableById(issuerId, id).then(({ data }) => {
      commit('SET_CONFIGURATION_VARIABLES', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Configuration Variable get error:', err);
    });
  },
  getIssuerConfigurationVariables(ctx, { params }) {
    const args = getArgumentsForApi(configurqationVariablesArguments, params);
    return getConfigurationVariables(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Configuration Variables get error:', err);
      });
  },

};
