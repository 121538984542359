export default {
  signup: {
    button: {
      register: {
        local: 'Register',
        google: 'REGISTER WITH GOOGLE-ID',
      },
    },
    input: {
      password: 'Password',
    },
    message: {
      error: 'Something went wrong! Please contact customer support.',
      expire: 'Registration link has expired or invalid. Please contact support.',
      account: 'You already have an account? {0}',
      loginNow: 'Login now!',
    },
    label: {
      requirements: {
        upperCase: '1 Upper case',
        lowerCase: '1 Lower case',
        number: '1 Number',
        chars: '8 Chars',
      },
    },
  },
};
