export default {
  current: {
    logo: 'logo.png',
    name: '',
  },
  issuerList: {
  },
  allIssuersList: [],
  allSystemIssuersList: [],
};
