export default {
  issuerQuestions: {
    title: 'Issuer FAQ Questions',
    button: {
      question: {
        add: 'Add Question',
      },
    },
    label: {
      table: {
        section: 'Section',
        question: 'Question',
        position: 'Position',
      },
    },
    status: {
      generalQuestions: 'GENERAL QUESTIONS',
      investmentRequirements: 'INVESTMENT REQUIREMENTS',
      investmentProcess: 'INVESTMENT PROCESS',
      offeringDetails: 'OFFERING DETAILS',
    },
    // modal
    questionsModal: {
      title: '{0} Question',
      input: {
        question: 'Question',
        position: 'Position',
        section: 'Section',
        answer: 'Answer',
      },
    },
  },
};
