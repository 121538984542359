
import styles from '@/vendor/main.scss';
import store from '~/store';

const limitAccessPlugin = {
  install(Vue) {
    const vm = new Vue({
      styles,
      store,
      render: h => h(),
    }).$mount();

    const isLimitAccess = () => {
      const isNonMegaAdmin = vm.$store.getters['currentOperator/operatorData'].authorizationLevel !== 'megaadmin';
      const { isDemo } = vm.$store.getters['issuersInfo/issuerInfo'];
      // isDemo is undefined when /issuer/{issuerId}/info call was not completed yet
      return (isDemo === undefined) || (isDemo && isNonMegaAdmin);
    };

    const showLimitAccessError = () => {
      Vue.$toast.open({
        message: 'Access denied or Issuer not found',
        type: 'error',
        duration: 5000,
      });
  };

    Vue.showLimitAccessError = showLimitAccessError;
    Vue.prototype.$showLimitAccessError = showLimitAccessError;

    Vue.prototype.$isLimitedAccess = isLimitAccess;
    Vue.isLimitedAccess = isLimitAccess;
  },
};

export default limitAccessPlugin;
