export default {
  investorDetails: {
    label: {
      securitizeId: 'Securitize Id',
      lastUpdate: 'Last update:',
      externalId: 'External ID:',
      securitizeIdMarkets: 'Securitize Id Markets',
    },
    button: {
      back: 'Back to List',
      invite: 'Send Invite',
      reset: 'Reset Password',
      disable: 'Disable 2FA',
      delete: 'Delete',
      syncSecuritizeId: 'Securitize ID Sync',
      securitizeIdUnLink: 'Securitize ID UnLink',
    },
    tooltip: {
      edit: {
        message: 'Personal Information cannot be changed for this investor. Please contact support',
      },
    },
    option: {
      transactionType: {
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        liquidation: 'Liquidity',
        refund: 'Refund',
      },
    },
    loading: 'Loading Investor Data...',
    // common modals
    addInvestmentModal: {
      title: 'Add Investment',
      label: {
        rounds: 'Rounds',
        currency: 'Currency',
      },
    },
    subscriptionAgreementStatusLogsModal: {
      title: 'Show Signature Log',
      tableColumns: {
        date: 'Date',
        signedBy: 'Signed by',
        signerDetails: 'Signer details',
        status: 'New status',
      },
    },
    addNextInvestmentModal: {
      title: 'Add Investment to round: {0}',
      label: {
        rounds: 'Rounds',
        currency: 'Currency',
      },
    },
    removeModal: {
      title: 'Delete {0}',
      message: 'Are you sure you want to delete this {0}?',
    },
    sendInviteModal: {
      title: 'Send Invitation Email',
      input: {
        emailContent: {
          placeholder: 'Invitation Email Text',
        },
      },
    },
    disable2faModal: {
      title: 'Disable 2FA',
      message: `2 Factor authentication (2FA) is for extra account security of the investor.\n
        Please disable only after you verified the identity of the removal requestor`,
      button: {
        disable: 'Disable',
      },
    },
    confirmSyncSecuritizeIdModal: {
      title: 'Securitize ID Sync',
      okText: 'Confirm',
      message: 'Are you sure you want to synchronize the personal information and KYC / Accreditation status from Securitize ID to this issuer investor account?',
    },
    confirmForceSyncSecuritizeIdModal: {
      title: 'SYNC Failed', // TODO: translate japanese
      okText: 'Force Sync',
      message: 'You can force the syncing process by clicking on ‘Force Sync’ button',
    },
    confirmUnlinkSecuritizeIdModal: {
      title: 'Securitize ID Un-Link',
      okText: 'Confirm',
      message: 'Are you sure you want to un-link the investor from his SecuritizeID account?',
    },
    // general section
    general: {
      title: 'General information',
      input: {
        firstName: 'First name:',
        middleName: 'Middle name:',
        lastName: 'Last name:',
        email: 'Email:',
        fboEmail: 'FBO Email:',
        phone: 'Phone:',
        phonePrefix: 'Phone Prefix:',
        birthday: 'Birthday:',
        gender: 'Gender:',
        investorType: 'Investor Type:',
        entityName: 'Entity Name:',
        entityDba: 'Entity DBA:',
        entityLeiCode: 'LEI Code',
        address: 'Address:',
        postalCode: 'Postal Code:',
        city: 'City:',
        country: 'Country:',
        state: 'State:',
        taxId: 'Tax ID:',
        taxId2: 'Tax ID2:',
        taxId3: 'Tax ID3:',
        taxCountryCode: 'Tax Country (FATCA):',
        taxCountryCode2: 'Tax Country 2 (FATCA):',
        taxCountryCode3: 'Tax Country 3 (FATCA):',
        identityDocumentNumber: 'Identity Document Number',
        additionalInfo: 'Additional Info',
        countryOfBirth: 'Country of Birth',
        cityOfBirth: 'City of Birth',
        creationDate: 'Creation date:',
        incorporationDate: 'Date of Incorporation:',
        custodian: 'Custodian:',
        entityType: 'Type:',
        jsonTextareaOpen: {
          label: 'Additional info',
        },
        jsonTextareaPrivate: {
          label: 'Additional Private Info',
        },
      },
      option: {
        gender: {
          female: 'Female',
          male: 'Male',
        },
      },
    },
    generalPropertiesModal: {
      title: 'General Properties - Preview',
      button: {
        download: 'Download Latest {0} Report',
      },
    },
    // kyc section
    kyc: {
      title: '{0} & Accreditation',
      label: {
        currentRegulationStatus: 'Current {0} status',
        performRegulationCheck: 'Perform {0} Check',
        sendRegulationEmail: 'Send {0}  Email',
        rerunRegulationCheck: 'Rerun {0} Check',
        viewRegulationReport: 'View {0} Report',
        lastRegulationChange: 'Last {0} Change',
        expirationDate: '{0} Expiration Date',
        transactionId: 'External {0} Transaction ID',
        currentAccreditationStatus: 'Current Accreditation status',
        qualificationStatusFor: 'Qualification Status for {0}',
        lastAccreditationChange: 'Last Accreditation Change',
        accreditedExpiryDate: 'Accreditation Expiry Date',
        kycProvider: '{0} Provider',
        kycStatusLog: '{0} Status Log',
        errors: 'Verification and SSN check results',
        enhanceRequired: 'Enhance Requested',
      },
      button: {
        sendAccreditationEmail: 'Send Accreditation email',
        fboTooltipText: 'This functionality cannot be used with Custodian investor',
      },
      message: {
        documents: 'Documents(at least document front type)',
        missingField: 'Please, fill in the fields: "{0}"',
        inProgress: '{0} check is already in progress, if the check is stuck move status to none first and rerun',
      },
      option: {
        status: {
          none: 'None',
          processing: 'Processing',
          updatesRequired: 'Updates Required',
          manualReview: 'Manual Review',
          verified: 'Verified',
          rejected: 'Rejected',
          expired: 'Expired',
        },
        accreditation: {
          none: 'None',
          pending: 'Pending',
          confirmed: 'Confirmed',
          rejected: 'Rejected',
          no: 'No',
          noAccepted: 'No Accepted',
          expired: 'Expired',
          updatesRequired: 'Updates Required',
          processing: 'Processing',
          notAccredited: 'Not Accredited',
        },
        tokenQualification: {
          none: 'None',
          pending: 'Pending',
          confirmed: 'Confirmed',
          rejected: 'Rejected',
          no: 'No',
          noAccepted: 'No Accepted',
          expired: 'Expired',
          updatesRequired: 'Updates Required',
          processing: 'Processing',
          notAccredited: 'Not Accredited',
        },
        kycProvider: {
          internal: 'Internal',
          'securitize-id': 'SecuritizeID',
          brokerdealer: 'BrokerDealer',
        },
      },
      field: {
        general: {
          gender: 'Gender',
          birthdate: 'Birthday',
          address1: 'Address',
          zipCode: 'Postal Code',
          city: 'City',
        },
      },
    },
    // legal signers section
    legalSigners: {
      title: 'Legal Signers',
      logoLabel: 'Legal Signers are managed by SecuritizeID',
      addLegalSignerButton: 'Legal Signer',
      confirmDeleteLegalSignerModal: 'Please confirm you would like to delete this legal signer',
      addLegalSignerModal: {
        title: 'Add Legal Signer',
        okText: 'Add Legal Signer',
        signerType: 'Legal Signer Type',
        ultimateBeneficialOwner: 'Ultimate beneficial owner (25% or more)',
        individual: {
          input: {
            firstName: 'First Name',
            middleName: 'Middle Name',
            lastName: 'Last Name',
            email: 'Email',
            birthDate: 'Birthdate',
            state: 'State',
            country: 'Country',
            city: 'City',
            streetName: 'Street Name',
            streetNumber: 'Street Number',
            postalCode: 'Postal Code',
            taxCountry: 'Tax Country ',
            taxId: 'Tax ID',
          },
        },
        entity: {
          input: {
            name: 'Name',
            email: 'Email',
            dba: 'DBA',
            type: 'Type',
            taxId: 'Tax ID',
            docType: 'Document Type',
          },
        },
      },
    },
    // investment section
    investment: {
      title: 'Investment',
      subTitle: {
        transactions: 'Transactions',
        depositAddresses: 'Deposit Addresses',
        investments: 'Investments',
      },
      label: {
        investment: 'investment',
        round: 'Round:',
        status: 'Status: ',
        investmentStatus: 'Investment Status:',
        pledgedAmount: 'Pledged Amount:',
        pledgedDate: 'Pledge Date:',
        pledgedInitiator: 'Pledge Initiator:',
        totalFunded: 'Total Funded:',
        subscriptionAgreement: 'Subscription Agreement:',
        subscriptionAgreementSignedAt: 'Signature date:',
        transaction: 'transaction',
        token: 'Token',
        isEvergreenRoundStatus: 'Evergreen',
        table: {
          transactionTime: 'Date',
          tokenName: 'Token',
          roundName: 'Round',
          direction: 'Type',
          amount: 'Amount',
          currencyId: 'Currency',
          worth: '{0} Value',
          source: 'Source',
          externalTransactionConfirmation: 'TX ID',
          type: 'Type',
          address: 'Address',
          investorData: 'Investor Data',
        },
      },
      status: {
        round: {
          active: 'active',
          planned: 'planned',
          done: 'done',
        },
        transactionType: {
          deposit: 'deposit',
          withdraw: 'withdraw',
          liquidation: 'liquidity',
          refund: 'refund',
        },
        source: {
          manual: 'manual',
          automated: 'automated',
        },
      },
      button: {
        investment: {
          toolTip: 'Select a token to add investment',
          loading: 'Creating Investment',
          add: 'Add Investment',
          sendInstructionsEmail: {
            sendInstructions: 'Send Instructions',
            toolTip: 'Please add an investment to send instructions to custodian',
          },
          sendAgreementEmail: {
            sendAgreement: 'Send Agreement',
          },
        },
        transaction: {
          add: 'Add transaction',
          toolTip: 'Cannot add transaction without investment',
        },
      },
      message: {
        noData: 'There is no investment yet.',
        selectRound: 'Please select a round',
        noRound: 'Select a round to view investments',
      },
      option: {
        subscriptionAgreement: {
          none: 'None',
          requested: 'Requested',
          sent: 'Sent',
          signed: 'Signed',
          confirmed: 'Confirmed',
          rejected: 'Rejected',
          preSigned: 'Pre Signed',
        },
        investmentStatus: {
          none: 'None',
          inProgress: 'In-Progress',
          pending: 'Pending',
          confirmed: 'Confirmed',
          na: 'n/a',
        },
      },
    },
    investmentTableFields: {
      date: 'Date',
      pledge: 'Pledge',
      funded: 'Funded',
      subscriptionAgreement: 'Subscription Agreement',
      subscriptionAgreementSignedAt: 'Signature Date',
      investmentStatus: 'Investment Status',
    },
    addTransactionModal: {
      title: 'Add transaction',
      input: {
        date: 'Date',
        direction: 'Type',
        amount: {
          text: 'Amount',
          placeholder: 'e.g. 500',
        },
        currency: 'Currency',
        worth: '{0} Value',
        txid: 'TX ID (optional)',
      },
    },
    editTransactionModal: {
      title: 'Edit transaction',
      input: {
        txid: 'TX ID',
        direction: 'Type',
        worth: {
          text: '{0} Value',
          placeholder: 'e.g. 500',
        },
      },
    },
    editIssuanceModal: {
      title: 'Edit Issuance',
      input: {
        status: 'Status',
        issuanceTime: 'Issuance date',
      },
    },
    editDepositWalletModal: {
      title: 'Edit deposit wallet',
      input: {
        investorData: {
          text: 'Investor Data',
          placeholder: 'Textarea',
        },
      },
    },
    // tokens & wallets section
    tokensWallets: {
      title: 'Tokens & Wallets',
      subTitle: {
        general: 'General',
        forRound: 'For Round: {0}',
        wallets: 'Wallets',
        issuances: 'Issuances',
      },
      label: {
        wallet: 'wallet',
        issuance: 'issuance',
        blockchainId: 'Blockchain ID:',
        totalTokensHeld: 'Total Tokens Held:',
        isAnAffiliate: 'Investor is an affiliate',
        totalTreasuryTokens: 'Total TBE Securities:',
        totalBonusTokens: 'Total Bonus Tokens:',
        totalLockedTokens: 'Total Locked Tokens',
        bonusTokens: 'Bonus Tokens:',
        assignedBonusTokens: 'Assigned Bonus Tokens:',
        assignedTokens: 'Assigned Tokens:',
        sourceTypeWallet: 'wallet',
        sourceTypeTreasuryTBE: 'TBE',
        dripParticipant: 'DRIP Participant',
        table: {
          wallets: {
            name: 'Name',
            address: 'Address',
            walletType: 'Wallet Type',
            owner: 'Owner',
            status: 'Status',
            tokensPending: 'Tokens Pending',
            tokensHeld: 'Tokens Held',
            tokensLocked: 'Tokens Locked',
            creationDate: 'Creation Date',
            tokenName: 'Token',
            blockchainType: 'Blockchain type',
          },
          issuances: {
            id: 'ID',
            creationDate: 'Created',
            executionDate: 'Executed',
            issueAmount: 'Issue Amount',
            source: 'Source',
            target: 'Target',
            status: 'Status',
            issuanceTime: 'Issuance Date',
            description: 'Description',
            tokenName: 'Token',
          },
        },
      },
      info: {
        aboutTokensRefertTo: '"Tokens" refers to Securities in Token Form',
        aboutTbeStandsFor: '"TBE" stands for Traditional Book Entry',
        hasActiveIssuance: 'Assigned Tokens are pending signature',
      },
      status: {
        walletType: {
          normal: 'normal',
          omnibus: 'omnibus',
        },
        source: {
          manual: 'manual',
          automated: 'automated',
        },
        walletsList: {
          treasury: 'TBE',
          wallet: 'wallet',
        },
      },
      button: {
        bonus: {
          move: 'Move Bonus',
        },
        wallets: {
          add: 'Add wallet',
          transferFromTreasury: 'Transfer to wallet from TBE',
        },
        issuance: {
          add: 'Add issuance',
        },
      },
      option: {
        walletStatuses: {
          syncing: 'syncing',
          syncingInvestorPays: 'syncing investor pays',
          pending: 'pending',
          ready: 'ready',
          rejected: 'rejected',
          cancelled: 'cancelled',
        },
        issuanceStatuses: {
          pending: 'pending',
          processing: 'processing',
          success: 'success',
          failure: 'failure',
          amended: 'amended',
        },
      },
      transactionHistory: {
        options: {
          transactionHistoryTypes: {
            issuance: 'Issuance',
            transferOk: 'Transfer Ok',
            destroy: 'Destroy',
            redemption: 'Redemption',
          },
        },
      },
      autoIssuanceConfirmationModal: {
        title: 'Assign Tokens Auto Issuance',
        message: 'Note that when changing the assigned tokens for this investor, while auto-issuance is enabled, you will trigger an issuance record pending to signature',
      },
    },
    moveBonusModal: {
      title: 'Move Bonus',
      message: 'Please confirm you wish to move {0} Assigned Bonus Tokens tokens to Bonus Tokens',
    },
    addInvestorWalletModal: {
      title: 'Add wallet',
      input: {
        name: 'Name',
        address: 'Address',
      },
      blockchainType: 'Blockchain type',
    },
    addIssuanceModal: {
      title: 'Issue Tokens',
      label: {
        roundIssuanceDate: 'Round issuance date:',
        clearAssignedTokens: 'Clear assigned tokens after successful issuance',
        costBasisCalculation: 'Cost Basis Calculation',
        totalAmountFunded: 'Total Amount Funded',
        pricePerUnit: 'Price Per Unit',
      },
      input: {
        issueTo: 'Issue to:',
        reason: {
          text: 'Description:',
          placeholder: 'Type your description here...',
        },
        tokenAmount: 'Token amount:',
        numberOfTokens: {
          text: 'Number of Tokens:',
          placeholder: 'e.g. 500',
        },
      },
      message: {
        confirm: 'Please confirm the issuance of {amount} tokens to {name}',
      },
      option: {
        tokenAmount: {
          roundsAssignedTokens: 'Round’s assigned tokens',
          customTokenAmount: 'Custom token amount',
        },
        walletsList: {
          treasury: 'TBE',
        },
      },
    },
    editTokenTransaction: {
      title: 'EditTransaction',
      label: {
        roundIssuanceDate: 'Round issuance date:',
        clearAssignedTokens: 'Clear assigned tokens after issuance',
      },
      input: {
        transactionDate: 'Date',
        transactionType: 'Transaction Type',
        tokensAmount: 'Units',
        pricePerUnit: 'Price Per Unit',
        transactionAmount: 'Transaction Amount',
      },
    },
    editInvestorWalletModal: {
      title: 'Edit wallet',
      input: {
        name: 'Name',
        address: 'Address',
        status: 'Status',
      },
    },
    transferTreasuryModal: {
      loading: 'loading...',
      amountToWithdraw: 'Withdrawable Balance: {0}',
      amountToWithdrawInputLabel: 'Amount to Withdraw',
      title: 'Transfer Treasury to Token Wallet',
      input: {
        issueTo: 'Issue to:',
      },
    },
    // transaction history section
    transactionHistory: {
      title: 'Transaction History',
      label: {
        table: {
          transactionDate: 'Date',
          transactionType: 'Transaction Type',
          units: 'Units',
          price: 'Price Per Unit',
          transactionAmount: 'Transaction Amount',
        },
      },
      option: {
        transactionType: {
          sell: 'Sell',
          buy: 'Buy',
          redemption: 'Redemption',
          issuance: 'Issuance',
          reallocation: 'Reallocation',
          destroy: 'Destroy',
          p2p: 'P2P',
        },
      },
    },
    // payouts section
    payouts: {
      title: 'Payout History',
      label: {
        table: {
          distributionType: 'Distribution Type',
          confirmationDate: 'Payout Date',
          payoutMethod: 'Payout Method',
          units: 'Units',
          withholding: 'Withholding',
          netAmount: 'Net Amount',
        },
      },
      button: {
        payout: {
          add: 'Add payout',
        },
      },
      option: {
        statusesList: {
          paid: 'paid',
          notPaid: 'not paid',
          rejected: 'rejected',
        },
        payoutTypeOptions: {
          dividend: 'dividend',
          buyback: 'buyback',
          profitSharing: 'profit-sharing',
          other: 'other',
        },
      },
    },
    createPayoutModal: {
      title: 'Add payout',
      input: {
        payoutDate: 'Payout date:',
        payoutWallet: 'Payout wallet:',
        payoutAmount: {
          text: 'Payout amount:',
          placeholder: 'e.g. 500',
        },
        tokensAmount: {
          text: 'Tokens amount:',
          placeholder: 'e.g. 500',
        },
        status: 'Status:',
        payoutType: 'Payout type:',
        payoutDescription: {
          text: 'Payout description:',
          placeholder: 'Payout description...',
        },
      },
    },
    // affiliate section
    affiliate: {
      title: 'Affiliate',
      label: {
        table: {
          status: 'Status',
          createdAt: 'Date',
          comment: 'Comment',
        },
      },
      status: {
        yes: 'yes',
        no: 'no',
        pending: 'pending',
      },
      message: {
        issuerSupportLink: 'Securitize is your Transfer Agent and can be notified of an affiliate status change by emailing Securitize Issuer Support.',
        issuerSupportMail: '{0}: Affiliate change request',
        setAffiliatePendingToolTip: 'Investor cannot be set as an affiliate when Pending',
        setAffiliateYesToolTip: 'Investor is already an affiliate',
      },
      button: {
        affiliate: {
          set: 'Set as Affiliate',
        },
      },
    },
    setAffiliateModal: {
      title: 'Set as affiliate',
      input: {
        date: 'Date:',
        comment: {
          text: 'Comment:',
          placeholder: 'Textarea',
        },
      },
    },
    // documents section
    documents: {
      title: 'Documents',
      label: {
        document: 'document',
        table: {
          image: 'Image',
          documentTitle: 'Title',
          type: 'Type',
          documentFace: 'Document Face',
          createdAt: 'Creation Date',
          origin: 'Origin',
          token: 'Token',
        },
      },
      status: {
        type: {
          passport: 'passport',
          other: 'other',
          driverLicense: 'driver-license',
          nationalIdentityCard: 'national-identity-card',
          residencePermit: 'residence-permit',
          visa: 'visa',
          passportCard: 'passport-card',
          workPermit: 'work-permit',
          stateId: 'state-id',
          ukBiometricResidencePermit: 'uk-biometric-residence-permit',
          taxId: 'tax-id',
          voterId: 'voter-id',
          certificateOfNaturalisation: 'certificate-of-naturalisation',
          homeOfficeLetter: 'home-office-letter',
          immigrationStatusDocument: 'immigration-status-document',
          birthCertificate: 'birth-certificate',
        },
        face: {
          front: 'Front',
          back: 'Back',
        },
        origin: {
          internal: 'Internal',
          securitizeId: 'SecuritizeID',
        },
      },
      button: {
        document: {
          download: 'Download',
          add: 'Add document',
        },
      },
      message: {
        downloadFailure: 'Failed to fetch file from server',
      },
      option: {
        type: {
          passport: 'Passport',
          other: 'Other',
          driverLicense: 'Driver License',
          nationalIdentityCard: 'National ID card',
          residencePermit: 'Residence permit',
          visa: 'Visa',
          passportCard: 'Passport card',
          workPermit: 'Work permit',
          stateId: 'State ID',
          ukBiometricResidencePermit: 'UK biometric residence permit',
          taxId: 'Tax ID',
          voterId: 'Voter ID',
          certificateOfNaturalisation: 'Certificate of naturalisation',
          homeOfficeLetter: 'Home office letter',
          immigrationStatusDocument: 'Immigration status document',
          birthCertificate: 'Birth certificate',
          selfie: 'Selfie',
          certificateOfFormation: 'Certificate of formation',
          articlesOfOrganization: 'Articles of organization',
          operatingAgreement: 'Operating Agreement',
          addressProof: 'Proof of address',
          shareholdersListAndAuthorisedSignersList: 'Members list and authorized signer list',
          byLaws: 'By-laws',
          cashCard: 'Cash Card',
          healthInsuranceCard: 'Health Insurance Card',
          driverHistoryCertificate: 'Driver History Certificate',
          myNumberCard: 'My Number Card',
          specialPermanentResidentCertificate: 'Special Permanent Resident Certificate',
          residentRecordCard: 'Resident Record Card',
        },
        face: {
          front: 'Front',
          back: 'Back',
        },
      },
    },
    addDocumentModal: {
      title: 'Add Document',
      input: {
        category: 'Category',
        documentFace: 'Document Face',
        showToInvestor: 'Show to Investor',
        title: 'Document Title',
        tokenName: 'Token',
      },
      message: {
        noFileFailure: 'Please upload the document',
        fileSizeFailure: 'File must be between 50KB and 10MB',
      },
    },
    previewDocumentModal: {
      title: 'Investor Document - Preview',
      label: {
        imageAlt: 'Document Preview',
      },
    },
    confirmInvestorTypeModal: {
      title: 'Change investor type',
      text: 'Note that when changing the investor type, some of the information might be lost after you save your changes',
    },
    confirmKycStatusModal: {
      title: 'KYC Ownership',
      text: 'Note that when changing a KYC status for a Securitize ID investor you are taking full responsibility for the KYC process. You can click on \'Cancel\' to undo your selection.',
    },
    confirmPIIModel: {
      title: 'KYC Ownership',
      /* eslint-disable max-len */
      text: 'Note that when changing a PII for a Securitize ID investor you are taking full responsibility for the KYC process. Additional Info, Additional Private Info and Comment are excluded. You can click on \'Cancel\' to undo your selection.',
    },
    anchors: {
      generalInformation: 'General Information',
      kycAccreditation: 'KYC & Accreditation',
      investment: 'Investment',
      tokenWallets: 'Token & Wallets',
      documents: 'Documents',
    },
  },
};
