import qs from 'qs';
import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class AuditLogIssuerCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2' };

    that.authorizationHeader = function authorizationHeader() {
      // const _store = operatorStore;
      // that.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = { };
      // localVarHeaderParams.authorization = `Bearer ${that.configuration.apiKey}`;

      return localVarHeaderParams;
    };

    this.getAuditLogsByIssuerId = function getAuditLogsByIssuerId(params) {
      const { issuerId } = params;
      delete params.issuerId;
      const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/audit-logs`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
        params,
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'brackets' }),
      });
    };

    this.getAuditLogOperatorsByIssuerId = function getOperatorsByIssuerId(issuerId) {
      const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/audit-logs/operators`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
      });
    };

    this.getAuditLogTokensByIssuerId = function getTokensByIssuerId(issuerId) {
      const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/audit-logs/tokens`;
      return axios.get(localVarPath, {
        headers: that.authorizationHeader(),
      });
    };
  }
}

export const { getAuditLogsByIssuerId, getAuditLogOperatorsByIssuerId, getAuditLogTokensByIssuerId } = new AuditLogIssuerCpApi();
