export default {
  controlBookTopSection: {
    label: {
      numberOfAuthorizedSecurities: '発行可能{0}',
      numberOfIssuedSecuritiesCb: '発行済{0}（CB）',
      numberOfIssuedSecuritiesMsf: '発行済{0}（MSF）',
      totalIssuedToBlockchain: 'ブロックチェーン上の発行数',
      totalIssuedToDrsSecurities: 'トレジャリー上の発行数',
      totalWhenIssuedSecurities: 'ボーナストークン発行数',
      principalAmount: '債券合計額',
      number: '証券数',
    },
  },
  transferAgentHead: {
    title: '証券代行',
  },
};
