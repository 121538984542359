export default {
  component: {
    cpTable: {
      button: {
        checkbox: {
          all: 'Select all {totalItems} on {tableTitle}',
          clear: 'Clear Selection',
        },
      },
      message: {
        numberSelected: 'You have chosen {0} Investors',
        clearAll: 'All {totalItems} investors on {tableTitle} are selected.',
        loading: 'Loading table data, please wait..',
        error: 'Something went wrong.',
        noRecords: 'No records were found for your filter definitions.',
      },
    },
  },
};
