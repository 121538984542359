import { defaultCountrySettingsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import axios from '~/utilities/axios';


const {
  getDefaultCountrySettings,
} = defaultCountrySettingsApi;
const defaultCountrySettingsArguments = {
  page: undefined,
  limit: undefined,
  q: undefined,
  orderDirection: undefined,
  orderField: undefined,
};

const basePath = '/api/v2/default-country-settings';
const config = { headers: { 'Content-Type': 'application/json' } };

export default {
  getDefaultCountrySettings({ commit }, params) {
    const args = getArgumentsForApi(defaultCountrySettingsArguments, params);
    return getDefaultCountrySettings(...args)
      .then(({ data }) => {
        commit('SET_SYSTEM_COUNTRIES', data.data);
        return data.totalItems;
      }).catch((err) => {
        this.$log.error('System countries get error:', err);
      });
  },

  getDefaultCountrySettingsById(ctx, id) {
    const url = `${basePath}/${id}`;
    return axios.get(url, config).then().catch((err) => {
      this.$log.error('System country get error:', err);
    });
  },

  updateDefaultCountrySettings({ commit }, { id, data }) {
    const url = `${basePath}/${id}`;
    return axios.post(url, data).then(() => {
      commit('global/CALL_TOASTER', 'Country settings are updated', { root: true });
    }).catch((err) => {
      this.$log.error('System countries update error:', err);
    });
  },

  getDefaultCountrySettingsList(ctx, { params }) {
    const args = getArgumentsForApi(defaultCountrySettingsArguments, params);
    return getDefaultCountrySettings(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('System countries get error:', err);
        return err;
      });
  },
};
