<template>
  <div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
    <div
      class="ui-bg-overlay bg-dark ui-bg-cover login-bg"
      :style="{'background-image':`url(${getImagePath('login-background.jpg','theme')}`}"
    />
    <div class="authentication-inner py-5">
      <b-card no-body>
        <div class="p-4 p-sm-5">
          <!-- Logo -->
          <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
            <div class="ui-w-60">
              <div class="w-100 position-relative">
                <img
                  class="d-block w-100"
                  :src="getImagePath('securitize.png', 'logo')"
                  alt="Securitize"
                >
              </div>
            </div>
          </div>

          <cp-input-container ref="cpInputContainer">
            <div class="form-group">
              <cp-input
                ref="emailInput"
                v-model.trim="email"
                name="email"
                :label="$t('home.input.email')"
                input-type="email"
                placeholder="e.g. jamie@gmail.com"
                validate="required|email"
              />
            </div>
            <div class="form-group pb-4">
              <cp-input
                id="issuer-name"
                v-model.trim="password"
                name="password"
                :label="$t('home.input.password')"
                input-type="password"
                placeholder="password"
                validate="required"
              />
            </div>

            <button
              type="button"
              class="btn btn-outline-primary btn-block mb-3"
              :disabled="isLoading"
              @click="singInWithPassword"
            >
              <span v-if="!isLoading">{{ $t('home.button.signIn') }}</span>
              <b-spinner
                v-if="isLoading"
                class="align-middle"
                small
              />
            </button>

            <b-btn
              variant="primary"
              class="btn-block btn-google btn-block blue"
              :disabled="isLoading"
              @click="singIn"
            >
              <span>
                <img :src="getImagePath('logo-google.png','logo')">
              </span>
              {{ $t('home.button.google') }}
            </b-btn>

            <vue-recaptcha
              v-if="sitekey"
              ref="invisibleRecaptcha"
              size="invisible"
              :sitekey="sitekey"
              :load-recaptcha-script="true"
              @verify="onVerifyRecaptcha"
            />
          </cp-input-container>
          <div
            v-if="loginError"
            class="alert alert-danger mt-3 mb-0"
          >
            <small>
              {{ errorMessage }}
              <a
                v-if="errorMessage === $t('home.message.error')"
                href="mailto:issuersupport@securitize.io"
              >
                issuersupport@securitize.io
              </a>
            </small>
          </div>
        </div>
        <div
          v-if="!featureFlag"
          class="card-footer text-center"
        >
          {{ $t('home.message.noAccount') }} <router-link
            to="/register"
          >
            {{ $t('home.link.register') }}
          </router-link>
        </div>
      </b-card>
    </div>
    <twofa-modal
      ref="twofaModal"
      :is-button-active="twofaIsButtonActive"
      :twofa-token="twofaToken"
      @verifyTwofa="verifyTwofaAndLogin"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import twofaModal from '~/components/common/twofa-modal';
import { login as googleLoginAuth } from '~/utilities/googleAuth';
import routeNames from '~/utilities/routes';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import { CpInput } from '~/components/common/standalone-components/inputs';
import cpInputContainer from '~/components/common/cpInputContainer';

export default {
  metaInfo: {
    title: 'Securitize',
  },
  components: {
    twofaModal,
    VueRecaptcha,
    CpInput,
    cpInputContainer,
  },
  mixins: [
    staticPathGenerationMixin,
  ],
  data() {
    return {
      email: '',
      password: '',
      loginError: false,
      featureFlag: process.env.VUE_APP_HIDE_REGISTER_LINK,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      isLoading: false,
      twofaToken: '',
      twofaIsButtonActive: false,
      recaptchaToken: undefined,
    };
  },
  methods: {
    ...mapActions('currentOperator', ['login', 'googleLogin']),
    ...mapMutations('currentOperator', ['SET_REGISTER_TOKEN']),
    routeToPreLoginUrl(basePath) {
      const lastRoute = localStorage.getItem('lastRoute');
      if (lastRoute) {
        localStorage.removeItem('lastRoute');
        this.$router.push({ path: lastRoute });
      } else this.$router.push({ path: basePath });
    },
    async singIn() {
      this.loginError = false;
      this.isLoading = true;

      try {
        const operator = await googleLoginAuth();
        if (!operator || !operator.authorization || !operator.authorization.access_token) {
          this.isLoading = false;
          this.loginError = true;
          this.$log.warn('No auth response object');
          this.$log.warn(operator);
          this.registerErrorMessage();
          return;
        }

        const { authorization } = operator;

        const { issuerId, defaultTokenId } = await this.googleLogin(authorization.access_token);

        const route = issuerId ? routeNames.dashboard(issuerId, defaultTokenId) : routeNames.issuerList();
        this.routeToPreLoginUrl(route);
      } catch (error) {
        this.isLoading = false;
        this.loginError = true;
        this.registerErrorMessage();
      }
    },
    singInWithPassword() {
      if (this.sitekey) {
        this.$refs.cpInputContainer.validateForm().then(() => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.$refs.invisibleRecaptcha.execute();
          }
        });
      } else {
        this.onVerifyRecaptcha();
      }
    },
    onVerifyRecaptcha(recaptchaToken) {
      this.isLoading = true;
      this.recaptchaToken = recaptchaToken;
      this.login({
        email: this.email,
        password: this.password,
        recaptchaToken,
        tfaToken: this.twofaToken || null,
      }).then(({ issuerId, defaultTokenId }) => {
        this.isLoading = false;
        this.SET_REGISTER_TOKEN(undefined);
        const route = issuerId ? routeNames.dashboard(issuerId, defaultTokenId) : routeNames.issuerList();
        this.routeToPreLoginUrl(route);
      }).catch((error) => {
        if (this.sitekey) {
          this.$refs.invisibleRecaptcha.reset();
        }
        this.isLoading = false;
        const { name } = error.response.data;
        this.registerErrorMessage(name);
        });
    },
    registerErrorMessage(error = '') {
      switch (error) {
        case 'WRONG_EMAIL_OR_PASSWORD':
          this.errorMessage = this.$t('home.message.wrongEmailOrPassword');
          this.loginError = true;
          break;
        case 'ACCOUNT_WAS_LOCKED':
          this.errorMessage = this.$t('home.message.lock');
          this.loginError = true;
          break;
        case 'UNAUTHORIZED':
          this.errorMessage = this.$t('home.message.loginProcessFailed');
          this.isLoading = false;
          this.loginError = true;
          break;
        case 'FORBIDDEN':
          this.isLoading = false;
          this.$refs.twofaModal.show();
          break;
        default:
          this.errorMessage = this.$t('home.message.error');
          this.loginError = true;
      }
    },
    verifyTwofaAndLogin(tfaToken) {
      this.loginError = false;
      this.isLoading = true;
      this.twofaIsButtonActive = false;
      this.login({
        email: this.email,
        password: this.password,
        tfaToken,
        recaptchaToken: this.recaptchaToken || null,
      }).then(({ issuerId, defaultTokenId }) => {
        this.isLoading = false;
        this.SET_REGISTER_TOKEN(undefined);
        const route = issuerId ? routeNames.dashboard(issuerId, defaultTokenId) : routeNames.issuerList();
        this.routeToPreLoginUrl(route);
      }).catch((error) => {
          this.$refs.twofaModal.clearDigits();
          if (error.response.data.statusCode === 403) {
            this.twofaToken = '';
            this.twofaIsButtonActive = true;
          } else {
            this.isLoading = false;
            this.registerErrorMessage(error.response.data.message);
          }
          return error;
        });
    },
  },
};
</script>

<style src="~/vendor/styles/pages/authentication.scss" lang="scss"></style>
<style lang="scss">
  .btn-google.blue{
    background-color: #3275e4;
    &:disabled {
      background: #04bec4!important;
    }
  }
</style>
