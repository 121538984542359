export default {
  distributions: {
    title: '分配',
    label: {
      description: `ある時点の各投資家のトークン保有量をスナップショットとして記録し、
        投資家への支払いなどに使います。`,
      table: {
        name: 'スナップショット名',
        date: '日付',
        status: 'ステータス',
      },
    },
    status: {
      distributed: '分配済み',
      none: 'なし',
    },
    button: {
      snapshot: {
        create: 'スナップショット作成',
        edit: '編集',
        view: '閲覧',
      },
      csv: {
        download: 'ダウンロード',
      },
      distribution: {
        generate: '分配の実行',
        confirm: '分配の確認',
      },
    },
    snapshotModal: {
      title: {
        common: 'スナップショット{0}',
        edit: '編集',
        generate: '作成',
      },
      input: {
        name: {
          text: 'スナップショット名',
          defaultValue: '{0}のスナップショット',
        },
        dateTime: '日時',
      },
    },
    generateDistributionModal: {
      title: '分配作成',
      input: {
        distributedAmount: '保有者に分配される{distributionModel} ({currencySymbol})',
        distributionModel: '分配モデル',
        distributionMethod: '分配方法',
      },
      button: {
        ok: '分配を保存してダウンロード',
        loading: 'Loading...',
      },
      option: {
        distributionModel: {
          total: '分配の総額',
          perToken: 'トークンあたりの金額',
        },
        distributionMethod: {
          bankAccount: '銀行口座',
          directToWallet: 'ウォレット送金',
        },
      },
    },
  },
};
