export default {
  issuerAgreements: {
    title: '契約書管理',
    button: {
      agreements: {
        add: '契約書の追加',
      },
    },
    label: {
      table: {
        name: '契約書名',
        title: 'タイトル',
      },
    },
    // modal
    agreementModal: {
      title: '契約書{0}',
      input: {
        name: '契約書名',
        title: 'タイトル',
        subtitle: 'サブタイトル',
        type: 'タイプ',
        yesText: '承認ボタン文言',
        noText: '否認ボタン文言',
        documentUrl: 'ドキュメントURL',
        checkOptions: 'チェックオプション',
        text: '契約書本文',
      },
      status: {
        yes: '承認',
        yesNo: '承認/否認',
      },
    },
  },
};
