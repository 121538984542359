export default {
  configurations: {},
  filters: {
    domains: {
      all: [],
    },
    configKeys: {
      all: [],
    },
  },
};
