import Cookies from 'js-cookie';

const RECENT_ISSUERS_LIST = 'recentIssuersList';
const routesToAvoidFetch = ['index', 'dashboard', 'signup'];
const routesToFetchNavList = routesToAvoidFetch.filter(route => route !== 'dashboard');

function getRecentIssuersCookie() {
  const recentIssuersCookie = localStorage.getItem(RECENT_ISSUERS_LIST) || '[]';
  return JSON.parse(recentIssuersCookie);
}

function clearOverFlowIssuers(recentIssuerList) {
  const recentIssuers = recentIssuerList;
  while (recentIssuers.length > 6) {
    recentIssuers.pop();
  }
  return recentIssuers;
}

function updateRecentIssuersLocalStorage(issuer) {
  const recentIssuers = getRecentIssuersCookie();
  const filteredRecentIssuers = recentIssuers.filter(({ id }) => id !== issuer.id);
  filteredRecentIssuers.unshift(issuer);
  clearOverFlowIssuers(filteredRecentIssuers);
  localStorage.setItem(RECENT_ISSUERS_LIST, JSON.stringify(clearOverFlowIssuers(filteredRecentIssuers)));
}

function clearRecentIssuersListCookie() {
  const recentIssuersInCookie = Cookies.get(RECENT_ISSUERS_LIST);
  const recentIssuersInLocalStorage = localStorage.getItem(RECENT_ISSUERS_LIST);

  if (recentIssuersInCookie) {
    Cookies.remove(RECENT_ISSUERS_LIST);
    if (!recentIssuersInLocalStorage) {
      localStorage.setItem(RECENT_ISSUERS_LIST, recentIssuersInCookie);
    }
  }
}

export default async function ($store, $route, $router) {
  const issuerId = $route.params.idIssuer;
  const issuers = $store.getters['global/issuers'];
  const issuersNavList = $store.getters['global/issuersNavList'];
  const countries = $store.getters['global/countries'];
  const currencies = $store.getters['global/currencies'];
  const roundsList = $store.getters['rounds/rounds'];

  if (!issuers.length && !routesToAvoidFetch.includes($route.name)) {
    const limit = Cookies.get('issuerListPerPage');
    const params = {};
    if (limit) params.limit = limit;
    if ($route.query && $route.query.search) params.search = $route.query.search;
    if ($route.query && $route.query.labels) params.labels = Array.isArray($route.query.labels) ? $route.query.labels : [$route.query.labels];
    await $store.dispatch('global/fetchIssuersList', params);
  }

  if (!issuersNavList.length && !routesToFetchNavList.includes($route.name)) {
    await $store.dispatch('global/fetchIssuersNavList', { issuerId });
  }

  if (issuerId) {
    const issuer = $store.getters['global/issuersNavList'].find(({ id }) => id === issuerId);
    if (!issuer) {
      $router.push('issuerList');
    } else {
      $store.commit('issuerList/SELECTED_ISSUER', issuer);
      const recentIssuer = {
        id: issuer.id,
        name: issuer.name,
        defaultTokenId: issuer.defaultTokenId,
        logoDark: issuer.logoDark,
      };
      updateRecentIssuersLocalStorage(recentIssuer);
    }
    if (!countries.length || !currencies.length || !roundsList.length) {
      await $store.dispatch('global/fetchIssuerInfo', issuerId);
    }
    await $store.dispatch('issuersInfo/getPermissionsPerIssuer', { issuerId });
  } else {
    $store.commit('issuerList/CLEAR_SELECTED_ISSUER');
  }

  clearRecentIssuersListCookie();
}
