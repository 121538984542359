import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';

export default async () => {
    const isDistributionLegacy = process.env.VUE_APP_IS_DISTRIBUTION_LEGACY === 'true';

    if (!isDistributionLegacy) {
        return Frame(
            'distribution',
            'Distributions',
            './DistributionApp',
            process.env.VUE_APP_DISTRIBUTION_MFE_ENTRY_POINT,
            () => {
                const { state: { idUUID: operatorId } } = currentOperatorStore;
                return { operatorId };
            },
        );
    }

    return import('@/pages/_idIssuer/distributions/index');
};
