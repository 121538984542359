export default {
  dashboard: {
    chart: {
      title: {
        countries: '国',
        type: 'タイプ',
      },
      legend: {
        pledged: '申込み済',
        funded: '資金受取済',
        registered: '登録済',
        authorized: '許可済',
        rest: 'その他',
      },
    },
    activities: {
      title: 'アクティビティ',
      label: {
        newInvestorRegistered: '新規投資家登録: ',
        newInvestorFrom: '{from}からの新規投資家: {investor}',
        openFinance: 'OpenFinance',
        fundsReceivedFrom: '{from}からの受取額: {amount}',
        subscriptionSignedBy: '{by}による署名済み申込額: {amount}',
        newSignatureRequired: {
          path: '{0}',
          for: '署名が必要です',
        },
        walletRegisteredBy: '{0}によりウォレットが登録されました',
        investorRequiringManualReview: 'レビューが必要な投資家:',
      },
      button: {
        showMore: 'もっと見る',
      },
    },
    fundraiseChartCard: {
      title: '資金調達状況',
      label: {
        status: 'ステータス',
        amountPledged: '申込み額',
        amountRaised: '調達額',
      },
      status: {
        active: 'アクティブ',
        inactive: '非アクティブ',
      },
      loading: 'データを読み込み中...',
    },
    holdersChartCard: {
      title: '保有者',
      label: {
        usHolders: '米国保有者',
        euHolders: '欧州保有者',
        worldwideHolders: '全世界保有者',
        maximumStakes: '上位保有者',
        tokens: 'トークン',
      },
      loading: '保有者データを読込中...',
    },
    onboardingChartCard: {
      title: 'オンボーディング',
      label: {
        totalRegistered: '登録人数',
        totalPledged: '申込み済',
        totalFunded: '支払い済',
      },
      loading: 'オンボーディングデータを読み込み中...',
    },
  },
};
