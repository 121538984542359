export default {
  controlBookTopSection: {
    label: {
      numberOfAuthorizedSecurities: '{0} of Authorized Securities',
      numberOfIssuedSecuritiesCb: '{0} of Issued Securities (CB)',
      numberOfIssuedSecuritiesMsf: '{0} of Issued Securities (MSF)',
      totalIssuedToBlockchain: 'Total issued to blockchain',
      totalIssuedToDrsSecurities: 'Total issued to TBE securities',
      totalWhenIssuedSecurities: 'Total when-issued securities',
      totalIssuedToBlockchainDebt: 'Principal Amount on Blockchain',
      totalIssuedToDrsSecuritiesDebt: 'Principal Amount of TBE Securities',
      totalWhenIssuedSecuritiesDebt: 'Total When-Principal Amount Securities',
      principalAmount: 'Principal Amount',
      number: 'Number',
    },
  },
  transferAgentHead: {
    title: 'Transfer Agent',
  },
};
