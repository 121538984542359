import FileSaver from 'file-saver';
import { snapshotsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import {
  getDistributionDownloadUrl,
  getPayoutsSnapshotRecords,
  getTaxFormSignedUrl,
} from '@/utilities/external-api/apis/payoutCpApi';
import { i18n } from '~/i18n';

const { getSnapshotRecords } = snapshotsApi;
const snapshotRecordsArguments = {
  issuerId: undefined,
  snapshotId: undefined,
  tokenId: undefined,
  q: undefined,
  page: undefined,
  limit: undefined,
  hasPayoutWallet: undefined,
  orderDirection: undefined,
  orderField: undefined,
};

export default {
  getSnapshotRecords({ commit }, { ...params }) {
    const args = getArgumentsForApi(snapshotRecordsArguments, params);
    return getSnapshotRecords(...args)
      .then(({ data }) => {
        commit('SET_SNAPSHOT_RECORDS', data.data);
        commit('SET_SNAPSHOT_RECORDS_TOTAL_ITEM', data.totalItems);
        return data;
      }).catch((err) => {
        this.$log.error('SnapshotRecords get error:', err);
        if (err.response.body.message) {
          throw err.response.body.message;
        }
      });
    },
  getPayoutsSnapshotRecords({ commit }, params) {
    return getPayoutsSnapshotRecords(params)
      .then(({ data }) => {
        commit('SET_SNAPSHOT_RECORDS', data.data);
        commit('SET_SNAPSHOT_RECORDS_TOTAL_ITEM', data.totalItems);
        return data;
      }).catch((err) => {
        this.$log.error('SnapshotRecords get error:', err);
        if (err.response.body.message) {
          throw err.response.body.message;
        }
      });
  },
  exportSnapshotsRecords({ commit }, { name, ...params }) {
    const args = getArgumentsForApi(snapshotRecordsArguments, params);
    const options = {
      responseType: 'blob',
      headers: { 'Content-Type': 'text/csv', accept: 'text/csv' },
    };
    return getSnapshotRecords(...args, options)
        .then(({ data }) => {
          const blob = new Blob([data]);
          FileSaver.saveAs(blob, `${name}_data.csv`);
          commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshotsRecords.exportSnapshotsRecords'), { root: true });
          return data;
        }).catch((err) => {
          this.$log.error('exportSnapshotsRecords download CSV file error:', err);
        });
  },
  getSnapshotRecordsList(ctx, { params }) {
    const args = getArgumentsForApi(snapshotRecordsArguments, params);
    return getSnapshotRecords(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Snapshot Records get error:', err);
        return err;
      });
  },
  getPayoutsSnapshotRecordsList(ctx, { params }) {
    return getPayoutsSnapshotRecords(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Snapshot Records get error:', err);
        return err;
      });
  },
  getDistributionDownloadUrl({ commit }, params) {
    return new Promise((res, rej) => {
      getDistributionDownloadUrl(params)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshotsRecords.exportSnapshotsRecords'), { root: true });
        window.open(data.url, '_self');
        res(true);
      }).catch((err) => {
        this.$log.error('getDistributionDownloadUrl get error:', err);
        if (err.response.body.message) {
          throw err.response.body.message;
        }
        rej(err);
      });
    });
  },
  getTaxFormSignedUrl(ctx, params) {
    getTaxFormSignedUrl(params)
      .then(({ data }) => {
        window.open(data.url, '_self');
      }).catch((err) => {
        this.$log.error('getTaxFormSignedUrl get error:', err);
        if (err.response.body.message) {
          throw err.response.body.message;
        }
      });
  },
};
