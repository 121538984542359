export default {
  component: {
    cpTable: {
      button: {
        checkbox: {
          all: '{tableTitle}からすべて({totalItems})選択',
          clear: '選択をクリア',
        },
      },
      message: {
        numberSelected: '{0} 個のアイテムを選択中',
        clearAll: '{tableTitle}のすべて({totalItems} 個)のアイテムが選択されました',
        loading: 'データ読み込み中。しばらくお待ち下さい。',
        error: 'エラーが発生しました。',
        noRecords: '選択条件に合うレコードは見つかりませんでした。',
      },
    },
  },
};
