import Vue from 'vue';
import Vuex from 'vuex';
import global from './modules/global';
import issuerList from './modules/issuer-list';
import issuersInfo from './modules/issuers-info';
import operators from './modules/operators';
import fundraise from './modules/configuration/fundraise';
import opportunities from './modules/configuration/opportunities';
import rounds from './modules/rounds';
import investors from './modules/investors';
import messages from './modules/messages';
import currentOperator from './modules/current-operator';
import jurisdictions from './modules/configuration/jurisdictions';
import jurisdictionsPerToken from './modules/configuration/jurisdictions-per-token';
import configToken from './modules/configuration/token';
import configuration from './modules/configuration/general-conf';
import signatures from './modules/signatures';
import blockchainMessages from './modules/blockchain-messages';
import dashboard from './modules/dashboard';
import systemAgreements from './modules/system-config/agreements';
import systemQuestions from './modules/system-config/questions';
import systemCountries from './modules/system-config/countries';
import systemEmails from './modules/system-config/email-temlates';
import systemTexts from './modules/system-config/system-texts';
import walletConnectConfig from './modules/system-config/wallet-connect';
import controlBook from './modules/transfer-manager/control-book';
import dailyTransactionLog from './modules/transfer-manager/daily-transaction-log';
import snapshots from './modules/snapshots';
import snapshotsRecords from './modules/snapshots/records';
import distributions from './modules/snapshots/distributions';
import issuerAffiliates from './modules/advanced/issuer-affiliates';
import issuerAffiliateCampaigns from './modules/advanced/issuer-affiliate-campaigns';
import issuerAgreements from './modules/advanced/issuer-agreements';
import issuerBankAccounts from './modules/advanced/issuer-bank-accounts';
import issuerConfigurationVariables from './modules/advanced/issuer-configuration-variables';
import issuerCurrencies from './modules/advanced/issuer-currencies';
import issuerEmailTemplates from './modules/advanced/issuer-email-templates';
import issuerPlatformDocuments from './modules/advanced/issuer-platform-documents';
import issuerQuestion from './modules/advanced/issuer-question';
import issuerTexts from './modules/advanced/issuer-texts';
import issuerPrerenderedAddresses from './modules/advanced/issuer-prerendered-addresses';
import affiliateManagement from './modules/transfer-manager/affiliate-management';
import procedures from './modules/transfer-manager/procedures';
import omnibusWalletOwner from './modules/omnibus-wallet-owner';
import securitizeId from './modules/securitize-id';
import brokerDealerGroups from './modules/broker-dealer-groups';
import predefinedLabels from './modules/system-config/predefined-lables';
import custodians from './modules/custodians';
import brokerDealerOpportunities from './modules/broker-dealer/opportunities';
import brokerDealerInvestors from './modules/broker-dealer/investors';
import brokerDealerSystemConfig from './modules/broker-dealer/system-config';
import brokerDealerAccreditations from './modules/broker-dealer/accreditations';
import outreach from './modules/outreach';
import auditLog from './modules/audit-log';
import privacyControl from './modules/privacy-control';
import issueDetails from './modules/issue-details';
import clientConfigurations from './modules/client-configuration';
import auditLogIssuer from './modules/audit-log-issuer';
import authorize from './modules/authorize';
import organizations from './modules/organizations';
import stableCoins from './modules/configuration/stable-coins';
import socketio from './modules/socketio';
import importInvestors from './modules/import-investors';
import createWebSocketPlugin from './plugins/webSocketPlugin';
import navigationHistory from './modules/navigation-history';
import importIssuance from '@/store/modules/import-issuance';
import issuerLabels from './modules/issuer-labels';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    global,
    issuerList,
    issuersInfo,
    operators,
    investors,
    messages,
    currentOperator,
    jurisdictions,
    jurisdictionsPerToken,
    configuration,
    configToken,
    signatures,
    blockchainMessages,
    dashboard,
    fundraise,
    opportunities,
    rounds,
    systemAgreements,
    systemQuestions,
    systemCountries,
    systemEmails,
    controlBook,
    dailyTransactionLog,
    snapshots,
    snapshotsRecords,
    distributions,
    systemTexts,
    issuerAffiliates,
    issuerAffiliateCampaigns,
    issuerAgreements,
    issuerBankAccounts,
    issuerConfigurationVariables,
    issuerCurrencies,
    issuerEmailTemplates,
    issuerPlatformDocuments,
    issuerQuestion,
    issuerTexts,
    issuerPrerenderedAddresses,
    affiliateManagement,
    procedures,
    omnibusWalletOwner,
    securitizeId,
    brokerDealerGroups,
    predefinedLabels,
    outreach,
    issueDetails,
    custodians,
    brokerDealerOpportunities,
    brokerDealerInvestors,
    brokerDealerSystemConfig,
    brokerDealerAccreditations,
    auditLog,
    privacyControl,
    clientConfigurations,
    auditLogIssuer,
    authorize,
    organizations,
    stableCoins,
    socketio,
    importInvestors,
    importIssuance,
    navigationHistory,
    walletConnectConfig,
    issuerLabels,
  },
  plugins: [
    createWebSocketPlugin(),
  ],
  strict: debug,
});

export default store;
