/* eslint-disable */
export default {
  methods: {
    doesTextExceedContainer(text, maxWidth) {
      if (!text && !maxWidth) {
        return false;
      }

      // Creating a temporary element
      const measureElement = document.createElement('span');
      measureElement.id = 'measure';
      measureElement.style.visibility = 'hidden';
      measureElement.style.whiteSpace = 'nowrap';
      measureElement.style.position = 'absolute';
      measureElement.style.top = '-9999px';

      measureElement.textContent = text;
      document.body.appendChild(measureElement);
      const textWidth = measureElement.offsetWidth;
      document.body.removeChild(measureElement);

      return textWidth >= maxWidth;
    },
  },
};
