export default {
  privacyControl: {
    header: {
      title: {
        privacyControl: 'Privacy Control',
      },
      disclaimer: {
        thisScreenAllows: {
          line1: 'This screen allows you to monitor and control the email addresses of the operators that you wish to grant them view & edit permission of the investor\'s personal information.',
          line2: 'To add a new allowed operator, click on \'Add Operator\' and insert a valid email of an operator that has access to this issuer.',
        },
      },
      button: {
        addOperator: 'Add Operator',
      },
    },
    table: {
      name: 'Name',
      email: 'Email',
      createdAt: 'Created',
    },
    deleteOperatorModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to remove the email {0} from the list?',
    },
    addOperatorModal: {
      title: 'Add Allowed Operator Email',
      message: 'Insert an Email address of an operator that you wish to grant him view/edit permission:',
      placeholder: 'Operator Email',
    },
  },
};
