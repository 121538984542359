<template>
  <div>
    <h5 class="text-center mb-4 font-weight-bold">
      {{ $t('twoFactorAuth.title.set') }}
    </h5>

    <h6 class="font-weight-bold">
      {{ $t('twoFactorAuth.label.step1.title') }}<span class="font-weight-normal">{{ $t('twoFactorAuth.label.step1.text') }}</span>
    </h6>
    <div class="mb-4">
      <div class="app-info d-flex align-items-center justify-content-center mt-4">
        <img
          :src="gAuth"
          class="p-1"
        >
        <p class="small apps-description font-16 ml-3 m-0 pr-5">
          {{ $t('twoFactorAuth.label.googleAuthDetail') }}
        </p>
      </div>
      <div class="app-info d-flex align-items-center justify-content-center mt-2">
        <img
          :src="authy"
          class="p-1"
        >
        <p class="small apps-description font-16 ml-3 m-0 pr-5">
          {{ $t('twoFactorAuth.label.authyDetail') }}
        </p>
      </div>
    </div>

    <h6 class="font-weight-bold">
      {{ $t('twoFactorAuth.label.step2.title') }}<span class="font-weight-normal">{{ $t('twoFactorAuth.label.step2.text') }}</span>
    </h6>
    <div class="qr-code text-center mb-4">
      <qr-code-oauth
        v-if="twoFaURI"
        :uri="twoFaURI"
      />
    </div>
  </div>
</template>

<script>
import gAuth from '~/assets/img/auth/g_auth.svg';
import authy from '~/assets/img/auth/authy.svg';
import qrCodeOauth from './qr-code-ouath';

export default {
  name: 'RegisterInfo',
  components: {
    qrCodeOauth,
  },
  props: {
    twoFaURI: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gAuth,
      authy,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-info {
  img {
    width: 40px;
    height: 40px;
  }
}

.qr-code {
  height: 120px;
}
</style>
