/* eslint-disable import/prefer-default-export */
import qs from 'qs';
import axios from '@/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class BrokerDealerCpApi {
  constructor() {
    this.configuration = {
      apiKey: '',
      basePath: '/api/v2',
    };

    this.authorizationHeader = () => {
      // const _store = operatorStore;
      // this.configuration.apiKey = _store.state.accessToken;
      const localVarHeaderParams = {};
      // localVarHeaderParams.authorization = `Bearer ${this.configuration.apiKey}`;

      return localVarHeaderParams;
    };

    this.getAllOpportunities = ({ search, issuerIds, type } = {}) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities`;
      const params = {};
      if (search) params.search = search;
      if (issuerIds && issuerIds.length) params.issuerIds = issuerIds;
      if (type && type.length) params.type = type;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
        params,
        paramsSerializer: p => qs.stringify(p, { arrayFormat: 'brackets' }),
      });
    };

    this.createOpportunity = (opportunity) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities`;
      return axios.post(localVarPath, opportunity, {
        headers: this.authorizationHeader(),
      });
    };

    this.getIssuerOriginalOpportunities = ({ issuerId, tokenId }) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/${issuerId}/${tokenId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.deleteOpportunity = (opportunityId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/${opportunityId}`;
      return axios.delete(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getOpportunityById = (opportunityId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/${opportunityId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateOpportunity = (opportunityId, opportunity) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/${opportunityId}`;
      return axios.patch(localVarPath, opportunity, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAllTags = () => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/tags`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getInvestorsList = ({ q, 'order-direction': order, ...params }) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors`;
      if (q) {
        Object.assign(params, { search: q });
      }
      return axios.get(localVarPath, {
        params,
        headers: this.authorizationHeader(),
      });
    };

    this.getInvestorById = (investorId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getLegalSignersByInvestorId = (investorId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/legal-signers`;
      const localVarHeaderParams = { ...this.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    this.getLegalSignerById = (investorId, legalSignerId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}`;
      const localVarHeaderParams = { ...this.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    this.getLegalSignerDocumentById = (investorId, legalSignerId, documentId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/legal-signers/${encodeURIComponent(legalSignerId)}/legal-signer-documents/${encodeURIComponent(documentId)}`;
      const localVarHeaderParams = { ...this.authorizationHeader(), ...headers };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    this.getInvestorKycHistory = (investorId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/kyc-history`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getDocumentsByInvestorId = (investorId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/documents`;
      return axios.get(localVarPath, {
        headers: { ...this.authorizationHeader(), ...headers },
      });
    };

    this.getConfidentialDocumentsByInvestorId = (investorId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/confidential-documents`;
      return axios.get(localVarPath, {
        headers: { ...this.authorizationHeader(), ...headers },
      });
    };

    this.getInvestorDocumentById = (investorId, documentId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/documents/${encodeURIComponent(documentId)}`;
      return axios.get(localVarPath, {
        headers: { ...this.authorizationHeader(), ...headers },
      });
    };
    this.getInvestorConfidentialDocumentById = (investorId, documentId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/confidential-documents/${encodeURIComponent(documentId)}`;
      return axios.get(localVarPath, {
        headers: { ...this.authorizationHeader(), ...headers },
      });
    };

    this.getWalletsByInvestorId = (investorId, headers) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${encodeURIComponent(investorId)}/wallets`;
      return axios.get(localVarPath, {
        headers: { ...this.authorizationHeader(), ...headers },
      });
    };

    this.getCsvInvestors = (params) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/csv`;
      return axios.get(localVarPath, {
        params,
        headers: { ...this.authorizationHeader(), ...{ accept: 'text/csv' } },
      });
    };

    this.updateOpportunitiesOrder = (opportunities) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/opportunities/order`;
      return axios.put(localVarPath, { opportunities }, {
        headers: this.authorizationHeader(),
      });
    };

    this.getSuitabilityStatusLog = (investorId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/suitability-logs`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAccountStatusLog = (investorId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/account-status-logs`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getBrokerDealerVerificationLog = (investorId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/broker-dealer-verification-logs`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateSuitabilityByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/suitability`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateEuSuitabilityByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/eu-suitability`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateAccountStatusByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/account-status`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateEuAccountStatusByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/eu-account-status`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateBrokerDealerVerificationByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/broker-dealer-verification`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateAccreditationStatusByInvestorId = (investorId, body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/investors/${investorId}/accreditation`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };

    this.getFeatureFlags = () => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/system-config`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.updateFeatureFlag = (body) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/system-config`;
      return axios.patch(localVarPath, body, {
        headers: this.authorizationHeader(),
      });
    };
  }
}

export const {
  getAllOpportunities,
  createOpportunity,
  getIssuerOriginalOpportunities,
  deleteOpportunity,
  getOpportunityById,
  updateOpportunity,
  getAllTags,
  getInvestorsList,
  getInvestorById,
  getLegalSignersByInvestorId,
  getLegalSignerById,
  getLegalSignerDocumentById,
  getInvestorKycHistory,
  getDocumentsByInvestorId,
  getConfidentialDocumentsByInvestorId,
  getInvestorDocumentById,
  getInvestorConfidentialDocumentById,
  getWalletsByInvestorId,
  getCsvInvestors,
  updateOpportunitiesOrder,
  getSuitabilityStatusLog,
  getAccountStatusLog,
  getBrokerDealerVerificationLog,
  updateSuitabilityByInvestorId,
  updateEuSuitabilityByInvestorId,
  updateAccountStatusByInvestorId,
  updateEuAccountStatusByInvestorId,
  updateBrokerDealerVerificationByInvestorId,
  updateAccreditationStatusByInvestorId,
  getFeatureFlags,
  updateFeatureFlag,
} = new BrokerDealerCpApi();
