export default {
  issuerPlatformDocuments: {
    title: 'Issuer Platform documents',
    label: {
      table: {
        name: 'Name',
        title: 'Title',
        url: 'Url',
        type: 'Type',
        category: 'Category',
      },
    },
    button: {
      document: {
        add: 'Add document',
      },
    },
    status: {
      category: {
        basic: 'basic',
        investorOnly: 'investor only',
      },
    },
  },
  issuerDocumentModal: {
    title: '{0} Document',
    input: {
      name: 'Name',
      title: 'Title',
      url: 'Url',
      type: 'Type',
      category: 'Category',
      created: 'Created',
      updated: 'Updated',
    },
    option: {
      category: {
        basic: 'Basic',
        investorOnly: 'Investor Only',
      },
    },
  },
};
