export default {
  configurationJurisdictions: {
    title: 'Jurisdictions',
    loading: 'Loading Jurisdictions Data...',
    button: {
      exportJurisdictionsSettings: 'Export',
      importJurisdictionsSettings: 'Import',
      ok: 'Ok',
      cancel: 'Cancel',
    },
    // Restricted Jurisdictions section
    restrictedJurisdictions: {
      title: 'Restricted Jurisdictions',
      message: {
        description: `In this section jurisdictions which will not allow token holders can be defined.
        These restrictions will apply only for fundraise (investor onboarding).
        Issued tokens cannot change restricted countries via Control Panel.`,
      },
      label: {
        countries: 'Countries',
        restrictedCountries: 'Restricted countries',
        state: 'States / Regions',
        restrictedState: 'Restricted States / Regions',
      },
    },
    // Disclaimers and Definitions section
    disclaimersAndDefinitions: {
      title: 'Disclaimers and Definitions',
      subTitle: {
        generalDisclaimer: 'General Disclaimer',
        standardCountryDisclaimer: 'Standard Country Disclaimer',
        standardQualificationDefinition: 'Standard Qualification Definition',
      },
      label: {
        placeholder: 'Standard text...',
        generalDisclaimer: 'This disclaimer is presented and accepted by all investors to sign-up.',
        standardCountryDisclaimer: 'This disclaimer may be additionally presented for investors in certain countries.',
        standardQualificationDefinition: 'This is the default definition for an investor to be allowed to invest.',
      },
    },
    // Per Jurisdiction Disclaimers and Definitions
    perCountryTable: {
      addStateAndRegion: 'Add state/region',
      title: 'Per Jurisdiction Disclaimers and Definitions',
      label: {
        table: {
          country: 'Country',
          regionState: 'Region/State',
          disclaimerType: 'Disclaimer',
          qualificationType: 'Qualification',
          qualificationRequired: 'Qual.Req',
          reverseSolicitation: 'Reverse Solicitation',
        },
      },
      status: {
        disclaimerTypes: {
          standard: 'standard',
          specific: 'specific',
          none: 'none',
        },
        qualTypes: {
          standard: 'standard',
          specific: 'specific',
        },
      },
    },
    // modal
    addStateRegionModal: {
      title: 'Add State/Region',
      country: 'Country',
      state: 'State',
      region: 'Region',
    },
    jurisdictionsEditModal: {
      title: 'Edit {0}{1}',
      label: {
        disclaimer: 'Disclaimer',
        qualification: 'Qualification',
        placeholder: 'Write your text here...',
        additionalAccreditation: 'Additional Accreditation',
        accreditationFirst: 'Accreditation First',
        reverseSolicitation: 'Reverse Solicitation',
      },
      input: {
        initialAgreement: 'Initial Agreement',
        accreditedAgreements: 'Accredited agreements',
        qualification: 'Qualification required?',
        qualificationTitle: 'Qualification Title',
      },
      option: {
        disclaimer: {
          standard: 'Standard',
          specific: 'Issuer Agreements',
          none: 'None',
        },
        qualification: {
          standard: 'Standard',
          specific: 'Issuer Agreements',
        },
        qualificationTitle: {
          accredited: 'Accredited',
          qualification: 'Qualification',
          professional: 'Professional',
          other: 'Other',
        },
      },
      message: {
        qualification: 'Investors not matching the qualification criteria cannot invest',
      },
    },
    confirmDeleteModal: {
      title: 'Delete state/region jurisdiction settings',
      message: 'Are you sure you want to delete state/region jurisdiction settings?',
    },
    importJurisdictionsModal: {
      title: 'Import Jurisdictions',
      label: {
        update: 'Please upload a CSV file:',
      },
      button: {
        ok: 'Import Jurisdictions',
        cancel: 'Close',
      },
    },
    importJurisdictionsErrorsModal: {
      title: 'Import Jurisdictions',
      label: {
        fieldName: 'Field Name',
        errorType: 'Error Type',
        message: 'Message',
        csvErrorList: 'Field Name,Error Type,Message\n',
      },
      button: {
        download: 'Download errors',
      },
      message: {
        invalid: 'Invalid CSV',
      },
    },
  },
};
