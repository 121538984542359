export default {
  issuerQuestions: {
    title: 'FAQ',
    button: {
      question: {
        add: '質問を追加',
      },
    },
    label: {
      table: {
        section: 'セクション',
        question: '質問',
        position: '並び順',
      },
    },
    status: {
      generalQuestions: '一般的な質問',
      investmentRequirements: '投資要件',
      investmentProcess: '投資プロセス',
      offeringDetails: '募集詳細',
    },
    // modal
    questionsModal: {
      title: '質問{0}',
      input: {
        question: '質問',
        position: '並び順',
        section: 'セクション',
        answer: '答え',
      },
    },
  },
};
