export default {
  defaultCountrySettings: {
    label: {
      qualificationRequired: 'Qualification required',
      additionalAccreditation: 'Additional Accreditation',
      accreditationFirst: 'Accreditation First',
      accreditationText: 'Qualification Title',
      agreementOnEmail: 'Agreement On Email',
      customTitle: 'Custom Title',
    },
  },
};
