import { systemQuestionsApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const {
  createSystemQuestion,
  deleteSystemQuestionById,
  getSystemQuestions,
  getSystemQuestionById,
  updateSystemQuestionById,
} = systemQuestionsApi;
const systemQuestionsArguments = {
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};
export default {
  createSystemQuestion({ commit }, body) {
    return createSystemQuestion(body)
      .then(() => {
        commit('global/CALL_TOASTER', 'System question is created', { root: true });
      }).catch((err) => {
        this.$log.error('System question create error:', err);
      });
  },

  updateSystemQuestionById({ commit }, { id, data }) {
    return updateSystemQuestionById(id, data)
      .then(() => {
        commit('global/CALL_TOASTER', 'System question is updated', { root: true });
      }).catch((err) => {
        this.$log.error('System question update error:', err);
      });
  },

  deleteSystemQuestionById({ commit }, id) {
    return deleteSystemQuestionById(id)
      .then(() => {
        commit('global/CALL_TOASTER', 'System question is deleted', { root: true });
      }).catch((err) => {
        this.$log.error('System question delete error:', err);
      });
  },

  getSystemQuestionById(ctx, id) {
    return getSystemQuestionById(id)
      .catch((err) => {
        this.$log.error('Get system questions by ID error:', err);
      });
  },

  getSystemQuestionsList(ctx, { params }) {
    const args = getArgumentsForApi(systemQuestionsArguments, params);
    return getSystemQuestions(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('System Questions get error:', err);
        return err;
      });
  },
};
