export default {
  legalSigner: {
    title: 'Legal Signer',
    backButton: 'Back to Investor Details',
    editButton: 'Edit Legal Signer',
    addLegalSignerDocumentModal: {
      title: 'Add Legal Signer Document',
      input: {
        category: 'Category:',
      },
      cancelButton: 'Cancel',
      addLegalSignerDocumentButton: 'Add Legal Signer Document',
    },
    previewLegalSignerDocumentModal: {
      title: 'Preview Legal Signer Document',
      label: {
        imageAlt: 'Legal Signer Document Preview',
      },
    },
    generalInformation: {
      title: 'General Information',
      inputs: {
        signerType: 'Legal Signer Type:',
        email: 'Email:',
        dba: 'DBA:',
        name: 'Name:',
        firstName: 'First Name:',
        middleName: 'Middle Name:',
        lastName: 'Last Name:',
        birthdate: 'Birthdate',
        taxId: 'TaxID:',
        type: 'Type:',
        country: 'Country:',
        state: 'State:',
        city: 'City:',
        postalCode: 'Postal Code:',
        streetName: 'Street Name:',
        streetNumber: 'Street Number:',
        ultimateBeneficialOwner: 'Ultimate beneficial owner (25% or more):',
        taxCountry: 'Tax Country:',
      },
      editTooltipMessage: 'Personal Information cannot be changed for this investor. Please contact support',
      note: 'Note that when changing the investor type, some of the information might be lost.',
    },
    documents: {
      title: 'Documents',
      addDocumentButton: 'Add document',
      downloadButton: 'Download',
      deleteButton: 'Delete',
      note: 'At least one document must be associated',
      imageAlt: 'Document Image',
      removeModal: {
        title: 'Delete Legal Signer Document',
      },
    },
  },
};
