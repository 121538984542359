
// eslint-disable-next-line import/prefer-default-export
export const login = () => {
  const { google } = window;
  const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;
  return new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      prompt: 'select_account',
      scope: 'profile email',
      callback: async (authorization) => {
        try {
          resolve({ authorization });
        } catch (error) {
          reject(error);
        }
      },
      error_callback: (err) => {
        reject(err);
      },
    });

    client.requestAccessToken();
  });
};

// TODO: deprecated by ISR2-1413
// export const performGoogleRegister = () => {
//   const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;
//   const { gapi } = window;
//   return new Promise((resolve, reject) => {
//     gapi.load('client:auth2', () => {
//       gapi.client.init({
//         redirect_uri: 'postmessage',
//         client_id: GOOGLE_CLIENT_ID,
//         scope: 'profile',
//       }).then(() => gapi.auth2.getAuthInstance().signIn({
//         prompt: 'select_account',
//       })).then(() => {
//         const GoogleAuth = gapi.auth2.getAuthInstance();
//         const user = GoogleAuth.currentUser.get();
//         resolve(user);
//       }).catch(reject);
//     });
//   });
// };
