
export default {
  SET_IMPORT_ISSUANCE_PROGRESS(state, value) {
    state.importIssuanceProgress = value;
  },
  CLEAN_IMPORT_ISSUANCE_PROGRESS(state) {
    state.importIssuanceProgress = {};
  },
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
  TOGGLE_MODAL_OPEN(state, value) {
    state.isModalOpen = value;
  },
  TOGGLE_IS_SENDING_CSV(state, value) {
    state.isSendingCsv = value;
  },
};
