import { controlBookApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  createControlBookRecord,
  getControlBook,
  getControlBookRecords,
  updateControlBookRecordById,
} = controlBookApi;
const controlBookArguments = {
  issuerId: undefined,
  tokenId: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  fetchRecords({ commit }, { ...params }) {
    params = {
      'order-direction': 'desc',
      ...(params || {}),
    };
    const args = getArgumentsForApi(controlBookArguments, params);
    return getControlBookRecords(...args)
      .then(({ data }) => {
        commit('SET_RECORDS', data.data);
      }).catch((err) => {
        this.$log.error('Control book records get error:', err);
      });
  },

  updateRecord({ commit }, {
    issuerId, tokenId, recordId, data,
  }) {
    return updateControlBookRecordById(issuerId, tokenId, recordId, data)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.controlBook.updateRecord'), { root: true });
      });
  },
  fetchSummary({ commit }, { issuerId, tokenId }) {
    return getControlBook(issuerId, tokenId)
      .then(({ data }) => {
        commit('SET_SUMMARY', data);
      }).catch((err) => {
        this.$log.error('Control book stats get error:', err);
      });
  },

  updateSecurities({ dispatch, commit }, { issuerId, tokenId, ...data }) {
    return createControlBookRecord(issuerId, tokenId, data)
      .then(() => {
        dispatch('fetchRecords', { issuerId, tokenId });
        dispatch('fetchSummary', { issuerId, tokenId });
        commit('global/CALL_TOASTER', i18n.t('store.toaster.controlBook.updateSecurities'), { root: true });
      }).catch((err) => {
        this.$log.error('Control book add record get error:', err);
      });
  },

  getIssuerControlBookRecords(ctx, { params }) {
    const args = getArgumentsForApi(controlBookArguments, params);
    return getControlBookRecords(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Control Book Records get error:', err);
        return err;
      });
  },
};
