import moment from 'moment';
import CpCountryName from '~/components/common/country-name.vue';
import {
  currenciesApi,
  infoApi,
  issuersApi,
  assetsApi,
} from '~/utilities/mainApiClient';
import axios from '~/utilities/axios';
import axiosV2 from '~/utilities/axiosV2';

const basePath = '/api/v2/';
const basePathNewGW = '/isr/api';

const { getCurrencies } = currenciesApi;
const { getInfo, getStatesAndRegions } = infoApi;
const { getIssuersNavList } = issuersApi;
const { getS3PresignedUrl } = assetsApi;


const { getCountryName } = CpCountryName;
let query;

export default {
  fetchStatesAndRegions({ commit }) {
    return getStatesAndRegions()
      .then(({ data }) => {
        commit('SET_GLOBAL_STATES_AND_REGIONS', data);
      })
      .catch((err) => {
        commit('SET_GLOBAL_STATES_AND_REGIONS_ERROR', err);
        this.$log.error('Fetch states and regions get error:', err);
      });
  },
  fetchCurrencies({ commit }, issuerId, page = 0, limit = 999) {
    return getCurrencies(issuerId, query, page, limit)
      .then(({ data }) => {
        commit('SET_GLOBAL_CURRENCIES', data.data);
      })
      .catch((err) => {
        commit('SET_GLOBAL_CURRENCIES_ERROR', err);
        this.$log.error('Fetch currencies get error:', err);
      });
  },

  fetchIssuersList({ commit }, params = {}) {
    const { page, limit = 6, search, view, labels = [] } = params;
    const url = `${basePathNewGW}/v1/issuers`;
    const filterParams = { page, q: search, limit, view, includes: 'labels', labels };
    return axiosV2.get(url, { params: filterParams, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => {
        const issuers = (data && data.data) || [];
        commit('SET_GLOBAL_ISSUERS', issuers);
        commit('issuerList/SET_ISSUERS', data, { root: true });
        return data;
      }).catch((err) => {
        this.$log.error('Fetch issuers get error:', err);
      });
  },

  fetchIssuersNavList({ commit }, { issuerId }) {
    return getIssuersNavList(issuerId)
      .then(({ data }) => {
        const issuers = (data && data.data) || [];
        commit('SET_GLOBAL_ISSUERS_NAV_LIST', issuers);
      }).catch((err) => {
        this.$log.error('Fetch issuers nav list get error:', err);
      });
  },

  fetchIssuerInfo({ commit }, issuerId) {
    return getInfo(issuerId).then(({ data }) => {
      commit('SET_GLOBAL_CURRENCIES', data.currencies);
      commit('ISSUER_MAIN_CURRENCY', data);
      commit('issuersInfo/ISSUER_INFO', data, { root: true });
      commit('rounds/SET_ROUNDS', data.rounds, { root: true });
      commit('SET_GLOBAL_COUNTRIES',
        [].concat(data.countries).sort((a, b) => {
          const aCName = getCountryName(a);
          const bCName = getCountryName(b);
          return (aCName > bCName ? 1 : (bCName > aCName ? -1 : 0));
        }));
    }).catch((err) => {
      this.$log.error('Fetch issuer info error:', err);
    });
  },
  async setLocale({ commit }, locale) {
    this.$i18n.locale = locale;
    moment.locale(locale);
    commit('SET_LOCALE', locale);
    commit('UPDATE_GLOBAL_COUNTRIES', locale);

    const url = `${basePath}local`;
    try {
      const config = { headers: { 'Content-Type': 'application/json' } };
      await axios.put(url, { languageCode: locale }, config);
    } catch (err) {
      this.$log.error('Put Locale error:', err);
      throw err;
    }
  },
  getUploadLink(context, useSecure = false) {
    return getS3PresignedUrl({ params: { 'use-secure': useSecure } })
      .then(({ data }) => data)
      .catch(err => this.$log.error('Fetch upload link error:', err));
  },
};
