export default {
  outreach: {
    prependTitle: '投資家',
    title: 'メール送付',
    loading: 'メール送付データ読み込み中...',
    resetDomainButton: 'ドメインをリセット',
    upgradeMessage: '投資家エンゲージメントを最大化！',
    upgradeText: 'Securitize投資家のメール送付を使用すると投資家と直接繋がることができます',
    upgradeInfo: '投資家のメール送付機能を使用すると、発行体はコントロールパネルから直接投資家へのEメール送信および追跡ができます。',
    upgradeButton: '営業担当へ問い合わせ',
    label: {
      tab: {
        dashboard: 'ダッシュボード',
        contacts: '連絡先',
        sendEmail: 'Eメールを送る',
        tooltipEmail: 'Your domain must be authenticated to send emails',
      },
      table: {
        investorName: '投資家',
        emailAddress: 'Eメール',
        country: '居住国',
        investorType: 'タイプ',
        investorStatus: 'ステータス',
        tokenAmount: 'トークン発行数',
        percentage: '割合',
        kycStatus: 'KYCステータス',
        walletRegistrationStatus: 'ウォレット登録ステータス',
        accreditationStatus: '適格性ステータス',
        investedStatus: '投資した',
        subscriptionAgreementStatus: 'SAステータス',
        rounds: 'ラウンド',
        labels: 'ラベル',
        fundingStatus: '資金ステータス',
        subject: '件名',
        date: '日付',
        recipients: '受信者',
        delivered: '配信済み',
        bounce: '配信失敗',
        open: '開封済み',
        click: 'クリック数',
        unsubscribe: '登録を解除',
        spamreport: '迷惑メール報告',
      },
      filter: {
        country: '居住国',
        all: 'すべて',
        investorType: '投資家タイプ',
        kycStatus: 'KYC ステータス',
        accreditationStatus: '適格性ステータス',
        walletStatus: 'ウォレットステータス',
        fundingStatus: '資金ステータス',
        percentage: 'トークン発行数',
        investorStatus: '投資家ステータス',
        subscriptionAgreementStatus: 'SA ステータス',
        rounds: 'ラウンド',
        labels: 'ラベル',
      },
    },
    status: {
      kyc: {
        none: 'なし',
        pending: '保留中',
        processing: '処理中',
        manualReview: '個別レビュー',
        processingError: '進行エラー',
        passed: '承認',
        rejected: '却下',
      },
      walletRegistration: {
        none: 'なし',
        pending: '保留中',
        syncing: '同期中',
        ready: '準備完了',
        rejected: '不合格',
        cancelled: '取消済',
      },
      investor: {
        ready: '発行準備完了',
        pendingKyc: 'KYC待ち',
        pendingAccreditation: '適格性評価待ち',
        pendingInvestorSignature: '投資家署名待ち',
      },
    },
    option: {
      fundingStatus: {
        commited: '申込み済',
        funded: '支払い済',
      },
      percentage: {
        ten: '10%',
        quarter: '25%',
        half: '50%',
        threeQuarter: '75%',
      },
      kycStatus: {
        processing: '処理中',
        processing_error: '進行エラー',
        pending: '保留中',
        pending_aml: '保留中AML',
        manual_review: '個別レビュー',
        passed: '承認',
        rejected: '却下',
      },
      investorStatus: {
        pendingKyc: 'KYC待ち',
        pendingAccreditation: '適格性評価待ち',
        pendingInvestorSignature: '投資家署名待ち',
        pendingIssuerSignature: '発行体署名待ち',
        pendingFunding: '入金待ち',
        ready: '準備完了',
      },
    },
    dashboard: {
      message: {
        causeDescription: `投資家に電子メール通信を送信する前に、
        AmazonやGodaddyなどのドメインホストプロバイダーにDNSレコードを追加して、
        ドメインを認証する必要があります。`,
        actionDescription: `以下の情報を入力して、必要なDNSレコードを生成し、
        貴社の技術者に送信します。`,
        reset: 'ドメインがリセットされました',
        confirmation: '警告：新しいドメインが認証されるまで、Eメールのサービスとアクティビティは無効になります',
        confirmationButton: 'ドメインをリセット',
        footerText: '説明ビデオを見る',
      },
      label: {
        submitButton: '送信',
        domain: 'ドメイン*',
        toEmail: '送信先Eメール*',
        ccedEmail: 'CC Eメール',
        toolToEmail: 'このメール受信者は、ドメインホストプロバイダーに追加する必要のあるDNSレコードを受け取ります。（必須）',
        toolDomain: 'example.comなど、メールの送信元のドメインを入力します。（必須）',
        toolCcedEmail: 'このメール受信者は、DNSレコードが送信されるメールに追加されます。（任意）',
      },
      pendingStatus: {
        pendingTitle: 'ドメイン認証は保留中です',
        pendingMessage: 'DNSレコードがドメインホストプロバイダーに追加されると、Eメールで通知されます。',
      },
      chart: {
        sent: '送信されたEメール',
        opened: '開かれたEメール',
        click: 'Eメールのクリック数',
        days: '過去30日間',
      },
      emailActivity: {
        title: 'Eメールのアクティビティ',
        details: '詳細',
        back: '戻る',
        emailSubject: 'メールの件名：',
        dateSent: '送信日：',
      },
    },
    contact: {
      investmentTitle: '投資段階',
      createContactTitle: '連絡先リストを作成',
      savedContactListTitle: '保存された連絡先リスト',
      newList: '新しいリスト名',
      save: 'リストを保存',
    },
    email: {
      form: {
        fromAddress: '送信元のEメール',
        fromName: '送信元の名前',
        replyToAddress: '返信先のEメール',
        replyToName: '返信先の名前',
        cc: 'CC Eメール',
        bcc: 'Bcc Eメール',
        subject: '件名',
        body: 'Eメール本文',
        required: '必須',
        optional: '任意',
        send: '送る',
        test: 'プレビュー',
        example: 'example@example.com',
        confirmation: '送信してもよろしいですか？',
      },
      additionalContacts: {
        title: '追加の連絡先',
        add: '連絡先を追加',
      },
      index: {
        messageTry: 'テストメールが送信されました。',
        messageSend: 'Eメールが送信されました。',
      },
      messages: {
        duplicates: '重複するメールアドレスを削除してください',
        noSelected: 'メールアドレスが選択されていません',
        processing: 'リクエストを処理中',
      },
      toaster: {
        sent: 'Eメールが送信されました',
        listCreated: '連絡先リストが作成されました',
        listRemoved: '連絡先リストが削除されました',
      },
    },
  },
};
