import { systemEmailTemplatesApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const {
  getSystemEmailTemplates,
  getSystemEmailTemplateById,
  updateSystemEmailTemplateById,
} = systemEmailTemplatesApi;
const systemEmailTemplatesArguments = {
  page: undefined,
  limit: undefined,
  orderDirection: undefined,
  orderField: undefined,
  q: undefined,
};
export default {
  getEmailTemplateById(ctx, emailTemplateId) {
    return getSystemEmailTemplateById(emailTemplateId)
      .catch((err) => {
        this.$log.error('System email get error:', err);
      });
  },
  updateEmailTemplateById({ commit }, { id, ...body }) {
    return updateSystemEmailTemplateById(id, body)
      .then(() => {
        commit('global/CALL_TOASTER', 'System email is updated', { root: true });
      }).catch((err) => {
        this.$log.error('System email update error:', err);
      });
  },
  getEmailTemplates(ctx, { params }) {
    const args = getArgumentsForApi(systemEmailTemplatesArguments, params);
    return getSystemEmailTemplates(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('System Email Templates get error:', err);
        return err;
      });
  },
};
