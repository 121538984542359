export default {
  affiliates: {
    title: 'アフィリエイター',
    button: {
      affiliate: {
        add: 'アフィリエイターの追加',
      },
    },
    label: {
      table: {
        name: '名前',
        createdAt: '作成日',
      },
    },
    // modal
    affiliateModal: {
      title: 'アフィリエイター{0}',
      input: {
        name: '名前',
      },
      label: {
        createdAt: '作成日',
        updatedAt: '更新日',
      },
    },
  },
};
