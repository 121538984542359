const EthereumConstants = {
  wei: 1e18,
  decimalPlaces: 5,
};
export const AlgorandConstants = {
  algo: 1e6,
  decimalPlaces: 5,
};
export default EthereumConstants;
export const fromWeiToGWei = amountInWei => amountInWei / (10 ** 9);
export const fromGWeiToWei = amountInGwei => amountInGwei * (10 ** 9);
