import { sanitize } from 'dompurify';
import { i18n } from '~/i18n';
import axiosV2 from '~/utilities/axiosV2';

const basePath = '/isr/api/v1/';

export default {
  getRounds({ commit }, { issuerId, tokenId }) {
    const url = `${basePath }issuers/${issuerId}/tokens/${tokenId}/investment-rounds`;
    const params = { issuerId, tokenId };

    return axiosV2.get(url, { params, headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => {
        commit('SET_ROUNDS', data.data);
      }).catch((err) => {
        this.$log.error('Get rounds error:', err);
      });
  },

  updateRound({ commit }, { body, issuerId, roundId, tokenId }) {
    const url = `${basePath }issuers/${issuerId}/tokens/${tokenId}/investment-rounds/${roundId}`;
    return axiosV2.patch(url, body, { headers: { 'Content-Type': 'application/json' } })
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.rounds.updateRound'), { root: true });
      });
  },

  createRound({ commit }, { body, issuerId, tokenId }) {
    const url = `${basePath }issuers/${issuerId}/tokens/${tokenId}/investment-rounds`;

    return axiosV2.post(url, body, { headers: { 'Content-Type': 'application/json' } })
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.rounds.createRound'), { root: true });
      });
  },

  deleteRound({ commit }, { issuerId, tokenId, roundId }) {
    const url = `${basePath }issuers/${issuerId}/tokens/${tokenId}/investment-rounds/${roundId}`;

    return axiosV2.delete(url, { headers: { 'Content-Type': 'application/json' } })
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.rounds.deleteRound'), { root: true });
      });
  },

  getInvestmentRoundById(ctx, { issuerId, roundId, tokenId }) {
    const url = `${basePath }issuers/${issuerId}/tokens/${tokenId}/investment-rounds/${roundId}`;
    return axiosV2.get(url, { headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => {
        data.investmentTermsAndConditions = sanitize(data.investmentTermsAndConditions);
        data.investmentSubscriptionAgreement = sanitize(data.investmentSubscriptionAgreement);
        return data;
      });
  },
};
