import { agreementsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addAgreement,
  deleteAgreementsByName,
  getAgreementByName,
  getAgreements,
  updateAgreementByName,
} = agreementsApi;
const agreementsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  getIssuerAgreements({ commit }, { params }) {
    const args = getArgumentsForApi(agreementsArguments, params);
    return getAgreements(...args).then((res) => {
      commit('SET_ISSUER_AGREEMENTS', res.data);
      return res;
    }).catch((err) => {
      this.$log.error('Issuer Agreements get error:', err);
      throw err;
    });
  },

  update({ commit }, { issuerId, agreement: { name, ...data } }) {
    return updateAgreementByName(issuerId, name, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAgreements.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, name }) {
    return deleteAgreementsByName(issuerId, name).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAgreements.delete'), { root: true });
    });
  },

  create({ commit }, { issuerId, agreement }) {
    return addAgreement(issuerId, agreement).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerAgreements.create'), { root: true });
    });
  },

  getByName({ commit }, { issuerId, agreementName }) {
    return getAgreementByName(issuerId, agreementName).then(({ data }) => {
      commit('SET_ISSUER_AGREEMENT', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Agreement get error:', err);
    });
  },
};
