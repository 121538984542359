export default {
  twoFactorAuth: {
    title: {
      auth: '二要素認証（2FA）',
      set: '二要素認証（2FA）の設定',
    },
    subTitle: '二要素認証アプリを開き、 Securitize ID用に表示されたコード6桁を入力します。',
    button: {
      signIn: 'Sign in',
      set: 'Set 2FA',
    },
    input: {
      digists: 'コード6桁を入力',
    },
    message: {
      error: '問題が発生しました。コンタクトサポートにご連絡ください。',
      wrong: '2FAコードが間違っています。',
      lock: 'このアカウントはロックされています。 10分後に再度サインインできます。',
      expire: 'セッションの有効期限が切れました。 もう一度サインインしてください。',
      lost: 'アクセスコードをなくした場合は{0}にご連絡ください。',
      contactUs: 'コンタクトサポート',
    },
    label: {
      googleAuthDetail: '例）AppleストアまたはGoogle PlayストアのGoogle認証アプリ',
      authyDetail: '例）AppleストアまたはGoogle PlayストアのAuthyアプリ',
      step1: {
        title: 'ステップ１：',
        text: '二要素認証アプリをダウンロード',
      },
      step2: {
        title: 'ステップ２：',
        text: 'アプリを開いてQRコードをスキャン',
      },
    },
  },
};
