
export default {
  SET_SECURITIZE_ID_INVESTOR_INFO(state, generalInfo) {
    state.securitizeIdInvestor = generalInfo;
  },
  SET_SECURITIZE_ID_TFA(state, status) {
    state.securitizeIdInvestor.tfaEnabled = status;
  },
  CHANGE_DETAILS_EDITABLE_BLOCK(state, blockName) {
    state.editableBlock = blockName;
  },
  CREATE_SECURITIZE_ID_LEGAL_SIGNER(state, data) {
    state.createdLegalSigner = data;
  },
  SECURITIZE_ID_LEGAL_SIGNERS_LIST(state, data) {
    state.legalSignerList = data;
  },
  SECURITIZE_ID_LEGAL_SIGNER_BY_ID(state, data) {
    state.legalSignerByIdInfo = data;
  },
};
