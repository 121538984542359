<template>
  <div>
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <div
      class="input-group flex-nowrap range-block"
      :class="inputClass"
    >
      <b-input
        v-model="fromDateVal"
        type="number"
        min="0"
        :max="value[1]"
        step="any"
        @input="debouncedInput"
      />
      <div class="input-group-prepend">
        <span class="input-group-text bg-light">
          {{ $t('component.cpDateRange.to') }}
        </span>
      </div>
      <b-input
        v-model="toDateVal"
        type="number"
        min="0"
        step="any"
        @input="debouncedInput"
      />
    </div>
    <span
      v-if="notValid"
      class="text-danger range-message mt-1"
    >
      (The left value should not exceed the right value.)
    </span>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'CpDateRange',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => ([null, null]),
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'form-group',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localData: [null, null],
      notValid: false,
      controlledValue: this.value,
    };
  },
  computed: {
    fromDateVal: {
      get() {
        return this.value[0] !== null ? this.value[0] : (this.controlledValue || [])[0];
      },
      set(val) {
        this.localData[0] = val === '' ? null : +val;
      },
    },
    toDateVal: {
      get() {
        return this.value[1] !== null ? this.value[1] : (this.controlledValue || [])[1];
      },
      set(val) {
        this.localData[1] = val === '' ? null : +val;
      },
    },
  },
  watch: { // clear local data
    controlledValue(val) {
      if (val === null) {
        this.notValid = false;
        this.localData = [null, null];
      }
    },
  },
  methods: {
    debouncedInput: _.debounce(function () { // eslint-disable-line func-names
      const [first, second] = this.localData;
      this.notValid = first > second && second > 0;
      if (this.notValid) {
        return;
      }

      this.notValid = false;
      this.$emit('input', this.localData);
    }, 500),
    isCpInput() {
      return true;
    },
  },
};
</script>

<style lang="scss">
  .range-message {
    position: absolute;
    font-weight: 500;
    font-size: 0.7rem;
  }

  .range-block{
    width: 100%;
  }
</style>
