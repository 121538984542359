<template>
  <div class="row">
    <div class="col">
      <div class="dual-list-box-container row">
        <div class="box1 col-md-6">
          <label for="initialList">
            {{ label.left || $t('component.cpDualListBox.label.instances') }}
          </label>
          <div class="info-container">
            <span class="info">
              {{ initialListCount }}
            </span>
          </div>
          <input
            v-if="isFilter"
            v-model="initialListFilter"
            class="filter form-control"
            type="text"
            :placeholder="$t('common.placeholder.filter')"
            @input="debouncedGetAnswer"
          >
          <div class="btn-group buttons">
            <cp-button
              custom-class="move-all"
              :title="$t('component.cpDualListBox.label.moveAll')"
              variant="default"
              @click="allToSide('selectedOptions', 'availableOptions')"
            >
              <i class="ion ion-ios-arrow-round-forward" />
              <i class="ion ion-ios-arrow-round-forward" />
            </cp-button>
            <cp-button
              type="button"
              custom-class="move"
              :title="$t('component.cpDualListBox.label.moveSelected')"
              variant="default"
              @click="selectedToRight"
            >
              <i class="ion ion-ios-arrow-round-forward" />
            </cp-button>
          </div>
          <cp-select
            id="initialList"
            v-model="currentInstance"
            multiple
            injected-classes="form-control"
            name="_helper1"
            :options="availableOptions"
            @input="onSelect"
          />
        </div>
        <div class="box2 col-md-6">
          <label for="selectedList">
            {{ label.right || $t('component.cpDualListBox.label.selectedInstances') }}
          </label>
          <div class="info-container">
            <span class="info">
              {{ selectedListCount }}
            </span>
          </div>
          <input
            v-if="isFilter"
            v-model="selectedListFilter"
            class="filter form-control"
            type="text"
            :placeholder="$t('common.placeholder.filter')"
            @input="debouncedGetAnswer"
          >
          <div class="btn-group buttons">
            <cp-button
              custom-class="remove"
              :title="$t('component.cpDualListBox.label.removeSelected')"
              variant="default"
              @click="selectedToLeft"
            >
              <i class="ion ion-ios-arrow-round-back" />
            </cp-button>
            <cp-button
              custom-class="remove-all"
              :title="$t('component.cpDualListBox.label.removeAll')"
              variant="default"
              @click="allToSide('availableOptions', 'selectedOptions')"
            >
              <i class="ion ion-ios-arrow-round-back" />
              <i class="ion ion-ios-arrow-round-back" />
            </cp-button>
          </div>
          <cp-select
            id="selectedList"
            v-model="currentInstance"
            multiple
            injected-classes="form-control"
            :name="name"
            :options="selectedOptions"
            :error="controlledError"
            @input="onSelect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpButton from '~/components/common/standalone-components/cp-button';

export default {
  name: 'CpDualListBox',
  components: {
    CpSelect,
    CpButton,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: Object,
      default: () => ({
        left: '',
        right: '',
      }),
    },
    instances: {
      type: Array,
      required: true,
    },
    selectedInstances: {
      type: Array,
      default: () => [],
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    errorFields: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: 'form-group',
    },
    uniqueKey: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      controlledError: '',
      initialListFilter: '',
      selectedListFilter: '',
      currentInstance: [],
      availableOptions: [],
      selectedOptions: [],
    };
  },
  computed: {
    debouncedGetAnswer() {
      return debounce(() => this.applyFilter(), 1000);
    },
    initialListCount() {
      const list = this.availableOptions.length;
      return list
        ? this.$t('component.cpDualListBox.label.showingAll', [list])
        : this.$t('component.cpDualListBox.label.emptyList');
    },
    selectedListCount() {
      const list = this.selectedOptions.length;
      return list
        ? this.$t('component.cpDualListBox.label.showingAll', [list])
        : this.$t('component.cpDualListBox.label.emptyList');
    },
  },
  mounted() {
    this.availableOptions = cloneDeep(this.instances);
    this.selectedOptions = this.selectedInstances;
    this.selectedArrSubsInit('availableOptions', 'selectedOptions');
  },
  methods: {
    selectedArrSubsInit(changeableList, auxiliaryList) {
      this[changeableList] = this[changeableList].filter(
        initObj => !this[auxiliaryList].find(
          selectedObj => initObj.value[this.uniqueKey] === selectedObj.value[this.uniqueKey],
        ),
      );
      this.$emit('onChangeSelectedList', this.selectedOptions);
    },
    selectedToRight() {
      if (this.currentInstance.length) {
        this.currentInstance.forEach(item => this.selectedOptions.push({
          text: item.name,
          value: item,
        }));
        this.selectedArrSubsInit('availableOptions', 'selectedOptions');
        this.currentInstance = [];
        this.$emit('input', this.selectedOptions);
      }
    },
    selectedToLeft() {
      if (this.currentInstance.length) {
        const isItemInArray = this.availableOptions.some(
          item => item.value[this.uniqueKey] === this.currentInstance[0][this.uniqueKey],
        );
        if (!isItemInArray) {
          this.currentInstance.forEach(item => this.availableOptions.push({
            text: item.name,
            value: item,
          }));
          this.selectedArrSubsInit(
            'selectedOptions',
            'availableOptions',
          );
          this.currentInstance = [];
          this.$emit('select', {});
          this.$emit('input', this.selectedOptions);
        }
      }
    },
    allToSide(fillList, clearList) {
      this[fillList] = this.instances;
      this[clearList] = [];
      this.$emit('input', this.selectedOptions);
    },
    isCpInput() {
      return true;
    },
    onSelect([selectedItem]) {
      if (selectedItem) {
        const isItemInSelectedList = this.selectedOptions.some(item => item.value[this.uniqueKey] === selectedItem[this.uniqueKey]);
        this.$emit('select', isItemInSelectedList ? selectedItem : {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dual-list-box-container {
  label {
    display: block;
  }

  .info {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 11px;
  }

  .filter {
    display: inline-block;
    width: 100%;
    height: 31px;
    margin: 0 0 5px 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .buttons {
    width: 100%;
    margin-bottom: -1px;
  }

  .move-all {
    width: 40%;
  }

  .move {
    width: 60%;
  }

  .remove {
    width: 60%;
  }

  .remove-all {
    width: 40%;
  }

  .btn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  #initialList,
  #selectedList {
    height: 112px !important;
  }
}
</style>
