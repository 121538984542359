import { agreementsApi, defaultAgreementsApi, getArgumentsForApi } from '~/utilities/mainApiClient';

const {
  deleteDefaultAgreementById,
  updateDefaultAgreementById,
} = agreementsApi;
const {
  createDefaultAgreement,
  getDefaultAgreementById,
  getDefaultAgreements,
} = defaultAgreementsApi;
const agreementsArguments = {
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  getDefaultAgreements({ commit }, params) {
    const args = getArgumentsForApi(agreementsArguments, params);
    return getDefaultAgreements(...args)
      .then(({ data }) => {
        commit('SET_SYSTEM_AGREEMENT', data.data);
        return data;
      }).catch((err) => {
        this.$log.error('System agreements get error:', err);
      });
  },

  createDefaultAgreement({ commit }, body) {
    return createDefaultAgreement(body)
      .then(() => {
        commit('global/CALL_TOASTER', 'System agreement is added', { root: true });
      })
      .catch((err) => {
        this.$log.error('System agreement create error:', err);
      });
  },

  updateDefaultAgreementById({ commit }, { id, data }) {
    return updateDefaultAgreementById(id, data)
      .then(() => {
        commit('global/CALL_TOASTER', 'System agreement is updated', { root: true });
      }).catch((err) => {
        this.$log.error('System agreement update error:', err);
      });
  },

  deleteDefaultAgreementById({ commit }, agreementId) {
    return deleteDefaultAgreementById(agreementId)
      .then(() => {
        commit('global/CALL_TOASTER', 'System agreement is deleted', { root: true });
      }).catch((err) => {
        this.$log.error('System agreement delete error:', err);
      });
  },

  getDefaultAgreementById(ctx, agreementId) {
    return getDefaultAgreementById(agreementId)
      .catch((err) => {
        this.$log.error('System agreement get by ID error:', err);
      });
  },
  getDefaultAgreementsList(ctx, { params }) {
    const args = getArgumentsForApi(agreementsArguments, params);
    return getDefaultAgreements(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('System agreements get error:', err);
      });
  },
};
