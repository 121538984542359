import { questionsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addIssuerQuestion,
  deleteIssuerQuestion,
  getIssuerQuestions,
  editIssuerQuestion,
} = questionsApi;
const questionsArguments = {
  issuerId: undefined,
  q: undefined,
  page: undefined,
  limit: undefined,
};

export default {
  create({ commit }, { issuerId, data }) {
    return addIssuerQuestion(issuerId, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerQuestion.create'), { root: true });
    });
  },
  update({ commit }, { issuerId, data: { id, ...rest } }) {
    return editIssuerQuestion(issuerId, id, rest).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerQuestion.update'), { root: true });
    });
  },
  delete({ commit }, { issuerId, id }) {
    return deleteIssuerQuestion(issuerId, id).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerQuestion.delete'), { root: true });
    });
  },
  getQuestions(ctx, { params }) {
    const args = getArgumentsForApi(questionsArguments, params);
    return getIssuerQuestions(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Questions get error:', err);
        return err;
      });
  },
};
