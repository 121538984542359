import axios from '~/utilities/axios';

const Cookies = require('js-cookie');

const setTokenData = (data) => {
  Cookies.set('oauthAccessToken', data.accessToken);
  Cookies.set('oauthRefreshToken', data.refreshToken);
  Cookies.set('oauthTokenExpiredAt', {
    accessTokenExpired: data.accessTokenExpiredAt,
    refreshTokenExpired: data.refreshTokenExpiredAt,
  });
};
const basePath = '/api/v2/oauth';
const config = { headers: { 'Content-Type': 'application/json' } };

const oauthValidateGoogleToken = async (idToken, clientId) => {
  const url = `${basePath}/google-login`;
  try {
    const { data } = await axios.post(url, { idToken, clientId });
    return data;
  } catch (error) {
    throw error;
  }
};

const getAuthentication = async (commit, authentication) => {
  commit('TOGGLE_GOOGLE_LOADING');
  const cookieClientId = Cookies.get('clientId');

  if (cookieClientId) {
    try {
      const oauthTokens = await oauthValidateGoogleToken(authentication.credential, cookieClientId);
      Cookies.remove('clientId');
      Cookies.remove('redirectUrl');
      commit('SET_ACCESS_TOKENS_OAUTH', oauthTokens);
    } catch (error) {
      // Todo: logger
    }
  }
  commit('TOGGLE_GOOGLE_LOADING');
};


export default {
  async oauthLogin(ctx, body = {}) {
    try {
      const { data } = await axios.post(`${basePath}/login`, body, config);
       setTokenData(data);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async validateClientConfig(ctx, params = {}) {
    return axios.get(`${basePath}/validate-client-config`, { params });
  },
  async validateCpBearer(ctx, { clientId, redirectUrl, authToken }) {
    const { ...localConfig } = config;
    localConfig.headers.authorization = `Bearer ${authToken}`;
    try {
      const { data } = await axios.post(`${basePath}/validate-cp-bearer`, { clientId, redirectUrl }, config);
      setTokenData(data);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async initGoogleAuthetication({ commit }) {
    const { google } = window;
    const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID;

      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: authentication => getAuthentication(commit, authentication),
      });
      google.accounts.id.renderButton(document.getElementById('google-button'), {
        theme: 'filled_blue',
      });
  },
  async toggleGoogleLoading({ commit }) {
    commit('TOGGLE_GOOGLE_LOADING');
  },
};
