import moment from 'moment';
import { blockchainTransactionsApi, tokensStatusApi, roundsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';


const { createBlockchainTransaction } = blockchainTransactionsApi;
const { getTokenWalletByAddress, getTokenInvestorByExternalId } = roundsApi;
const { getTokenStatus } = tokensStatusApi;

const tokenInvestorByExternalIdArguments = {
  issuerId: undefined,
  tokenId: undefined,
  userExternalId: undefined,
  isReceiverInternalTbeTransfer: undefined,
};

export default {
  getInvestorByWallet(ctx, { issuerId, tokenId, walletAddress = '' }) {
    return getTokenWalletByAddress(issuerId, tokenId, walletAddress)
      .then(({ data }) => data || {})
      .catch((err) => {
        this.$log.error('Investors get by wallet error:', err);
      });
  },
  getTokenInvestorByExternalId(ctx, { params }) {
    const args = getArgumentsForApi(tokenInvestorByExternalIdArguments, params);
    return getTokenInvestorByExternalId(...args).then(({ data }) => data || {})
      .catch((err) => {
        this.$log.error('Investors get by external Id error:', err);
      });
  },
  executeOperationalProcedure({ commit }, { issuerId, tokenId, procedure, model = {} }) {
    const body = {
      procedure,
      data: {
        userId: model.userId ? Number(model.userId) : undefined,
        senderUserId: model.senderUserId ? Number(model.senderUserId) : undefined,
        receiverUserId: model.receiverUserId ? Number(model.receiverUserId) : undefined,
        issuanceDate: model.issuanceDate ? moment(model.issuanceDate).toISOString() : undefined,
        tokenAmount: model.tokensAmount || undefined,
        reason: model.reason || undefined,
        destinationWalletAddress: model.destinationWallet || undefined,
        sourceWalletAddress: model.sourceWallet || undefined,
        transactionHash: model.transactionHash || undefined,
        documentId: model.documentId || undefined,
        isPause: model.isPause,
      },
    };

    return createBlockchainTransaction(issuerId, tokenId, body)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.procedures.executeOperationalProcedure'), { root: true });
        return data || {};
      }).catch((err) => {
        this.$log.error('Execute procedure error:', err);
      });
  },
  getTokenStatus({ commit }, { issuerId, tokenId }) {
    getTokenStatus(issuerId, tokenId)
      .then(({ data }) => {
        commit('SET_TOKEN_STATUS', data);
      }).catch((err) => {
        this.$log.error('Get token status error:', err);
      });
  },
};
