/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-unresolved
import * as MainApiClient from '@codegen/control-panel-gw-client';
import { kebabCase } from 'change-case';
import axios from '~/utilities/axios';
import kebabToCamel from '~/utilities/convert-object-keys';

const basePath = '/api/v2';
const configuration = { apiKey: '' };

class MainApiService {
  constructor() {
    this.affiliatesApi = new MainApiClient.AffiliatesApiFactory(configuration, basePath, axios);
    this.agreementsApi = new MainApiClient.AgreementsApiFactory(configuration, basePath, axios);
    this.alertsApi = new MainApiClient.AlertsApiFactory(configuration, basePath, axios);
    this.assetsApi = new MainApiClient.AssetsApiFactory(configuration, basePath, axios);
    this.bankAccountsApi = new MainApiClient.BankAccountsApiFactory(configuration, basePath, axios);
    this.blockchainTransactionsApi = new MainApiClient.BlockchainTransactionsApiFactory(configuration, basePath, axios);
    this.brokerDealerGroupsApi = new MainApiClient.BrokerDealerGroupsApiFactory(configuration, basePath, axios);
    this.campaignsApi = new MainApiClient.CampaignsApiFactory(configuration, basePath, axios);
    this.clientConfigApi = new MainApiClient.ClientConfigApiFactory(configuration, basePath, axios);
    this.configurationVariablesApi = new MainApiClient.ConfigurationVariablesApiFactory(configuration, basePath, axios);
    this.configurationsApi = new MainApiClient.ConfigurationsApiFactory(configuration, basePath, axios);
    this.tokenConfigurationsApi = new MainApiClient.TokenConfigurationsApiFactory(configuration, basePath, axios);
    this.controlBookApi = new MainApiClient.ControlBookApiFactory(configuration, basePath, axios);
    this.currenciesApi = new MainApiClient.CurrenciesApiFactory(configuration, basePath, axios);
    this.dashboardApi = new MainApiClient.DashboardApiFactory(configuration, basePath, axios);
    this.defaultAgreementsApi = new MainApiClient.DefaultAgreementsApiFactory(configuration, basePath, axios);
    this.defaultConfigurationsApi = new MainApiClient.DefaultConfigurationsApiFactory(configuration, basePath, axios);
    this.defaultCountrySettingsApi = new MainApiClient.DefaultCountrySettingsApiFactory(configuration, basePath, axios);
    this.documentsApi = new MainApiClient.DocumentsApiFactory(configuration, basePath, axios);
    this.emailTemplatesApi = new MainApiClient.EmailTemplatesApiFactory(configuration, basePath, axios);
    this.holderAffiliatesApi = new MainApiClient.HolderAffiliatesApiFactory(configuration, basePath, axios);
    this.importInvestorApi = new MainApiClient.ImportInvestorApiFactory(configuration, basePath, axios);
    this.infoApi = new MainApiClient.InfoApiFactory(configuration, basePath, axios);
    this.investorsApi = new MainApiClient.InvestorsApiFactory(configuration, basePath, axios);
    this.investorsEmailApi = new MainApiClient.InvestorsEmailApiFactory(configuration, basePath, axios);
    this.issuersApi = new MainApiClient.IssuersApiFactory(configuration, basePath, axios);
    this.labelsApi = new MainApiClient.LabelsApiFactory(configuration, basePath, axios);
    this.logsApi = new MainApiClient.LogsApiFactory(configuration, basePath, axios);
    this.messagesApi = new MainApiClient.MessagesApiFactory(configuration, basePath, axios);
    this.msfApi = new MainApiClient.MsfApiFactory(configuration, basePath, axios);
    this.omnibusWalletOwnersApi = new MainApiClient.OmnibusWalletOwnersApiFactory(configuration, basePath, axios);
    this.omnibusWalletsApi = new MainApiClient.OmnibusWalletsApiFactory(configuration, basePath, axios);
    this.operatorsApi = new MainApiClient.OperatorsApiFactory(configuration, basePath, axios);
    this.prerenderedAddressesApi = new MainApiClient.PrerenderedAddressesApiFactory(configuration, basePath, axios);
    this.questionsApi = new MainApiClient.QuestionsApiFactory(configuration, basePath, axios);
    this.rateApi = new MainApiClient.RateApiFactory(configuration, basePath, axios);
    this.ratesApi = new MainApiClient.RatesApiFactory(configuration, basePath, axios);
    this.roundsApi = new MainApiClient.RoundsApiFactory(configuration, basePath, axios);
    this.securitizeIdInvestors = new MainApiClient.SecuritizeIdInvestorApiFactory(configuration, basePath, axios);
    this.selfServiceApi = new MainApiClient.SelfServiceApiFactory(configuration, basePath, axios);
    this.sessionApi = new MainApiClient.SessionApiFactory(configuration, basePath, axios);
    this.snapshotsApi = new MainApiClient.SnapshotsApiFactory(configuration, basePath, axios);
    this.systemEmailTemplatesApi = new MainApiClient.SystemEmailTemplatesApiFactory(configuration, basePath, axios);
    this.systemQuestionsApi = new MainApiClient.SystemQuestionsApiFactory(configuration, basePath, axios);
    this.systemTextsApi = new MainApiClient.SystemTextsApiFactory(configuration, basePath, axios);
    this.textsApi = new MainApiClient.TextsApiFactory(configuration, basePath, axios);
    this.tokenIssuancesApi = new MainApiClient.TokenIssuancesApiFactory(configuration, basePath, axios);
    this.tokenTransactionsApi = new MainApiClient.TokenTransactionsApiFactory(configuration, basePath, axios);
    this.tokensStatusApi = new MainApiClient.TokensStatusApiFactory(configuration, basePath, axios);
    this.walletManagersApi = new MainApiClient.WalletManagersApiFactory(configuration, basePath, axios);
    this.webhookApi = new MainApiClient.WebhookApiFactory(configuration, basePath, axios);
    this.predefinedLabelsApi = new MainApiClient.PredefinedLabelsApiFactory(configuration, basePath, axios);
    this.custodiansApi = new MainApiClient.CustodiansApiFactory(configuration, basePath, axios);
    this.tokensApi = new MainApiClient.TokensApiFactory(configuration, basePath, axios);
    this.privacyControl = new MainApiClient.PrivacyControlApiFactory(configuration, basePath, axios);
    this.securitizeIdIssuerApi = new MainApiClient.SecuritizeIdIssuerApiFactory(configuration, basePath, axios);
    this.gwMainApi = new MainApiClient.GWMainApiApi(configuration, basePath, axios);
  }
}
const dateFields = ['startDate', 'endDate', 'fromCreatedAt', 'toCreatedAt'];

export function getArgumentsForApi(argumentsSchema, params = {}) {
  const convertedParams = kebabToCamel(params);
  dateFields.forEach((key) => {
    if (convertedParams[key]) {
      convertedParams[key] = new Date(convertedParams[key]);
    }
  });
  return Object.keys(argumentsSchema).map((key) => {
    if (convertedParams[key] === 0 || convertedParams[key] === false) return convertedParams[key];
    return convertedParams[key] || undefined;
  });
}

export function getArgumentsForExport(argumentsSchema, params = {}) {
  const convertedParams = kebabToCamel(params);
  dateFields.forEach((key) => {
    if (convertedParams[key]) {
      convertedParams[key] = new Date(convertedParams[key]);
    }
  });

  const argumentsObj = {};

  Object.keys(argumentsSchema).forEach((key) => {
    if (convertedParams[key] || convertedParams[key] === 0 || convertedParams[key] === false) {
      argumentsObj[kebabCase(key)] = convertedParams[key];
    }
  });

  return argumentsObj;
}

export const {
  affiliatesApi,
  agreementsApi,
  alertsApi,
  assetsApi,
  bankAccountsApi,
  blockchainTransactionsApi,
  brokerDealerGroupsApi,
  campaignsApi,
  clientConfigApi,
  configurationVariablesApi,
  configurationsApi,
  controlBookApi,
  currenciesApi,
  dashboardApi,
  defaultAgreementsApi,
  defaultConfigurationsApi,
  defaultCountrySettingsApi,
  documentsApi,
  emailTemplatesApi,
  holderAffiliatesApi,
  importInvestorApi,
  infoApi,
  investorsApi,
  investorsEmailApi,
  issuersApi,
  labelsApi,
  logsApi,
  messagesApi,
  msfApi,
  omnibusWalletOwnersApi,
  omnibusWalletsApi,
  operatorsApi,
  prerenderedAddressesApi,
  questionsApi,
  rateApi,
  ratesApi,
  roundsApi,
  securitizeIdInvestors,
  selfServiceApi,
  sessionApi,
  snapshotsApi,
  systemEmailTemplatesApi,
  systemQuestionsApi,
  systemTextsApi,
  textsApi,
  tokenIssuancesApi,
  tokenTransactionsApi,
  tokensStatusApi,
  walletManagersApi,
  webhookApi,
  tokenConfigurationsApi,
  predefinedLabelsApi,
  custodiansApi,
  tokensApi,
  privacyControl,
  securitizeIdIssuerApi,
  gwMainApi,
} = new MainApiService();
