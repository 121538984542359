<template>
  <nav
    class="layout-footer footer"
    :class="getLayoutFooterBg()"
  >
    <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
      <div class="pt-3">
        ©
        <span class="footer-text font-weight-bolder">
          {{ $t('common.companyInfo.name') }}
        </span>
      </div>
      <div>
        <a
          href="mailto:issuersupport@securitize.io"
          class="footer-link pt-3 ml-4"
        >
          {{ $t('component.footer.link.contact') }}
        </a>
        <a
          href="https://www.securitize.io/terms-of-service"
          target="_blank"
          class="footer-link pt-3 ml-4"
        >
          {{ $t('component.footer.link.termsAndConditions') }}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CpFooter',
  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    },
  },
};
</script>
<style scoped>
  .layout-footer {
   z-index: 1;
  }
</style>
