import axios from '@/utilities/axios';

const basePath = '/api/v2/blockchain/messages';
export default class BlockchainMessagesService {
    static getAuthorizationHeader = () => {
        const that = this;
        that.configuration = {
            apiKey: '',
            basePath: '/api/v2/blockchain/messages',
        };

        // const _store = operatorStore;
        // that.configuration.apiKey = _store.state.accessToken;
        const localVarHeaderParams = {
            // authorization: `Bearer ${that.configuration.apiKey}`,
        };
        return {
            ...localVarHeaderParams,
        };
    }

    static getEIP712Message(requestBody) {
        return this.post('/eip-712', { ...requestBody }, { headers: this.getAuthorizationHeader() });
    }

    static get(path, config = {}) {
        return axios.get(basePath + path, config);
    }

    static delete(path, config = {}) {
        return axios.delete(basePath + path, config);
    }

    static post(path, body = {}, config = {}) {
        return axios.post(basePath + path, body, config);
    }

    static put(path, body = {}, config = {}) {
        return axios.put(basePath + path, body, config);
    }
}
