import { i18n } from '~/i18n';
import axios from '~/utilities/axios';
import { createHSMOperator, toggleHSMOperatorStatus, createHSMOperatorKey, getOperatorHSMKeys, removeHSMOperatorKey } from '@/utilities/external-api/apis/abstractionLayerCpApi';

const basePath = '/api/v2/';

export default {
  async addOperator({ commit }, body) {
    const url = `${basePath}operators`;
    try {
      const { data } = await axios.post(url, body, { headers: { 'Content-Type': 'application/json' } });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.operators.addOperator'), { root: true });
      return data;
    } catch (err) {
      this.$log.error('Add Operator error:', err);

      throw err;
    }
  },
  async updateOperator({ commit }, { body, operatorId }) {
    const url = `${basePath}operators/${operatorId}`;
    try {
      const { data } = await axios.patch(url, body, { headers: { 'Content-Type': 'application/json' } });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.operators.updateOperator'), { root: true });
      return data;
    } catch (err) {
      this.$log.error('Update Operator error:', err);

      throw err;
    }
  },
  async getOperatorById({ commit }, operatorId) {
    const url = `${basePath}operators/${operatorId}`;
    try {
      const { data } = await axios.get(url, { headers: { 'Content-Type': 'application/json' } });
      commit('SET_CURRENT_OPERATOR', data);
      return data;
    } catch (err) {
      this.$log.error('Get Operator by id error:', err);

      throw err;
    }
  },
  async activateOperator({ commit }, operatorId) {
    const url = `${basePath}operators/${operatorId}/enable`;
    try {
      await axios.post(url, null, { headers: { 'Content-Type': 'application/json' } });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.operators.activateOperator'), { root: true });
    } catch (err) {
      this.$log.error('Activate Operator error:', err);

      throw err;
    }
  },
  async deactivateOperator({ commit }, operatorId) {
    const url = `${basePath}operators/${operatorId}/disable`;
    try {
      await axios.post(url, null, { headers: { 'Content-Type': 'application/json' } });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.operators.deactivateOperator'), { root: true });
    } catch (err) {
      this.$log.error('Deactivate Operator error:', err);

      throw err;
    }
  },
  async resetAuthentication({ commit }, operatorId) {
    const url = `${basePath}operators/${operatorId}/reset-authentication`;
    try {
      await axios.post(url, null, { headers: { 'Content-Type': 'application/json' } });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.operators.resetAuthentication'), { root: true });
    } catch (err) {
      this.$log.error('Reset operator authentication error:', err);

      throw err;
    }
  },
  async getOperatorsList(ctx, { params }) {
    const url = `${basePath}operators`;
    try {
      const { data } = await axios.get(url, { params, headers: { 'Content-Type': 'application/json' } });
      return data;
    } catch (err) {
      this.$log.error('Get Operators List error:', err);

      throw err;
    }
  },
  async fetchOperators({ commit }, { params }) {
    const url = `${basePath}operators`;
    try {
      const { data: { data } } = await axios.get(url, { params, headers: { 'Content-Type': 'application/json' } });
      commit('SET_OPERATORS_LIST', data);
      return data;
    } catch (err) {
      this.$log.error('Get Operators List error:', err);

      throw err;
    }
  },

  getOperatorHSMKeys(ctx, { operatorId }) {
    return getOperatorHSMKeys(operatorId);
  },

  createHSMOperator(ctx, { operatorId }) {
    return createHSMOperator(operatorId);
  },

  createHSMOperatorKey(ctx, { operatorId }) {
    return createHSMOperatorKey(operatorId);
  },

  removeHSMOperatorKey(ctx, { operatorId, hsmAddress }) {
    return removeHSMOperatorKey(operatorId, hsmAddress);
  },

  toggleHSMOperatorStatus(ctx, { operatorId }) {
    return toggleHSMOperatorStatus(operatorId);
  },
};
