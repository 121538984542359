import axios from '~/utilities/axios';

const BASE_PATH = '/api/v2';

const BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG = 'bc-bulk-ds-protocol-transactions-enabled';

export default class IssuerClientConfigurations {
    static async isBulkDsProtocolTransactionEnabledForIssuer(issuerId) {
        try {
            const res = await axios.get(`${BASE_PATH}/issuers/${issuerId}/configurations/client-configs`);
            const clientConfigs = res.data;

            if (clientConfigs.data?.length) {
                const featureFlag = clientConfigs.data?.find(({ name }) => name === BULK_DS_PROTOCOL_TRANSACTIONS_FEATURE_FLAG);

                if (featureFlag && featureFlag.value) {
                    return featureFlag.value === 'true';
                }
            }
        } catch (error) {
            console.error('Error fetching client configurations:', error);
            return false;
        }

        return false;
    }
}
