export default {
  SET_CONFIGURATION(state, configuration) {
    state.configuration = configuration;
    state.isLoading = true;
  },
  SET_TOKEN_CONFIGURATION(state, configuration) {
    state.tokenConfiguration = configuration;
  },
  CHANGE_LANDING_TYPES_CONFIG(state, { type, field, value }) {
    state.configuration.landingPage[type][field] = value;
  },
  CHANGE_LANDING_LIMITED_ACCESS(state, { field, value }) {
    state.configuration.landingPage.basic.limitedAccess[field] = value;
  },
  CHANGE_LANDING_RESTRICT_LOGIN(state, value) {
    state.configuration.landingPage.basic.restrictLogin = value;
  },
  CHANGE_LANDING_TYPE(state, type) {
    state.configuration.landingPage.type = type;
  },
  SET_LANDING_PAGE(state, lpConfig) {
    state.configuration.landingPage = { ...lpConfig };
  },
  SET_LANDING_PAGE_BG_KEY(state, bgImageKey) {
    state.configuration.landingPage.basic.backgroundImageKey = bgImageKey;
  },
  CHANGE_SOCIAL_LINKS(state, { name, value }) {
    if (state.configuration.investorsArea.socialLinks[name]) {
      state.configuration.investorsArea.socialLinks[name].url = value;
    }
  },
  CHANGE_INVESTORS_ANNOUNCEMENTS(state, { name, value }) {
    state.configuration.investorsArea.announcements[name] = value;
  },
  CHANGE_INVESTORS_ABOUT(state, { name, value }) {
    state.configuration.investorsArea.about[name] = value;
  },
  CHANGE_WIZARD_ANNOUNCEMENTS(state, { name, value }) {
    state.configuration.investorsArea.wizard[name] = value;
  },
  SET_SOCIAL_LINKS(state, data) {
    state.configuration.investorsArea.socialLinks = data;
  },
  SET_INVESTORS_ANNOUNCEMENTS(state, data) {
    state.configuration.investorsArea.announcements = data;
  },
  SET_INVESTORS_ABOUT(state, data) {
    state.configuration.investorsArea.about = data;
  },
  SET_WIZARD_ANNOUNCEMENTS(state, data) {
    state.configuration.investorsArea.wizard = data;
  },
};
