
export default {
    SET_ACCESS_TOKENS_OAUTH({ tokensAuthentication }, {
        accessToken, accessTokenExpiredAt, refreshToken, refreshTokenExpiredAt,
      }) {
        tokensAuthentication.accessToken = accessToken;
        tokensAuthentication.accessTokenExpiredAt = accessTokenExpiredAt;
        tokensAuthentication.refreshToken = refreshToken;
        tokensAuthentication.refreshTokenExpiredAt = refreshTokenExpiredAt;
    },
    TOGGLE_GOOGLE_LOADING(state) {
        state.googleIsLoading = !state.googleIsLoading;
    },
};
