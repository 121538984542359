export default {
  labelsList: [],
  labelsListOriginal: [],
  filters: {
    onboarding: {},
    fundraise: {},
    holders: {},
  },
  sorting: {
    onboarding: {
      'order-field': 'created-at',
      'order-direction': 'desc',
    },
    fundraise: {
      'order-field': 'created-at',
      'order-direction': 'desc',
    },
    holders: {
      'order-field': 'created-at',
      'order-direction': 'desc',
    },
  },
  details: {
    editableBlock: '',
    general: {},
    investment: {},
    investmentForDisplay: {},
    kyc: {},
    tokensWalletsSection: {
      hasInvestmentRequests: false,
      wallets: [],
      issuances: [],
    },
    tokens: {
      hasInvestmentRequests: false,
      wallets: [],
      issuances: [],
    },
  },
  legalSignerGeneralInfo: {
  },
  supportedCurrencies: [],
  isDripActive: false,
};
