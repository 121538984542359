<template>
  <form
    class="cp-input-container"
    :data-vv-scope="scope"
    @submit.prevent="validateForm"
  >
    <slot />
  </form>
</template>

<script>
export default {
  name: 'CpInputContainer',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    defaultValue: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isFromTouched: false,
      submitted: false,
      edited: false,
      defaults: this.defaultValue,
      touched: {},
      childrenCache: this.$children.map(({ _uid }) => _uid),
    };
  },
  computed: {
    children() {
      return this.$children;
    },
    scope() {
      return `scope-${this.validationErrors.vmId}`;
    },
    errors() {
      return this.validationErrors.items.filter(
        item => item.scope === this.scope && (this.touched[item.field] || this.submitted),
      );
    },
  },
  watch: {
    value: {
      handler(val) {
        this.$nextTick(() => {
          this.updateComponents(val);
        });
      },
      deep: true,
    },
    'validationErrors.items': {
      deep: true,
      handler() {
        const activeErrors = this.errors;
        this.updateErrors(activeErrors);
        this.$emit('error', activeErrors);
      },
    },
  },
  updated() {
    if (this.childrenCache.length !== this.$children.length
        || !this.childrenCache.every((_uid, index) => this.$children[index]._uid === _uid)) {
      this.updateComponents({});
      this.childrenCache = this.$children.map(({ _uid }) => _uid);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.childrenCache = this.$children.map(({ _uid }) => _uid);
      this.setDefaults();
      this.updateComponents(null);
    });
  },
  methods: {
    reset() {
      this.data = {
        isFromTouched: false,
        submitted: false,
        edited: false,
        defaults: this.defaultValue,
        touched: {},
      };
      this.setDefaults();
      this.updateComponents({});
    },
    setDefaults() {
      this.defaults = {
        ...(this.value || {}),
        ...(this.defaultValue || {}),
      };
    },
    updateErrors(errors) {
      const errorsObj = errors.reduce(
        (res, error) => {
          res[error.field] = error.msg;
          return res;
        },
        {},
      );

      this.getAllChildren().forEach((child) => {
        child.controlledError = errorsObj[child.$props.name || ''];
      });
    },
    getAllChildren() {
      return this.getChildren(this, true).filter(child => child.$props && child.$props.name);
    },
    updateComponents(val) {
      const values = val && Object.keys(val).length ? { ...val } : { ...this.value };
      const defaultValue = { ...this.defaults };

      this.getAllChildren().forEach((child) => {
        const fieldKey = child.$props.name || '';
        child.controlledValue = values[fieldKey] !== undefined ? values[fieldKey] : defaultValue[fieldKey] || null;
        if (!child.isConnected) {
          child.isConnected = true;
          this.$validator.attach({ name: fieldKey, rules: child.$props.validate });
          child.$on('input', $event => this.handleInput(child.$props.name, $event));
        }
      });
    },
    getChildren(child, skipChild) {
      const children = [];

      if (child && !skipChild) {
        children.push(child);

        if (typeof child.isCpInput === 'function' && child.isCpInput()) {
          return children;
        }
      }

      if (child.children) {
        child.$children.forEach(
          (grandChild) => {
            // apply on push is significantly faster than foreach or concat
            // eslint-disable-next-line prefer-spread,no-unused-expressions
            grandChild && children.push.apply(children, this.getChildren(grandChild));
          },
        );
      }

      return children;
    },
    handleInput(name, e) {
      this.isFromTouched = true;
      this.touched[name] = true;

      const updatedValue = {
        ...(this.value || {}),
        [name]: e,
      };
      this.$emit('input', updatedValue);
      this.checkEmitEdited(updatedValue);

      this.$validator.validate(name);
    },
    checkEmitEdited(updatedValue) {
      this.edited = Object.keys(updatedValue).some(
        field => this.defaults[field] !== updatedValue[field],
      );
      this.$emit('edited', this.edited);
    },
    isEdited() {
      return this.edited;
    },
    isTouched() {
      return this.isTouched;
    },
    validateForm() {
      this.submitted = true;
      return this.$validator.validateAll(this.scope).then(
        () => {
          if (this.isValid()) {
            this.$emit('submit', this.value);
          } else {
            this.$emit('error', this.errors);
          }
          this.updateComponents({});
          return this.isValid();
        },
      );
    },
    isValid(field) {
      if (field) {
        return (this.submitted || this.isFromTouched) && !this.errors.find(err => err.field === field);
      }
      return (this.submitted || this.isFromTouched) && Boolean(!this.errors.length);
    },
  },
};
</script>
