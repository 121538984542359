import { fetchDeploymentLogs } from '~/utilities/external-api/apis/blockchainGwApi';
import DeploymentsManagementService from '~/utilities/deploymentsManagementService';

export default {
  connect({ commit, rootState }) {
    if (!rootState.configToken.deploymentToken.abstractionLayerDeploymentId) {
      commit('SOCKET_SUCCESS');
      commit('SOCKET_FETCHLOGS', []);
      setTimeout(() => {
        DeploymentsManagementService.getIssuerDeployment(rootState.issuersInfo.issuerId, rootState.configToken.deploymentToken.id).then(
          (data) => {
            commit('SOCKET_CONNECT', data.abstractionLayerDeploymentId);
          },
        );
      }, 2000); // We wait a bit so that the job that creates the abstractionLayerID is finished
    } else {
      commit('SOCKET_CONNECT', rootState.configToken.deploymentToken.abstractionLayerDeploymentId);
    }
  },
  disconnect({ commit }) {
    commit('SOCKET_DISCONNECT');
  },
  requestEventLogs({ commit }) {
    commit('SOCKET_REQUESTLOGEVENTS');
  },
  fetchLogs({ commit, rootState }) {
    commit('SOCKET_FETCHINGLOGS');
    return fetchDeploymentLogs(rootState.configToken.deploymentToken.abstractionLayerDeploymentId).then(
      (deploymentLogs) => {
        commit('SOCKET_FETCHLOGS', deploymentLogs.data);
      },
    )
      .catch((err) => {
        commit('SOCKET_ERROR', `Error while fetching logs: ${err}`);
        commit('SOCKET_FETCHLOGS', []);
      });
  },
};
