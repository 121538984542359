export default {
  component: {
    createIssuerModal: {
      title: 'Create Issuer',
      input: {
        issuerName: 'Issuer name',
        tokenName: 'Token name',
      },
    },
  },
};
