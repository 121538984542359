import axios from '~/utilities/axios';
import axiosV2 from '~/utilities/axiosV2';

const controlPanelGwBasePath = '/api/v2/';
const isrControlPanelGwBasePath = '/isr/api/v1/';

export default {
  async getImportInvestorsProgress({ commit }, issuerId) {
    const useNewGw = process.env.VUE_APP_ENABLE_ISR_GW_IMPORT_INVESTORS === 'true';
    const basePath = useNewGw ? isrControlPanelGwBasePath : controlPanelGwBasePath;
    const url = `${basePath}issuers/${issuerId}/import-investor`;
    try {
      const { data } = await (useNewGw ? axiosV2 : axios).get(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_IMPORT_INVESTORS_PROGRESS', data);
    } catch (err) {
      const errorParsed = err.response.data ? err.response.data : { statusCode: err.response.status };
      commit('SET_IMPORT_INVESTORS_PROGRESS', errorParsed);
      this.$log.error('Getting import investors progress:', err);

      throw err;
    }
  },
  toggleIsLoading({ commit }) {
    commit('TOGGLE_LOADING');
  },
  setIsModalOpen({ commit }, value) {
    commit('TOGGLE_MODAL_OPEN', value);
  },
  setIsSendingCsv({ commit }, value) {
    commit('TOGGLE_IS_SENDING_CSV', value);
  },
  cleanImportInvestorsProgress({ commit }) {
    commit('CLEAN_IMPORT_INVESTORS_PROGRESS');
  },
};
