import { documentsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  addIssuerDocument,
  deleteIssuerDocumentById,
  getIssuerDocuments,
  getIssuerDocumentById,
  updateIssuerDocumentById,
} = documentsApi;
const documentsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  q: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

export default {
  update({ commit }, { issuerId, data: { id, ...docData } }) {
    return updateIssuerDocumentById(issuerId, id, docData).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPlatformDocuments.update'), { root: true });
    });
  },

  delete({ commit }, { issuerId, id }) {
    return deleteIssuerDocumentById(issuerId, id).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPlatformDocuments.delete'), { root: true });
    });
  },

  create({ commit }, { issuerId, data }) {
    return addIssuerDocument(issuerId, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPlatformDocuments.create'), { root: true });
    });
  },

  getById({ commit }, { issuerId, id }) {
    return getIssuerDocumentById(issuerId, id).then(({ data }) => {
      commit('SET_ISSUER_PLATFORM_DOCUMENT', data);
      return data;
    }).catch((err) => {
      this.$log.error('Issuer Platform Document get error:', err);
    });
  },

  getDocuments(ctx, { params }) {
    const args = getArgumentsForApi(documentsArguments, params);
    return getIssuerDocuments(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Platform Documents get error:', err);
        return err;
      });
  },
};
