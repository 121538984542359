export default {
  configurationJurisdictionsPerToken: {
    pageTitle: 'Asset Configuration',
    title: 'Jurisdictions',
    loading: 'Loading Jurisdictions Data...',
    // Per Jurisdiction Disclaimers and Definitions
    perCountryTable: {
      addStateAndRegion: 'Add state/region',
      title: 'Per Jurisdiction Disclaimers and Definitions',
      label: {
        table: {
          country: 'Country',
          regionState: 'Region/State',
          qualificationType: 'Qualification',
          tokenSpecific: 'Asset Specific',
        },
        filterByTokenSpecific: 'Filter by Asset Specific:',
      },
      assetSpecificOptions: {
        yes: 'Yes',
        no: 'No',
      },
      status: {
        qualTypes: {
          standard: 'standard',
          specific: 'specific',
        },
      },
    },
    // modal
    jurisdictionsEditModal: {
      title: 'Edit {0}{1}',
      label: {
        qualification: 'Qualification',
        placeholder: 'Write your text here...',
        additionalAccreditation: 'Additional Accreditation',
        reverseSolicitation: 'Reverse Solicitation',
        allowSkipAccreditation: 'Skip Accreditation',
      },
      input: {
        initialAgreement: 'Initial Agreement',
        accreditedAgreements: 'Accredited agreements',
        qualification: 'Qualification required?',
        qualificationTitle: 'Qualification Title',
      },
      option: {
        qualification: {
          standard: 'Standard',
          specific: 'Issuer Agreements',
        },
        qualificationTitle: {
          accredited: 'Accredited',
          qualification: 'Qualification',
          professional: 'Professional',
          other: 'Other',
        },
      },
      message: {
        qualification: 'Investors not matching the qualification criteria cannot invest',
      },
    },
    confirmDeleteModal: {
      title: 'Delete Jurisdiction per asset settings',
      message: 'Are you sure you want to delete this Jurisdiction per asset settings?',
    },
  },
};
