export default {
  activities: [],
  totalLogs: null,
  fundingStatistics: {},
  onboardingStatistics: {},
  onboardingByCountrySummary: [],
  onboardingRegisteredSummary: [],
  onboardingAuthorizedSummary: [],
  fudingByCountrySummary: [],
  fundingByPledgeSummary: [],
  fundingFundedSummary: [],
  holdersStatistics: {},
  holdersByCountrySummary: [],
  holdersByTypeSummary: [],
  holdersTokenStakes: [],
};
