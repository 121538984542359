const { io } = require('socket.io-client');

let websocket;

export default function createWebSocketPlugin() {
    return (store) => {
        store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'socketio/SOCKET_CONNECT':
                    try {
                            // Avoid duplicating websockets due to Vue re-rendering
                            if (websocket) {
                                break;
                            }
                        websocket = io(process.env.VUE_APP_SOCKETIO_PROXY, {
                            transports: ['websocket', 'polling'],
                            path: '/blockchain/ws',
                            cors: {
                                origin: '*',
                            },
                            query: {
                                room: `deployment-${ mutation.payload}`,
                            },
                        });

                        websocket.on('connect', () => {
                            store.commit('socketio/SOCKET_CONNECTED');
                            store.commit('socketio/SOCKET_REQUESTLOGEVENTS');
                        });
                    } catch (e) {
                        store.commit('socketio/SOCKET_ERROR', `Error at connecting: ${ e}`);
                    }
                    break;
                case 'socketio/SOCKET_DISCONNECT':
                    try {
                        websocket.disconnect();
                        websocket.removeAllListeners();
                        websocket = undefined;
                    } catch (e) {
                        store.commit('socketio/SOCKET_ERROR', `Error at disconnecting: ${e}`);
                    }
                    break;
                case 'socketio/SOCKET_REQUESTLOGEVENTS':
                    try {
                            websocket.on('events-logs', (payload) => {
                                if (payload.status === 'failure') {
                                    store.commit('socketio/SOCKET_ERROR', `Deployment failed at step ${payload.step}. See errors below`);
                                    store.commit('socketio/SOCKET_EVENTLOG', payload);
                                    store.commit('socketio/SOCKET_DISCONNECT');
                                } else {
                                    store.commit('socketio/SOCKET_EVENTLOG', payload);
                                    store.commit('socketio/SOCKET_SUCCESS');
                                    if (payload.step === 0) {
                                        setTimeout(() => { // If we are receiving the "successful deployment" step, the previous step may be lagged, thus we cut it some slack to arrive
                                            store.commit('configToken/SET_DEPLOYMENT_STATUS', 'success');
                                        }, 5000);
                                    }
                                }
                            });
                    } catch (e) {
                        store.commit('socketio/SOCKET_ERROR', `Error while subscribing to logs: ${ e}`);
                        store.commit('socketio/SOCKET_DISCONNECT');
                    }
                    break;
                case 'issuersInfo/SET_CURRENT_TOKEN':
                case 'configuration/SET_TOKEN_CONFIGURATION':
                    if (websocket && websocket.connected) {
                        store.commit('socketio/SOCKET_DISCONNECT');
                    }
                    store.commit('socketio/SOCKET_CLEANLOGS');
                    break;
                default:
            }
        });
    };
}
