<template>
  <div @click="$emit('click')">
    <b-img
      ref="cpImage"
      class="w-100"
      alt="Logo"
      :src="src"
      :title="title"
    />
  </div>
</template>

<script>
export default {
  name: 'CpImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    defaultSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  watch: {
    src() {
      this.setImage();
    },
  },
  mounted() {
    this.setImage();
  },
  methods: {
    setImage() {
      const img = new Image();
      img.src = this.src;
      img.onload = () => {
        this.$emit('load');
      };
      img.onerror = () => {
        this.$refs.cpImage.src = this.defaultSrc;
        this.$emit('imageError');
      };
    },
  },
};
</script>
