import Vue from 'vue';
import VueI18n from 'vue-i18n';
import _ from 'lodash';

Vue.use(VueI18n);

export const localeList = ['en', 'ja'];

const loadLangFiles = () => {
  const langs = require.context('../', true, /\/lang\/[A-Za-z0-9-_]+\.js$/);
  const messages = {};
  _.forEach(langs.keys(), (key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1 && localeList.includes(matched[1])) {
      const lang = matched[1];
      messages[lang] = _.merge(langs(key).default, messages[lang]);
    } else {
      throw new Error(`invalid lang file found: ${key}`);
    }
  });
  return messages;
};

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLangFiles(),
  missing: () => '',
});
