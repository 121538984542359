import axios from '~/utilities/axios';

class IssuanceCpApi {
    constructor() {
        const that = this;
        that.configuration = { apiKey: '', basePath: '/api/v2' };

        that.authorizationHeader = function authorizationHeader() {
            const localVarHeaderParams = { };
            return localVarHeaderParams;
        };

        this.editTokenIssuance = function editTokenIssuance(issuanceId, issuerId, userId, body) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/investors/${userId}/token-issuances/${issuanceId}`;
            return axios.patch(localVarPath, body, {
                headers: that.authorizationHeader(),
            });
        };
    }
}

// eslint-disable-next-line import/prefer-default-export
export const { editTokenIssuance } = new IssuanceCpApi();
