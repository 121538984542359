import axios from '~/utilities/axios';

class HistoricalTokenValueCpApi {
    constructor() {
        const that = this;
        that.configuration = { apiKey: '', basePath: '/api/v2', basePathNewGW: '/api/v2' };

        that.authorizationHeader = function authorizationHeader() {
            const localVarHeaderParams = { };
            return localVarHeaderParams;
        };

        this.getHistoricalTokenValues = function getHistoricalTokenValues({
 issuerId, tokenId, page,
                                                                              limit,
                                                                              q,
                                                                              orderField,
                                                                              orderDirection,
}) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/historical-token-values`;
            const params = {
                page,
                limit,
                q,
                orderField,
                orderDirection,
            };
            return axios.get(localVarPath, { params, headers: that.authorizationHeader() });
        };

        this.getHistoricalTokenValueById = function getHistoricalTokenValueById({
                                                                              issuerId, tokenId, id,
                                                                          }) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/historical-token-values/${id}`;
            return axios.get(localVarPath, { headers: that.authorizationHeader() });
        };

        this.deleteHistoricalTokenValue = function deleteHistoricalTokenValue({
                                                                                    issuerId, tokenId, id,
                                                                                }) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/historical-token-values/${id}`;
            return axios.delete(localVarPath, { headers: that.authorizationHeader() });
        };

        this.createHistoricalTokenValue = function createHistoricalTokenValue({
                                                                                  issuerId, tokenId,
            tokenValue, tokenValueDate,
                                                                              }) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/historical-token-values`;
            return axios.post(localVarPath, { tokenValue, tokenValueDate }, { headers: that.authorizationHeader() });
        };

        this.updateHistoricalTokenValue = function updateHistoricalTokenValue({
                                                                                  issuerId, tokenId, id,
                                                                                  tokenValue, tokenValueDate,
                                                                              }) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/tokens/${tokenId}/historical-token-values/${id}`;
            return axios.patch(localVarPath, { tokenValue, tokenValueDate }, { headers: that.authorizationHeader() });
        };
    }
}

// eslint-disable-next-line import/prefer-default-export
export const {
    getHistoricalTokenValues,
    getHistoricalTokenValueById,
    deleteHistoricalTokenValue,
    createHistoricalTokenValue,
    updateHistoricalTokenValue,
} = new HistoricalTokenValueCpApi();
