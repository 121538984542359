import $store from '../../store/index';
import { ISR_MFE } from './frame.constants';

export default async (
  name,
  title,
  exposedPath,
  entryPointUrl,
  getData = () => {},
) => {
  // constants
  const containerID = `${name}-frame`;
  const refName = `mfe${name}Ref`;

  // load frame function
  const loadFrame = async () => {
    const scriptId = `mfe-script-${name}`;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = scriptId;
    script.src = `${entryPointUrl}?${new Date().getTime()}`;
    script.async = true;

    return new Promise((resolve, reject) => {
      const el = document.getElementById(scriptId);
      if (el) {
        resolve();
        return;
      }
      script.onload = resolve;
      script.onerror = () => {
        reject(new Error(`Failed to load ${name} Frame`));
      };
      document.head.appendChild(script);
    });
  };

  // load frame
  await loadFrame();
  const app = await window[name].get(exposedPath);
  const { mount, unmount } = app();

  return {
    name,
    metaInfo: {
      title,
    },
    data() {
      return {
        onParentNavigate: undefined,
      };
    },
    mounted() {
      try {
        let initialPath;

        if (ISR_MFE.includes(name)) {
          const lastVisitedRoute = $store.getters['navigationHistory/getLastVisitedRoute'];
          initialPath = [lastVisitedRoute, this.$router.history.current.path];
        } else {
          initialPath = this.$router.history.current.path;
        }
        const mfeElement = this.$refs[refName];
        const { onParentNavigate } = mount(mfeElement, {
          initialPath,
          onNavigate: ({ pathname }) => {
            const currentPath = this.$router.history.current.path;
            if (currentPath !== pathname) {
              this.$router.push(pathname);
            }
          },
          data: getData(),
        });
        this.onParentNavigate = onParentNavigate;
      } catch (e) {
        // an error occured on ${moduleName} execution.
      }
    },
    beforeDestroy() {
      const mfeElement = this.$refs[refName];
      unmount(mfeElement);
    },
    updated() {
      if (this.onParentNavigate) {
        this.onParentNavigate({ pathname: this.$router.history.current.path });
      }
    },
    render() {
      return (
        <main>
          <router-view></router-view>
          <div id={containerID} ref={refName} />
        </main>
      );
    },
  };
};
