export default {
  store: {
    toaster: {
      common: {
        success: 'リクエストが送信されました。',
        error: 'リクエストが送信されませんでした',
        copyToClipboard: 'Copied to clipboard',
      },
      issuerAffiliateCampaigns: {
        update: 'キャンペーンを更新しました',
        delete: 'キャンペーンを削除しました',
        create: 'キャンペーンを作成しました',
      },
      issuerAffiliates: {
        update: 'アフィリエイターを更新しました',
        delete: 'アフィリエイターを削除しました',
        create: 'アフィリエイターを作成しました',
      },
      issuerAgreements: {
        update: '契約書を更新しました',
        delete: '契約書を削除しました',
        create: '契約書を作成しました',
      },
      bankAccountById: {
        update: '口座を更新しました',
        delete: '口座を削除しました',
        create: '口座を作成しました',
      },
      issuerCurrencies: {
        update: '受け入れ通貨を更新しました',
      },
      issuerEmailTemplates: {
        update: 'Eメールテンプレートを更新しました',
        delete: 'Eメールテンプレートを削除しました',
      },
      issuerPlatformDocuments: {
        update: 'ドキュメントを更新しました',
        delete: 'ドキュメントを削除しました',
        create: 'ドキュメントを作成しました',
      },
      issuerPrerenderedAddresses: {
        update: '手動アドレス設定を更新しました',
        delete: '手動アドレス設定を削除しました',
        create: '手動アドレス設定を作成しました',
      },
      issuerQuestion: {
        update: 'FAQを更新しました',
        delete: 'FAQを削除しました',
        create: 'FAQを作成しました',
      },
      issuerTexts: {
        update: 'テキストを更新しました',
      },
      clientConfiguration: { // TODO: translate japanese
        update: 'Client Configuration was updated',
        delete: 'Client Configuration was deleted',
        create: 'Client Configuration was created',
      },
      issuerLabel: {
        deleteSuccess: 'Label {0} deleted',
      },
      fundraise: {
        update: '設定を更新しました',
      },
      configuration: {
        updateTokenConfiguration: 'トークン設定を更新しました',
        updateGeneralConfiguration: '基本設定を更新しました',
        updateLandingPageConfiguration: 'ランディングページの設定を更新しました',
        updateEmailConfiguration: 'Eメール設定を更新しました',
        updateInvestorsConfiguration: '投資家ダッシュボードの設定を更新しました',
      },
      jurisdictions: {
        updateJurisdictions: '設定を更新しました',
        updateCountry: '設定を更新しました',
        updateStateAndRegion: 'State/Region is updated',
        deleteStateAndRegion: 'State/Region is deleted',
        createStateAndRegion: 'State/Region is created',
        importJurisdictionsSettings: '{0} jurisdictions have been updated successfully.',
      },
      jurisdictionsPerToken: { // TODO: translate to jp
        updateTokenCountry: 'Country Jurisdiction per asset was updated successfully',
        updateTokenStateAndRegion: 'State/Region Jurisdiction per asset was updated successfully',
        resetTokenCountry: 'Country Jurisdiction per asset was reset successfully',
        resetTokenStateAndRegion: 'State/Region Jurisdiction per asset was reset successfully',
      },
      configToken: {
        updateTokenFundraise: '資金調達の設定をを更新しました',
        updateTokenLifecycle: 'トークンライフサイクル管理の設定を更新しました',
        created: 'Token successfully created',
        manageToken: { // TODO: translate japanese
          tokenDescriptionAdded: 'Token Description Added',
          tokenDescriptionUpdated: 'Token Description Updated',
          tokenComplianceAdded: 'Token Compliance Added',
          tokenComplianceUpdated: 'Token Compliance Updated',
          mustHaveTransferAgent: 'Must have at least one transfer agent',
          addressExist: 'The same address already exist with a different role',
          tokenPermissionsAdded: 'Token Permissions Added',
          tokenPermissionsUpdated: 'Token Permissions Updated',
          walletAddressMustNotBeMaster: 'The same wallet address was already declared as master',
          walletAddressMustNotBeWalletSyncer: 'The same wallet address was already declared as wallet syncer',
          walletAddressMustNotBeOmnibusTBE: 'The same wallet address was already declared as Omnibus TBE Wallet',
          walletAddressMustNotBeRedemptionAddress: 'The same wallet address was already declared as Redemption Wallet',
          invalidWalletAddress: 'The Role wallet address is not valid',
          omnibusWalletAddressEqualsToRedemption: 'The omnibus wallet must be different to redemption wallet',
          masterWalletAddressEqualsToRedemption: 'The master wallet must be different to redemption wallet',
          masterWalletAddressEqualsToOmnibusTbe: 'The master wallet must be different to omnibus tbe wallet',
          syncerWalletAddressEqualsToOmnibusTbe: 'The wallet syncer must be different to omnibus tbe wallet',
          syncerWalletAddressEqualsToRedemption: 'The wallet syncer must be different to redemption wallet',
          custodianWalletAddressEqualsToMaster: 'The custodian address must be different to master wallet',
          custodianWalletAddressEqualsToWalletRegistrar: 'The custodian address must be different to wallet syncer',
          custodianWalletAddressEqualsToRedemption: 'The custodian address must be different to redemption wallet',
          custodianWalletAddressEqualsToOmnibusTbe: 'The custodian address must be different to omnibus tbe wallet',
          custodianAddressMustNotBeIssuerOrExchangeAddress: 'The custodian address must not be an issuer or exchange wallet',
          duplicatedWalletName: 'Wallet name already in use',
          invalidOwnerAddressField: 'Owner address field on wallet is not valid or missing. Please check for duplicated or invalid ETH address',
          emptyRoles: 'No roles have been defined',
        },
        historicalTokenValue: {
          created: 'Token value successfully added', // TODO: translate japanese
          updated: 'Token value successfully updated', // TODO: translate japanese
        },
      },
      investors: {
        labelsBatchOperation: 'ラベルを更新しました',
        sendInviteEmail: '招待メールを送信しました',
        sendTreasuryToWallet: '{0}への転送リクエストを保存しました。署名待ちです。',
        createPayout: '払い出しを追加しました',
        remove2Fa: '2FAを無効化しました',
        syncSecuritizeIdInvestor: 'Securitize ID Sync was successful.', // TODO: translate japanese
        unlinkSecuritizeIdInvestor: 'SecuritizeID Unlink was successful', // TODO: translate japanese
        addInvestor: '投資家を追加しました',
        deleteInvestorByUserId: '投資家を削除しました',
        importList: '投資家リストをインポートしました or being processed', // TODO: translate japanese
        updateInvestorTokensConfirmed: 'トークンを更新しました',
        updateTokenPreferenceDripConfirmed: 'Investors token preference has been updated', // TODO: translate japanese
        updateInvestorDetailGeneral: '一般情報を更新しました',
        deleteTokenIssuanceById: 'トークンの発行を削除しました',
        createTokenIssuance: 'トークンを発行しました',
        editTokenIssuance: 'Token issuance is edited', // TODO: translate japanese
        updateInvestorInvestmentById: '投資を更新しました',
        createInvestorInvestment: '投資を作成しました',
        addInvestorDetailDocument: 'ドキュメントを追加しました',
        deleteInvestorDetailDocument: 'ドキュメントを削除しました',
        addInvestorDetailTokenWallet: 'ウォレットを追加しました',
        editInvestorDetailTokenWallet: 'ウォレットを更新しました',
        deleteInvestorDetailTokenWallet: 'ウォレットを削除しました',
        checkInvestorDetailPerformManualKYC: '{0}のため外部リクエストを送信しました',
        sendAccreditationEmail: '適格認定のためリクエストを送信しました',
        sendInvestorDetailKYCEmail: '{0}のためリクエストメールを送信しました',
        updateKYCDetails: '{0}&適格性のステータスを更新しました',
        addInvestorDetailTransaction: 'トランザクションを追加しました',
        updateInvestorDetailTransaction: 'トランザクションを更新しました',
        deleteInvestorDetailTransaction: 'トランザクションを削除しました',
        updateDepositWalletById: '入金アドレスを更新しました',
        moveBonus: 'ボーナストークンを移動しました',
        fundingInstructionsEmailSent: 'Funding instructions email was sent',
        agreementEmailSent: 'Agreement emails were sent',
        deleteLegalSigner: 'Legal Signer was deleted', // TODO: translate jp
        addLegalSigner: 'Legal Signer was created',
        bulkSecIDReverseSync: 'Reverse SecID Sync started.',
        bulkRunIssuanceErrorTimeout: 'Bulk action process not finished - process still runs in the background.', // TODO: translate jp
      },
      issuerList: {
        removeIssuer: '発行体を削除しました',
        createIssuer: '発行体を作成しました',
      },
      operators: {
        addOperator: 'オペレーターを追加しました',
        updateOperator: 'オペレーターを更新しました',
        activateOperator: 'オペレーターを有効化しました',
        deactivateOperator: 'オペレーター無効化しました',
        resetAuthentication: 'オペレーターの認証情報がリセットされました',
      },
      organizations: { // TODO: translate to jp
        addOrganization: 'Organization is added',
        updateOrganization: 'Organization is updated',
      },
      rounds: {
        updateRound: 'ラウンドを更新しました',
        createRound: 'ラウンドを作成しました',
        deleteRound: 'ラウンドを削除しました',
      },
      signatures: {
        deleteBlockchainTransactionById: '署名を削除しました',
        sendTransactionSign: 'トランザクションに署名しました',
        bulkSendTransactionSign: 'Bulk Transaction is signed',
        transactionSent: 'Transaction hash sent',
      },
      snapshots: {
        postSnapshotsById: 'スナップショットを作成しました',
        patchSnapshotsById: 'スナップショット名を更新しました',
      },
      snapshotsDistributions: {
        postDistribution: '分配を作成しました',
        confirmDistribution: '分配を確認しました',
      },
      snapshotsRecords: {
        exportSnapshotsRecords: 'スナップショットをダウンロードしました',
      },
      affiliateManagement: {
        createHolderAffiliateRecords: 'レコードが更新されました',
      },
      controlBook: {
        updateRecord: 'レコードを更新しました',
        updateSecurities: 'コントロールブックを更新しました',
      },
      dailyTransactionLog: {
        setTransactionData: 'トランザクションを更新しました',
        addManualTransaction: 'トランザクションを追加しました',
        deleteManualTransaction: 'トランザクションを削除しました',
      },
      procedures: {
        executeOperationalProcedure: 'ブロックチェーン上にトランザクションを作成しました',
      }, // TODO add translation to japanese
      custodian: {
        custodianUpdated: 'Custodian details updated',
        custodianAdded: 'Custodian was successfully added',
      }, // TODO add translation to japanese
      privacyControl: {
        error: {
          deleteOperator: 'Operator not found. Please contact support',
        },
        success: {
          addOperator: 'Operator was added',
          deleteOperator: 'Operator was removed',
        },
      },
    },
  },
};
