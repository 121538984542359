export default {
  issuerList: {
    title: '発行体リスト',
    button: {
      issuer: {
        create: '発行体作成',
      },
    },
    searchByName: 'Search by name', // TODO: translate to jp
    filterByLabel: 'Filter by label', // TODO: translate to jp
    message: {
      error: '発行体の読み込みに失敗しました',
      noIssuers: '発行体が存在しません',
      loading: '発行体リストを読み込み中です。しばらくお待ち下さい。',
    },
    deleteIssuerModal: {
      title: '発行体削除',
      message: '削除を実行するには発行体の名前を入力して下さい。',
    },
  },
};
