<template>
  <div
    class="date-block"
    @click="$emit('focus')"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
      <small
        v-if="!previewDateHide"
      >
        {{ previewDate }}
      </small>
    </label>
    <div class="input-group">
      <datepicker
        ref="cpRangeDatePicker"
        v-click-outside="hide"
        typeable
        calendar-button
        input-class="d-none"
        bootstrap-styling
        calendar-button-icon="ion ion-md-calendar"
        name="cp-datepicker"
        :disabled="disabled"
        :initial-view="initialView"
        :open-date="openDate"
        :disabled-dates="disabledDates"
        @selected="handleInput"
      />
      <b-input
        v-validate.initial="validation"
        class="cp-border-r-off"
        :class="[ inputClass, { 'border border-danger': error || controlledError }]"
        :value="currentValue"
        :disabled="disabled"
        :readonly="readOnly"
        :name="name"
        :data-vv-as="name.split(/(?=[A-Z])/).join(' ').toLowerCase()"
        @input="onInput"
        @focus="onFocus"
        @blur="inFocus = false"
      />
      <span
        v-if="error || controlledError"
        class="invalid-feedback d-block"
      >
        {{ error || controlledError }}
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import ClickOutside from 'vue-click-outside';
import {
  checkDateInput, formatDate, isAfter, isBefore,
  formatDateUTC,
} from '~/utilities/date-utils';


export default {
  name: 'CpDatePicker',
  components: {
    Datepicker,
  },
  directives: {
    ClickOutside,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabledDates: {
      type: Object,
      default: () => ({}),
    },
    openDate: {
      type: Date,
      default: () => (new Date()),
    },
    initialView: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    inputClass: {
      type: [String, Object, Array],
      default: '',
    },
    calendarOnRight: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    setTime: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'll',
    },
    serverFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    useUtc: {
      type: Boolean,
      default: false,
    },
    previewDateHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inFocus: false,
      userValue: this.value,
      controlledError: '',
      controlledValue: this.value,
    };
  },
  computed: {
    previewDate() {
      const isValid = checkDateInput(this.currentValue);
      return isValid ? this.toFormatll(this.currentValue) : '';
    },
    validation() {
      return this.validate ? `any_date|${this.validate}` : 'any_date';
    },
    currentValue() {
      return this.inFocus ? this.userValue : this.toFormatll(this.value || this.controlledValue);
    },
  },
  watch: {
    value(val) { // sync values on clear filter
      if (val === '') {
        this.userValue = val;
        this.controlledValue = val;
      }
    },
  },
  mounted() {
    if (this.calendarOnRight) {
      this.$refs.cpRangeDatePicker.$children[1].$el.style.right = 0;
    }
  },
  methods: {
    onFocus() {
      this.userValue = this.currentValue;
      this.inFocus = true;
    },
    onInput(val) {
      this.$emit('dateIsChanged', val);
      this.userValue = val;
      this.handleInput(val);
    },
    handleInput(val) {
      const isValid = checkDateInput(val);
      if (!isValid) {
        this.$emit('input', val);
        return;
      }
      if (Object.keys(this.disabledDates).length) {
        const isBeforeDisabled = this.disabledDates.from && isBefore(val, this.disabledDates.from);
        if (isBeforeDisabled) {
          this.$emit('input', this.formatTimeForServer(this.disabledDates.from));
          return;
        }
        const isAfterDisabled = this.disabledDates.to && isAfter(val, this.disabledDates.to);
        if (isAfterDisabled) {
          this.$emit('input', this.formatTimeForServer(this.disabledDates.to));
          return;
        }
      }
      this.$emit('input', this.formatTimeForServer(val));
    },
    isCpInput() {
      return true;
    },
    hide() {
      this.$refs.cpRangeDatePicker.close();
    },
    toFormatll(date) {
      const isValid = checkDateInput(date);
      const formatter = this.useUtc ? formatDateUTC : formatDate;
      return isValid ? formatter(date, this.dateFormat) + this.setTime : date;
    },
    formatTimeForServer(date) {
      return formatDate(date, this.serverFormat);
    },
  },
};
</script>

<style lang="scss">
  .cp-border-r-off {
    border-radius: 0 !important;
  }

  .vdp-datepicker {
    .input-group {
      height: 100% !important;
    }
    .vdp-datepicker__calendar-button{
      .input-group-text {
        border-radius: 0;
        background-color: rgba(10, 23, 39, 0.06) !important;
      }
    }
  }
</style>
