export default {
  configurationGeneral: {
    title: 'General',
    message: {
      unsaved: 'You have unsaved changes, are you sure you want to leave?',
    },
    // Preview modal
    previewModal: {
      title: {
        logoSquare: 'General Properties - Logo Square',
        logoFullName: 'General Properties - Logo Full Name',
        favicon: 'General Properties - Favicon',
        announcement: 'Investor Area - Title / Announcement Background',
        about: 'Investor Area - About Background',
        wizard: 'Investor Area - Wizard Background',
      },
    },
    // General Properties section
    generalProperties: {
      title: 'General Properties',
      label: {
        primaryColor: 'Primary color',
        secondaryColor: 'Secondary color',
        actionColor: 'Action color',
        recommendedRatio: 'Recommended ratio:',
        recommendedRatioValue: '2:1, 3:1 or 4:1',
        recommendedFormat: 'Recommended format:',
        recommendedFormatValue: 'transparent PNG',
        minimumHeight: 'Minimum height:',
        minimumHeightValue: '250px',
        recommendedSize: 'Recommended size:',
        recommendedSizeValue: '260px * 260px',
        name: 'Name',
        surname: 'Surname',
        dateOfBirth: 'Date Of Birth',
      },
      input: {
        issuerName: {
          text: 'Issuer name',
          placeholder: 'e.g. Apple inc.',
        },
        issuerLeiCode: {
          text: 'Issuer LEI Code',
          placeholder: 'e.g. 506700GE1G29325QX363',
        },
        beneficialOwnerInformation: 'Beneficial Owner Information',
        colorScheme: 'Color Scheme',
        logoSquare: 'Logo Square',
        logoFullName: 'Logo Full Name',
        favicon: 'Favicon',
      },
    },
    // Landing Page section
    landingPage: {
      title: 'Landing Page',
      label: {
        size: 'Size:',
        sizeValue: '3000px * 1688px',
        format: 'Format:',
        formatValue: 'JPG',
      },
      button: {
        basicLandingPage: 'Basic Landing Page',
        externalLandingPage: 'External Landing Page',
      },
      input: {
        platformUrl: {
          text: 'Platform URL:',
          placeholder: 'e.g. https://www.securitize.io',
        },
        externalLandingUrl: {
          text: 'External Landing URL:',
          placeholder: 'e.g. https://www.securitize.io',
        },
        radio: {
          allowHolders: 'Allow Holders:',
          requireLabel: 'Require Label:',
        },
        restrictLogin: 'Restrict Login',
        enablePage: 'Enable Page:',
        mode: 'Mode:',
        enableLimitedAccess: 'Enable Limited Access:',
        landingPageUser: 'Landing page User:',
        landingPagePassword: 'Landing page Password:',
        backgroundImage: 'Background Image',
        headline: 'Headline',
        text: 'Text',
        loginButtonText: 'Log-in Button Text:',
        registrationButtonText: 'Registration Button Text:',
      },
      message: {
        basicLandingPageSection: 'This sections allows to set-up an optional basic landing page for your investors to be hosted in {0}',
        externalLandingPageSection: `This sections allows you to give access to investors via your own domain and use your own web page.
          You can integrate it following the instructions at {0} and using these parameters:`,
      },
      option: {
        mode: {
          comingSoon: 'Coming soon',
          loginOnly: 'Login-only',
          loginRegistration: 'Login & Registration',
        },
      },
    },
    // Investors Area section
    investorsArea: {
      title: 'Investors Area (Dashboard)',
      subTitle: {
        announcement: 'Title / Announcement',
        about: 'About',
        wizard: 'Wizard',
        socialLinks: 'Social Links (optional)',
      },
      label: {
        size: 'Size:',
        sizeValue: {
          announcement: '1400px * 400px',
          about: '1400px * 600px',
          wizard: '1440px * 500px',
        },
        format: 'Format:',
        formatValue: 'JPG',
        comment: 'Comment:',
        commentValue: 'Please leave a bit white space to the top or add a gradient fading into white.',
      },
      input: {
        backgroundImage: 'Background Image',
        title: 'Title',
        subtitle: 'Subtitle',
        text: 'Text',
        youtubeVideoUrl: 'Youtube-Video URL (optional)',
        wizardBackground: 'Wizard Background',
        facebook: 'Facebook:',
        whatsapp: 'Whatsapp:',
        twitter: 'Twitter:',
        telegram: 'Telegram:',
        medium: 'Medium:',
      },
    },
    // Email Configuration section
    emailConfiguration: {
      title: 'Email Configuration',
      button: {
        defaultEmailAddress: 'Default Email Address',
        customEmailAddress: ' Custom Email Address',
      },
      input: {
        customEmailAddress: 'Custom Email Address:',
      },
      message: {
        receiveCommunication: 'Your investors will receive communications from the address {0}',
        configureYourEmailProvider: {
          text: 'To use your own email address for investor communications you must configure your email provider as described {0}',
          link: 'here',
        },
      },
    },
  },
};
