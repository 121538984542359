import countries from 'i18n-iso-countries';
import { i18n } from '../i18n';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ja.json'));

export const i18nKeyListConvert = (items, key, values) => {
  const convertedItems = items.map((item) => {
    if (item && item.i18nKey) {
      const res = {
        ...item,
        [key]: i18n.t(item.i18nKey, values),
      };
      delete res.i18nKey;
      return res;
    }
    return item;
  });
  return convertedItems;
};

export const getCountryName = (countryCode, locale = 'en') => countries.getName(countryCode, locale);
