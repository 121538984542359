import axiosV2 from '~/utilities/axiosV2';
import kebabToCamel from '~/utilities/convert-object-keys';

const authorizationHeader = () => ({});
const basePathNewGW = '/isr/api';

class InvestorCpApi {
  constructor() {
    this.getTokenPreferences = (params) => {
      const { investorId, issuerId, ...restParams } = params;

      const localVarPath = `${basePathNewGW}/v1/issuers/${issuerId}/investors/${investorId}`;
      let localVarHeaderParams;

      return axiosV2.get(localVarPath, {
        headers: localVarHeaderParams,
        params: restParams,
      });
    };

    this.createUpdateTokenPreferences = (issuerId, investorId, tokenId, method, updatedByOperatorId, updatedBy) => {
      const localVarPath = `${basePathNewGW}/v1/issuers/${issuerId}/investors/${investorId}`;
      let localVarHeaderParams;
      const body = {
        tokenId,
        method,
        updatedByOperatorId,
        updatedBy,
      };
      return axiosV2.post(localVarPath, body, {
        headers: localVarHeaderParams,
      });
    };

    this.getFundraiseInvestors = function getFundraiseInvestors(issuerId, tokenId, params) {
      const localVarPath = `${basePathNewGW}/v1/issuers/${issuerId}/investors`;
      const localVarHeadersParams = { ...authorizationHeader() };
      return axiosV2.get(localVarPath, {
        headers: localVarHeadersParams,
        params: { ...params },
      });
    };
    const dateFields = ['startDate', 'endDate', 'fromCreatedAt', 'toCreatedAt'];
    this.getArgumentsForFundraise = function getArgumentsForFundraise(argumentsSchema, params = {}) {
      const convertedParams = kebabToCamel(params);
      dateFields.forEach((key) => {
        if (convertedParams[key]) {
          convertedParams[key] = new Date(convertedParams[key]);
        }
      });
      return convertedParams;
    };
  }
}

// eslint-disable-next-line import/prefer-default-export
export const {
  getTokenPreferences, createUpdateTokenPreferences, getFundraiseInvestors,
  getArgumentsForFundraise,
} = new InvestorCpApi();
