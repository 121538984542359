export default {
  distributionDetail: {
    label: {
      filterFieldName: 'Payout Wallet',
      table: {
        name: 'Name / Entity',
        email: 'Email',
        tokens: 'Tokens',
        wallet: 'Wallet',
        type: 'Type',
        payoutWallet: 'Payout Wallet',
      },
    },
    status: {
      wallet: {
        wallet: 'wallet',
        bonus: 'bonus',
        treasury: 'treasury',
      },
    },
    button: {
      back: 'Back to overview',
      download: 'Download',
      generate: 'Generate Distribution',
    },
    loading: 'Loading Distributions Data...',
  },
};
