export default {
  affiliateManagement: {
    title: 'Affiliate Management',
    label: {
      table: {
        fullName: 'Name',
        affiliateStatus: 'Affiliate',
        lastChange: 'Last change',
        comment: 'Comment',
      },
    },
    status: {
      yes: 'yes',
      no: 'no',
      pending: 'pending',
    },
    message: {
      setAffiliateToolTip: 'Investor cannot be set as an affiliate when Pending',
    },
    deleteModal: {
      title: {
        activate: 'Activate Affiliate',
        deactivate: 'Deactivate Affiliate',
      },
      message: {
        activate: 'Please note that you will need to sign a signature per token in order to mark this investor as affiliate.',
        deactivate: 'Please note that you will need to sign a signature per token in order to mark this investor as non affiliate.',
      },
      input: {
        comment: 'Comment:',
        documentId: 'Document ID:',
      },
      label: {
        table: {
          createdAt: 'Last change',
          status: 'Status',
          comment: 'Comment',
        },
      },
    },
  },
};
