import axios from '~/utilities/axios';
import currentOperatorStore from '@/store/modules/current-operator';

const basePath = '/isr/api/v1/';

export default {
  async getImportIssuanceProgress({ commit }, { issuerId, tokenId }) {
    const url = `${basePath}issuers/${issuerId}/tokens/${tokenId}/import-issuance/status`;
    try {
      const { data } = await axios.get(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_IMPORT_ISSUANCE_PROGRESS', {
        ...data,
        percentageProgress: (data?.totalPages ?? 0) / (data?.processedPages ?? 1),
      });
    } catch (err) {
      const errorParsed = err.response.data ? err.response.data : { statusCode: err.response.status };
      commit('SET_IMPORT_ISSUANCE_PROGRESS', errorParsed);
      this.$log.error('Getting import issuance progress:', err);

      throw err;
    }
  },
  async executeImport({ commit }, { issuerId, tokenId, fileKey, forceIssuanceToTBE }) {
    const { state: { id: operatorId } } = currentOperatorStore;
    const url = `${basePath}issuers/${issuerId}/tokens/${tokenId}/import-issuance`;
    try {
      const { data } = await axios.post(url, {
        operatorId,
        fileKey,
        forceIssuanceToTBE,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      const { status, processableRecords, totalRecords, recordsWithErrors } = data;
      return { status, processableRecords, totalRecords, recordsWithErrors };
    } catch (err) {
      const errorParsed = err.response.data ? err.response.data : { statusCode: err.response.status };
      commit('SET_IMPORT_ISSUANCE_PROGRESS', errorParsed);
      this.$log.error('Executing import issuance:', err);

      throw err;
    }
  },
  async validateImport({ commit }, { issuerId, tokenId, fileKey }) {
    const { state: { id: operatorId } } = currentOperatorStore;
    const url = `${basePath}issuers/${issuerId}/tokens/${tokenId}/import-issuance/validate`;
    try {
      const { data } = await axios.post(url, {
        operatorId,
        fileKey,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      const { status, processableRecords, totalRecords, recordsWithErrors } = data;
      return { status, processableRecords, totalRecords, recordsWithErrors };
    } catch (err) {
      const errorParsed = err.response.data ? err.response.data : { statusCode: err.response.status };
      commit('SET_IMPORT_ISSUANCE_PROGRESS', errorParsed);
      this.$log.error('Getting import issuance validation summary:', err);

      throw err;
    }
  },
  toggleIsLoading({ commit }) {
    commit('TOGGLE_LOADING');
  },
  setIsModalOpen({ commit }, value) {
    commit('TOGGLE_MODAL_OPEN', value);
  },
  setIsSendingCsv({ commit }, value) {
    commit('TOGGLE_IS_SENDING_CSV', value);
  },
  cleanImportIssuanceProgress({ commit }) {
    commit('CLEAN_IMPORT_ISSUANCE_PROGRESS');
  },
};
