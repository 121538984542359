export default {
  affiliates: {
    title: 'Issuer Affiliates',
    button: {
      affiliate: {
        add: 'Add affiliate',
      },
    },
    label: {
      table: {
        name: 'Name',
        createdAt: 'Created',
      },
    },
    // modal
    affiliateModal: {
      title: '{0} Affiliate',
      input: {
        name: 'Name',
      },
      label: {
        createdAt: 'Created',
        updatedAt: 'Updated',
      },
    },
  },
};
