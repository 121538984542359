export default {
  configurationFundraise: {
    title: '資金調達',
    subTitle: {
      general: '一般',
      round: 'ラウンド',
    },
    errorsMsg: {
      depositOptions: 'Must contain at least one checkbox', // TODO
      iraDepositOptions: 'IRA cannot be selected without another deposit method', // TODO
    },
    values: {
      web3: 'Web3', // TODO
      standard: 'Standard', // TODO
      'wire-transfer-crypto': 'Wire Transfer/Crypto', // TODO
      'cash-account': 'Cash account', // TODO
      ira: 'IRA', // TODO
    },
    investmentRegulationTypes: {
      none: 'None', // TODO
      'reg-cf': 'Reg CF', // TODO
      'reg-d-506c': 'Reg D 506c', // TODO
      'reg-d-506b': 'Reg D 506b', // TODO
      'reg-a': 'Reg A', // TODO
      'reg-s': 'Reg S', // TODO
      'reg-d-506c-reg-s': 'Reg D 506c/Reg S', // TODO
      'reg-d-506b-reg-s': 'Reg D 506b/Reg S', // TODO
    },
    label: {
      wireTransferDetailsBank: '入金銀行口座',
      currencies: '通貨',
      ethXPub: 'ETH拡張公開鍵',
      btcXPub: 'BTC拡張公開鍵',
      investmentType: 'Investment type', // TODO
      depositOptions: 'Deposit options', // TODO
      cashAccountIdentifier: 'Cash account identifier', // TODO
      investmentRegulationType: 'Investment regulation', // TODO

      table: {
        name: '名前',
        status: 'ステータス',
        startsAt: '開始日',
        endsAt: '終了日',
        issuanceDate: '発行日',
        minimumCryptoInvestments: '最小投資額（{0}/暗号資産）',
        minimumFiatInvestments: '最小投資額（{0}/法定通貨）',
        investmentTermsAndConditions: '投資利用規約',
        investmentSubscriptionAgreement: '契約',
        subscriptionAgreementDocusignId: 'DocuSign ID',
      },
    },
    status: {
      active: '募集中',
      planned: '企画済み',
      done: '終了',
    },
    button: {
      round: {
        add: 'ラウンドの追加',
      },
    },
    loading: '資金調達データ読み込み中です。しばらくお待ちください。',
    // modals
    deleteModal: {
      title: 'ラウンドの削除',
      message: '{0}を削除してもよいですか？',
    },
    updateDoneRoundModal: {
      title: 'ラウンドの編集',
      message: '以前の「終了」ラウンドを再度アクティブにすると、現在の「アクティブ」ラウンドがすべて終了します。',
    },
    roundModal: {
      title: {
        add: 'ラウンド追加',
        edit: 'ラウンド編集',
      },
      label: {
        bonusForLargeInvestments: '大口投資へのボーナス',
        tokenPrice: 'トークン価格',
        bonusAmount: '最小投資額',
        bonusPercentage: 'ボーナス割合',
        mandatory: '必須',
        jurisdictionalExceptions: '国・地域別条件指定',
      },
      message: {
        numericZeroError: '{0}は0より大きい必要があります',
        numericRangeError: '{0}は0より大きく100以下である必要があります',
        numericTypeError: '{0}は数値である必要があります',
        minAmountError: '{0}は{1}より大きい必要があります',
        jurisdictionalExceptionExists: '国・地域別条件指定が存在します',
        countryCodeRequired: '国が必須です',
        exceptionNameRequired: '条件指定名が必須です',
        wrongExceptionValue: '値が間違っています',
        exceptionValueRequired: '必須です',
      },
      input: {
        roundName: 'ラウンド名:',
        activeStatus: 'アクティブ:',
        visibleToInvestor: '投資家への表示:',
        minimumFiatInvestments: '最小投資額（{0}/法定通貨での入金）:',
        minimumCryptoInvestments: '最小投資額（{0}/暗号資産での入金）:',
        minSubsequentInvestmentFiat: 'Min. subsequent investment ({0} for FIAT):',
        minSubsequentInvestmentCrypto: 'Min. subsequent investment ({0} for Crypto):',
        docuSignId: 'DocuSign ID',
        custodianDocuSignId: 'カストディアン DocuSign ID',
        startDate: '開始日:',
        closeDate: '終了日:',
        issuanceDate: '発行日:',
        tokenPrice: 'トークン価格:',
        minimumInvestmentForBonus: 'ボーナス対象者の最小投資額:',
        bonus: 'ボーナス:',
        investmentTermsAndConditions: '投資利用規約:',
        subscriptionAgreement: '契約:',
        json: 'JSON', // TODO: translate to jp
      },
      button: {
        getLastRoundAgreement: '直近のラウンドと同じにする',
      },
    },
  },
};
