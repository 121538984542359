import {
  getAuditLogsByIssuerId,
  getAuditLogOperatorsByIssuerId,
  getAuditLogTokensByIssuerId,
} from '~/utilities/external-api/apis/auditLogIssuerCpApi';

export default {
  getAuditLogsListByIssuerId(ctx, { params }) {
    return getAuditLogsByIssuerId(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Audit Logs get error:', err);
      });
  },

  getOperatorListByIssuerId({ commit }, { issuerId }) {
    return getAuditLogOperatorsByIssuerId(issuerId)
      .then(({ data }) => {
        commit('SET_AUDIT_LOG_ISSUER_OPERATOR_LIST', data);
      })
      .catch((err) => {
        this.$log.error('Issuer Audit Logs get operators error:', err);
      });
  },

  getTokenListByIssuerId({ commit }, { issuerId }) {
    return getAuditLogTokensByIssuerId(issuerId)
      .then(({ data }) => {
        commit('SET_AUDIT_LOG_ISSUER_TOKEN_LIST', data);
      })
      .catch((err) => {
        this.$log.error('Issuer Audit Logs get tokens error:', err);
      });
  },

};
