import FileSaver from 'file-saver';
import moment from 'moment';
import {
  getAccreditationDetail,
  getAccreditationsList,
  getS3ZipUrl, updateAccreditation,
  getAttorneys, putAttorney,
} from '@/utilities/external-api/apis/accreditationCpApi';
import axios from '~/utilities/axios';

export default {
  getBrokerDealerAccreditationsList(ctx, { params }) {
    return getAccreditationsList(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID accreditations get error:', err);
      });
  },
  getBrokerDealerAccreditationDetail(ctx, { accreditationId }) {
    return getAccreditationDetail(accreditationId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID accreditation detail get error:', err);
      });
  },
  getBrokerDealerAccreditationZipUrl(ctx, { accreditationId }) {
    return getS3ZipUrl(accreditationId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID accreditations zip url get error:', err);
      });
  },
  updateBrokerDealerAccreditation(ctx, { accreditationId, status, rejectReason, note }) {
    return updateAccreditation(accreditationId, status, rejectReason, note)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID accreditations update error:', err);
      });
  },
  getBrokerDealerAttorneys({ commit }) {
    return getAttorneys()
      .then(({ data }) => {
        commit('SET_BROKER_DEALER_ATTORNEYS', data);
        return { data };
      })
      .catch((err) => {
        this.$log.error('Securitize ID attorneys get error:', err);
      });
  },
  putBrokerDealerAttorney(ctx, { accreditationId, attorney }) {
    return putAttorney(accreditationId, attorney)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID accreditations Attorney put Error', err);
      });
  },
  async getBrokerDealerOperatorById(ctx, { externalId }) {
    const url = `/api/v2/operators/${externalId}`;
    return axios.get(url, { headers: { 'Content-Type': 'application/json' } })
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Get Operator by id error:', err);
      });
  },
  getBrokerDealerAccreditationsListCsv(ctx, { params }) {
    return getAccreditationsList(params, true)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, `accreditations-${moment().format('YYYY-MM-DD, h-mm')}.csv`);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },
};
