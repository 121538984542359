import { infoApi, walletManagersApi } from '~/utilities/mainApiClient';

const { getWalletManagers } = walletManagersApi;
const { getPermissionsPerIssuer } = infoApi;

export default {
  getIssuerWalletManagers({ commit }, { issuerId, tokenId }) {
    return getWalletManagers(issuerId, tokenId)
      .then(({ data }) => {
        commit('SET_ISSUER_WALLET_MANAGERS', data);
      }).catch((err) => {
        this.$log.error('Wallet Managers - error:', err);
      });
  },
  getPermissionsPerIssuer({ commit }, { issuerId }) {
    return getPermissionsPerIssuer(issuerId).then(({ data }) => {
      commit('PERMISSIONS_PER_ISSUER', data);
      return data;
    })
      .catch(err => this.$log.error('get Permissions Per Issuer error:', err));
  },
};
