import { i18n } from '~/i18n';
import axios from '~/utilities/axios';

const basePath = '/api/v2/';

export default {
    async addOrganization({ commit }, body) {
        const url = `${basePath}organizations`;
        try {
            const { data } = await axios.post(url, body, { headers: { 'Content-Type': 'application/json' } });
            commit('global/CALL_TOASTER', i18n.t('store.toaster.organizations.addOrganization'), { root: true });
            return data;
        } catch (err) {
            this.$log.error('Add Organization error:', err);
            throw err;
        }
    },
    async updateOrganization({ commit }, { body, organizationId }) {
        const url = `${basePath}organizations/${organizationId}`;
        try {
            const { data } = await axios.patch(url, body, { headers: { 'Content-Type': 'application/json' } });
            commit('global/CALL_TOASTER', i18n.t('store.toaster.organizations.updateOrganization'), { root: true });
            return data;
        } catch (err) {
            this.$log.error('Update Organization error:', err);
            throw err;
        }
    },
    async getOrganizationById({ commit }, organizationId) {
        const url = `${basePath}organizations/${organizationId}`;
        try {
            const { data } = await axios.get(url, { headers: { 'Content-Type': 'application/json' } });
            commit('SET_CURRENT_ORGANIZATION', data);
            return data;
        } catch (err) {
            this.$log.error('Get Organization by id error:', err);

            throw err;
        }
    },
    async getOrganizationsList({ commit }, args) {
        const url = `${basePath}organizations`;
        try {
            const { params } = args || {};
            const { data } = await axios.get(url, { params, headers: { 'Content-Type': 'application/json' } });
            commit('SET_ORGANIZATIONS_LIST', data);
            return data;
        } catch (err) {
            this.$log.error('Get Organizations List error:', err);
            throw err;
        }
    },
};
