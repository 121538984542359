import axios from '~/utilities/axios';
import axiosV2 from '~/utilities/axiosV2';

class PayoutCpApi {
  constructor() {
    this.configuration = {
      apiKey: '',
      basePath: '/api/v2',
      basePathNewGW: '/isr/api',
    };

    this.authorizationHeader = () => ({});

    this.getPayoutsSnapshotRecords = (queryParams) => {
      const { issuerId, tokenId, snapshotId, ...rest } = queryParams;
      const localVarPath = `${this.configuration.basePath}/transfer-agent/payouts/distributions/${issuerId}/${tokenId}/investors`;
      let localVarHeaderParams;

      return axios.get(localVarPath, {
        params: { ...rest, snapshot: snapshotId },
        headers: localVarHeaderParams,
      });
    };

    this.getDistributionDownloadUrl = (params) => {
      const { issuerId, tokenId, snapshotId: snapshot } = params;
      const localVarPath = `${this.configuration.basePath}/transfer-agent/payouts/distributions/${issuerId}/${tokenId}/download`;
      let localVarHeaderParams;

      return axios.get(localVarPath, {
        params: { snapshot },
        headers: localVarHeaderParams,
        timeout: 1000 * 60 * 15,
      });
    };

    this.getTaxFormSignedUrl = (params) => {
      const { taxFormId } = params;
      const localVarPath = `${this.configuration.basePath}/transfer-agent/payouts/tax-forms/${taxFormId}/download`;
      let localVarHeaderParams;

      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
      });
    };

    this.getHistoryPayouts = (params) => {
      const { investorId, issuerId, tokenId, ...restParams } = params;

      const localVarPath = `${this.configuration.basePathNewGW}/v1/issuers/${issuerId}/tokens/${tokenId}/investors/${investorId}/payouts`;
      let localVarHeaderParams;

      return axiosV2.get(localVarPath, {
        headers: localVarHeaderParams,
        params: restParams,
      });
    };
  }
}

export const {
  getDistributionDownloadUrl,
  getPayoutsSnapshotRecords,
  getTaxFormSignedUrl,
  getHistoryPayouts,
} = new PayoutCpApi();
