import { sanitize } from 'dompurify';
import { configurationsApi, tokensApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  editEmailConfiguration,
  editGeneralConfigurationSection,
  editInvestorsArea,
  editLandingPage,
  getGeneralConfigurations,
} = configurationsApi;

const { getTokenById, updateToken, createToken } = tokensApi;

export default {
  createToken({ commit }, { issuerId, body }) {
    return createToken(issuerId, body).then(({ data }) => {
      commit('issuersInfo/SET_CURRENT_TOKEN', { ...data, tokenId: data.id }, { root: true });
      commit('global/CALL_TOASTER', i18n.t('store.toaster.configToken.created'), { root: true });
      return data.id;
    }).catch((err) => {
      this.$log.error('Create issuer token error:', err);
      throw err;
    });
  },

  getConfiguration({ commit }, issuerId) {
    return getGeneralConfigurations(issuerId).then(({ data }) => {
      data.investorsArea.announcements.subtitle = sanitize(data.investorsArea.announcements.subtitle);
      commit('SET_CONFIGURATION', data);
    }).catch((err) => {
      this.$log.error('Get configuration error:', err);
      throw err;
    });
  },

  getTokenConfiguration({ commit }, { issuerId, tokenId }) {
    return getTokenById(issuerId, tokenId).then(({ data }) => {
      commit('SET_TOKEN_CONFIGURATION', data);
    }).catch((err) => {
      this.$log.error('Get Token Configuration Error:', err);
      throw err;
    });
  },

  updateTokenConfiguration({ dispatch, commit }, { issuerId, tokenId, body }) {
    return new Promise((resolve, reject) => {
      updateToken(issuerId, tokenId, body, { headers: { 'Content-Type': 'application/json' } }).then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.configuration.updateTokenConfiguration'), { root: true });
        Promise
          .all([dispatch('getTokenConfiguration', { issuerId, tokenId }), dispatch('global/fetchIssuerInfo', issuerId, { root: true })])
          .then(resolve)
          .catch(reject);
      }).catch((err) => {
        this.$log.error('Update configuration general error:', err);
        reject(err);
      });
    });
  },

  updateGeneralConfiguration({ dispatch, commit }, { issuerId, body }) {
    return new Promise((resolve, reject) => {
      editGeneralConfigurationSection(issuerId, body).then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.configuration.updateGeneralConfiguration'), { root: true });
        dispatch('getConfiguration', issuerId).then(resolve).catch(reject);
      }).catch((err) => {
        this.$log.error('Update configuration general error:', err);
        reject(err);
      });
    });
  },

  updateLandingPageConfiguration({ dispatch, getters, commit }, issuerId) {
    const landingPageConfiguration = getters.getLandingPage;
    const { type } = landingPageConfiguration;
    const body = type === 'basic' ? { type, basic: landingPageConfiguration.basic } : { type, external: landingPageConfiguration.external };
    if (type === 'basic') delete body.basic.limitedAccess.username;

    return editLandingPage(issuerId, body).then(() => {
      dispatch('getConfiguration', issuerId);
      commit('global/CALL_TOASTER', i18n.t('store.toaster.configuration.updateLandingPageConfiguration'), { root: true });
    }).catch((err) => {
      this.$log.error('Update configuration general error:', err);
      throw err;
    });
  },

  updateEmailConfiguration({ dispatch, commit }, { issuerId, body }) {
    return new Promise((resolve, reject) => {
      editEmailConfiguration(issuerId, body).then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.configuration.updateEmailConfiguration'), { root: true });
        dispatch('getConfiguration', issuerId)
          .then(resolve)
          .catch(reject);
      }).catch((err) => {
        this.$log.error('Update configuration general error:', err);
        reject(err);
      });
    });
  },

  updateInvestorsConfiguration({ dispatch, getters, commit }, issuerId) {
    const config = getters.getInvestorsArea;
    delete config.socialLinks.supportEmail; // i am sorry but there is no choice
    return editInvestorsArea(issuerId, config).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.configuration.updateInvestorsConfiguration'), { root: true });
      dispatch('getConfiguration', issuerId);
    }).catch((err) => {
      this.$log.error('Update configuration investors error:', err);
      throw err;
    });
  },
};
