import axiosV2 from '~/utilities/axiosV2';

class TokenTransactionCpApi {
    constructor() {
        const that = this;
        that.configuration = { apiKey: '', basePath: '/api/v2', basePathNewGW: '/isr/api' };

        this.authorizationHeader = () => ({});

        this.getInvestorTokenTransactionsHistory = function getInvestorTokenTransactions(issuerId, tokenId, userId, page, limit) {
            const localVarPath = `${that.configuration.basePathNewGW}/v1/issuers/${issuerId}/tokens/${tokenId}/investors/${userId}/transaction-history?page=${page}&limit=${limit}`;
            const localVarHeadersParams = { ...that.authorizationHeader() };
            return axiosV2.get(localVarPath, {
                headers: localVarHeadersParams,
            });
        };

        this.updateTokenTransactionById = function updateTokenTransaction({ issuerId, tokenId, tokenTransactionId, body }) {
            const localVarPath = `${that.configuration.basePathNewGW}/v1/issuers/${issuerId}/tokens/${tokenId}/token-transactions/${tokenTransactionId}`;
            const localVarHeadersParams = { ...that.authorizationHeader() };
            return axiosV2.patch(localVarPath, body, {
                headers: localVarHeadersParams,
            });
        };
    }
}

// eslint-disable-next-line import/prefer-default-export
export const {
    updateTokenTransactionById,
    getInvestorTokenTransactionsHistory,
} = new TokenTransactionCpApi();
