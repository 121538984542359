import moment from 'moment';

export default {
  methods: {
    setHours(date, isEndOfTheDay) {
      if (isEndOfTheDay) {
        return moment(date).endOf('day').toISOString(true);
      }
      return moment(date).startOf('day').toISOString(true);
    },
  },
};
