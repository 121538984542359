
export default {
  SET_IMPORT_INVESTORS_PROGRESS(state, value) {
    state.importInvestorProgress = value;
  },
  CLEAN_IMPORT_INVESTORS_PROGRESS(state) {
    state.importInvestorProgress = {};
  },
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading;
  },
  TOGGLE_MODAL_OPEN(state, value) {
    state.isModalOpen = value;
  },
  TOGGLE_IS_SENDING_CSV(state, value) {
    state.isSendingCsv = value;
  },
};
