
export default {
  SET_OPERATOR(state, {
    id, idUUID, name, email, authorizationLevel, externalId, authorizations, organizationId, picture,
  }) {
    state.id = id;
    state.idUUID = idUUID;
    state.name = name;
    state.email = email;
    state.authorizationLevel = authorizationLevel;
    state.externalId = externalId;
    state.authorizations = authorizations;
    state.organizationId = organizationId;
    state.picture = picture;
  },
  SET_AUTHENTICATED(state, value) {
    state.isAuthenticated = value;
  },
  SET_VIEW_DATA(state, value) {
    state.viewData = value;
  },
  SET_SECURITIZE_ID_ALERTS_COUNT(state, { count }) {
    state.securitizeIdNotificationsCount = count;
  },
  SET_SECURITIZE_ID_NOTIFICATIONS(state, list) {
    state.securitizeIdNotifications = list;
  },
  SET_REGISTER_TOKEN(state, registerToken) {
    state.registerToken = registerToken;
  },
  SET_TWO_FA_URI(state, twoFaUri) {
    state.twoFaUri = twoFaUri;
  },
};
