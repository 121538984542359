export default {
  home: {
    button: {
      signIn: 'Sign in',
      google: 'SIGN IN WITH GOOGLE',
    },
    input: {
      email: 'Email',
      password: 'Password',
    },
    message: {
      error: 'There was a problem signing in. Please try again or contact',
      wrongEmailOrPassword: 'Wrong email or password',
      loginProcessFailed: 'The login process has failed. Please try again.',
      lock: 'This account has been locked. You can sign in again after 10 minututes.',
      noAccount: 'You don\'t have an account yet?',
    },
    link: {
      register: 'Register now!',
    },
  },
};
