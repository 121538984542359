export default {
  issuerCurrencies: {
    title: '受入れ通貨',
    label: {
      table: {
        name: '通貨名',
        identifier: '通貨コード',
        type: 'タイプ',
        decimals: '小数部桁数',
        active: 'アクティブ',
      },
    },
    status: {
      fiat: '法定通貨',
      crypto: '暗号資産',
    },
    // modal
    currencyModal: {
      title: '受入れ通貨編集',
      label: {
        active: 'アクティブ',
      },
      message: {
        alert: '少なくとも1つの受け入れ通貨がアクティブである必要があります',
      },
    },
  },
};
