<template>
  <div
    class="image-overlay-container"
    :class="{'image-overlay-container--only': !shouldShowBlockchainImage}"
    @click="$emit('click')"
  >
    <b-img
      ref="CpMainLogo"
      class="overlay-image"
      :class="{'overlay-image--only': !shouldShowBlockchainImage}"
      alt="Logo"
      :src="srcMainLogo"
      :title="title"
    />
    <b-img
      v-if="shouldShowBlockchainImage"
      ref="CpBlockchainLogo"
      class="background-image"
      alt="Logo"
      :src="srcBlockchainLogo"
      :title="title"
    />
  </div>
</template>

<script>
export default {
  name: 'CpImageWithBlockchainName',
  props: {
    srcMainLogo: {
      type: String,
      required: true,
    },
    srcBlockchainLogo: {
      type: String,
      required: true,
    },
    defaultMainLogoSrc: {
      type: String,
      required: true,
    },
    defaultBlockchainLogoSrc: {
      type: String,
      required: true,
    },
    shouldHideBlockchainDefaultImgIfFail: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return { shouldShowBlockchainImage: true };
  },
  watch: {
    srcMainLogo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setImage();
      }
    },
    srcBlockchainLogo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setImage();
      }
    },
    src() {
      this.setImage();
    },
  },
  mounted() {
    this.setImage();
  },
  methods: {
    setImage() {
      const imgMainLogo = new Image();
      imgMainLogo.src = this.srcMainLogo;
      imgMainLogo.onload = () => {
        this.$emit('load');
      };
      imgMainLogo.onerror = () => {
        this.$refs.CpMainLogo.src = this.defaultMainLogoSrc;
        this.$emit('imageError');
      };

      const imgBlockchainLogo = new Image();
      imgBlockchainLogo.src = this.srcBlockchainLogo;
      imgBlockchainLogo.onload = () => {
        this.$emit('load');
      };
      imgBlockchainLogo.onerror = () => {
        this.$refs.CpBlockchainLogo.src = this.defaultBlockchainLogoSrc;
        this.$emit('imageError');
        if (this.shouldHideBlockchainDefaultImgIfFail) {
          this.shouldShowBlockchainImage = false;
        }
      };
    },
  },
};
</script>

<style lang="css" scoped>
.image-overlay-container {
  position: relative;
  width: 64px;

}
.image-overlay-container.image-overlay-container--only{
  justify-content: center;
  display: flex;
  justify-items: center;
  padding-right: 10px;
  width: 42px;
}

.background-image {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 2px;
  left: 24px;
  border-radius: 50%;
}

.overlay-image {
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  z-index: 0;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 1;
  background-color: white;
}
.overlay-image.overlay-image--only{
  position: relative;
}
</style>
