import { configurationsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const areaTypes = {
  state: 'state',
  region: 'region',
};

const {
  updateTokenStateSetting,
  updateTokenRegionSetting,
  getJurisdictionsPerTokenByCountryCode,
  getJurisdictionsPerTokenConfigurations,
  getTokenStateJurisdictionalConfig,
  getTokenRegionJurisdictionalConfig,
  getJurisdictionsPerTokenConfigurationsListPerCountry,
  updateJurisdictionPerTokenByCountryCode,
  deleteTokenCountrySetting,
  deleteTokenStateSetting,
  deleteTokenRegionSetting,
} = configurationsApi;
const jurisdictionsConfigurationsArguments = {
  issuerId: undefined,
  tokenId: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
  q: undefined,
  isTokenSpecific: undefined,
};
export default {
  getJurisdictionsPerTokenConfigurations({ commit }, issuerId) {
    return getJurisdictionsPerTokenConfigurations(issuerId).then(({ data }) => {
      commit('SET_JURISDICTIONS_PER_TOKEN', data);
      return data;
    }).catch((err) => {
      this.$log.error('Get Jurisdictions Per Asset error:', err);
      if (err.response.body.message) {
        throw err.response.body.message;
      }
    });
  },

  getJurisdictionsPerTokenCountries({ commit }, { params }) {
    const args = getArgumentsForApi(jurisdictionsConfigurationsArguments, params);
    return getJurisdictionsPerTokenConfigurationsListPerCountry(...args)
      .then((res) => {
        const { data } = res;
        commit('SET_JURISDICTIONS_PER_TOKEN_COUNTRIES', data);
        return res;
      })
      .catch((err) => {
        this.$log.error('Jurisdictions Per Asset Configurations List Per Country get error:', err);
        return err;
      });
  },

  fetchTokenCountryByCode({ commit }, { issuerId, tokenId, countryCode }) {
    return getJurisdictionsPerTokenByCountryCode(issuerId, tokenId, countryCode).then(({ data }) => {
      commit('SET_EDITABLE_TOKEN_COUNTRY', data);
      return data;
    });
  },

  fetchTokenStateRegionSetting({ commit }, { issuerId, tokenId, countryCode, stateAndRegionCode, areaType }) {
    const promise = areaType === areaTypes.state
      ? getTokenStateJurisdictionalConfig(issuerId, tokenId, countryCode, stateAndRegionCode)
      : getTokenRegionJurisdictionalConfig(issuerId, tokenId, countryCode, stateAndRegionCode);

    return promise.then(({ data }) => {
      commit('SET_EDITABLE_TOKEN_COUNTRY', data);
      return data;
    });
  },

  resetTokenCountrySetting({ commit }, { issuerId, tokenId, countryCode }) {
    return deleteTokenCountrySetting(issuerId, tokenId, countryCode).then(() => {
      // TODO: add translations
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictionsPerToken.resetTokenCountry'), { root: true });
    });
  },

  resetTokenStateRegionSetting({ commit }, { issuerId, tokenId, countryCode, stateAndRegionCode, areaType }) {
    const promise = areaType === areaTypes.state
      ? deleteTokenStateSetting(issuerId, tokenId, countryCode, stateAndRegionCode)
      : deleteTokenRegionSetting(issuerId, tokenId, countryCode, stateAndRegionCode);

    return promise.then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictionsPerToken.resetTokenStateAndRegion'), { root: true });
    });
  },

  updateTokenCountrySetting({ commit }, { issuerId, tokenId, countryCode, data }) {
    return updateJurisdictionPerTokenByCountryCode(issuerId, tokenId, countryCode, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictionsPerToken.updateTokenCountry'), { root: true });
    });
  },

  updateTokenStateRegionSetting({ commit }, { issuerId, tokenId, stateAndRegionCode, areaType, countryCode, data }) {
    const promise = areaType === areaTypes.state
      ? updateTokenStateSetting(issuerId, tokenId, countryCode, stateAndRegionCode, data)
      : updateTokenRegionSetting(issuerId, tokenId, countryCode, stateAndRegionCode, data);

    return promise.then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictionsPerToken.updateTokenStateAndRegion'), { root: true });
    });
  },
};
