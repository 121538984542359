import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import Cookie from 'js-cookie';
import $store from '~/store';
import {
  checkRouteAvailability,
  redirectFromUnavailableRoute,
} from '~/utilities/route-helpers';
import globalListsMiddleware from '~/middleware/global-lists';

import Home from '~/pages/index';
import registrationForm from '~/pages/register';
import signupForm from '~/pages/auth/signup';
import twoFaForm from '~/pages/auth/two-factor-auth';
import NestedRoutesWrapper from '~/pages/nestedRoutesWrapper';
import authorize from '~/pages/authorize/index';
import systemConfigRoutes from './systemConfigRoutes';
import configurationRoutes from './configurationRoutes';
import issuerConfigurationRoutes from './issuerConfigurationRoutes';
import issuerAdvancedRoutes from './issuerAdvancedRoutes';
import securitizeIdRoutes from './securitizeIdRoutes';
import brokerDealerRoutes from './brokerDealerRoutes';
import DistributionRoutesWrapper from '../pages/_idIssuer/distributions/distributionRoutesWrapper';
import AssetsRoutesWrapper from '../pages/assets/assetsRoutesWrapper';
import OperationalProceduresRoutesWrapper from '../pages/_idIssuer/transfer-agent/operational-procedures/operationalProceduresRoutesWrapper';
import SnapshotsRoutesWrapper from '../pages/snapshots/snapshotsRoutesWrapper';
import ControlBookRoutesWrapper from '../pages/_idIssuer/transfer-agent/control-book/controlBookRoutesWrapper';
import MsfRoutesWrapper from '../pages/_idIssuer/transfer-agent/msf/msfRoutesWrapper';
import SecuritiesTransactionsRoutesWrapper from '../pages/_idIssuer/transfer-agent/dtl/securitiesTransactionsRoutesWrapper';
import AffiliateManagementRoutesWrapper from '../pages/_idIssuer/transfer-agent/affiliate-management/AffiliateManagementRoutesWrapper';
import InvestorDetailsRoutesWrapper from '../pages/_idIssuer/investors/details-mfe/InvestorDetailsRoutesWrapper';

Vue.use(Router);
Vue.use(Meta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true,
});

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Home,
    },
    {
      path: '/authorize',
      name: 'authorizeFrom',
      component: authorize,
    },
    {
      path: '/register',
      name: 'registrationForm',
      component: registrationForm,
    },
    {
      path: '/signup',
      name: 'signup',
      component: signupForm,
    },
    {
      path: '/two-factor-auth',
      name: 'twoFaAuth',
      component: twoFaForm,
    },
    {
      /* webpackChunkName: "issuers" */
      path: '/issuer-list',
      name: 'issuerList',
      component: () => import('../pages/issuer-list/index'),
    },
    /* webpackChunkName: "securitize-id" */
    {
      path: '/securitize-id',
      component: NestedRoutesWrapper,
      children: securitizeIdRoutes,
    },
    {
      /* webpackChunkName: "operators" */
      path: '/operators',
      component: NestedRoutesWrapper,
      children: [
        {
          path: '/',
          name: 'operators',
          component: () => import('../pages/operators'),
        },
        {
          path: '/operators/:operatorId/hsm-details',
          name: 'hsm-details',
          component: () => import('../pages/operators/hsm-details'),
        },
      ],
    },
    {
      path: '/system-config',
      component: NestedRoutesWrapper,
      children: systemConfigRoutes,
    },
    {
      /* webpackChunkName: "dashboard" */
      path: '/:idIssuer/:tokenId/dashboard',
      name: 'dashboard',
      component: () => import('../pages/_idIssuer/dashboard'),
    },
    {
      /* webpackChunkName: "onboarding" */
      path: '/:idIssuer/investors/onboarding',
      name: 'onboarding',
      component: () => import('../pages/_idIssuer/investors/onboarding'),
    },
    {
      /* webpackChunkName: "fundraise" */
      path: '/:idIssuer/:tokenId/investors/fundraise',
      name: 'fundraise',
      component: () => import('../pages/_idIssuer/investors/fundraise'),
    },
    {
      /* webpackChunkName: "holders" */
      path: '/:idIssuer/:tokenId/investors/holders',
      name: 'holders',
      component: () => import('../pages/_idIssuer/investors/holders'),
    },
    {
      /* webpackChunkName: "outreach" */
      path: '/:idIssuer/:tokenId/investors/outreach',
      name: 'outreach',
      component: () => import('../pages/_idIssuer/investors/outreach'),
    },
    {
      /* webpackChunkName: "issue-detail" */
      path: '/:idIssuer/:tokenId/investors/issue-details',
      name: 'issueDetails',
      component: () => import('../pages/_idIssuer/investors/issue-details'),
      async beforeEnter(to, from, next) {
        const { idIssuer } = to.params;
        let permissionsPerIssuer = $store.getters['issuersInfo/permissionsPerIssuer'];
        if (!permissionsPerIssuer) {
          permissionsPerIssuer = await $store.dispatch(
            'issuersInfo/getPermissionsPerIssuer',
            idIssuer,
          );
        }
        const { isBDRestrictedAccess } = permissionsPerIssuer;
        if (isBDRestrictedAccess) return next('/issuer-list');
        return next();
      },
    },
    {
      /* webpackChunkName: "holders" */
      path: '/:idIssuer/:tokenId/investors-old/details/:detailsId',
      name: 'details',
      component: () => import('../pages/_idIssuer/investors/details/_detailsId'),
    },
    {
      path: '/:idIssuer/:tokenId/investors-old/details/:detailsId/legal-signer/:legalSignerId',
      name: 'legalSigner',
      component: () => import('../pages/_idIssuer/investors/legal-signer'),
    },
    {
      /* webpackChunkName: "holders" */
      path: '/:idIssuer/:tokenId/investors/details/:detailsId',
      name: 'investor-details-mfe',
      component: () => InvestorDetailsRoutesWrapper(),
    },
    {
      /* webpackChunkName: "holders" */
      path: '/:idIssuer/:tokenId/investors/details/:detailsId/legal-signer/:legalSignerId',
      name: 'investor-details-mfe',
      component: () => InvestorDetailsRoutesWrapper(),
    },
    {
      /* webpackChunkName: "signatures" */
      path: '/:idIssuer/:tokenId/signatures',
      name: 'signatures',
      component: () => import('../pages/_idIssuer/signatures'),
    },
    {
      /* webpackChunkName: "distributions" */
      path: '/:idIssuer/:tokenId/distributions/:distributionId?',
      name: 'distributions',
      component: () => DistributionRoutesWrapper(),
      async beforeEnter(to, from, next) {
        const { idIssuer } = to.params;
        let permissionsPerIssuer = $store.getters['issuersInfo/permissionsPerIssuer'];
        if (!permissionsPerIssuer) {
          permissionsPerIssuer = await $store.dispatch(
            'issuersInfo/getPermissionsPerIssuer',
            idIssuer,
          );
        }
        const { isBDRestrictedAccess } = permissionsPerIssuer;
        if (isBDRestrictedAccess) return next('/issuer-list');
        return next();
      },
    },
    {
      /* webpackChunkName: "distributions" */
      path: '/:idIssuer/:tokenId/distributions/:idSnapshot/:name',
      name: 'snapshot',
      component: () => import('../pages/_idIssuer/distributions/_idSnapshot'),
    },
    {
      /* webpackChunkName: "snapshots" */
      path: '/:idIssuer/:tokenId/snapshots/:snapshotId?',
      name: 'snapshots',
      component: () => {
        const operatorData = $store.getters['currentOperator/operatorData'];
        if (operatorData.authorizations.includes('new-cp-ui')) { return SnapshotsRoutesWrapper(); }
        return import('../pages/_idIssuer/snapshots');
      },
      async beforeEnter(to, from, next) {
        const { idIssuer } = to.params;
        let permissionsPerIssuer = $store.getters['issuersInfo/permissionsPerIssuer'];
        if (!permissionsPerIssuer) {
          permissionsPerIssuer = await $store.dispatch(
            'issuersInfo/getPermissionsPerIssuer',
            idIssuer,
          );
        }
        const { isBDRestrictedAccess } = permissionsPerIssuer;
        if (isBDRestrictedAccess) return next('/issuer-list');
        return next();
      },
    },
    {
      /* webpackChunkName: "snapshots" */
      path: '/:idIssuer/:tokenId/snapshots/:idSnapshot/:name',
      name: 'snapshot',
      component: () => import('../pages/_idIssuer/snapshots/_idSnapshot'),
    },
    {
      path: '/:idIssuer/:tokenId/transfer-agent',
      redirect: { name: 'transferControlBook' },
      component: NestedRoutesWrapper,
      children: [
        {
          /* webpackChunkName: "control-book" */
          path: 'control-book',
          name: 'transferControlBook',
          component: () => ControlBookRoutesWrapper(),
        },
        {
          /* webpackChunkName: "transferMSF" */
          path: 'msf',
          name: 'transferMSF',
          component: () => MsfRoutesWrapper(),
        },
        {
          /* webpackChunkName: "transferDTL" */
          path: 'dtl',
          name: 'transferDTL',
          component: () => SecuritiesTransactionsRoutesWrapper(),
        },
        {
          /* webpackChunkName: "transferProcedures" */
          path: 'procedure',
          name: 'transferProcedure',
          component: () => OperationalProceduresRoutesWrapper(),
        },
        {
          path: 'affiliate-management',
          name: 'affiliateManagement',
          component: () => AffiliateManagementRoutesWrapper(),
        },
      ],
    },
    {
      path: '/:idIssuer/:tokenId/configuration',
      redirect: { name: 'fundraiseConfiguration' },
      component: NestedRoutesWrapper,
      children: configurationRoutes,
    },
    {
      path: '/:idIssuer/advanced',
      redirect: { name: 'issuerAffiliates' },
      component: NestedRoutesWrapper,
      children: issuerAdvancedRoutes,
    },
    {
      path: '/:idIssuer/privacy-control',
      name: 'privacyControl',
      component: () => import('../pages/_idIssuer/privacy-control'),
    },
    {
      path: '/:idIssuer/audit-log',
      name: 'auditLogIssuer',
      component: () => import('../pages/_idIssuer/audit-log-issuer'),
    },
    {
      path: '/:idIssuer/issuer-configuration',
      redirect: { name: 'generalConfiguration' },
      component: NestedRoutesWrapper,
      children: issuerConfigurationRoutes,
    },
    /* webpackChunkName: "omnibusWalletOwner" */
    {
      path: '/:idIssuer/:tokenId/omnibus-wallet-owners/details/:detailsId',
      name: 'omnibusWalletOwnerDetails',
      component: () => import('../pages/_idIssuer/investors/omnibus/details'),
    },
    {
      path: '/:idIssuer/assets',
      name: 'assets',
      component: () => AssetsRoutesWrapper(),
    },
    {
      path: '/markets',
      component: NestedRoutesWrapper,
      children: brokerDealerRoutes,
    },
    {
      path: '/audit-log',
      name: 'auditLog',
      component: () => import('../pages/audit-log'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (['/authorize', '/authorize/'].includes(to.path)) {
    next();
  } else {
    const {
      isAuthenticated,
      authorizationLevel,
      authorizations,
    } = $store.getters['currentOperator/operatorData'];
    if (
      !isAuthenticated
      && ['/register', '/two-factor-auth'].includes(to.path)
    ) {
      Cookie.remove('auth_token');
      Cookie.remove('auth_type');
      Cookie.remove('refresh_token');
      return next();
    }
    if (isAuthenticated) {
      const saveLastVisitedRoute = $store._actions['navigationHistory/saveLastVisitedRoute'];
      saveLastVisitedRoute[0](from.path);
      await globalListsMiddleware($store, to, router);
      const brokerDealerGroupPermissions = $store.getters['issuersInfo/brokerDealerGroupPermissions'];
      if (
        !checkRouteAvailability(
          to.name,
          authorizationLevel,
          authorizations,
          brokerDealerGroupPermissions,
        )
      ) redirectFromUnavailableRoute(to, next);
      next(to.path === '/' ? { name: 'issuerList' } : undefined);
    } else {
      try {
        const authType = Cookie.get('auth_type');

        let targetUser;
        if (authType) {
          const { user } = await $store.dispatch('currentOperator/refresh');
          targetUser = user;
          await globalListsMiddleware($store, to, router);
        }

        const brokerDealerGroupPermissions = $store.getters['issuersInfo/brokerDealerGroupPermissions'];
        if (
          !checkRouteAvailability(
            to.name,
            targetUser.authorizationLevel,
            targetUser.authorizations,
            brokerDealerGroupPermissions,
          )
        ) redirectFromUnavailableRoute(to, next);
        next(to.path === '/' ? { name: 'issuerList' } : undefined);
      } catch (e) {
        if (to.path !== '/') localStorage.setItem('lastRoute', to.path);
        next(
          !['index', 'signup'].includes(to.name)
            ? { name: 'index' }
            : undefined,
        );
      }
    }
  }
});
export default router;
