import Cookie from 'js-cookie';

export default {
  SET_LABELS_LIST(state, labelsList) {
    state.labelsList = labelsList;
  },
  SET_ORIGINAL_LABELS_LIST(state, labelsList) {
    state.labelsListOriginal = labelsList;
  },
  DELETE_LABEL_FROM_LIST(state, label) {
    const index = state.labelsList.indexOf(label);
    if (index > -1) {
      state.labelsList.splice(index, 1);
    }
  },
  CREATE_NEW_LABEL(state, label) {
    state.labelsList = [
      ...label,
      ...state.labelsList,
    ];
  },
  SET_INVESTOR_DETAILS_GENERAL(state, generalInfo) {
    const { investorType } = generalInfo;
    Cookie.set('stockExchangeRegulation', investorType === 'entity' ? 'KYB' : 'KYC');
    state.details.general = generalInfo;
  },
  SET_INVESTOR_DETAILS(state, investorDetails) {
    state.details.investment = investorDetails;
  },
  SET_INVESTOR_DETAILS_INVESTMENT(state, investmentInfo) {
    state.details.investment = investmentInfo;
  },
  SET_INVESTOR_DETAILS_TOKENS(state, tokens) {
    state.details.tokens = tokens;
  },
  SET_SUBSCRIPTION_AGREEMENT_STATUS_LOGS(state, subscriptionAgreementStatusLogs) {
    state.details.subscriptionAgreementStatusLogs = subscriptionAgreementStatusLogs;
  },
  SET_TOKEN_PREFERENCES_DRIP(state, methodPayment) {
    state.details.tokensWalletsSection = methodPayment;
  },
  TOKENS_AND_WALLETS_SECTION(state, tokens) {
    state.details.tokensWalletsSection = tokens;
  },
  SET_INVESTOR_INVESTMENT_REQUEST_FOR_DISPLAY(state, data) {
    state.details.investmentForDisplay = data;
  },
  SET_INVESTOR_TOKEN_CONFIG_DATA(state, data) {
    state.supportedCurrencies = data?.supportedCurrencies || [];
  },
  SET_INVESTOR_DETAILS_TOKENS_BONUS(state, { bonusTokens, tokensAssigned }) {
    state.details.tokens = {
      ...state.details.tokens,
      bonusTokens,
      tokensAssigned,
    };
  },
  SET_INVESTOR_DETAILS_KYC(state, kycInfo) {
    state.details.kyc = kycInfo;
  },
  CHANGE_DETAILS_EDITABLE_BLOCK(state, blockName) {
    state.details.editableBlock = blockName;
  },
  SET_INVESTOR_SORTING(state, { type, sorting }) {
    state.sorting[type] = sorting;
  },
  SET_LEGAL_SIGNER_GENERAL_INFO(state, legalSignerGeneralInfo) {
    state.legalSignerGeneralInfo = legalSignerGeneralInfo;
  },
};
