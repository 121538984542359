import { issuersApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const { createIssuer, deleteIssuer } = issuersApi;
const { getIssuers, getSystemIssuers } = issuersApi;

export default {
  createIssuer({ commit }, params) {
    return createIssuer(params).then(({ data }) => {
      const { issuerId } = data;
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerList.createIssuer'), { root: true });
      return issuerId;
    }).catch((err) => {
      this.$log.error('create issuer error:', err);
    });
  },
  removeIssuer({ commit }, issuerId) {
    return deleteIssuer(issuerId).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerList.removeIssuer'), { root: true });
    }).catch((err) => {
      this.$log.error('delete issuer error:', err);
    });
  },
  getAllIssuersList({ commit }) {
    const limit = 999;
    return getIssuers(undefined, undefined, limit)
      .then(({ data }) => {
        const issuers = (data && data.data) || [];
        commit('SET_All_ISSUERS', issuers);
      }).catch((err) => {
        this.$log.error('get ALL issuers get error:', err);
      });
  },
  getAllSystemIssuers({ commit }) {
    return getSystemIssuers()
        .then(({ data }) => {
          commit('SET_All_SYSTEM_ISSUERS', data);
        }).catch((err) => {
          this.$log.error('get ALL system issuers get error:', err);
        });
  },
};
