export default [
  {
    /* webpackChunkName: "fundraiseConfiguration" */
    path: 'fundraise',
    name: 'fundraiseConfiguration',
    component: () => import('../pages/_idIssuer/configuration/fundraise'),
  },
  {
    /* webpackChunkName: "tokenConfiguration" */
    path: 'fundraise-token',
    name: 'tokenConfiguration',
    component: () => import('../pages/_idIssuer/configuration/fundraise-token'),
  },
  {
    /* webpackChunkName: "tokenManagement" */
    path: 'fundraise-token/manage-token',
    name: 'manageToken',
    component: () => import('../pages/_idIssuer/configuration/fundraise-token/manage-token'),
  },
  {
    path: 'jurisdictions',
    name: 'jurisdictionsPerToken',
    component: () => import('../pages/_idIssuer/configuration/jurisdictions-per-token'),
  },
  {
    path: 'opportunity',
    name: 'EditOpportunityMain',
    component: () => import('../pages/_idIssuer/configuration/opportunities/edit'),
    children: [{
      path: 'summary',
      name: 'EditOpportunitySummary',
      component: () => import('../pages/_idIssuer/configuration/opportunities/edit/summary'),
    },
    {
      path: 'content',
      name: 'OpportunityContentMain',
      component: () => import('../pages/_idIssuer/configuration/opportunities/edit/content'),
    },
    {
      path: 'documents',
      name: 'OpportunityDocumentsMain',
      component: () => import('../pages/_idIssuer/configuration/opportunities/edit/documents'),
      children: [{
        path: 'edit/:docId?',
        name: 'UpdateOpportunityDocumentModal',
        component: () => import('../pages/_idIssuer/configuration/opportunities/edit/documents/edit'),
      }, {
        path: 'view/:docId',
        name: 'ViewOpportunityDocumentModal',
        component: () => import('../pages/_idIssuer/configuration/opportunities/edit/documents/view'),
      },
      ],
    },
    ],
  },
];
