export default {
  component: {
    issuerBox: {
      title: 'Create Issuer',
      input: {
        issuerName: 'Issuer name',
        tokenName: 'Token name',
      },
      issuerLabels: {
        manageLabels: 'Manage Labels',
      },
      button: {
        yes: 'Yes',
        no: 'No',
        issuer: {
          view: 'View Issuer',
        },
      },
      label: {
        fundraise: 'Fundraise',
        token: 'Token',
        amountPledged: 'Amount Pledged (total):',
        amountFunded: 'Amount Funded (total):',
        registeredInvestors: 'Registered Investors:',
        holders: 'Holders (total):',
        pendingActions: 'Pending Actions:',
      },
      status: {
        active: 'active',
        inactive: 'inactive',
        issued: 'issued',
        notIssued: 'not issued',
      },
    },
    issuerLabelsModal: {
      title: {
        manage: 'Manage',
        labels: 'Labels',
      },
      notFound: 'No label found',
      loading: 'Loading',
      deleteLabel: {
        title: 'Delete Label',
        message1: 'You are about to delete the label',
        message2: 'If you proceed, it will also be removed from every user who shares this label. Are you sure you want to delete it?',
      },
    },
  },
};
