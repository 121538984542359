<template>
  <label class="switcher switcher-lg switcher-success m-0">
    <input
      ref="cpSwitch"
      type="checkbox"
      class="switcher-input"
      :value="currentValue"
      :checked="currentValue"
      :disabled="disabled"
      @change="onChangeSwitch"
    >
    <span class="switcher-indicator">
      <span class="switcher-yes" />
      <span class="switcher-no" />
    </span>
  </label>
</template>

<script>
export default {
  name: 'CpSwitch',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      restS: true,
      controlledValue: this.value,
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
  },
  mounted() {
    this.$refs.cpSwitch.checked = this.currentValue;
  },
  methods: {
    onChangeSwitch({ target }) {
      this.$emit('input', target.checked);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
