export default {
  outreachIsEnabled: false,
  domain: '',
  isAuthenticated: false,
  previewContactList: [],
  savedContactLists: [],
  investorsOutreachList: [],
  totalItems: 0,
  customEmails: [],
  emailActivity: [],
  emailActivityTotal: 0,
  selectedIdDetailsData: {},
  emailActivityChart: [],
};
