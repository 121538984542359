export default {
  issuerBankAccounts: {
    title: 'Issuer Bank accounts',
    button: {
      account: {
        add: 'Add account',
      },
    },
    label: {
      table: {
        name: 'Name',
        instructions: 'Instructions',
      },
    },
    // modal
    bankModal: {
      title: '{0} Bank Account',
      input: {
        name: 'Name',
        instructions: 'Instructions',
      },
    },
  },
};
