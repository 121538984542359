<template>
  <div
    class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
  >
    <div
      class="ui-bg-overlay bg-dark ui-bg-cover login-bg"
      :style="{
        'background-image': `url(${getImagePath(
          'login-background.jpg',
          'theme'
        )}`
      }"
    />
    <div
      v-if="!initLoader"
      class="authentication-inner py-5"
    >
      <b-card no-body>
        <div class="authentication-inner">
          <div class="card">
            <div class="card-body">
              <!-- Logo -->
              <div
                class="d-flex justify-content-center align-items-center pb-2 mb-4"
              >
                <div class="ui-w-60">
                  <div class="w-100 position-relative">
                    <img
                      class="d-block w-100"
                      :src="getImagePath('securitize.png', 'logo')"
                      alt="Securitize"
                    >
                  </div>
                </div>
              </div>
              <!-- / Logo -->

              <cp-input-container
                v-if="registerToken"
                ref="cpInputContainer"
              >
                <div class="form-group">
                  <cp-input
                    id="password"
                    v-model.trim="password"
                    name="password"
                    :label="$t('signup.input.password') "
                    input-type="password"
                    validate="max:30"
                  />
                </div>

                <div class="requirements">
                  <div :class="['requirement-text', {'clear': isPwdUppercaseChar}]">
                    {{ $t('signup.label.requirements.upperCase') }}
                  </div>
                  <div :class="['requirement-text', {'clear': isPwdLowercaseChar}]">
                    {{ $t('signup.label.requirements.lowerCase') }}
                  </div>
                  <div :class="['requirement-text', {'clear': hasPwdNumber}]">
                    {{ $t('signup.label.requirements.number') }}
                  </div>
                  <div :class="['requirement-text', {'clear': isPwdMinLength}]">
                    {{ $t('signup.label.requirements.chars') }}
                  </div>
                </div>

                <button
                  type="button"
                  class="btn btn-outline-primary btn-block mb-3"
                  :disabled="!canRegister || isLoading"
                  @click="register"
                >
                  <span v-if="!isLoading">{{ $t('signup.button.register.local') }}</span>
                  <b-spinner
                    v-if="isLoading"
                    class="align-middle"
                    small
                  />
                </button>

                <button
                  type="button"
                  class="btn btn-google btn-block blue"
                  :disabled="isLoading"
                  @click="signIn"
                >
                  <span>
                    <img :src="getImagePath('logo-google.png', 'logo')">
                  </span>
                  {{ $t('signup.button.register.google') }}
                </button>

                <vue-recaptcha
                  v-if="sitekey"
                  ref="invisibleRecaptcha"
                  size="invisible"
                  :sitekey="sitekey"
                  :load-recaptcha-script="true"
                  @verify="onVerifyRecaptcha"
                />
              </cp-input-container>

              <!-- Error Message -->
              <div
                v-if="registerError"
                class="alert alert-danger mt-3 mb-0"
              >
                <small>
                  {{ errorMessage }}
                  <a href="mailto:issuersupport@securitize.io">
                    issuersupport@securitize.io
                  </a>
                </small>
              </div>
              <!-- / Error Message -->
            </div>
            <i18n
              class="card-footer text-center"
              path="signup.message.account"
              tag="div"
            >
              <router-link to="/">
                {{ $t('signup.message.loginNow') }}
              </router-link>
            </i18n>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="initLoader"
      class="background-overlay"
    />
    <Spinner
      v-if="initLoader"
      name="wave"
      color="#04bec4"
      fade-in="quarter"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Spinner from 'vue-spinkit';
import VueRecaptcha from 'vue-recaptcha';
import { login as googleLoginAuth } from '~/utilities/googleAuth';
import routeNames from '~/utilities/routes';
import staticPathGenerationMixin from '~/mixins/static-path-generation';

import { CpInput } from '~/components/common/standalone-components/inputs';
import cpInputContainer from '~/components/common/cpInputContainer';

export default {
  metaInfo: {
    title: 'signup form',
    name: 'signupForm',
  },
  components: {
    Spinner,
    VueRecaptcha,
    CpInput,
    cpInputContainer,
  },
  mixins: [staticPathGenerationMixin],
  data() {
    return {
      registerError: false,
      password: '',
      errorMessage: '',
      initLoader: false,
      isValid: false,
      isLoading: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    };
  },
  computed: {
    ...mapState('currentOperator', ['registerToken']),
    isPwdMinLength() {
      return this.password.length >= 8;
    },
    isPwdMaxLength() {
      return this.password.length <= 30;
    },
    hasPwdNumber() {
      return /\d/.test(this.password);
    },
    isPwdUppercaseChar() {
      return /[A-Z]/.test(this.password);
    },
    isPwdLowercaseChar() {
      return /[a-z]/.test(this.password);
    },
    canRegister() {
      return this.isPwdMinLength && this.hasPwdNumber && this.isPwdUppercaseChar && this.isPwdLowercaseChar && this.isPwdMaxLength;
    },
  },
  mounted() {
    const registerToken = this.$route.query.token;
    this.initLoader = true;
    this.verifyRegisterToken(registerToken).catch(
      (error) => {
        this.registerError = true;
        const { name } = error.response.data;
        this.registerErrorMessage(name);
      },
    ).finally(() => {
      this.initLoader = false;
      this.$router.replace({ query: null });
    });
  },
  methods: {
    ...mapActions('currentOperator', ['googleLogin', 'signup', 'verifyRegisterToken']),
    ...mapMutations('currentOperator', ['SET_VIEW_DATA']),
    register() {
      if (this.sitekey) {
        this.$refs.invisibleRecaptcha.execute();
      } else {
        this.onVerifyRecaptcha();
      }
    },
    onVerifyRecaptcha(recaptchaToken) {
      this.isLoading = true;
      const params = {
        password: this.password,
        recaptchaToken,
      };
      this.signup(params).then(
        () => this.$router.push({ path: routeNames.twoFactorAuth(), query: { type: 'register' } }),
        (error) => {
          this.isLoading = false;
          this.$log.error('Signup error:', error);
          this.$refs.invisibleRecaptcha.reset();
          this.registerError = true;
          const { name } = error.response.data;
          this.registerErrorMessage(name);
        },
      );
    },
    async signIn() {
      this.registerError = false;
      this.isLoading = true;

      try {
        const operator = await googleLoginAuth();
        if (!operator || !operator.authorization || !operator.authorization.access_token) {
          this.isLoading = false;
          this.registerError = true;
          this.$log.warn('No auth response object');
          this.$log.warn(operator);
          this.registerErrorMessage();
          return;
        }

        const { authorization } = operator;

        const { issuerId, defaultTokenId } = await this.googleLogin(authorization.access_token);
        const route = issuerId ? routeNames.dashboard(issuerId, defaultTokenId) : routeNames.issuerList();
        this.$router.push(route);
      } catch (error) {
        this.isLoading = false;
        this.registerError = true;
        this.registerErrorMessage();
      }
    },
    registerErrorMessage(error = '') {
      switch (error) {
        case 'UNAUTHORIZED':
          error = this.$t('signup.message.expire');
          break;
        case 'RECAPTCHA_ERROR':
          error = this.$t('signup.message.error');
          break;
        default:
          error = this.$t('signup.message.error');
      }
      this.errorMessage = error;
    },
  },
};
</script>

<style lang="scss" scoped>
.requirements {
  display: flex;
  flex-wrap: wrap;
  margin: 11px -4px 32px;
}
.requirement-text {
  font-size: 12px;
  font-weight: 300;
  color: #b3b2b2;
  padding-left: 16px;
  margin: 0px 5px;
  position: relative;
  white-space: nowrap;

  &.clear {
    color: #0a1828;
    &:before {
      background: #04bec4;
    }
  }

  &:before {
  content: "";
  position: absolute;
  background: #dadada;
  top: 50%;
  left: 0px;
  margin-top :-6px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  }
}
</style>
