export default {
  issuerBankAccounts: {
    title: '銀行口座情報',
    button: {
      account: {
        add: '口座の追加',
      },
    },
    label: {
      table: {
        name: '口座名',
        instructions: '詳細説明',
      },
    },
    // modal
    bankModal: {
      title: '銀行アカウント{0}',
      input: {
        name: '口座名',
        instructions: '詳細説明',
      },
    },
  },
};
