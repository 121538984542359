import axios from '~/utilities/axios';
// import operatorStore from '@/store/modules/current-operator';

const basePath = '/api/v2/blockchain/signatures';
const DEFAULT_GAS_PRICE = '100000000000';
const DEFAULT_GAS_CRYPTO_CURRENCY_SYMBOL = 'ETH';
const DEFAULT_GAS_PRICE_UNIT = 'wei';

export default class SignaturesService {
  static getAuthorizationHeader = () => {
    const that = this;
    that.configuration = {
      apiKey: '',
      basePath: '/api/v2/blockchain/signatures',
    };

    // const _store = operatorStore;
    // that.configuration.apiKey = _store.state.accessToken;
    const localVarHeaderParams = {
      // authorization: `Bearer ${that.configuration.apiKey}`,
    };
    return {
      ...localVarHeaderParams,
    };
  }

  static getTransactionSignaturesList(issuerId, tokenId, filter) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions?limit=${filter.limit}&page=${filter.page}&status=${filter.status}`;
    return this.get(path, { headers: this.getAuthorizationHeader() });
  }

  static getTransactionSignatureById(issuerId, tokenId, id) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}`;
    return this.get(path, { headers: this.getAuthorizationHeader() });
  }

  static deleteTransactionSignatureById(issuerId, tokenId, id) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}`;
    return this.delete(path, { headers: this.getAuthorizationHeader() });
  }

  static clearTransactionSignaturesById(issuerId, tokenId, id) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/multisig-wallets/transactions/${id}/signatures`;
    return this.delete(path, { headers: this.getAuthorizationHeader() });
  }

  static invokeDsProtocol(issuerId, tokenId, id, identity) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}/ds-protocol`;
    return this.post(path, { identity }, { headers: this.getAuthorizationHeader() });
  }

  static bulkDsProtocolTransactions(issuerId, tokenId, identity, transactionsList) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/bulk-transactions/ds-protocol`;
    const transactionSignatureIds = transactionsList.map(transaction => transaction.id);
    return this.post(path, { identity, transactionSignatureIds }, { headers: this.getAuthorizationHeader() });
  }

  static sendTransactionSignature(issuerId, tokenId, id, body) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}/signature`;
    return this.post(path, body, { headers: this.getAuthorizationHeader() });
  }

  static sendBulkDsProtocolTransactionSignature(issuerId, tokenId, body) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/bulk-transactions/signature`;
    return this.post(path, body, { headers: this.getAuthorizationHeader() });
  }

  static sendTransactionHash(deploymentId, signatureTxId, transactionProviderId) {
    return this.put(
      `/deployments/${deploymentId}/transactions/${signatureTxId}/status`, { transactionProviderId },
      { headers: this.getAuthorizationHeader() },
    );
  }

  static updateBulkDsProtocolTransactionStatus(issuerId, tokenId, bulkDsProtocolTransactionId, body) {
    return this.put(
        `/issuers/${issuerId}/tokens/${tokenId}/bulk-transactions/${bulkDsProtocolTransactionId}/status`, body,
        { headers: this.getAuthorizationHeader() },
    );
  }

  static speedUpTransaction(issuerId, tokenId, id, identity, gasPrice) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}/speed-up`;
    return this.post(path, { identity, gasPrice }, { headers: this.getAuthorizationHeader() });
  }

  static cancelTransaction(issuerId, tokenId, id, identity) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}/cancel`;
    return this.post(path, { identity }, { headers: this.getAuthorizationHeader() });
  }

  static async getProviderTransactionDetail(issuerId, tokenId, id) {
    const providerTransactionDetail = {
      gasPrice: DEFAULT_GAS_PRICE,
      signerAddress: '',
    };
    try {
      const path = `/issuers/${issuerId}/tokens/${tokenId}/transactions/${id}/provider-detail`;
      const response = await this.get(path, { headers: this.getAuthorizationHeader() });
      if (response.data) {
        providerTransactionDetail.gasPrice = response.data.gasPrice;
        providerTransactionDetail.signerAddress = response.data.signerAddress;
      }
      return providerTransactionDetail;
    } catch (error) {
      console.warn('Error getting provider transaction detail. Using default gasPrice', error);
      const currentDeploymentGasPrice = await this.getDeploymentGasPrice(issuerId, tokenId);
      providerTransactionDetail.gasPrice = currentDeploymentGasPrice.gasPrice;
      return providerTransactionDetail;
    }
  }

  static async getDeploymentGasPrice(issuerId, tokenId) {
    const path = `/issuers/${issuerId}/tokens/${tokenId}/gas-price`;
    const currentDeploymentGasPrice = {
      gasPrice: DEFAULT_GAS_PRICE,
      gasPriceUnit: DEFAULT_GAS_PRICE_UNIT,
      cryptoCurrencySymbol: DEFAULT_GAS_CRYPTO_CURRENCY_SYMBOL,
    };
    try {
      const response = await this.get(path, { headers: this.getAuthorizationHeader() });
      if (response.data) {
        currentDeploymentGasPrice.gasPrice = response.data.gasPrice;
        currentDeploymentGasPrice.gasPriceUnit = response.data.gasPriceUnit;
        currentDeploymentGasPrice.cryptoCurrencySymbol = response.data.cryptoCurrencySymbol;
      }
      return currentDeploymentGasPrice;
    } catch (error) {
      console.warn('Error getting provider transaction detail. Using default gasPrice', error);
      currentDeploymentGasPrice.gasPrice = DEFAULT_GAS_PRICE;
      return currentDeploymentGasPrice;
    }
  }

  static get(path, config = {}) {
    return axios.get(basePath + path, config);
  }

  static delete(path, config = {}) {
    return axios.delete(basePath + path, config);
  }

  static post(path, body = {}, config = {}) {
    return axios.post(basePath + path, body, config);
  }

  static put(path, body = {}, config = {}) {
    return axios.put(basePath + path, body, config);
  }
}
