import moment from 'moment';
import FileSaver from 'file-saver';
import { configurationsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const areaTypes = {
  state: 'state',
  region: 'region',
};

const {
  updateStateSetting,
  updateRegionSetting,
  createStateSetting,
  createRegionSetting,
  getJurisdictionsByCountryCode,
  getJurisdictionsConfigurations,
  getStateJurisdictionalConfig,
  getRegionJurisdictionalConfig,
  getJurisdictionsConfigurationsListPerCountry,
  updateJurisdictionsByCountryCode,
  updateJurisdictionsConfigurations,
  deleteStateSetting,
  deleteRegionSetting,
  importJurisdictionSettingFromCsv,
} = configurationsApi;
const jurisdictionsConfigurationsArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
  q: undefined,
  statesAndRegionsOnly: undefined,
};
export default {
  getJurisdictionsConfigurations({ commit }, issuerId) {
    return getJurisdictionsConfigurations(issuerId).then(({ data }) => {
      commit('SET_JURISDICTIONS', data);
      return data;
    }).catch((err) => {
      this.$log.error('Investors get error:', err);
      if (err.response.body.message) {
        throw err.response.body.message;
      }
    });
  },

  updateJurisdictions({ dispatch, commit }, { issuerId, data }) {
    return updateJurisdictionsConfigurations(issuerId, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.updateJurisdictions'), { root: true });
      dispatch('global/fetchIssuerInfo', issuerId, { root: true });
      dispatch('getJurisdictionsConfigurations', issuerId);
    });
  },

  getJurisdictionsCountries({ commit }, { params }) {
    const args = getArgumentsForApi(jurisdictionsConfigurationsArguments, params);
    return getJurisdictionsConfigurationsListPerCountry(...args)
      .then((res) => {
        const { data } = res;
        commit('SET_JURISDICTIONS_COUNTRIES', data);
        return res;
      })
      .catch((err) => {
        this.$log.error('Jurisdictions Configurations List Per Country get error:', err);
        return err;
      });
  },

  getJurisdictionsStatesAndRegions({ commit }, issuerId) {
    const params = { issuerId, statesAndRegionsOnly: true };
    const args = getArgumentsForApi(jurisdictionsConfigurationsArguments, params);

    return getJurisdictionsConfigurationsListPerCountry(...args)
      .then((res) => {
        const { data } = res;
        commit('SET_JURISDICTIONS_STATES_AND_REGIONS', data);
        return res;
      })
      .catch((err) => {
        this.$log.error('Jurisdictions Configurations List Per State And Region get error:', err);
        return err;
      });
  },

  fetchCountryByCode({ commit }, { issuerId, countryCode }) {
    return getJurisdictionsByCountryCode(issuerId, countryCode).then(({ data }) => {
      commit('SET_EDITABLE_COUNTRY', data);
      return data;
    });
  },

  fetchIssuerStateRegionSetting({ commit }, { issuerId, countryCode, stateAndRegionCode, areaType }) {
    const promise = areaType === areaTypes.state
      ? getStateJurisdictionalConfig(issuerId, countryCode, stateAndRegionCode)
      : getRegionJurisdictionalConfig(issuerId, countryCode, stateAndRegionCode);

    return promise.then(({ data }) => {
      commit('SET_EDITABLE_COUNTRY', data);
      return data;
    });
  },

  deleteIssuerStateRegionSetting({ commit }, { issuerId, countryCode, stateAndRegionCode, areaType }) {
    const promise = areaType === areaTypes.state
      ? deleteStateSetting(issuerId, countryCode, stateAndRegionCode)
      : deleteRegionSetting(issuerId, countryCode, stateAndRegionCode);

    return promise.then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.deleteStateAndRegion'), { root: true });
    });
  },

  updateIssuerCountry({ commit }, { issuerId, countryCode, data }) {
    return updateJurisdictionsByCountryCode(issuerId, countryCode, data).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.updateCountry'), { root: true });
    });
  },

  updateIssuerStateRegionSetting({ commit }, { issuerId, stateAndRegionCode, areaType, countryCode, data }) {
    const promise = areaType === areaTypes.state
      ? updateStateSetting(issuerId, countryCode, stateAndRegionCode, data)
      : updateRegionSetting(issuerId, countryCode, stateAndRegionCode, data);

    return promise.then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.updateStateAndRegion'), { root: true });
    });
  },

  createIssuerStateRegionSetting({ commit }, { issuerId, countryCode, stateAndRegionCode, areaType }) {
    const promise = areaType === areaTypes.state
      ? createStateSetting(issuerId, countryCode, { stateCode: stateAndRegionCode })
      : createRegionSetting(issuerId, countryCode, { regionCode: stateAndRegionCode });

    return promise.then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.createStateAndRegion'), { root: true });
    });
  },

  getJurisdictionsSettingsCsv(ctx, { issuerName, params }) {
    const args = getArgumentsForApi(jurisdictionsConfigurationsArguments, params);
    const options = {
      headers: {
        accept: 'text/csv',
      },
    };
    return getJurisdictionsConfigurationsListPerCountry(...args, options)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, `${issuerName}_jurisdiction_settings_${moment().format('YYYY-MM-DD, h-mm')}.csv`);
        return data;
      })
      .catch((err) => {
        this.$log.error('Jurisdiction settings export error:', err);
      });
  },

  importJurisdictionsSettings({ commit }, { issuerId, body }) {
    return importJurisdictionSettingFromCsv(issuerId, false, body)
        .then(({ data }) => {
          commit('global/CALL_TOASTER', i18n.t('store.toaster.jurisdictions.importJurisdictionsSettings', [data.totalItems]), { root: true });
        }).catch((err) => {
          this.$log.error('Import jurisdictions settings error:', err);
          throw (err);
        });
  },
};
