<template>
  <div
    class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4"
  >
    <div
      class="ui-bg-overlay bg-dark ui-bg-cover login-bg"
      :style="{
        'background-image': `url(${getImagePath(
          'login-background.jpg',
          'theme'
        )}`
      }"
    />
    <div class="authentication-inner py-5">
      <b-card no-body>
        <div class="authentication-inner">
          <div class="card">
            <div class="card-body">
              <div
                v-if="!isRegister"
                class="mb-5"
              >
                <!-- Logo -->
                <div
                  class="d-flex justify-content-center align-items-center pb-2 mb-4"
                >
                  <div class="ui-w-60">
                    <div class="w-100 position-relative">
                      <img
                        class="d-block w-100"
                        :src="getImagePath('securitize.png', 'logo')"
                        alt="Securitize"
                      >
                    </div>
                  </div>
                </div>
                <!-- / Logo -->
                <h5 class="text-center mb-4 font-weight-bold">
                  {{ $t('twoFactorAuth.title.auth') }}
                </h5>
                <h6 class="font-weight-light text-center">
                  {{ $t('twoFactorAuth.subTitle') }}
                </h6>
              </div>

              <register-info
                v-if="isRegister"
                :two-fa-u-r-i="twoFaUri"
              />

              <h6 class="font-weight-bold">
                <span class="small font-weight-normal">{{ $t('twoFactorAuth.input.digists') }}</span>
              </h6>
              <digit-code-inputs
                v-model="digits"
                @input="onChangeDigits"
              />

              <button
                type="button"
                class="btn btn-primary btn-block"
                :disabled="digits.length < 6 || isLoading"
                @click="signIn"
              >
                <span v-if="!isRegister && !isLoading">{{ $t('twoFactorAuth.button.signIn') }}</span>
                <span v-if="isRegister && !isLoading">{{ $t('twoFactorAuth.button.set') }}</span>
                <b-spinner
                  v-if="isLoading"
                  class="align-middle"
                  small
                />
              </button>

              <!-- Error Message -->
              <div
                v-if="errorMessage"
                class="alert alert-danger mt-3 mb-0"
              >
                <small>{{ errorMessage }}</small>
              </div>
              <!-- / Error Message -->
            </div>
            <i18n
              v-if="!isRegister"
              class="card-footer text-center"
              path="twoFactorAuth.message.lost"
              tag="div"
            >
              <a href="mailto:issuersupport@securitize.io">{{ $t('twoFactorAuth.message.contactUs') }}</a>
            </i18n>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="initLoader"
      class="background-overlay"
    />
    <Spinner
      v-if="initLoader"
      name="wave"
      color="#04bec4"
      class="cp-button-spinner"
      fade-in="quarter"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import routeNames from '~/utilities/routes';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import digitCodeInputs from './components/digit-code-inputs';
import registerInfo from './components/register-info';

export default {
  metaInfo: {
    title: '2fa form',
    name: 'twoFaForm',
  },
  components: {
    digitCodeInputs,
    registerInfo,
  },
  mixins: [staticPathGenerationMixin],
  data() {
    return {
      errorMessage: '',
      initLoader: false,
      digits: '',
      isRegister: false,
      isLoading: false,
      isAutoSign: true,
    };
  },
  computed: {
    ...mapState('currentOperator', ['twoFaUri']),
  },
  mounted() {
    if (this.$route.query.type === 'register') {
      this.isRegister = true;
      this.getTwoFactorAuth()
        .catch(() => this.$router.push(routeNames.index));
    }
  },
  methods: {
    ...mapMutations('currentOperator', ['SET_VIEW_DATA', 'SET_REGISTER_TOKEN']),
    ...mapActions('currentOperator', ['getTwoFactorAuth', 'verifyTwoFactorAuth']),
    signIn() {
      this.isLoading = true;
      this.errorMessage = null;
      this.verifyTwoFactorAuth(this.digits)
        .then(({ issuerId, defaultTokenId }) => {
          const route = issuerId ? routeNames.dashboard(issuerId, defaultTokenId) : routeNames.issuerList();
          this.SET_REGISTER_TOKEN(undefined);
          this.$router.push(route);
        })
        .catch((error) => {
          this.isLoading = false;
          const { name } = error.response.data;
          this.registerErrorMessage(name);
        });
    },
    onChangeDigits() {
      this.errorMessage = null;
      if (this.digits.length === 6 && this.isAutoSign) {
        this.isAutoSign = false;
        this.signIn();
      }
    },
    registerErrorMessage(error = '') {
      switch (error) {
        case 'WRONG_TWO_FA_CODE':
          error = this.$t('twoFactorAuth.message.wrong');
          break;
        case 'ACCOUNT_WAS_LOCKED':
          error = this.$t('twoFactorAuth.message.lock');
          break;
        case 'UNAUTHORIZED':
          error = this.$t('twoFactorAuth.message.expire');
          break;
        default:
          error = this.$t('twoFactorAuth.message.error');
      }
      this.errorMessage = error;
    },
  },
};
</script>
