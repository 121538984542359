import {
  validateDomain, viewContacts,
  getDomain, deleteDomain, contactLists, createContactList, deleteContactList, sendEmail, emailActivity, emailActivityDetail, emailActivityChart, getS3PresignedUrl,
} from '~/utilities/external-api/apis/tranceCpApi';
import { i18n } from '~/i18n';

export default {
  getDomain({ commit }, { issuerId }) {
    return getDomain(issuerId)
      .then((data) => {
        commit('CHANGES_FEATURED_STATUS', true);
        return data;
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error checking existing domain:', err);
      });
  },
  deleteDomain(ctx, { issuerId }) {
    return deleteDomain(issuerId)
      .then(data => data)
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          ctx('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error deleting existing domain:', err);
      });
  },
  validateDomain(ctx, { issuerId, body }) {
    return validateDomain(issuerId, body)
      .then(data => data)
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          ctx('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error whitelisting domain:', err);
        throw err;
      });
  },
  viewContacts({ commit }, { issuerId, tokenId, stage, filters, queryParams }) {
    return viewContacts(issuerId, tokenId, stage, filters, queryParams)
      .then(({ data }) => {
        commit('SET_CONTACT_LIST', data.data);
        commit('SET_TOTAL_ITEMS', data.totalItems);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error fetching contact list:', err);
        throw err;
      });
  },
  contactLists({ commit }, { issuerId, tokenId, stage, round }) {
    return contactLists(issuerId, tokenId, stage, round)
      .then(({ data }) => {
        commit('SET_SAVED_CONTACT_LISTS', data);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error fetching saved contact lists:', err);
        throw err;
      });
  },
  createContactList({ commit }, { issuerId, tokenId, stage, requestBody }) {
    return createContactList(issuerId, tokenId, stage, requestBody)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', i18n.t('outreach.email.toaster.listCreated'), { root: true });
        commit('SET_NEW_SAVED_LIST', data);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error saving contact list:', err);
        throw err;
      });
  },
  deleteContactList({ commit }, { issuerId, tokenId, listId }) {
    return deleteContactList(issuerId, tokenId, listId)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('outreach.email.toaster.listRemoved'), { root: true });
        commit('REMOVE_CONTACT_LIST', listId);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error removing contact list:', err);
        throw err;
      });
  },
  sendEmail({ commit }, { issuerId, tokenId, emailContent, type, stage = '', allInvestors = false }) {
    return sendEmail(issuerId, tokenId, emailContent, type, stage, allInvestors)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('outreach.email.toaster.sent'), { root: true });
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error sending Email:', err);
        throw err;
      });
  },
  emailActivityChart({ commit }, { issuerId, tokenId, timezone }) {
    return emailActivityChart(issuerId, tokenId, timezone)
      .then(({ data }) => {
        commit('SET_EMAIL_ACTIVITIES_CHART', data);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error fetching activity chart:', err);
        throw err;
      });
  },
  emailActivity({ commit }, { issuerId, tokenId, queryParams }) {
    return emailActivity(issuerId, tokenId, queryParams)
      .then(({ data }) => {
        commit('SET_EMAIL_ACTIVITIES', data.data);
        commit('SET_EMAIL_ACTIVITIES_TOTAL', data.totalItems);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error fetching activity:', err);
        throw err;
      });
  },
  emailActivityDetail({ commit }, { issuerId, tokenId, subjectId, queryParams }) {
    return emailActivityDetail(issuerId, tokenId, subjectId, queryParams)
      .then(({ data }) => {
        commit('SET_SELECTED_ID_DETAIL_DATA', data);
      })
      .catch((err) => {
        if (err.response.data.name === 'INVESTOR_OUTREACH_DISABLED') {
          commit('CHANGES_FEATURED_STATUS', false);
        }
        this.$log.error('Error fetching activity detail:', err);
        throw err;
      });
  },
  getUploadLink(ctx, { issuerId }) {
    return getS3PresignedUrl(issuerId)
      .then(({ data }) => data)
      .catch(err => this.$log.error('Fetch upload link error:', err));
  },
  saveDomainData({ commit }, { domain, isAuthenticated }) {
    commit('SET_DOMAIN', { domain });
    commit('SET_IS_AUTHENTICATED', { isAuthenticated });
  },
  clearSavedDomain({ commit }) {
    commit('SET_DOMAIN', { domain: '' });
  },
  clearSelectedDetailData({ commit }) {
    commit('SET_SELECTED_ID_DETAIL_DATA', {});
  },
  saveFilterListState({ commit }, { filterData }) {
    commit('SET_FILTER_LIST_STATE', { filterData });
  },
  addCustomEmailState({ commit }, { email }) {
    commit('ADD_CUSTOM_EMAIL', { email });
  },
  setCustomEmailState({ commit }, { email }) {
    commit('SET_CUSTOM_EMAIL', { email });
  },
  clearSavedContactList({ commit }) {
    commit('CLEAR_SAVED_CONTACT_LISTS');
  },
};
