/* eslint-disable */
export default {
  methods: {
    getImagePath(path, type, defaulLogo) {
      path = path || defaulLogo || 'logo.png';
      const fullPath = type ? `${type}/${path}` : path;
      if (/^http:|^https:|^\//i.test(path)) {
        return path;
      }
      try {
        return require(`~/assets/img/${fullPath}`);
      } catch (e) {
        console.error('failed fetching: ', fullPath);
        return '';
      }
    },
  },
};
