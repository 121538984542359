import { has } from 'lodash';
import EthereumConstants, { AlgorandConstants } from './constants';

export default {
  SET_DEPLOYMENT_TOKEN(state, deploymentData) {
    const currentStatus = state.deploymentToken.status;
    const walletRegistrarOwnerBalanceData = has(deploymentData, 'owners') ? deploymentData.owners.walletRegistrarOwnerBalance : '';

    state.deploymentToken = {
      ...deploymentData,
      owners: {
        ...deploymentData.owners,
        walletRegistrarOwnerBalance: walletRegistrarOwnerBalanceData,
      },
      status: deploymentData.status || currentStatus,
    };
    state.swapContract.custodianAddress = deploymentData.custodianAddress;
    state.swapContract.stableCoin = deploymentData.stableCoin;
    state.swapContract.swapContractAddress = deploymentData.swapContractAddress;
  },
  SET_DEPLOYMENT_EXISTS(state, deploymentExists) {
    state.deploymentExists = deploymentExists;
  },
  SET_DEPLOYMENT_ID(state, deploymentId) {
    state.deploymentToken.id = deploymentId;
  },
  SET_DEPLOYMENT_DESCRIPTION(state, deploymentDescription) {
    state.deploymentToken.decimals = deploymentDescription.decimals;
    state.deploymentToken.name = deploymentDescription.name;
    state.deploymentToken.symbol = deploymentDescription.symbol;
    state.deploymentToken.provider = deploymentDescription.provider;
  },
  SET_DEPLOYMENT_COMPLIANCE_TYPE(state, complianceType) {
    state.deploymentToken.complianceType = complianceType;
  },
  SET_DEPLOYMENT_COMPLIANCE(state, complianceRules) {
    state.deploymentToken.complianceRules = complianceRules;
  },
  SET_COUNTRIES_COMPLIANCE(state, countriesComplianceStatuses) {
    state.deploymentToken.countriesComplianceStatuses = countriesComplianceStatuses;
  },
  SET_ROLES(state, roles) {
    state.deploymentToken.roles = roles;
  },
  SET_OWNERS(state, owners) {
    state.deploymentToken.owners = owners;
  },
  SET_DEPLOYMENT_STATUS(state, deploymentStatus) {
    state.deploymentToken.status = deploymentStatus;
  },
  SET_DEPLOYMENT_TRANSACTIONS(state, deploymentTransactionData) {
    state.deploymentTransactions = {
      ...state.deploymentTransactions,
      data: deploymentTransactionData,
      totalItems: deploymentTransactionData.length,
    };
  },
  SET_DEPLOYMENT_LOCK(state, isLocked) {
    state.deploymentTransactions = {
      ...state.deploymentTransactions,
      isLocked,
    };
  },
  SELECT_DEPLOYMENT_STEP(state, selectedStep) {
    state.selectedStep = selectedStep;
  },
  SET_DEPLOYMENT_AVAILABLE_NETWORKS(state, networks) {
    state.deploymentAvailableNetworks = networks;
  },
  SET_DEPLOYMENT_REGISTRAR_WALLET_BALANCE({ deploymentToken }, { address, balance }) {
    if (!deploymentToken.owners) {
      deploymentToken.owners = {
        walletRegistrarOwner: address,
        walletRegistrarOwnerBalance: balance,
        walletRegistrarOwnerBalanceInEth: (balance / EthereumConstants.wei).toFixed(EthereumConstants.decimalPlaces),
        walletRegistrarOwnerBalanceInAlgo: (balance / AlgorandConstants.algo).toFixed(AlgorandConstants.decimalPlaces),
      };
    } else {
      deploymentToken.owners = {
        ...deploymentToken.owners,
        walletRegistrarOwner: address,
        walletRegistrarOwnerBalance: balance,
        walletRegistrarOwnerBalanceInEth: (balance / EthereumConstants.wei).toFixed(EthereumConstants.decimalPlaces),
        walletRegistrarOwnerBalanceInAlgo: (balance / AlgorandConstants.algo).toFixed(AlgorandConstants.decimalPlaces),
      };
    }
  },
  SET_DEPLOYMENT_MULTISIG_WALLETS(state, wallets) {
    state.deploymentMultiSig = wallets;
  },
  SET_SWAP_CONTRACT(state, swapContract) {
    state.swapContract = swapContract;
  },
  SET_ABSTRACTION_LAYER_ID(state, abstractionLayerId) {
    state.deploymentToken.abstractionLayerDeploymentId = abstractionLayerId;
  },
  SET_SUBMITBUTTONDISABLED(state, value) {
    state.submitButtonDisabled = value;
  },
  SET_PROCESSINGACTIVATE(state, value) {
    state.processingActivate = value;
  },
};
