export default {
  SET_SNAPSHOTS(state, snapshots) {
    state.snapshots = snapshots;
  },
  SET_SNAPSHOTS_TOTAL_ITEMS(state, totalItems) {
    state.totalItems = totalItems;
  },
  SET_DISTRIBUTE_CREATED(state, snapshotById) {
    state.snapshotById = snapshotById;
  },
};
