import { omnibusWalletOwnersApi } from '~/utilities/mainApiClient';

const {
  addOmnibusWalletOwner,
  editOmnibusWalletOwnerById,
  getOmnibusWalletOwnerById,
} = omnibusWalletOwnersApi;

export default {
  createOmnibusWalletOwner({ commit }, { body, tokenId, issuerId }) {
    return addOmnibusWalletOwner(issuerId, tokenId, body)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Omnibus wallet owner is added', { root: true });
        return res;
      }).catch((err) => {
        this.$log.error('Investors add error:', err);
      });
  },
  getOmnibusWalletOwnerDetails({ commit }, { issuerId, tokenId, owoId }) {
    return getOmnibusWalletOwnerById(issuerId, tokenId, owoId)
      .then(({ data }) => {
        commit('SET_OMNIBUS_WALLET_OWNER_DETAILS_GENERAL', data);
      }).catch((err) => {
        this.$log.error('Omnibus wallet owner details general get error:', err);
        throw err;
      });
  },
  updateOmnibusWalletOwnerDetailGeneral({ commit }, { issuerId, tokenId, owoId, body }) {
    return editOmnibusWalletOwnerById(issuerId, tokenId, owoId, body)
      .then(() => {
        commit('SET_OMNIBUS_WALLET_OWNER_DETAILS_GENERAL', body);
        commit('global/CALL_TOASTER', 'Omnibus wallet owner details general is updated', { root: true });
        return body;
      })
      .catch((err) => {
        this.$log.error('Omnibus wallet owner details general update error:', err);
      });
  },
  changeDetailsEditableBlock({ commit }, blockName) {
    commit('CHANGE_DETAILS_EDITABLE_BLOCK', blockName);
  },
};
