<template>
  <cp-general-modal
    ref="twofaModal"
    :title="title"
    hide-footer
    @shown="setInitialState"
  >
    <div class="card-body">
      <!-- Logo -->
      <div
        class="d-flex justify-content-center align-items-center pb-2 mb-4"
      >
        <div class="ui-w-60">
          <div class="w-100 position-relative">
            <img
              class="d-block w-100"
              :src="getImagePath('securitize.png', 'logo')"
              alt="Securitize"
            >
          </div>
        </div>
      </div>
      <!-- / Logo -->
      <h5 class="text-center mb-4 font-weight-bold">
        {{ $t('twoFactorAuth.title.auth') }}
      </h5>
      <h6 class="font-weight-light text-center">
        {{ $t('twoFactorAuth.subTitle') }}
      </h6>
    </div>
    <h6 class="font-weight-bold">
      <span class="small font-weight-normal">{{ $t('twoFactorAuth.input.digists') }}</span>
    </h6>
    <digit-code-inputs
      ref="digitCodeInputs"
      v-model="digits"
      @input="onChangeDigits"
    />

    <button
      type="button"
      class="btn btn-primary btn-block"
      :disabled="digits.length < 6 || isLoading"
      @click="signIn"
    >
      <span v-if="!isLoading">{{ $t('twoFactorAuth.button.signIn') }}</span>
      <b-spinner
        v-if="isLoading"
        class="align-middle"
        small
      />
    </button>
    <i18n
      class="card-footer text-center"
      path="twoFactorAuth.message.lost"
      tag="div"
    >
      <a href="mailto:issuersupport@securitize.io">{{ $t('twoFactorAuth.message.contactUs') }}</a>
    </i18n>
    <div
      v-if="initLoader"
      class="background-overlay"
    />
    <Spinner
      v-if="initLoader"
      name="wave"
      color="#04bec4"
      class="cp-button-spinner"
      fade-in="quarter"
    />
  </cp-general-modal>
</template>

<script>
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpGeneralModal from './modals-components/general-modal';
import digitCodeInputs from './digit-code-inputs';

export default {
  metaInfo: {
    name: 'twoFaForm',
  },
  components: {
    CpGeneralModal,
    digitCodeInputs,
  },
  mixins: [staticPathGenerationMixin],
  props: {
    isButtonActive: {
      type: Boolean,
      default: false,
    },
    twofaToken: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: '',
      initLoader: false,
      digits: this.twofaToken,
      isLoading: this.isButtonActive,
      isAutoSign: true,
    };
  },
  computed: {
  },
  watch: {
    isButtonActive: {
      handler(newVal) {
        this.isLoading = !newVal;
      },
    },
  },
  methods: {
    setInitialState() {
      this.digits = '';
      this.isAutoSign = true;
    },
    show() {
      this.$refs.twofaModal.show();
    },
    signIn() {
      this.isLoading = true;
      this.$emit('verifyTwofa', this.digits);
    },
    onChangeDigits() {
      if (this.digits.length === 6 && this.isAutoSign) {
        this.isAutoSign = false;
        this.signIn();
      }
      if (this.digits.length !== 6) this.isLoading = false;
    },
    clearDigits() {
      this.$refs.digitCodeInputs.digits = ['', '', '', '', '', ''];
    },
  },
};
</script>
