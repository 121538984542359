import axios from '~/utilities/axios';

class BulkServiceCpApi {
    constructor() {
        const that = this;
        that.configuration = { apiKey: '', basePath: '/api/v2' };

        this.authorizationHeader = () => ({});

        this.reverseSync = function reverseSync(issuerId, requestBody) {
            const localVarPath = `${that.configuration.basePath}/issuers/${issuerId}/investors-bulk/sec-id-reverse-sync`;
            const localVarHeadersParams = { ...that.authorizationHeader() };
            return axios.post(localVarPath, requestBody, {
                headers: localVarHeadersParams,
            });
        };
    }
}

// eslint-disable-next-line import/prefer-default-export
export const {
    reverseSync,
} = new BulkServiceCpApi();
