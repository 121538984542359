<template>
  <div>
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <sketch-picker
      :disabled="disabled"
      :value="currentValue"
      @input="updateValue"
    />
  </div>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
  name: 'CpColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      controlledValue: this.value,
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
  },
  methods: {
    updateValue({ rgba }) {
      const {
        r,
        g,
        b,
        a,
      } = rgba;
      this.$emit('input', `rgba(${r},${g},${b},${a},)`);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
