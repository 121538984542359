export default {
  opportunities: {
    title: '投資案件',
    opportunitiesList: {
      noOpportunities: {
        title: '投資案件はありません',
        content: 'このページでは、投資家に提示する投資案件の設定を変更できます。',
        addButton: {
          label: '投資案件を作成',
        },
      },
      addButton: {
        label: '投資案件を作成',
      },
      deleteButton: {
        label: '削除',
      },
      detailButton: {
        label: '詳細',
      },
      publishButton: {
        label: '公開にする',
      },
      unPublishButton: {
        label: '非公開にする',
      },
      draftText: '下書き',
      publishedText: '公開済み',
      tokenName: 'トークン名',
      roundName: 'ラウンド名',
      deleteConfirmation: {
        title: 'この投資案件を削除しますか？',
        content: 'このアクションは元に戻せません',
        deleteButton: '削除',
      },
      publishConfirmation: {
        title: 'この投資案件を公開にしますか？',
        content: '公開にすると投資家はアクセスできるようになります。 必要な場合、再度非公開にすることができます。',
        publishButton: '公開',
      },
      unPublishConfirmation: {
        title: 'この投資案件を非公開にしますか？',
        content: '非公開にすると投資家はアクセスできなくなります。 必要な場合、再度公開にすることができます。',
        unPublishButton: '非公開',
      },
    },
    addOpportunity: {
      windowTitle: '投資案件作成',
      title: '投資案件についての情報',
      createOpportunityButtonText: '投資案件を作成',
      cancelButtonText: 'キャンセル',
      tokenSelect: {
        label: 'トークン',
      },
      roundSelect: {
        label: 'ラウンド',
      },
      titleText: {
        label: '投資案件名',
        placeholder: '例）2020年の投資案件',
      },
    },
    editOpportunity: {
      tabs: {
        summaryTab: '概要',
        contentTab: 'コンテンツ',
        documentsTab: 'ドキュメント',
      },
      summary: {
        header: {
          text: '投資案件概要',
        },
        publishedText: '公開済み',
        unpublishedText: '非公開',
        shadowOn: 'シャドウ モード オン',
        shadowOff: 'シャドウ モード オフ',
        signedDate: '署名日',
        token: {
          label: 'トークン',
        },
        round: {
          label: 'ラウンド',
        },
        image: {
          label: '画像 (機会の詳細)',
        },
        smallImage: {
          label: '小さな画像 (機会リスト)',
        },
        title: {
          label: '投資案件名',
        },
        description: {
          label: '説明 (機会の詳細)',
        },
        shortDescription: {
          label: '簡単な説明 (機会リスト)',
        },
        scheduleCallLink: {
          label: '通話をスケジュールする',
        },
        order: {
          label: '順序',
        },
        attributes: {
          attribute1: {
            label: '属性名',
            valueLabel: '値',
          },
          attribute2: {
            label: '属性名',
            valueLabel: '値',
          },
        },
        fields: {
          annualizedYield: {
            label: 'Annualized Yield',
          },
          term: {
            label: 'Term',
          },
        },
      },
      content: {
        visibility: {
          whoTitle: '表示対象',
          whereTitle: '表示箇所',
          showToVerifiedInvestorsOnly: 'KYC済み',
          showInTokenDetails: '案件詳細',
          showInOpportunity: '投資案件',
        },
        options: {
          kycRequiredText: 'KYC required to see Contents',
          noKycRequiredText: 'KYC not required to see Contents',
          headerText: 'Options',
        },
        add: {
          button: {
            text: 'コンテンツボックスを追加',
          },
          modal: {
            title: {
              label: 'どのタイプのコンテンツボックスを追加しますか？',
            },
            buttons: {
              video: {
                title: '動画ボックス',
                description: '投資案件に関連する動画',
              },
              text: {
                title: 'テキストボックス',
                description: '投資案件についての説明',
              },
              imgWithDesc: {
                title: '画像ボックス',
                description: '投資案件についての説明付きの画像',
              },
              telegram: {
                title: 'Telegram Widget Box',
                description: 'Telegram widget for displaying channels or post related to your opportunity',
              },
              documents: {
                title: '書類',
                description: '機会に関連する書類',
              },
              progress: {
                title: '進行中の投資',
                description: '進行中の投資のリスト',
              },
            },
          },
        },
        videoBox: {
          title: '動画コンテンツ',
          play: {
            label: '再生',
          },
          inputs: {
            title: {
              label: '動画タイトル',
              placeholder: '例）説明動画',
            },
            video: {
              label: 'Youtube/Vimeo リンク',
              placeholder: '例）https://youtu.be/5qap5aO4i9A',
              error: '動画のURLは必須です',
            },
            description: {
              label: '説明',
              placeholder: '例）この動画は投資案件に関する必要な情報を説明します。',
            },
            order: {
              label: '順序',
              placeholder: '',
            },
          },
        },
        textBox: {
          title: 'テキストコンテンツ',
          inputs: {
            title: {
              label: 'タイトル',
              placeholder: '例）投資案件の概要',
            },
            description: {
              label: '内容',
              placeholder: '例）この内容は投資案件に関する必要な情報を説明します。',
            },
            order: {
              label: '順序',
              placeholder: '',
            },
            sideType: {
              label: '側',
              options: {
                left: '左の列',
                right: '右の列',
              },
              infoRightMsg: '画像の1回の公開とテキストの1回の公開を行うことができます。',
            },
          },
        },
        imageBox: {
          title: '画像コンテンツ',
          inputs: {
            description: {
              label: '説明',
            },
            order: {
              label: '順序',
              placeholder: '',
            },
            image: {
              label: '画像',
            },
            alignment: '画像のアライメント',
          },
        },
        telegramBox: {
          title: 'Telegram Widget',
          inputs: {
            title: {
              label: 'Title',
              placeholder: 'e.g. Summary about us',
            },
            widgetType: {
              label: 'Type',
              options: {
                discussion: 'Discussion',
                post: 'Post',
              },
            },
            widgetResourceId: {
              label: 'Resource Id',
              placeholder: 'e.g telegram/83',
            },
            order: {
              label: 'Order',
              placeholder: '',
            },
          },
        },
        documentsBox: {
          title: '書類',
          inputs: {
            title: {
              label: 'タイトル',
              placeholder: '例）投資案件の概要',
            },
            order: {
              label: '順序',
              placeholder: '',
            },
          },
        },
        progressBox: {
          title: '進行中の投資',
          inputs: {
            title: {
              label: 'タイトル',
              placeholder: '例）投資案件の概要',
            },
            order: {
              label: '順序',
              placeholder: '',
            },
          },
        },
        newText: '新規作成',
        deleteConfirmation: {
          title: 'このコンテンツを削除しますか？',
          content: 'このアクションは元に戻せません',
          deleteButton: '削除',
        },
        language: {
          label: '言語',
          publishText: '公開',
        },
      },
      document: {
        edit: {
          header: 'ドキュメント詳細',
          changeFile: {
            label: 'ファイルを変更',
          },
          title: {
            label: 'ドキュメント名',
            placeholder: '例）定款',
          },
          restrictions: {
            label: 'ダウンロードの制限',
            kycOrKyb: 'KYC/KYBが必要',
            qualification: '適格性が必要',
          },
          order: {
            label: '順序',
          },
          addButton: {
            label: 'ドキュメントを追加',
          },
          saveButton: {
            label: '保存',
            savingText: '保存中...',
          },
        },
        list: {
          addButton: {
            label: 'ドキュメントを追加',
          },
          downloadButton: {
            label: 'ダウンロード',
          },
          table: {
            th: {
              title: 'ドキュメント名',
              image: 'サムネイル',
              requireKyc: 'KYC/KYB制限',
              requireQualification: '適格性制限',
              createdAt: '作成日',
              actions: '',
            },
          },
          deleteConfirmation: {
            title: 'このドキュメントを削除しますか？',
            content: 'このアクションは元に戻せません',
            deleteButton: '削除',
          },
        },
      },
    },
  },
};
