<template>
  <div>
    <qrcode-vue
      :value="decodeURIComponent(uri)"
      :size="size"
      level="H"
    />
  </div>
</template>
<script>
  import QrcodeVue from 'qrcode.vue';

  export default {
    name: 'QrCodeOauth',
    components: {
      QrcodeVue,
    },
    props: {
      uri: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      size: 120,
    }),
  };
</script>
