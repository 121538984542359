export default {
  createInvestorModal: {
    title: '投資家追加',
    input: {
      firstName: 'ファーストネーム（名前）',
      middleName: 'ミドルネーム',
      lastName: 'ラストネーム（名字）',
      email: 'Eメール',
      country: '居住国',
      state: '州',
      custodian: 'カストディアン',
      investorType: 'Investor Type', // TODO add translation to japanese
      entityName: 'Entity legal name',
      entityDba: 'Entity DBA',
      entityLeiCode: 'LEI Code', // TODO add translation to japanese
      entityType: 'Entity type',
    },
  },
  addEditCustodianModal: {
    titleAdd: 'カストディアンの追加',
    titleEdit: 'カストディアンの編集',
    input: {
      name: '名前',
      friendlyName: '略称',
      email: 'Eメール',
      wallet: 'ウォレット',
      logo: 'Logo', // TODO
    },
  },
  importInvestorsModal: {
    title: '投資家インポート',
    label: {
      update: 'CSVファイルをアップロードして下さい:',
    },
    button: {
      ok: 'インポート',
      cancel: '閉じる',
    },
    switch: {
      label: 'Trigger Investment Email', // TODO add translation to japanese
    },
    overlayTitle: 'Cannot import while previous process is not done.', // TODO add translation to japanese
    checkingState: 'We are verifying if an import process is currently running.', // TODO add translation to japanese
    sendingFile: 'Wait a moment, we are sending the file.', // TODO add translation to japanese
  },
  importInvestorsErrorsModal: {
    title: '投資家インポートエラー',
    label: {
      fieldName: 'フィールド名',
      errorType: 'エラータイプ',
      message: 'メッセージ',
      csvErrorList: 'Field Name,Error Type,Message\n',
      generalErrorMsg: 'Error: CSV is not valid. ',
    },
    button: {
      download: 'エラーリストダウンロード',
    },
    message: {
      invalid: '無効なCSV',
    },
  },
  importIssuanceModal: {
    title: 'Import Issuance Records', // TODO add translation to japanese
    label: {
      update: 'Please upload a CSV file:', // TODO add translation to japanese
      note: 'Note:', // TODO add translation to japanese
    },
    button: {
      ok: 'Import Issuance Records', // TODO add translation to japanese
      cancel: 'Close', // TODO add translation to japanese
      downloadSample: 'Download a CSV Sample', // TODO add translation to japanese
    },
    noteDescription: 'This will import a lean CSV file that is used to update the issuance records of the imported investors', // TODO add translation to japanese
    overlayTitle: 'Cannot import while previous process is not done.', // TODO add translation to japanese
    checkingState: 'We are verifying if an import process is currently running.', // TODO add translation to japanese
    sendingFile: 'Wait a moment, we are sending the file.', // TODO add translation to japanese
    summary: {
      errors: {
        noId: 'Invalid id/email.', // TODO add translation to japanese
        invalidAssignedTokens: 'Tokens assigned is not a valid value.', // TODO add translation to japanese
        invalidAssignedTokensZero: 'Tokens assigned must be greater than zero.', // TODO add translation to japanese
        invalidPricerPerUnit: 'Price per unit is not a valid value.', // TODO add translation to japanese
        invalidIssuanceDate: 'Issuance date is invalid or formatted incorrectly (use MM/DD/YYYY).', // TODO add translation to japanese
        investorNotFound: 'Investor not found for the given id/email.', // TODO add translation to japanese
        investorWithoutInvestmentRequest: 'Investor doesn\'t have an active investment request.', // TODO add translation to japanese
        errorMultipleIssuancesOnSameDate: 'The investor already has an issuance for this date.', // TODO add translation to japanese
        errorInvalidAmountOfFields: 'The row couldn\'t be parsed due to an invalid amount of fields (expected at least 4).', // TODO add translation to japanese
        investorAlreadyHasAnActiveIssuance: 'Investor has a pending signature.', // TODO add translation to japanese
      },
    },
    forceTBEIssuance: 'Force TBE Issuance', // TODO add translation to japanese
  },
  labelModal: {
    title: {
      edit: 'ラベル編集',
      show: 'ラベルを表示',
    },
    subTitle: '定義済みラベル',
    label: {
    },
    placeholder: {
      labelName: 'ラベル名',
    },
    button: {
      ok: '適用',
      label: {
        create: '作成',
      },
    },
  },
  bulkSummaryModal: {
    title: '一括操作サマリ',
    label: {
      user: 'ユーザー',
      error: 'エラー',
      csvErrorList: 'UserId,User Name,Error,\n',
    },
    button: {
      download: 'エラーリストダウンロード',
      downloadCsv: 'CSVダウンロード',
    },
    message: {
      error: '合計 {0} 操作がエラーによってスキップされました',
      success: '合計 {0} 操作が正常に完了しました',
    },
  },
};
