import axios from '~/utilities/axios';

class WalletConnectCpApi {
   constructor() {
       const that = this;
       that.configuration = { apiKey: '', basePath: '/api/v2/system' };

         that.authorizationHeader = function authorizationHeader() {
                const localVarHeaderParams = { };
                return localVarHeaderParams;
         };

         this.getWalletConnectConfig = function getWalletConnectConfig() {
                const localVarPath = `${that.configuration.basePath}/wallet-connect`;
                return axios.get(localVarPath, {
                    headers: that.authorizationHeader(),
                });
         };
   }
}

// eslint-disable-next-line import/prefer-default-export
export const { getWalletConnectConfig } = new WalletConnectCpApi();
