export default {
  SELECTED_ISSUER(state, instance) {
    if (window.Intercom) {
      window.intercomSettings = {
        ...window.intercomSettings,
        company: {
          name: instance.name,
          id: instance.id,
        },
      };
      window.Intercom('update');
    }
    state.current = instance;
  },
  SET_ISSUERS(state, data) {
    state.issuerList = data;
  },
  SET_All_ISSUERS(state, data) {
    state.allIssuersList = data;
  },
  SET_All_SYSTEM_ISSUERS(state, data) {
    state.allSystemIssuersList = data;
  },
  CLEAR_SELECTED_ISSUER(state) {
    state.current = {
      name: '',
      logo: 'logo.png',
    };
  },
};
