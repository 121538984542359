export default {// TODO: translate to jp
    issuerDetails: {
        title: 'Issuer Details',
        description: 'Set basic details of the new issuer',
    },
    tokenDetails: {
        title: 'Token Details',
        description: 'Set and view token configuration',
    },
    assetDetails: {
        title: 'Asset Details',
        description: 'Set and view asset configuration',
    },
};
