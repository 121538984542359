export default {
  distributionDetail: {
    label: {
      table: {
        name: 'Name / Entity',
        email: 'Email',
        tokens: 'Tokens',
        taxForms: 'Tax documents',
        payoutMethod: 'Payout method',
      },
    },
    status: {
      wallet: {
        wallet: 'wallet',
        bonus: 'bonus',
        treasury: 'treasury',
      },
    },
    button: {
      back: 'Back to overview',
      download: 'Download',
      downloading: 'Downloading',
      generate: 'Generate Distribution',
    },
    loading: 'Loading Distributions Data...',
  },
};
