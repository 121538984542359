export default {
  SET_JURISDICTIONS_PER_TOKEN(
    state,
    {
      disclaimersAndDefinitionsPerToken,
      perCountryDisclaimersAndDefinitionsPerToken,
    },
  ) {
    state.disclaimersAndDefinitionsPerToken = disclaimersAndDefinitionsPerToken;
    state.perCountryDisclaimersAndDefinitionsPerToken = perCountryDisclaimersAndDefinitionsPerToken;
  },

  SET_JURISDICTIONS_PER_TOKEN_COUNTRIES(state, data) {
    state.totalTokenCountriesCount = data.totalItems;
    state.jurisdictionsPerTokenCountries = data.data;
  },

  SET_JURISDICTIONS_PER_TOKEN_STATES_AND_REGIONS(state, data) {
    state.totalTokenStatesAndRegionsCount = data.totalItems;
    state.jurisdictionsPerTokenStatesAndRegions = data.data;
  },

  SET_EDITABLE_TOKEN_COUNTRY(state, data) {
    state.editableTokenCountry = data;
  },
};
