import { snapshotsApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';
import {
  getSnapshots,
} from '~/utilities/external-api/apis/snapshotsCpApi';

const {
  addSnapshot,
  getSnapshotById,
  updateSnapshotById,
} = snapshotsApi;

export default {
  getSnapshotById({ commit }, { idIssuer, snapshotId }) {
    return getSnapshotById(idIssuer, snapshotId)
      .then(({ data }) => {
        commit('SET_DISTRIBUTE_CREATED', data.data);
        return data;
      }).catch((err) => {
        this.$log.error('getSnapshotById get error:', err);
        if (err.response.body.message) {
          throw err.response.body.message;
        }
      });
  },
  postSnapshotsById({ commit }, { issuerId, tokenId, ...body }) {
    return addSnapshot(issuerId, tokenId, body)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshots.postSnapshotsById'), { root: true });
      }).catch((err) => {
        this.$log.error('postSnapshotsById post error:', err);
      });
  },
  patchSnapshotsById({ commit }, { issuerId, id, name }) {
    return updateSnapshotById(issuerId, id, { name })
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.snapshots.patchSnapshotsById'), { root: true });
      }).catch((err) => {
        this.$log.error('patchSnapshotsById patch error:', err);
      });
  },
  getIssuerSnapshots(ctx, { params }) {
    return getSnapshots(params).then(data => data)
      .catch((err) => {
        this.$log.error('Snapshots get error:', err);
        return err;
      });
  },
};
