import FileSaver from 'file-saver';
import {
  getInvestorsList,
  getInvestorById,
  getLegalSignersByInvestorId,
  getInvestorKycHistory,
  getConfidentialDocumentsByInvestorId,
  getDocumentsByInvestorId,
  getInvestorConfidentialDocumentById,
  getInvestorDocumentById,
  getWalletsByInvestorId,
  getCsvInvestors,
  getSuitabilityStatusLog,
  getAccountStatusLog,
  updateSuitabilityByInvestorId,
  updateEuSuitabilityByInvestorId,
  updateAccountStatusByInvestorId,
  updateEuAccountStatusByInvestorId,
  updateBrokerDealerVerificationByInvestorId,
  getBrokerDealerVerificationLog,
  getLegalSignerById,
  getLegalSignerDocumentById,
  updateAccreditationStatusByInvestorId,
} from '@/utilities/external-api/apis/brokerDealerCpApi';

export default {
  getBrokerDealerInvestorsList(ctx, { params }) {
    return getInvestorsList(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID investors get error:', err);
      });
  },
  getBrokerDealerInvestorId({ commit }, { investorId }) {
    return getInvestorById(investorId)
      .then((res) => {
        commit('SET_BROKER_DEALER_INVESTOR_INFO', res.data);
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investors get error:', err);
      });
  },
  getLegalSignersList({ commit }, { params: { investorId, ...queryOptions } }) {
    return getLegalSignersByInvestorId(investorId, queryOptions)
      .then((data) => {
        commit('SET_BROKER_DEALER_LEGAL_SIGNERS_LIST', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer get list error:', err);
        return err;
      });
  },
  getLegalSignerById({ commit }, { params: { investorId, legalSignerId } }) {
    return getLegalSignerById(investorId, legalSignerId)
      .then(({ data }) => {
        commit('SET_BROKER_DEALER_LEGAL_SIGNER_BY_ID', data.data);
        return data.data;
      })
      .catch((err) => {
        this.$log.error('Broker dealer Legal Signer by ID GET error:', err);
        return err;
      });
  },
  getLegalSignerDocumentById(storeObj, { investorId, legalSignerId, documentId }) {
    return getLegalSignerDocumentById(investorId, legalSignerId, documentId)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Broker dealer Legal Signer Document by ID GET error:', err);
        return err;
      });
  },
  getInvestorKycHistory(ctx, { params }) {
    return getInvestorKycHistory(params.investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID investor kyc history get error:', err);
      });
  },
  getBrokerDealerInvestorDocuments(ctx, { params: { investorId } }) {
    return getDocumentsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id Investor Documents get error:', err);
        return err;
      });
  },
  getBrokerDealerInvestorConfidentialDocuments(ctx, { params: { investorId } }) {
    return getConfidentialDocumentsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id Investor confidential documents get error:', err);
        return err;
      });
  },
  downloadBrokerDealerInvestorDocumentById(ctx, { investorId, documentId, isConfidential }) {
    const downloadRequest = isConfidential ? getInvestorConfidentialDocumentById : getInvestorDocumentById;
    return downloadRequest(investorId, documentId)
      .then(({ data }) => data).catch((err) => {
        this.$log.error('Download file error:', err);
      });
  },
  getInvestorWallets(ctx, { params: { investorId } }) {
    return getWalletsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Investors wallets get error:', err);
      });
  },
  getInvestorsListCsv(ctx, { params, fileName }) {
    return getCsvInvestors(params)
      .then(({ data: { data } }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, `${fileName}.csv`);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },
  getSuitabilityStatusHistory(ctx, { params }) {
    return getSuitabilityStatusLog(params.investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor suitability history get error:', err);
      });
  },
  getAccountStatusHistory(ctx, { params }) {
    return getAccountStatusLog(params.investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor account status history get error:', err);
      });
  },
  getBrokerDealerVerificationHistory(ctx, { params }) {
    return getBrokerDealerVerificationLog(params.investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor account status history get error:', err);
      });
  },
  updateSuitabilityStatus(ctx, { investorId, ...body }) {
    return updateSuitabilityByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor update suitability status error:', err);
      });
  },
  updateEuSuitabilityStatus(ctx, { investorId, ...body }) {
    return updateEuSuitabilityByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor update EU suitability status error:', err);
      });
  },
  updateAccountStatus(ctx, { investorId, ...body }) {
    return updateAccountStatusByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets update account status error:', err);
      });
  },
  updateEuAccountStatus(ctx, { investorId, ...body }) {
    return updateEuAccountStatusByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets update EU account status error:', err);
      });
  },
  updateBrokerDealerVerification(ctx, { investorId, ...body }) {
    return updateBrokerDealerVerificationByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor update broker dealer verification status error:', err);
      });
  },
  updateInvestorAccreditationStatus(ctx, { investorId, ...body }) {
    return updateAccreditationStatusByInvestorId(investorId, body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Markets investor update verification status error:', err);
      });
  },
};
