export default {
  disclaimersAndDefinitionsPerToken: {},
  perCountryDisclaimersAndDefinitionsPerToken: null,
  jurisdictionsPerTokenCountries: [],
  editableTokenCountry: {
    disclaimer: {},
    qualification: {},
  },
  totalTokenCountriesCount: null,
  totalTokenStatesAndRegionsCount: null,
  jurisdictionsPerTokenStatesAndRegions: [],
};
