export default {
  controlBook: {
    title: '発行数管理',
    label: {
      me: '自分',
      table: {
        createdAt: '作成日',
        authorizer: '承認者',
        operatorName: '担当者',
        previousAuthorizedSecurities: '旧発行可能証券総数',
        authorizedSecurities: '新発行可能証券総数',
        previousCbSecurities: '旧発行済証券数',
        cbSecurities: '新発行済証券数',
        description: '説明',
        documentationId: 'ドキュメンテーションID',
        previousPrincipalAmountAuthorizedSecurities: '旧発行可能債券\n合計額',
        principalAmountauthorizedSecurities: '新発行可能債券\n合計額',
        previousCbPrincipalAmountSecurities: '旧発行済債券\n合計額',
        cbPrincipalAmountSecurities: '新発行済債券\n合計額',
      },
    },
    button: {
      controlBook: {
        update: 'コントロールブック更新',
      },
    },
    // modals
    controlBookCreateModal: {
      title: 'コントロールブック更新',
      input: {
        authorizer: '承認者',
        authorizedSecurities: '発行可能証券総数',
        cbSecurities: '発行済証券数(CB)',
        description: '説明',
        documentationId: 'ドキュメンテーションID',
      },
    },
    controlBookEditModal: {
      title: '編集',
      input: {
        createdAt: '作成日時',
        authorizer: '承認者',
        operatorName: '担当者',
        previousAuthorizedSecurities: '旧発行可能証券総数',
        authorizedSecurities: '新発行可能証券総数',
        previousCbSecurities: '旧発行済証券数',
        cbSecurities: '新発行済証券数',
        description: '説明',
        documentationId: 'ドキュメンテーションID',
      },
    },
  },
};
