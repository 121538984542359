export default {
  issuerEmailTemplates: {
    title: 'Eメールテンプレート',
    label: {
      table: {
        name: 'テンプレート名',
        status: 'Status', // TODO: translate to jp
      },
      button: { // TODO: translate to jp
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    // modal
    emailModal: {
      title: 'Eメールテンプレート編集',
      input: {
        name: 'テンプレート名',
        subject: '件名',
        title: 'タイトル',
        from: 'From',
        allowedVariables: '使用可能な変数',
        variableDescription: '補足情報',
        text: 'Eメール本文',
        content: 'EメールHTML',
      },
      label: {
        createdAt: '作成日',
        updatedAt: '更新日',
      },
      button: {
        preview: 'プレビュー',
      },
    },
  },
};
