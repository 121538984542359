import querystring from 'qs';
import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class TranceCpApi {
  constructor() {
    const that = this;
    that.configuration = { apiKey: '', basePath: '/api/v2' };

    this.authorizationHeader = () => ({});

    this.getDomain = function getDomain(issuerId) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/domains`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeaderParams,
        contentType: 'application/json',
      });
    };

    this.deleteDomain = function deleteDomain(issuerId) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/domains`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.delete(localVarPath, {
        headers: localVarHeaderParams,
        contentType: 'application/json',
      });
    };

    this.validateDomain = function validateDomain(issuerId, body) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/domains`;
      const localVarHeaderParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, body, {
        headers: localVarHeaderParams,
        contentType: 'application/json',
      });
    };

    this.viewContacts = function viewContacts(issuerId, tokenId, stage, filter, queryParams) {
      const params = querystring.stringify(queryParams);
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/contact-lists/view?stage=${stage}&${params}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, filter, {
        headers: localVarHeadersParams,
      });
    };
    this.contactLists = function contactLists(issuerId, tokenId, stage, round) {
      let roundParam = '';
      if (round !== undefined) {
        roundParam = `&round=${round}`;
      }
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/contact-lists?stage=${stage}${roundParam}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.createContactList = function createContactList(issuerId, tokenId, stage, requestBody) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/contact-lists?stage=${stage}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, requestBody, {
        headers: localVarHeadersParams,
      });
    };
    this.deleteContactList = function deleteContactList(issuerId, tokenId, listId) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/contact-lists/${listId}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.delete(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.sendEmail = function sendEmail(issuerId, tokenId, emailContent, type, stage, allInvestors) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/notifications/${type}?stage=${stage}&all-investors=${allInvestors}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.post(localVarPath, emailContent, {
        headers: localVarHeadersParams,
        contentType: 'multipart/form-data',
      });
    };
    this.emailActivityChart = function emailActivityChart(issuerId, tokenId, timezone) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/email-activity/histogram?timezone=${timezone}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.emailActivity = function emailActivity(issuerId, tokenId, queryParams) {
      const params = querystring.stringify(queryParams);
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/email-activity/details?${params}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.emailActivityDetail = function emailActivityDetail(issuerId, tokenId, subjectId, queryParams) {
      const params = querystring.stringify(queryParams);
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/${tokenId}/email-activity/${subjectId}?${params}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.getS3PresignedUrl = function getS3PresignedUrl(issuerId) {
      const localVarPath = `${that.configuration.basePath}/outreach/${issuerId}/notifications/upload`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.getControlBookRecords = function getControlBookRecords(issuerId, tokenId, queryParams) {
      const params = querystring.stringify(queryParams);
      const localVarPath = `${that.configuration.basePath}/transfer-agent/control-book/issuers/${issuerId}/tokens/${tokenId}/control-book-records?${params}`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.controlBookInfo = function controlBookInfo(issuerId, tokenId) {
      const localVarPath = `${that.configuration.basePath}/transfer-agent/control-book/issuers/${issuerId}/tokens/${tokenId}/control-book-records/info`;
      const localVarHeadersParams = { ...that.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
    this.getTokenTransactions = function getTokenTransactions(issuerId, tokenId, queryParams, acceptType) {
      const params = querystring.stringify(queryParams);
      const localVarPath = `${that.configuration.basePath}/transfer-agent/control-book/issuers/${issuerId}/tokens/${tokenId}/token-transactions?${params}`;
      const localVarHeadersParams = { ...that.authorizationHeader(), ...{ accept: acceptType } };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };
  }
}

export const {
  validateDomain, viewContacts, getDomain, deleteDomain, contactLists, createContactList, deleteContactList, sendEmail, emailActivity, emailActivityDetail, emailActivityChart,
  getS3PresignedUrl, getControlBookRecords, controlBookInfo, getTokenTransactions,
} = new TranceCpApi();
