export default {
  issuerTexts: {
    title: 'Issuer Texts',
    label: {
      table: {
        name: 'Name',
        type: 'Type',
        isHtml: 'Is Html',
        active: 'Active',
        value: 'Value',
      },
    },
    status: {
      system: 'system',
      content: 'content',
    },
    // modal
    affiliateModal: {
      title: 'Edit Issuer Text',
      input: {
        name: 'Name',
        type: 'Type',
        isHtml: 'is HTML',
        active: 'Active',
        value: 'Value',
      },
      label: {
        createdAt: 'Date Created',
        updatedAt: 'Date Updated',
      },
    },
  },
};
