import {
  getFeatureFlags,
  updateFeatureFlag,
} from '@/utilities/external-api/apis/brokerDealerCpApi';

export default {
  getSystemConfigs() {
    return getFeatureFlags()
      .catch((err) => {
        this.$log.error('Broker dealer get feature flags error', err);
      });
  },
  updateSystemConfig({ commit }, body) {
    return updateFeatureFlag(body)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Configuration updated successfully', { root: true });
        return data;
      })
      .catch((err) => {
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating the configuration');
        this.$log.error('Broker dealer update config error', err);
      });
  },
};
