export default {
  activities: ({ activities }) => activities,
  activitiesCount: ({ totalLogs }) => totalLogs,
  fundingStatistics: ({ fundingStatistics }) => fundingStatistics,
  onboardingStatistics: ({ onboardingStatistics }) => onboardingStatistics,
  onboardingByCountrySummary: ({ onboardingByCountrySummary }) => onboardingByCountrySummary,
  onboardingRegisteredSummary: ({ onboardingRegisteredSummary }) => onboardingRegisteredSummary,
  onboardingAuthorizedSummary: ({ onboardingAuthorizedSummary }) => onboardingAuthorizedSummary,
  fudingByCountrySummary: ({ fudingByCountrySummary }) => fudingByCountrySummary,
  fundingByPledgeSummary: ({ fundingByPledgeSummary }) => fundingByPledgeSummary,
  fundingFundedSummary: ({ fundingFundedSummary }) => fundingFundedSummary,
  holdersStatistics: ({ holdersStatistics }) => holdersStatistics,
  holdersByCountrySummary: ({ holdersByCountrySummary }) => holdersByCountrySummary,
  holdersByTypeSummary: ({ holdersByTypeSummary }) => holdersByTypeSummary,
  holdersTokenStakes: ({ holdersTokenStakes }) => holdersTokenStakes,
};
