export default {
  SOCKET_CONNECT(state) {
    state.isConnecting = true;
  },
  SOCKET_CONNECTED(state) {
    state.isConnected = true;
    state.isConnecting = false;
  },
  SOCKET_DISCONNECT(state) {
    state.isConnected = false;
    state.isConnecting = false;
  },
  SOCKET_REQUESTLOGEVENTS(state) {
    state.isReceivingEvents = true;
  },
  SOCKET_EVENTLOG(state, log) {
    state.isReceivingEvents = !state.isReceivingEvents;
    const logStep = log.step;
    if (logStep === 0) {
      setTimeout(() => { // If we are receiving the "successful deployment" step, the previous step may be lagged, thus we cut it some slack to arrive
        state.logMessages[Object.keys(state.logMessages).reduce((a, b) => (Number(a) > Number(b) ? a : b))].status = 'Success'; // a.k.a get the highest value of the key (the highest step number)
      }, 5000);
    } else {
      const stateLog = state.logMessages[logStep] // If it's a new step, we create a blank step template
        || {
        txHash: [],
        gasUsed: '',
        contractAddress: '',
        from: '',
        deploymentLogs: [],
        status: '',
        initialDate: '',
        finalDate: '',
        step: logStep,
        stepTitle: '',
      };
      stateLog.deploymentLogs = log.deploymentLogs ? [...stateLog.deploymentLogs, ...log.deploymentLogs] : ['No logs were produced at this step, most likely because it was skipped'];
      stateLog.txHash = [...stateLog.txHash, log.txHash];
      stateLog.gasUsed = log.gasUsed;
      stateLog.contractAddress = log.contractAddress;
      stateLog.from = log.from;
      stateLog.finalDate = new Date(log.finalDate).toUTCString();
      stateLog.initialDate = new Date(log.initialDate).toUTCString();
      stateLog.initialDateTimeStamp = new Date(log.initialDate).getTime(); // We force the timestamp conversion regardless of the received format
      stateLog.finalDateTimeStamp = new Date(log.finalDate).getTime();
      if (log.status === 'failure') {
        stateLog.status = 'Failed';
      } else if (!stateLog.status) {
        stateLog.status = log.status === 'skipped' ? 'Skipped' : 'Processing';
      }
      stateLog.stepTitle = log.stepTitle;

      state.logMessages[logStep] = stateLog;
      state.isReceivingEvents = !state.isReceivingEvents; // This triggers the watch on the component

      if (logStep > 1) {
        if (state.logMessages[logStep - 1]) {
          if (state.logMessages[logStep - 1].status !== 'Skipped') {
            state.logMessages[logStep - 1].status = 'Success';
          }
        } else { // In some cases (specially Ganache-deployed tokens) the order of events is not guaranteed, so we need to create a placeholder
          state.logMessages[logStep - 1] = {
            txHash: [],
            deploymentLogs: [],
            status: 'placeholder',
            step: logStep - 1,
          };
        }
      }
    }
  },
  SOCKET_STOPLOGEVENTS(state) {
    state.isReceivingEvents = false;
  },
  SOCKET_FETCHINGLOGS(state) {
    state.hasFetchedLogs = false;
  },
  SOCKET_FETCHLOGS(state, logs) {
    logs.forEach((log) => {
      const logStep = log.step;
      if (logStep !== 0) {
        const stateLog = state.logMessages[logStep] // If it's a new step, we create a blank step template
          || {
          txHash: [],
          gasUsed: '',
          contractAddress: '',
          from: '',
          deploymentLogs: [],
          status: '',
          initialDate: '',
          finalDate: '',
          step: logStep,
        };
        stateLog.deploymentLogs = log.deploymentLogs ? [...stateLog.deploymentLogs, ...log.deploymentLogs] : ['No logs were produced at this step'];
        stateLog.txHash = [...stateLog.txHash, log.txHash];
        stateLog.gasUsed = log.gasUsed;
        stateLog.contractAddress = log.contractAddress;
        stateLog.from = log.from;
        stateLog.finalDate = new Date(log.finalDate).toUTCString();
        stateLog.initialDate = new Date(log.initialDate).toUTCString();
        stateLog.initialDateTimeStamp = new Date(log.initialDate).getTime(); // We force the timestamp conversion regardless of the received format
        stateLog.finalDateTimeStamp = new Date(log.finalDate).getTime();

        if (log.status === 'failure') {
          stateLog.status = 'Failed';
          state.socketError = true;
          state.socketErrorMessage = `Deployment failed at step ${log.step}. See errors below`;
        } else if (!stateLog.status) {
          stateLog.status = log.status === 'skipped' ? 'Skipped' : 'Success';
        }
        stateLog.stepTitle = log.stepTitle;

        state.logMessages[logStep] = stateLog;
      }
    });
    state.hasFetchedLogs = true;
  },
  SOCKET_CLEANLOGS(state) {
    state.logMessages = {};
    state.socketError = false;
    state.socketErrorMessage = '';
    state.hasFetchedLogs = false;
  },

  SOCKET_ERROR(state, errorMessage) {
    state.socketError = true;
    state.socketErrorMessage = errorMessage;
  },
  SOCKET_SUCCESS(state) {
    state.socketError = false;
    state.socketErrorMessage = '';
  },
};
