export default {
  getGeneral: ({ configuration }) => configuration.general,
  getGeneralImages: ({ configuration }) => configuration.general.images,
  getInvestorsArea: ({ configuration }) => configuration.investorsArea,
  getInvestorsAbout: ({ configuration }) => configuration.investorsArea.about,
  getInvestorsAnnouncements: ({ configuration }) => configuration.investorsArea.announcements,
  getInvestorsWizard: ({ configuration }) => configuration.investorsArea.wizard,
  getGeneralEmail: ({ configuration }) => configuration.email,
  getInvestorsSocialLinks: ({ configuration: { investorsArea: { socialLinks } } }) => (index) => {
    if (socialLinks[index]) {
      return socialLinks[index].url !== undefined ? socialLinks[index].url : socialLinks[index];
    }
  },
  getAllInvestorsSocialLinks: ({ configuration }) => configuration.investorsArea.socialLinks,
  getLandingPage: ({ configuration }) => configuration.landingPage,
  getLandingInfo: ({ landingInfo }) => landingInfo,
  getTokenConfiguration: ({ tokenConfiguration }) => tokenConfiguration,
};
