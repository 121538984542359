export default {
  issuerEmailTemplates: {
    title: 'Issuer Email templates',
    label: {
      table: {
        name: 'Name',
        status: 'Status',
      },
      button: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
    // modal
    emailModal: {
      title: 'Edit Email Template',
      input: {
        name: 'Name',
        subject: 'Subject',
        title: 'Title',
        from: 'From',
        allowedVariables: 'Allowed variables',
        variableDescription: 'Template Variables',
        text: 'Email Text',
        content: 'Email HTML',
      },
      label: {
        createdAt: 'Created',
        updatedAt: 'Updated',
      },
      button: {
        preview: 'Preview',
      },
    },
  },
};
