export default {
  holders: {
    title: '保有者',
    label: {
      investor: '投資家',
      tokenSynced: '{0} 同期済',
      tokenPendingSync: '{0} 同期中',
      tab: {
        holdersOfRecord: '保有者',
        beneficialOwners: '受益者',
        custodianManagement: 'カストディアン管理',
      },
      filter: {
        country: '居住国',
        investorType: '個人/法人',
        tokenOwned: '保有トークン',
        walletOwner: 'ウォレット所有者',
        custodian: 'カストディアン',
      },
      table: {
        beneficialOwners: '受益者',
        recordHolder: '保有者',
        country: '居住国',
        name: '名前',
        friendlyName: '略称',
        investors: '投資家',
        email: 'Eメール',
        investorType: '投資家\nタイプ',
        totalTokens: 'トークン\n保有量',
        principalAmount: '債券合計額\n（{0}）',
        percentage: 'パーセント',
        walletAddress: 'ウォレットアドレス',
        walletRegistrationStatus: 'ウォレット\n登録ステータス',
        labels: 'ラベル',
      },
    },
    status: {
      walletRegistration: {
        none: 'なし',
        pending: '処理中',
        syncing: '同期中',
        ready: '準備完了',
        rejected: '不合格',
        cancelled: '取消済',
      },
    },
    loading: '投資家データを読込中...',
    importIssuance: {
      button: 'Import Issuance Records', // TODO: translate to jp
      processStartedMessage: 'A total of {0} issuance records were found and they will be processed in the background. '
          + 'You will receive an email once the process is completes including any error that may occur.', // TODO: translate to jp
      processStartedMessageWithErrors: 'A total of {0} issuance records were found, {1} records will be processed in the background, '
          + '{2} records contained validation errors and will not be processed. '
          + 'You will receive an email once the process is completes including any error that may occur.', // TODO: translate to jp
      processCouldNotBeStartedMessage: 'An error occurred and the import cannot continue. Please try again or contact an administrator. {0}', // TODO: translate to jp
    },
  },
};
