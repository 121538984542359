export default {
  SET_JURISDICTIONS(
    state,
    {
      restrictedJurisdictions,
      disclaimersAndDefinitions,
      perCountryDisclaimersAndDefinitions,
      restrictedJurisdictionsState,
    },
  ) {
    state.restrictedJurisdictions = restrictedJurisdictions;
    state.restrictedJurisdictionsState = restrictedJurisdictionsState;
    state.disclaimersAndDefinitions = disclaimersAndDefinitions;
    state.perCountryDisclaimersAndDefinitions = perCountryDisclaimersAndDefinitions;
  },

  SET_JURISDICTIONS_COUNTRIES(state, data) {
    state.totalCountriesCount = data.totalItems;
    state.jurisdictionsCountries = data.data;
  },

  SET_JURISDICTIONS_STATES_AND_REGIONS(state, data) {
    state.totalStatesAndRegionsCount = data.totalItems;
    state.jurisdictionsStatesAndRegions = data.data;
  },

  SET_EDITABLE_COUNTRY(state, data) {
    state.editableCountry = data;
  },
};
