export default {
  issuerPrerenderedAddresses: {
    title: 'Issuer Pre-Rendered Addresses',
    button: {
      address: {
        add: 'Add Address',
      },
    },
    label: {
      table: {
        address: 'Address',
        addressSignature: 'Address Signature',
        derivativeId: 'Derivative Id',
        currencyId: 'Currency',
        createdAt: 'Created',
        updatedAt: 'Updated',
      },
    },
    loading: 'Loading Data...',
    // modal
    prerenderedAddressModal: {
      title: '{0} Address',
      input: {
        address: 'Address',
        derivativeId: 'Derivative ID',
        currencyId: 'Currency',
      },
      label: {
        createdAt: 'Created',
        updatedAt: 'Updated',
      },
    },
  },
};
