import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';
import globalStore from '@/store/modules/global';
import issuerStore from '@/store/modules/issuer-list';

export default async () => {
  const { state: { authorizations } } = currentOperatorStore;
  if (authorizations.includes('new-cp-ui')) {
    return Frame(
      'controlbook',
      'Control Book',
      './ControlBookApp',
      process.env.VUE_APP_CONTROL_BOOK_MFE_ENTRY_POINT,
      () => {
        const {
          state: { idUUID: operatorId },
        } = currentOperatorStore;

        const {
          state: { locale },
        } = globalStore;

        const {
          state: { current },
        } = issuerStore;

        return { operatorId, locale, issuerId: current.id };
      },
    );
  }
  return import('@/pages/_idIssuer/transfer-agent/control-book/index');
};
