export default {
  component: {
    cpTableControls: {
      label: {
        show: '{0} 件表示',
      },
    },
    cpTablePagination: {
      label: {
        entries: '{totalItems}件中{shownItems}件を表示中',
        previous: '戻る',
        next: '次へ',
      },
    },
    cpTableDeleteModal: {
      message: '"{0}"を\n削除してもよいですか？',
    },
  },
};
