export default {
  records: [],
  authSecuritiesNum: 0,
  summary: {
    totalCbSecurities: 0,
    authorizedSecurities: 0,
    issuedSecurities: 0,
    totalIssuedToBlockchain: 0,
    totalIssuedToDRSTokens: 0,
    totalIssuedTokenBonus: 0,
  },
};
