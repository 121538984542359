export default {
  issuerInfo: ({ issuerInfo }) => issuerInfo,
  issuerCurrencies: ({ issuerInfo }) => issuerInfo.currencies,
  getIssuerId: ({ issuerId }) => issuerId,
  issuerWalletManagers: ({ issuerWalletManagers }) => issuerWalletManagers,
  brokerDealerGroupPermissions: ({ issuerInfo }) => (issuerInfo && issuerInfo.brokerDealerGroupPermissions) || {},
  issuerTokens: ({ issuerInfo }) => issuerInfo.tokens,
  issuerTokenNamesAndIds: ({ issuerInfo }) => issuerInfo.tokens.map(token => ({
    value: token.id,
    text: token.name,
  })),
  permissionsPerIssuer: state => state.permissionsPerIssuer,
};
