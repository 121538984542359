export default {
  investorsFundraise: {
    title: '資金調達状況',
    titleWithRoundName: '資金調達ラウンド - {0}',
    label: {
      allRounds: '全てのラウンド',
      filter: {
        country: '居住国',
        investorType: '投資家タイプ',
        investorStatus: 'KYCステータス',
        amountPledged: '申込み額',
        amountFunded: '入金額',
        tokensAssigned: 'Assigned Tokens', // TODO: translate
        subscriptionAgreementStatus: '契約ステータス',
      },
      table: {
        investorName: '投資家',
        investorStatus: 'KYC\nステータス',
        lastAmountPledged: 'Last Pledged', // TODO: translate
        pledgeDate: '申込日',
        lastAmountFunded: 'Last Funded', // TODO: translate
        tokensAssigned: 'Assigned Tokens', // TODO: translate
        subscriptionAgreementStatus: '契約\nステータス',
        roundIds: 'ラウンド',
        labels: 'ラベル',
      },
    },
    status: {
      investor: {
        ready: '発行準備完了',
        pendingKyc: 'KYC待ち',
        pendingAccreditation: '適格性評価待ち',
        pendingFunding: '入金待ち',
        pendingInvestorSignature: '投資家署名待ち',
        pendingIssuerSignature: '発行体署名待ち',
        issued: 'issued', // TODO: translate
      },
      subscriptionAgreement: {
        none: 'なし',
        requested: 'リクエスト済',
        sent: '送信済',
        signed: '署名済',
        confirmed: '確認済',
        rejected: '不合格',
        preSigned: '署名待ち',
      },
      tokenStatus: {
        issued: '発行済',
        notIssued: '未発行',
      },
    },
    loading: '投資家データ読み込み中...',
    option: {
      investorStatus: {
        ready: '準備完了',
        inProgress: '進行中',
      },
      subscriptionAgreement: {
        none: 'なし',
        requested: 'リクエスト済',
        sent: '送信済',
        signed: '署名済',
        confirmed: '確認済',
        rejected: '拒否',
        preSigned: '署名待ち',
      },
      tokenStatus: {
        issued: '発行済',
        notIssued: '未発行',
      },
      operations: {
        issueTokens: 'トークン発行',
      },
    },
    bulkActions: {
      issuance: {
        name: 'トークン発行',
        confirmModal: {
          title: 'トークン発行',
          okText: '実行',
          confirmMessage: 'この処理を実行すると、{0} 人の投資家に対しトークンが発行されます。ウォレットを登録している場合は最初に登録したウォレットに、登録していない場合はトレジャリーにトークンが発行されます。',
          confirmMessageBulk: '発行処理はバックグラウンドで実行されます。完了時にメールで通知いたします。',
          errorMessage: 'ラウンドの発行日を設定してください。',
        },
      },
      sendFundingInstructions: {
        name: 'Send Funding Instructions',
        confirmModal: {
          title: 'Action Confirmation',
          okText: 'Confirm',
          confirmMessage: 'Please confirm that you wish to send funding instructions to the custodian for {0} investors, according to their pledge amount in the current active round: {1}.',
          confirmMessageNonFbo: 'We will not send to {0} of the selected investors as they are not assigned to a custodian.',
        },
        summaryModal: {
          message: 'Total of {0} investors were included in the funding instructions sent to the custodian/s.',
        },
      },
      sendDocuSignEmail: {
        name: 'Send DocuSign Email',
        confirmModal: {
          title: 'Action Confirmation',
          okText: 'Confirm',
          cancelText: 'Close',
          confirmMessageAllFbo: 'Please confirm that you wish to trigger a DocuSign signature email to be sent to {0} investors, '
            + 'according to their pledge amount in the current active round: {1}.',
          confirmMessageSomeFbo: 'We will not send to {0} selected investors as they are not assigned to a custodian',
          confirmMessageNonFbo: 'The action cannot be completed as you had selected only non-custodian investors. Please select at least one custodian investor in order to Send {0} Email',
        },
        summaryModal: {
          messageFullSuccess: 'Total of {0} subscription agreements were successfully sent',
          messagePartialSuccess: 'Total of {0} out of {1} subscription agreements were successfully sent. Total of {2} agreements were not sent due to missing pledge amount for the active round',
        },
      },
    },
  },
};
