<template>
  <sidenav class="sidenav sidenav-vertical layout-sidenav bg-dark">
    <div class="app-brand demo">
      <b-dd
        v-if="hasIssuer"
        no-caret
        class="app-brand-text demo"
        variant="link"
        :text="currentIssuer.name"
        :right="isRTL"
        menu-class="sidenav-dd-menu"
      >
        <template slot="button-content">
          <div class="d-flex">
            <span
              v-if="hasIssuer"
              class="app-brand-logo app-brand-logo--mr"
            >
              <cp-image
                :src="logoSrc"
                :default-src="logoSrc"
                title="Current instance"
              />
            </span>
            <b-tooltip
              v-if="shouldShowTooltip(currentIssuer, 140)"
              target="current-issuer-name"
              triggers="hover"
            >
              {{ currentIssuer.name }}
            </b-tooltip>
            <a
              id="current-issuer-name"
              href="javascript:void(0)"
              class="font-weight-normal  ml-0 dropdown-toggle issuer-name-box"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentIssuer.name }}
            </a>
          </div>
        </template>
        <b-dd-header
          v-if="shouldDisplayRecentIssuers"
          class="recent-issuers-header"
        >
          {{ $t('component.sideNav.link.recentIssuers') }}
        </b-dd-header>
        <b-dd-item
          v-for="item in recentIssuersList"
          :key="`recent-${item.id}`"
          @click="openIssuer(item)"
        >
          <b-tooltip
            v-if="shouldShowTooltip(item, 140)"
            :target="`recent-issuer-${item.id}`"
            triggers="hover"
          >
            {{ item.name }}
          </b-tooltip>
          <span
            :id="`recent-issuer-${item.id}`"
            class="menu-item-wrapper"
          >
            {{ item.name }}
          </span>
        </b-dd-item>
        <b-dd-divider v-if="shouldDisplayRecentIssuers" />
        <b-dd-header class="active-issuers-header">
          {{ $t('component.sideNav.link.activeIssuers') }}
        </b-dd-header>
        <b-dd-item
          v-for="item in activeIssuersList"
          :key="`active-${item.id}`"
          @click="openIssuer(item)"
        >
          <b-tooltip
            v-if="shouldShowTooltip(item, 140)"
            :target="`active-issuer-${item.id}`"
            triggers="hover"
          >
            {{ item.name }}
          </b-tooltip>
          <span
            :id="`active-issuer-${item.id}`"
            class="menu-item-wrapper"
          >
            {{ item.name }}
          </span>
        </b-dd-item>
        <b-dd-divider />
        <b-dd-item
          @click="openIssuer()"
        >
          {{ $t('component.sideNav.link.issuersList') }}
        </b-dd-item>
      </b-dd>
      <div
        v-else
        class="app-brand-text demo"
      >
        <a
          href="javascript:void(0)"
          class="font-weight-normal"
          style="margin-left:6px;cursor: default"
        >
          {{ $t('common.companyInfo.name') }}
        </a>
      </div>
      <a
        href="javascript:void(0)"
        class="layout-sidenav-toggle sidenav-link text-large ml-auto"
        @click="closeNav()"
      >
        <i class="ion ion-md-menu align-middle" />
      </a>
    </div>
    <cp-token-selector v-if="hasIssuer" />
    <div class="sidenav-divider mt-0" />
    <div
      id="side-nav-container"
      class="sidenav-inner"
    >
      <cp-side-nav-menu
        v-for="item in menuItems"
        :key="item.route"
        :show-divider-on="showDividerOn(item)"
        :menu-item="item"
      />
    </div>
    <div class="sidenav-item">
      <span class="version-block">
        <small>
          {{ $t('component.sideNav.label.version', [version]) }}
        </small>
      </span>
    </div>
  </sidenav>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { version } from '~/../package.json';

import { Sidenav } from '~/vendor/libs/sidenav';
import CpImage from '~/components/common/standalone-components/cp-image';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import checkHtmlElementsOverflowMixin from '~/mixins/check-html-elements-overflow';
import routeNames from '~/utilities/routes';
import CpSideNavMenu from './cp-side-nav-menu';
import CpTokenSelector from './cp-token-selector';
import menuItems from './side-nav-menu';

export default {
  name: 'CpSidenav',
  components: {
    CpImage,
    Sidenav,
    CpSideNavMenu,
    CpTokenSelector,
  },
  mixins: [
    staticPathGenerationMixin,
    checkHtmlElementsOverflowMixin,
  ],
  data() {
    return {
      routeNames,
      version,
      activeIssuersList: [],
      recentIssuersList: [],
      menuItems,
    };
  },
  computed: {
    ...mapGetters('global', ['issuersNavList']),
    ...mapGetters('issuerList', ['currentIssuer']),
    logoSrc() {
      return this.getImagePath(this.currentIssuer.logoFullName || this.currentIssuer.logoSquare, 'logo');
    },
    hasIssuer() {
      return !!this.$route.params.idIssuer && this.$route.params.idIssuer !== 'undefined';
    },
    shouldDisplayRecentIssuers() {
      return this.getRecentIssuers().length > 0;
    },
  },
  mounted() {
    this.activeIssuersList = this.getActiveIssuers();
    this.recentIssuersList = this.getRecentIssuers();
  },
  methods: {
    ...mapMutations('issuerList', ['SELECTED_ISSUER']),
    showDividerOn({ route }) {
      return ['securitize-id', 'onboarding'].find(navRouteName => navRouteName === route);
    },
    openIssuer(issuer) {
      if (!issuer) {
        return this.$router.push('/');
      }
      const {
        name, logo, id, defaultTokenId,
      } = issuer;
      this.SELECTED_ISSUER({
        logo, name, id, defaultTokenId,
      });
      window.location.pathname = routeNames.dashboard(id, defaultTokenId);
    },
    closeNav() {
      const html = document.getElementsByTagName('html');
      html[0].classList.remove('layout-expanded');
    },
    getActiveIssuers() {
      return this.issuersNavList.slice(0, 5);
    },
    getRecentIssuers() {
      const recentIssuersListLocalStorage = localStorage.getItem('recentIssuersList') || '[]';
      const recentIssuersList = JSON.parse(recentIssuersListLocalStorage);
      return recentIssuersList.filter(({ id }) => id !== this.currentIssuer.id);
    },
    shouldShowTooltip(item, maxWidth) {
      return this.doesTextExceedContainer(item.name, maxWidth);
    },
  },
};
</script>

<style lang="scss" scoped>
  .app-brand {
    padding-left: 10px;
    overflow: visible;
    .btn {
      padding: 5px;
    }
    .dropdown-toggle {
      &:after {
        margin-left: 2px;
      }
    }
    .app-brand-logo{
      height: 28px;
      width: 28px;
      border-radius: 50%;
      &.app-brand-logo--mr{
        margin-right: 24px;
      }
    }
    .app-brand-text{
      .issuer-name-box{
        max-width: 140px;
      }
    }
  }
  .app-brand {
    .layout-sidenav-toggle {
      display: none;
    }
  }
  .version-block {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    justify-content: flex-end;
    padding: 1rem 2rem !important;
  }

  .recent-issuers-header,
  .active-issuers-header {
    font-size: 14px;
    color: #617d8d;
    margin-top: 5px;
    font-weight: bold;
  }

  .sidenav-dd-menu hr {
    margin: 0 10%;
    width: 80%;
    height: 1px;
    border-color: #e5e5e5 !important;
  }

  @media(max-width: 991px) {
    .app-brand {
      .layout-sidenav-toggle {
        display: block;
      }
    }

    .app-brand-text {
      &.demo {
        font-size: 0.9rem;
      }
    }
  }
  #measure {
    visibility: hidden;
    white-space: nowrap;
    position: absolute;
    top: -9999px;
    font-size: 16px;
    font-family: Arial, sans-serif;
  }
</style>
