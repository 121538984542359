export default {
  configurationJurisdictions: {
    title: '対象国・免責事項',
    loading: '対象国・免責事項データ読み込み中...',
    button: { // TODO: translate to jp
      exportJurisdictionsSettings: 'Export',
      importJurisdictionsSettings: 'Import',
    },
    // Restricted Jurisdictions section
    restrictedJurisdictions: {
      title: '制限対象国',
      message: {
        description: `このセクションでは、トークンを保有することのできない国を指定することができます。
          この制限は資金調達（投資家のオンボーディング）に関してのみ適用されます。
          発行済のトークンに関しては、コントロールパネルから制限国を変更することはできません。`,
      },
      label: {
        countries: '国名',
        restrictedCountries: '制限対象国',
        state: '州・地域',
        restrictedState: '制限対象州または地域',
      },
    },
    // Disclaimers and Definitions section
    disclaimersAndDefinitions: {
      title: '免責事項および定義',
      subTitle: {
        generalDisclaimer: '一般的な免責事項',
        standardCountryDisclaimer: '標準的な国別の免責事項',
        standardQualificationDefinition: '標準的な適格性の定義',
      },
      label: {
        placeholder: '標準的なテキスト...',
        generalDisclaimer: 'この免責事項は、サインアップするすべての投資家に提示されます',
        standardCountryDisclaimer: 'この免責事項は、特定の国の投資家のために追加で表示されます。',
        standardQualificationDefinition: 'これは、投資家が投資をしても良いとみなされるための標準的な定義です。',
      },
    },
    // Per Jurisdiction Disclaimers and Definitions
    perCountryTable: {
      addStateAndRegion: '州または地域の追加',
      title: '国別の免責事項および定義',
      label: {
        table: {
          country: '国名',
          regionState: '州・地域',
          disclaimerType: '免責事項',
          qualificationType: '適格性',
          qualificationRequired: '要適格',
          reverseSolicitation: '勧誘',
        },
      },
      status: {
        disclaimerTypes: {
          standard: '標準',
          specific: '特定',
          none: '無し',
        },
        qualTypes: {
          standard: '標準',
          specific: '特定',
        },
      },
    },
    // modal
    addStateRegionModal: {
      title: '州または地域の追加',
      country: '国名',
      state: '州',
      region: '地域',
    },
    jurisdictionsEditModal: {
      title: '{0}を編集',
      label: {
        disclaimer: '免責事項',
        qualification: '適格性について',
        placeholder: 'テキストを入力...',
        additionalAccreditation: 'Additional Accreditation',
        accreditationFirst: 'Accreditation First',
        reverseSolicitation: 'Reverse Solicitation',
      },
      input: {
        initialAgreement: 'Initial Agreement',
        accreditedAgreements: 'Accredited agreements',
        qualification: '適格性が必要ですか？',
        qualificationTitle: '適格性の種別',
      },
      option: {
        disclaimer: {
          standard: '標準',
          specific: '特定',
          none: 'なし',
        },
        qualification: {
          standard: '標準',
          specific: '特定',
        },
        qualificationTitle: {
          accredited: '認定済',
          qualification: '資格有',
          professional: 'プロ',
          other: 'その他',
        },
      },
      message: {
        qualification: '適格性の基準に合致しない投資家は投資することができません。',
      },
    },
    confirmDeleteModal: {
      title: '州・地域別条件の設定の削除',
      message: '州・地域別条件の設定を削除してもよろしいですか？',
    },
    importJurisdictionsModal: { // TODO: translate to jp
      title: 'Import Jurisdictions',
      label: {
        update: 'Please upload a CSV file:',
      },
      button: {
        ok: 'Import Jurisdictions',
        cancel: 'Close',
      },
    },
    importJurisdictionsErrorsModal: {
      title: 'Import Jurisdictions',
      label: {
        fieldName: 'Field Name',
        errorType: 'Error Type',
        message: 'Message',
        csvErrorList: 'Field Name,Error Type,Message\n',
      },
      button: {
        download: 'Download errors',
      },
      message: {
        invalid: 'Invalid CSV',
      },
    },
  },
};
