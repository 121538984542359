<template>
  <div class="sidenav-header">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SidenavHeader'
};
</script>
