export default {
  operators: {
    head: {
      title: '管理用パネル',
      disabledTooltip: 'オペレーターを追加するには発行体が必要です',
    },
    tabs: {
      operators: 'オペレーター管理',
      brokerDealerGroups: 'B/Dグループ',
      organizations: 'Organizations', // TODO: translate to jp
    },
    button: {
      operators: {
        add: 'オペレーター追加',
        reset: '認証情報リセット',
        hsm: 'HSM', // TODO: translate to jp
      },
    },
    label: {
      never: 'なし',
      table: {
        name: '名前',
        email: 'Eメール',
        lastLoginAt: '最終ログイン日',
        lastLoginIp: 'IP',
        authorizationLevel: '管理権限レベル',
        createdAt: '作成日',
        enabled: 'ステータス',
        issuers: '発行体',
        organization: 'Organization', // TODO: translate to jp
        operators: 'Operators', // TODO: translate to jp
      },
    },
    message: {
      titleTooltip: 'オペレーターを追加するには発行体が必要です',
    },
    dropdown: {
      title: '追加',
      operator: 'オペレーター',
      brokerDealerGroup: 'B/Dグループ',
      organization: 'Organization', // TODO: translate to jp
    },
    filter: {
      title: 'フィルタ',
      label: {
        issuers: '発行体',
        authorizations: '権限',
        authorizationLevel: 'Authorization Level', // TODO: translate to jp
        clearFilter: 'フィルタクリア',
      },
    },
    option: {
      authorizationLevel: {
        basic: 'Basic',
        admin: 'Admin',
        superAdmin: 'Super Admin',
        megaAdmin: 'Mega Admin',
        brokerdealer: 'Broker Dealer',
      },
    },
    confirmModal: {
      title: 'オペレーターステータス変更',
      message: '{name} のステータスを {status} に変更しますがよろしいですか？',
      status: {
        activate: '有効',
        deactivate: '無効',
      },
    },
    resetConfirmModal: {
      title: '認証情報リセット',
      message: '認証情報をリセットしますがよろしいですか？',
    },
    operatorModal: {
      title: {
        add: 'オペレーター追加',
        edit: 'オペレーター編集',
      },
      label: {
        name: '名前',
        email: 'Eメール',
        authorizationLevel: '管理権限レベル',
        authorizations: '管理権限',
        instances: 'インスタンス',
        selectedInstances: '選択中インスタンス',
        brokerDealerGroup: 'B/Dグループ',
        organization: 'Organization', // TODO: translate to jp
        createdBy: 'Created By', // TODO: translate to jp
      },
      warning: {
        emptyBrokerDealerGroupList: '少なくとも1つのB/Dグループを追加してください',
      },
    },
    brokerDealerGroupModal: {
      title: {
        add: 'B/Dグループ追加',
        edit: 'B/Dグループ編集',
      },
      label: {
        name: '名前',
        email: 'Email',
        instances: 'インスタンス',
        selectedInstances: '選択中インスタンス',
        authorizations: '権限',
      },
      button: {
        add: 'グループの追加',
        edit: 'グループの編集',
      },
      settings: {
        label: '設定: {issuerName}',
        noIssuerSelected: '発行体が選択されていません',
        userCanBeEditedByIssuer: 'B/Dがオンボードした投資家の詳細は発行体が編集できます',
        canIssueTokens: 'B/Dはトークンを発行できます',
        canAccessToIssuerConfiguration: 'B/Dは設定メニューにアクセスできます',
        canAccessToIssuerSignatures: 'B/Dは署名メニューにアクセスできます',
      },
    },
    organizationModal: { // TODO: translate to jp
      title: {
        add: 'Add Organization',
        edit: 'Edit Organization',
      },
      label: {
        name: 'Name',
        ipRange: 'IP Range',
        fromIp: 'From',
        toIp: 'To',
      },
      button: {
        add: 'Add Organization',
        edit: 'Save Changes',
      },
    },
    hsm: {
      backToPage: 'Back',
      pageTitle: 'HSM Wallet Info',
      toggleLabel: 'HSM Wallet Enabled',
      walletLabel: 'HSM Wallet',
      error: {
        register: 'Error while trying to register a new HSM Operator.',
        createKey: 'Error while trying to create Operator HSM Keys.',
        removeOperator: 'Error while trying to remove Operator HSM Keys',
        toggleStatus: 'Error while trying to change Operator HSM status.',
      },
    },
  },
};
