import { clientConfigApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '@/i18n';

const {
  createClientConfig,
  deleteClientConfigById,
  updateClientConfigById,
  getAllIssuersClientConfigFilters,
  getAllIssuersClientConfigs,
} = clientConfigApi;

const configsArguments = {
  q: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
  issuerId: undefined,
  name: undefined,
};

export default {

  getAllClientConfigFilters({ commit }) {
    return getAllIssuersClientConfigFilters().then(({ data }) => {
      commit('SET_CLIENT_CONFIGURATION_FILTERS', data);
      return data;
    })
      .catch(err => this.$log.error('Client configuration GET filters error:', err));
  },

  getAllClientConfigs({ commit }, { params }) {
    const args = getArgumentsForApi(configsArguments, params);
    return getAllIssuersClientConfigs(...args).then((configs) => {
      commit('SET_CLIENT_CONFIGURATIONS', configs);
      return configs;
    })
      .catch(err => this.$log.error('Client configuration GET configs error:', err));
  },

  updateClientConfigById({ commit }, { issuerId, configId, body }) {
    return updateClientConfigById(issuerId, configId, body).then((config) => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.clientConfiguration.update'), { root: true });
      return config;
    })
      .catch(err => this.$log.error('Client configuration UPDATE config error:', err));
  },

  createClientConfig({ commit }, { issuerId, body }) {
    return createClientConfig(issuerId, body).then((config) => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.clientConfiguration.create'), { root: true });
      return config;
    })
      .catch(err => this.$log.error('Client configuration CREATE config error:', err));
  },

  deleteClientConfigById({ commit }, { issuerId, configId }) {
    return deleteClientConfigById(issuerId, configId).then((config) => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.clientConfiguration.delete'), { root: true });
      return config;
    })
      .catch(err => this.$log.error('Client configuration DELETE config error:', err));
  },

};
