import Frame from '@/router/mfe/frame';
import currentOperatorStore from '@/store/modules/current-operator';

export default async () => Frame(
    'sid_cp_mfe',
    'Securitize ID',
    './SidApp',
    process.env.VUE_APP_SECURITIES_ID_MFE_ENTRY_POINT,
    () => {
        const { state: { idUUID: id, authorizations, name } } = currentOperatorStore;
        return { operator: { id, name, authorizations } };
    },
);
