<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar header-search align-items-lg-center container-p-x bg-white"
  >
    <!--sidenavToggle-->
    <b-navbar-nav
      v-if="sidenavToggle"
      class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto"
    >
      <div class="app-brand demo">
        <span
          v-if="isIssuerIdInUrl"
          class="app-brand-logo"
        >
          <cp-image
            :src="logoSrc"
            :default-src="logoSrc"
            title="Current instance"
          />
        </span>
        <span
          v-if="isIssuerIdInUrl"
          class="title"
        >
          {{ currentIssuer.name }}
        </span>
        <a
          class="navnav-link px-0 mr-lg-4"
          href="javascript:void(0)"
          @click="toggleSidenav"
        >
          <i class="ion ion-md-menu text-large align-middle" />
        </a>
      </div>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar" />
    <!--sidenavToggle-->

    <b-collapse
      id="app-layout-navbar"
      is-nav
    >
      <b-navbar-nav
        v-if="isIssuerIdInUrl"
        class="nav-list align-items-lg-center"
      >
        <!-- Search -->
        <label
          v-click-outside="hideDatalist"
          class="nav-item navbar-text navbar-search-box p-0 active"
          style="width:100%"
        >
          <i class="ion ion-ios-search navbar-icon align-middle" />
          <span
            class="navbar-search-input pl-2"
            style="width:100%"
          >
            <input
              v-model="investorSearch"
              type="text"
              class="form-control navbar-text mx-2 investorSearch"
              :placeholder="$t('component.navbar.input.investorSearch.placeholder')"
              style="width:100%"
              @focus="showDatalist = true"
              @keydown="searchInvestor"
              @input="investorSelected"
            >
          </span>
          <b-list-group
            v-if="showDatalist && investorSearch.length "
            class="data-list"
          >
            <b-list-group-item
              v-for="(option, index) in autoCompleteUnique"
              :id="option.id"
              :key="index + '-' + option.id"
              button
              @click="selectOption(option.label)"
            >
              {{ option.label }}
            </b-list-group-item>
          </b-list-group>
        </label>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <!--User-->
        <b-nav-item class="demo-navbar-user">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <img
              v-if="operatorData.picture"
              :src="image"
              alt
              class="d-block ui-w-30 rounded-circle"
            >
            <div
              v-if="!operatorData.picture"
              class="d-block text-avator rounded-circle"
            >{{ image }}</div>

            <span
              class="px-1 mr-lg-2 ml-2 ml-lg-0 text-left text-lg-right"
              style="line-height: 1.1;"
            ><strong>{{ name }}</strong>
              <br>
              <small>{{ email }}</small>
            </span>
          </span>
        </b-nav-item>
        <!--User-->

        <cp-language-selector v-if="localeList.length > 1" />

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mx-1">
          |
        </div>
        <!-- Divider -->
        <issuer-notifications v-if="isIssuerIdInUrl && showByAuthorizationLevel" />
        <securitize-id-notifications v-else-if="isSecuritizeIdPage && showByAuthorizationLevel" />

        <!--Logout-->
        <b-nav-item @click="logoutHandle()">
          <i class="ion ion-ios-log-out navbar-icon align-middle" />
        </b-nav-item>
        <!--Logout-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import _ from 'lodash';
import ClickOutside from 'vue-click-outside';
import Cookie from 'js-cookie';
import routeNames from '~/utilities/routes';
import { localeList } from '~/i18n';
import staticPathGenerationMixin from '~/mixins/static-path-generation';
import CpLanguageSelector from '~/components/layout/language-selector';
import IssuerNotifications from '@/components/layout/issuer-notifications';
import SecuritizeIdNotifications from '@/components/layout/securitize-id-notifications';
import CpImage from '~/components/common/standalone-components/cp-image';

export default {
  name: 'CpNavbar',
  components: {
    CpImage,
    CpLanguageSelector,
    IssuerNotifications,
    SecuritizeIdNotifications,
  },
  directives: {
    ClickOutside,
  },
  mixins: [staticPathGenerationMixin],
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      investorsType: {
        issuerId: this.$route.params.idIssuer,
        view: 'onboarding',
      },
      investorSearch: '',
      lastInvestorSearch: null,
      investorAutoComplete: [],
      showDatalist: false,
      localeList,
    };
  },
  computed: {
    ...mapGetters('currentOperator', ['alertsCount', 'notifications', 'authorizationLevel', 'operatorData']),
    ...mapGetters('issuerList', ['currentIssuer']),
    ...mapState('issuersInfo', ['currentToken']),
    isIssuerIdInUrl() {
      return this.$route.params.idIssuer && this.$route.params.idIssuer !== 'undefined';
    },
    isSecuritizeIdPage() {
      return this.$route.path.startsWith('/securitize-id');
    },
    logoSrc() {
      return this.getImagePath(this.currentIssuer.logoFullName || this.currentIssuer.logoSquare, 'logo');
    },
    autoCompleteUnique() {
      const unique = {};
      return this.investorAutoComplete.map((option) => {
        let label = `${option.firstName} ${option.lastName}`;
        while (unique[label]) {
          label = `${label} `; // note the space.
        }
        unique[label] = true;
        option.label = label;
        return option;
      });
    },
    investorSearchResults() {
      return _.debounce(this.fetchSearchData, 350);
    },
    showByAuthorizationLevel() {
      return this.authorizationLevel !== 'brokerdealer';
    },
    tokenId() {
      return this.$route.params.tokenId || (this.currentToken && this.currentToken.id);
    },
    image() {
      return this.operatorData.picture ? this.operatorData.picture : this.operatorData.name.slice(0, 1).toUpperCase();
    },
    name() {
      return this.operatorData.name ? this.operatorData.name : '-';
    },
    email() {
      return this.operatorData.email ? this.operatorData.email : '-';
    },
  },
  methods: {
    ...mapActions('currentOperator', ['logout']),
    ...mapActions('investors', ['getInvestorForAutoComplete']),
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    logoutHandle() {
      const authType = Cookie.get('auth_type');
      if (!authType) {
        this.$router.go();
      }
      this.logout()
        .then(() => {
          this.$router.push(routeNames.index);
        });
    },
    searchInvestor(e) {
      if (e.keyCode === 13) {
        return this.investorSelected(e);
      }
    },
    updateSearchOptions(newValue) {
      if (this.lastInvestorSearch !== this.investorSearch) {
        const searchTerm = newValue || this.investorSearch;
        this.lastInvestorSearch = searchTerm;
        this.investorSearchResults();
      }
    },
    fetchSearchData() {
      this.getInvestorForAutoComplete({
        params: {
          issuerId: this.$route.params.idIssuer,
          q: this.investorSearch,
          limit: 10,
        },
      })
        .then(({ data }) => {
          this.investorAutoComplete = data.data;
        });
    },
    investorSelected(e) {
      if (e instanceof InputEvent) {
        this.investorSearch = e.target.value;
        return this.updateSearchOptions(e.target.value);
      }
      this.viewResult();
    },
    viewResult() {
      const issuerId = this.$route.params.idIssuer;
      const selectedOption = this.autoCompleteUnique.find(({ label }) => label === this.investorSearch);
      if (selectedOption) {
        this.$router.push(routeNames.searchonboarding(issuerId, this.investorSearch));
        window.setTimeout(
          () => this.$router.push(routeNames.viewDetails(issuerId, this.tokenId, selectedOption.id)),
          0,
        );
      } else {
        this.$router.push(routeNames.searchonboarding(issuerId, this.investorSearch));
      }

      this.investorSearch = '';
    },
    hideDatalist() {
      this.showDatalist = false;
    },
    selectOption(value) {
      this.investorSearch = value;
      this.hideDatalist();
      this.viewResult();
    },
  },
};
</script>

<style lang="scss" scoped>
  .navbar-search-box {
    position: relative;
  }

  .data-list {
    position: absolute;
    top: 40px;
    background: #fff;
    z-index: 100;
  }

  .demo-navbar-user {
    .nav-link {
      color: inherit !important;
    }

    * {
      white-space: nowrap;
      cursor: default;
    }
  }

  .app-brand {
    .title {
      padding: 10px;
    }
  }

  .layout-navbar {
    &.header-search {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  #investors_list {
    cursor: pointer;

    option {
      cursor: pointer;
    }
  }

  .nav-list {
    width: 100%;
    justify-content: space-between;
  }

  .text-avator {
    background: #03a6ab;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
  }
</style>
