export default {
  component: {
    cpFilter: {
      title: 'フィルタ',
      button: {
        filter: {
          clear: 'フィルタクリア',
        },
      },
    },
    cpFileUploader: {
      error: 'This document is required', // TODO: translate to jp
    },
  },
};
