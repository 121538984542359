import {
  investorsApi,
  holderAffiliatesApi,
  getArgumentsForApi,
} from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const { addHolderAffiliateRecord, getHolderAffiliateRecords } = investorsApi;
const { getAffiliateHolders } = holderAffiliatesApi;
const affiliateHoldersArguments = {
  issuerId: undefined,
  tokenId: undefined,
  orderField: undefined,
  orderDirection: undefined,
  page: undefined,
  limit: undefined,
};
export default {
  getIssuerAffiliateHolders(ctx, { params }) {
    const args = getArgumentsForApi(affiliateHoldersArguments, params);
    return getAffiliateHolders(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Affiliate holders get error:', err);
        return err;
      });
  },
  getHolderAffiliateRecords({ commit }, { issuerId, tokenId, userId }) {
    return getHolderAffiliateRecords(issuerId, tokenId, userId)
      .then(({ data }) => {
        commit('SET_AFFILIATE_RECORDS', data.data);
      }).catch((err) => {
        this.$log.error('Holder-affiliate-records error:', err);
      });
  },
  createHolderAffiliateRecords({ commit }, {
    issuerId, userId, body,
  }) {
    return addHolderAffiliateRecord(issuerId, userId, body)
      .then(() => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.affiliateManagement.createHolderAffiliateRecords'), { root: true });
      }).catch((err) => {
        this.$log.error('System agreement create error:', err);
      });
  },
};
