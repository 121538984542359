<template>
  <div>
    <label class="form-label">
      {{ label }}
    </label>
    <b-select
      :value="currentValue"
      :options="options"
      :name="name"
      multiple
      class="mb-2"
      :select-size="4"
      @input="onChangeSelected"
    />
    <b-button
      v-if="!hideClearBtn"
      size="sm"
      variant="outline-primary"
      @click.stop="resetValue"
    >
      {{ clearButtonLabel }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'CpMultiSelect',
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    hideClearBtn: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      controlledValue: this.value,
    };
  },
  computed: {
    currentValue() {
      return this.value.length ? this.value : this.controlledValue;
    },
    clearButtonLabel() {
      return this.$t('component.cpMultiSelect.button.clear', [this.label.toLowerCase()]);
    },
  },
  methods: {
    onChangeSelected(val) {
      this.controlledValue = val;
      this.$emit('input', this.controlledValue);
    },
    resetValue() {
      this.controlledValue = [];
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
