export default {
  operationalProcedures: {
    title: 'Operational Procedures',
    input: {
      chooseProcedure: 'Choose Procedure:',
      sourceWalletId: {
        text: 'Source Wallet ID:',
        placeholder: 'e.g. 7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      numberOfSecurities: {
        text: 'Number of Securities:',
        placeholder: 'e.g. 50',
      },
      numberOfLockedTokens: {
        text: 'Number of Locked Tokens:',
        placeholder: 'e.g. 50',
      },
      investorName: 'Investor Name:',
      destinationWalletId: {
        text: 'Destination Wallet ID:',
        placeholder: 'e.g. 7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      issuanceDate: 'Issuance Date:',
      reason: {
        text: 'Reason:',
        placeholder: 'Type your reason here...',
      },
      txId: {
        text: 'Transaction ID:',
        placeholder: {
          tezos: 'e.g. op9TReQgmLUXouucihDwddRtimnaNcXQDNoyAGe6qYQq8JkJSPd',
          ethereum: 'e.g. 0x48b33f6c6d825fea0e7c26ade694c1c9b22168cdfe7574fdefece956310e2075',
          algorand: 'e.g. X4HPM7O3CWNBUZ7AI3IDYJYYE4537GFVR5635GFLPLM7IHBL3FMA',
        },
      },
      documentId: {
        text: 'Document ID:',
        placeholder: 'Insert your DocumentID here...',
      },
      walletId: {
        text: 'Wallet ID:',
        placeholder: 'e.g. 7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      investorId: {
        text: 'Investor ID:',
        placeholder: 'e.g. 127923531',
      },
      senderId: {
        text: 'Sender ID:',
        placeholder: 'e.g. 127923531',
      },
      receiverId: {
        text: 'Receiver ID:',
        placeholder: 'e.g. 127923531',
      },
      tbeAmount: {
        text: 'TBE Amount:',
        placeholder: 'e.g. 50',
      },
      contractStatus: 'Contract Status:',
    },
    button: {
      execute: 'Execute',
      holdTrading: 'Hold Trading',
      resumeTrading: 'Resume Trading',
    },
    option: {
      procedures: {
        lostShares: 'Lost Shares',
        clawback: 'Clawback',
        destroy: 'Destroy',
        holdTrading: 'Hold Trading',
        destroyTbe: 'Destroy TBE',
        internalTbeTransfer: 'Internal Transfer TBE',
        lockTokens: 'Lock Tokens',
        unlockTokens: 'Unlock Tokens',
      },
    },
    message: {
      lostShareFormDescription: `Destroy a number of tokens from a certain whitelisted wallet
        and reissue the same amount of tokens to a new wallet. A common use case is after a 'lost shares'
        where an investor loses his control over a holding wallet.`,
      clawbackFormDescription: `Destroy a number of tokens from a certain
        whitelisted wallet and reissue the same amount back to the sender.
        A common use case is the Clawback action, where the issuer wishes to 'reverse' or 'undo' token issuance.`,
      destroyFormDescription: `Destroy a number of securities from a certain whitelisted wallet.
        Can only be achieved for tokens that were assigned by the issuer.`,
      lockTokensDescription: 'Lock a number of securities from a certain whitelisted wallet. Can only be achieved for tokens that were assigned by the issuer.',
      unlockTokensDescription: 'Unlock a number of securities from a certain whitelisted wallet. Can only be achieved for locked tokens only.',
      holdTradingDescription: `Pause (or unpause) a whole blockchain contact.
        Pausing a contract will stop all trading (including secondary) with the token.`,
      destroyTbeFormDescription: `Destroy a number of securities from the investor TBE balance.
      Can only be achieved for tokens that were assigned by the issuer.`,
      internalTransferFormTbeDescription: 'Transfer a number of securities from a certain investor to another. Can only be achieved for TBE Balance',
    },
    deleteConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to destroy {tokensAmount} tokens for {investorName} ?',
    },
    deleteTbeConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to destroy {tokensAmount} TBE tokens for {investorName} ?',
    },
    lockTokensConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to lock {tokensAmount} tokens for {investorName} ?',
    },
    unlockTokensConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to unlock {tokensAmount} tokens for {investorName} ?',
    },
    proceduresConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to do a {0} procedure with the provided details?',
      label: {
        lostShare: 'lost shares',
        clawback: 'clawback',
      },
    },
    resumeConfirmationModal: {
      title: 'Please confirm',
      message: `Please confirm by typing the issuer name {currentIssuer}
        that you wish to change the status of the token from {tokenStatus} to {altStatus}`,
      input: {
        name: {
          text: 'Name',
          placeholder: 'Please type your issuer name...',
        },
      },
    },
  },
};
