<template>
  <b-button
    :size="size"
    :class="`d-flex justify-content-center align-items-center cp-button-with-loader ${customClass}`"
    :variant="variant"
    :disabled="isDisabled"
    @click="clickHandler"
  >
    <Spinner
      v-if="isLoading"
      name="wave"
      color="inherit"
      class="cp-button-spinner"
      fade-in="quarter"
    />
    <i
      v-if="icon"
      :class="getIcon"
    />
    <span :class="{ 'cp-hide-text': isLoading }">
      <slot />
    </span>
  </b-button>
</template>

<script>
import Spinner from 'vue-spinkit';

export default {
  name: 'CpButton',
  components: {
    Spinner,
  },
  props: {
    ctaType: {
      type: String,
      default: 'primary',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'sm',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  computed: {
    getIcon() {
      return `ion ion-${this.icon} mr-1`;
    },
    isDisabled() {
      return this.isLoading || this.disabled;
    },
  },
  methods: {
    clickHandler(env) {
      this.$emit('click', env);
    },
  },
};
</script>

<style lang="scss">
  .cp-hide-text {
    opacity: 0 !important;
  }

  .cp-button-spinner{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-height: 18px;
    opacity: 0.6;
  }
  .cp-button-with-loader {
    position: relative;
  }
</style>
