export default {
  createInvestorModal: {
    title: 'Add Investor',
    input: {
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      email: 'Email',
      country: 'Country',
      state: 'State',
      custodian: 'Custodian',
      investorType: 'Investor Type',
      entityName: 'Entity legal name',
      entityDba: 'Entity DBA',
      entityLeiCode: 'LEI Code',
      entityType: 'Entity type',
    },
  },
  addEditCustodianModal: {
    titleAdd: 'Add Custodian',
    titleEdit: 'Edit Custodian',
    input: {
      name: 'Custodian Name',
      friendlyName: 'Friendly Name',
      email: 'Email',
      wallet: 'Wallet',
      logo: 'Logo',
    },
  },
  importInvestorsModal: {
    title: 'Import investors',
    label: {
      update: 'Please upload a CSV file:',
    },
    button: {
      ok: 'Import Investors',
      cancel: 'Close',
    },
    switch: {
      label: 'Trigger Investment Email',
    },
    overlayTitle: 'Cannot import while previous process is not done.',
    checkingState: 'We are verifying if an import process is currently running.',
    sendingFile: 'Wait a moment, we are sending the file.',
  },
  importInvestorsErrorsModal: {
    title: 'Import investors',
    label: {
      fieldName: 'Field Name',
      errorType: 'Error Type',
      message: 'Message',
      csvErrorList: 'Field Name,Error Type,Message\n',
      generalErrorMsg: 'Error: CSV is not valid. ',
    },
    button: {
      download: 'Download errors',
    },
    message: {
      invalid: 'Invalid CSV',
    },
  },
  importIssuanceModal: {
    title: 'Import Issuance Records',
    label: {
      update: 'Please upload a CSV file:',
      note: 'Note:',
    },
    button: {
      ok: 'Import Issuance Records',
      cancel: 'Close',
      downloadSample: 'Download a CSV Sample',
    },
    noteDescription: 'This will import a lean CSV file that is used to update the issuance records of the imported investors',
    overlayTitle: 'Cannot import while previous process is not done.',
    checkingState: 'We are verifying if an import process is currently running.',
    sendingFile: 'Wait a moment, we are sending the file.',
    summary: {
      errors: {
        noId: 'Invalid id/email.',
        invalidAssignedTokens: 'Tokens assigned is not a valid value.',
        invalidAssignedTokensZero: 'Tokens assigned must be greater than zero.',
        invalidPricerPerUnit: 'Price per unit is not a valid value.',
        invalidIssuanceDate: 'Issuance date is invalid or formatted incorrectly (use MM/DD/YYYY).',
        investorNotFound: 'Investor not found for the given id/email.',
        investorWithoutInvestmentRequest: 'Investor doesn\'t have an active investment request.',
        errorMultipleIssuancesOnSameDate: 'The investor already has an issuance for this date.',
        errorInvalidAmountOfFields: 'The row couldn\'t be parsed due to an invalid amount of fields (expected at least 4).',
        investorAlreadyHasAnActiveIssuance: 'Investor has a pending signature.',
      },
    },
    forceTBEIssuance: 'Force TBE Issuance',
  },
  labelModal: {
    title: {
      edit: 'Edit Labels',
      show: 'Show Labels',
    },
    subTitle: 'Predefined labels',
    label: {
    },
    placeholder: {
      labelName: 'Label name',
    },
    button: {
      ok: 'Apply',
      label: {
        create: 'Create',
      },
    },
  },
  bulkSummaryModal: {
    title: 'Bulk Action Summary',
    label: {
      user: 'User',
      error: 'Error',
      csvErrorList: 'UserId,User Name,Error,\n',
    },
    button: {
      download: 'Download errors',
      downloadCsv: 'Download CSV',
    },
    message: {
      error: 'Total of {0} actions were skipped due to errors:',
      success: 'Total of {0} actions were successfully completed',
    },
  },
};
