export default {
  distributionDetail: {
    label: {
      filterFieldName: '払い出しウォレット',
      table: {
        name: '投資家',
        email: 'Eメール',
        tokens: 'トークン',
        wallet: 'ウォレット',
        type: 'タイプ',
        payoutWallet: '払い出し\nウォレット',
      },
    },
    status: {
      wallet: {
        wallet: 'ウォレット',
        bonus: 'ボーナス',
        treasury: 'トレジャリー',
      },
    },
    button: {
      back: 'リストに戻る',
      download: 'ダウンロード',
      generate: '分配の作成',
    },
    loading: '分配データを読み込み中...',
  },
};
