<template>
  <div class="form-group">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <quill-editor
      v-if="!viewSource"
      v-validate.initial="validate"
      :class="[ inputClass, 'editor-text', { 'border border-danger': error || controlledError }]"
      :value="currentValue"
      :name="name"
      :options="editorOption"
      :data-vv-as="name.split(/(?=[A-Z])/).join(' ').toLowerCase()"
      :disabled="disabled"
      @change="onChangeInput"
    />
    <b-textarea
      v-else
      v-validate.initial="validate"
      style="min-height: 331px"
      :class="[ inputClass, 'source-text', { 'border border-danger': error || controlledError }]"
      :disabled="disabled"
      :value="currentValue"
      :placeholder="placeholder"
      :name="name"
      :data-vv-as="name.split(/(?=[A-Z])/).join(' ').toLowerCase()"
      @input="onChangeInput"
    />
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
    <b-btn
      icon="ion ion-md-link"
      style="margin-top:20px"
      :disabled="disabled"
      @click="viewSource = !viewSource"
    >
      <i :class="!viewSource ? 'ion-md-eye-off' : 'ion-md-eye'" />
      {{ buttonText }}
    </b-btn>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'CpHtmlTextarea',
  components: {
    quillEditor,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    defaultViewSource: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: 'html-content',
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    validate: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      viewSource: this.defaultViewSource,
      controlledValue: this.value,
      controlledError: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            ['clean'],
            ['link'],
          ],
        },
      },
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    buttonText() {
      return this.viewSource
        ? this.$t('component.cpHtmlTextarea.button.preview')
        : this.$t('component.cpHtmlTextarea.button.view');
    },
  },
  methods: {
    onChangeInput(item) {
      this.$emit('input', this.viewSource ? item : item.html);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
<style src="~/vendor/libs/vue-quill-editor/typography.scss" lang="scss" />
<style src="~/vendor/libs/vue-quill-editor/editor.scss" lang="scss" />
<style scoped>
    .editor-text {
      max-height: 350px;
      overflow-y: auto;
    }
    .source-text {
      max-height: 416px;
      overflow-y: auto;
    }
    .inner-layout div {
        margin-top: 10px;
    }
    .modal-title h5{
        margin-bottom: 0;
    }
    .form-label{
        margin-top: 20px;
    }
</style>
