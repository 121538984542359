import fundraiseContentRoutesWrapper from '../pages/_idIssuer/configuration/fundraise/fundraiseContentRoutesWrapper';

export default [
  {
    /* webpackChunkName: "generalConfiguration" */
    path: 'general',
    name: 'generalConfiguration',
    component: () => import('../pages/_idIssuer/configuration/general'),
  },
  {
    /* webpackChunkName: "jurisdictionsConfiguration" */
    path: 'jurisdictions',
    name: 'jurisdictionsConfiguration',
    component: () => import('../pages/_idIssuer/configuration/jurisdictions'),
  },
  {
    path: 'opportunities',
    name: 'OpportunitiesList',
    component: () => import('../pages/_idIssuer/configuration/opportunities/list'),
    children: [{
      path: 'add',
      name: 'AddOpportunityModal',
      component: () => import('../pages/_idIssuer/configuration/opportunities/list/add'),
    }],
  },
  {
    path: 'fundraise-content',
    name: 'fundraiseContent',
    component: () => fundraiseContentRoutesWrapper(),
  },
];
