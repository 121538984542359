import { getStableCoinsByProvider } from '@/utilities/external-api/apis/blockchainGwApi';

const setStableCoinsByProvider = ({ commit }, { providerId }) => {
    getStableCoinsByProvider(providerId)
        .then(({ data }) => {
            commit('SET_STABLE_COINS', { providerId, stableCoins: data });
        })
        .catch((error) => {
            this.$log.error('Error while getting stable coins by provider', error);
        });
};

export default { setStableCoinsByProvider };
