export default {
  issueDetails: {
    loading: '発行情報詳細を読み込み中...',
    prependTitle: '発行情報',
    title: '詳細',
    label: {
      tab: {
        controlBook: 'コントロールブック',
        transactions: 'トランザクション',
      },

    },
    token: {
      securitiesAuthorized: '発行可能証券数',
      securitiesOutstanding: '発行済証券数',
      securitiesAvailableToIssue: '未発行証券数',
    },
    holding: {
      title: '発行済証券の形態',
      bookSecurities: 'トレジャリー',
      digitalSecurities: 'トークン',
    },
    table: {
      title: '証券数の更新',
      date: '日付',
      authorizer: '承認者',
      operator: '担当者',
      securitiesAuthorizedUpdate: '発行可能証券数の変動',
      securitiesIssuedUpdate: '発行済証券数の変動',
      updateType: '更新タイプ',
      closedTimestamp: '日付',
      type: 'トランザクションタイプ',
      quantity: '数量',
      sender: '送付者',
      receiver: '受領者',
      ethTransactionId: 'ETHトランザクションID',
    },
    typeSelect: {
      issuance: '発行',
      reallocation: '再配分',
      transferOk: '送金完了',
      transferRejected: '送金拒否',
      reclaim: '再発行',
      destroy: '破棄',
      freeze: '凍結',
      unfreeze: '解凍',
      redemption: 'Redemption', // TODO: translate to japanese
    },
    updateType: {
      issuedAndAuthorized: '発行可能数&発行数更新',
      authorized: '発行可能数更新',
      issued: '発行数更新',
    },
  },
};
