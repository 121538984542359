export default {
  component: {
    createIssuerModal: {
      title: '発行体作成',
      input: {
        issuerName: '発行体名称',
        tokenName: 'トークン名称',
      },
    },
  },
};
