import { predefinedLabelsApi } from '~/utilities/mainApiClient';

const {
  getPredefinedLabels,
} = predefinedLabelsApi;

export default {
  getSystemPredefinedLabels({ commit }) {
    return getPredefinedLabels()
      .then((res) => {
        commit('PREDEFINED_LABELS', res);
      }).catch((err) => {
        this.$log.error('Predefined labels get error:', err);
      });
  },
};
