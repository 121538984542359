import { prerenderedAddressesApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  createPrerenderedAddress,
  deletePrerenderedAddressById,
  getPrerenderedAddresses,
  getPrerenderedAddressById,
  updatePrerenderedAddressById,
} = prerenderedAddressesApi;
const prerenderedAddressArguments = {
  issuerId: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
  q: undefined,
};

export default {
  create({ commit }, { issuerId, address }) {
    return createPrerenderedAddress(issuerId, address).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPrerenderedAddresses.create'), { root: true });
    });
  },
  update({ commit }, { issuerId, address: { id, ...body } }) {
    return updatePrerenderedAddressById(issuerId, id, body).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPrerenderedAddresses.update'), { root: true });
    });
  },
  getById(ctx, { issuerId, id }) {
    return getPrerenderedAddressById(issuerId, id).then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Issuer Pre-rendered address get error:', err);
      });
  },
  delete({ commit }, { issuerId, id }) {
    return deletePrerenderedAddressById(issuerId, id).then(() => {
      commit('global/CALL_TOASTER', i18n.t('store.toaster.issuerPrerenderedAddresses.delete'), { root: true });
    });
  },
  getIssuerPrerenderedAddresses(ctx, { params }) {
    const args = getArgumentsForApi(prerenderedAddressArguments, params);
    return getPrerenderedAddresses(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Issuer Prerendered Addresses get error:', err);
        return err;
      });
  },
};
