export default {
  common: {
    companyInfo: {
      name: 'Securitize',
    },
    blockchain: {
      ethereum: 'Ethereum',
      tezos: 'Tezos',
      algorand: 'Algorand',
    },
    label: {
      all: 'すべて',
      yes: 'はい',
      no: 'いいえ',
      none: 'なし',
      na: 'N\\A',
      active: 'アクティブ',
      inactive: '非アクティブ',
    },
    button: {
      ok: 'OK',
      cancel: 'キャンセル',
      add: '追加',
      reset: 'リセット',
      edit: '編集',
      save: '保存',
      saveChanges: '変更を保存',
      change: '変更',
      send: '送信',
      close: '閉じる',
      delete: '削除',
      activate: '有効化',
      deactivate: '無効化',
      activateAll: 'Activate All', // TODO Add Japanese translation
      deactivateAll: 'Deactivate All', // TODO Add Japanese translation
      confirm: 'Confirm', // TODO Add Japanese translation
      yes: 'Yes', // TODO Add Japanese translation
      no: 'No', // TODO Add Japanese translation
    },
    placeholder: {
      filter: 'フィルタ',
      search: '検索...',
    },
    category: {
      authorizationLevel: {
        basic: 'Basic', // TODO Add Japanese translation
        admin: 'Admin', // TODO Add Japanese translation
        superadmin: 'Super Admin', // TODO Add Japanese translation
        megaadmin: 'Mega Admin', // TODO Add Japanese translation
        brokerdealer: 'Broker Dealer', // TODO Add Japanese translation
      },
      investorType: {
        entity: '法人',
        individual: '個人',
        omnibus: 'オムニバス',
        custodian: 'カストディ',
        fboIndividual: 'FBO Individual',
        fboEntity: 'FBO Entity',
        none: 'None', // TODO Add Japanese translation
      },
      entityType: { // TODO Add Japanese translation
        revocableTrust: 'Revocable Trust',
        irrevocableTrust: 'Irrevocable Trust',
        limitedGeneralPartnership: 'Limited Partnership/General Partnership',
        llc: 'LLC',
        corporation: 'Corporation',
        jointAccount: 'Joint Account',
        other: 'Other',
      },
    },
  },
};
