export default {
  component: {
    issuerBox: {
      title: '発行体作成',
      input: {
        issuerName: '発行体名称',
        tokenName: 'トークン名称',
      },
      issuerLabels: {// TODO: translate to jp
        manageLabels: 'Manage Labels',
      },
      button: {
        yes: 'Yes', // TODO: translate to jp
        no: 'No', // TODO: translate to jp
        issuer: {
          view: '発行体を見る',
        },
      },
      label: {
        fundraise: '資金調達',
        token: 'トークン',
        amountPledged: '申込み額 (合計):',
        amountFunded: '入金額 (合計):',
        registeredInvestors: '登録済み投資家:',
        holders: '保有者 (合計):',
        pendingActions: 'アクション待ち:',
      },
      status: {
        active: 'アクティブ',
        inactive: '非アクティブ',
        issued: '発行済',
        notIssued: '未発行',
      },
    },
    issuerLabelsModal: {// TODO: translate to jp
      title: {
        manage: 'Manage',
        labels: 'Labels',
      },
      notFound: 'No label found',
      loading: 'Loading',
      deleteLabel: {
        title: 'Delete Label',
        message1: 'You are about to delete the label',
        message2: 'If you proceed, it will also be removed from every user who shares this label. Are you sure you want to delete it?',
      },
    },
  },
};
