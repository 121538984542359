export default {
  investorsCommon: {
    title: '投資家',
    button: {
      table: {
        export: 'リストをエクスポート',
        edit: 'ラベルの編集',
        show: 'ラベルを表示',
        exportToolTip: 'You don’t have permission to export', // TODO: translate to jp

      },
      investor: {
        dropdown: '追加',
        addCustodian: 'カストディアンを追加',
        add: '投資家',
        omnibus: '共同ウォレットオーナー',
        import: 'インポート',
        delete: '投資家削除',
        importBulkMsg: '{0} investors are being generated in the background. You will receive an email once done. In case there would be errors you will see them in the email', // TODO: translate to jp

      },
    },
    message: {
      deleteConfirmation: '"{0}"を削除します。本当によろしいでしょうか？',
    },
  },
};
