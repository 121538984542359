export default {
  configurationGeneral: {
    title: '一般',
    message: {
      unsaved: '変更は保存されていません。移動しますか？',
    },
    // Preview modal
    previewModal: {
      title: {
        logoSquare: 'General Properties - Logo Square', // TODO Add Japanese translation
        logoFullName: 'General Properties - Logo Full Name', // TODO Add Japanese translation
        favicon: '基本設定 - ファビコン',
        announcement: '投資家ダッシュボード - タイトル / アナウンス背景画像',
        about: '投資家ダッシュボード - 発行体概要背景画像',
        wizard: '投資家ダッシュボード - ウィザード背景画像',
      },
    },
    // General Properties section
    generalProperties: {
      title: '基本設定',
      label: {
        primaryColor: 'プライマリーカラー',
        secondaryColor: 'セカンダリーカラー',
        actionColor: 'アクションカラー',
        recommendedRatio: '推奨アスペクト比:',
        recommendedRatioValue: '2:1、3:1または4:1',
        recommendedFormat: '推奨フォーマット:',
        recommendedFormatValue: '透過PNG',
        minimumHeight: '最小の高さ:',
        minimumHeightValue: '250px',
        recommendedSize: '推奨サイズ:',
        recommendedSizeValue: '260px * 260px',
        name: 'Name', // TODO Add Japanese translation
        surname: 'Surname', // TODO Add Japanese translation
        dateOfBirth: 'Date Of Birth', // TODO Add Japanese translation
      },
      input: {
        issuerName: {
          text: '発行体名称',
          placeholder: '例）Apple inc.',
        },
        issuerLeiCode: {
          text: 'Issuer LEI Code',
          placeholder: 'e.g. 506700GE1G29325QX363',
        }, // TODO Add Japanese translation
        beneficialOwnerInformation: 'Beneficial Owner Information', // TODO Add Japanese translation
        colorScheme: '配色',
        logoSquare: 'Logo Square', // TODO Add Japanese translation
        logoFullName: 'Logo Full Name', // TODO Add Japanese translation
        favicon: 'ファビコン',
      },
    },
    // Landing Page section
    landingPage: {
      title: 'ランディングページ',
      label: {
        size: 'サイズ:',
        sizeValue: '3000px * 1688px',
        format: 'フォーマット:',
        formatValue: 'JPG',
      },
      button: {
        basicLandingPage: 'ベーシックランディングページ',
        externalLandingPage: '外部ランディングページ',
      },
      input: {
        platformUrl: {
          text: 'プラットフォームURL:',
          placeholder: '例）https://www.securitize.io',
        },
        externalLandingUrl: {
          text: '外部ランディングページURL:',
          placeholder: '例）https://www.securitize.io',
        },
        radio: {
          allowHolders: 'Allow Holders:',
          requireLabel: 'Require Label:',
        },
        restrictLogin: 'Restrict Login',
        enablePage: 'ページ有効化:',
        mode: 'モード:',
        enableLimitedAccess: 'アクセス制限有効化:',
        landingPageUser: 'ユーザー名:',
        landingPagePassword: 'パスワード:',
        backgroundImage: '背景画像',
        headline: '見出し',
        text: 'テキスト',
        loginButtonText: 'ログインボタンテキスト:',
        registrationButtonText: '登録ボタンテキスト:',
      },
      message: {
        basicLandingPageSection: 'このセクションでは{0}でホストされる投資家向けのランディングページの設定ができます。',
        externalLandingPageSection: `このセクションでは自身が管理するWebページをランディングページとして設定し、投資家にアクセスさせることができます。
          下記のパラメータを{0}のイントロダクションに沿って入力することで設定ができます。`,
      },
      option: {
        mode: {
          comingSoon: '近日公開',
          loginOnly: 'ログインのみ',
          loginRegistration: 'ログイン&登録',
        },
      },
    },
    // Investors Area section
    investorsArea: {
      title: '投資家ダッシュボード',
      subTitle: {
        announcement: 'タイトル / アナウンス',
        about: '発行体概要',
        wizard: 'ウィザード',
        socialLinks: 'ソーシャルリンク（任意）',
      },
      label: {
        size: 'サイズ:',
        sizeValue: {
          announcement: '1400px * 400px',
          about: '1400px * 600px',
          wizard: '1440px * 500px',
        },
        format: 'フォーマット:',
        formatValue: 'JPG',
        comment: '備考:',
        commentValue: '画像上部に空白を設けるか、白色へのグラデーションを追加してください。',
      },
      input: {
        backgroundImage: '背景画像',
        title: 'タイトル',
        subtitle: 'サブタイトル',
        text: 'テキスト',
        youtubeVideoUrl: 'Youtube動画URL（任意）',
        wizardBackground: 'ウィザード背景画像',
        facebook: 'Facebook:',
        whatsapp: 'Whatsapp:',
        twitter: 'Twitter:',
        telegram: 'Telegram:',
        medium: 'Medium:',
      },
    },
    // Email Configuration section
    emailConfiguration: {
      title: 'Eメール設定',
      button: {
        defaultEmailAddress: 'デフォルトEメール',
        customEmailAddress: ' カスタムEメール',
      },
      input: {
        customEmailAddress: 'カスタムEメールアドレス:',
      },
      message: {
        receiveCommunication: '投資家は{0}からメールを受け取ります',
        configureYourEmailProvider: {
          text: '投資家へのメール送信にお持ちのメールアドレスを使用するには、{0}の説明に従ってメールプロバイダーを設定する必要があります',
          link: 'こちら',
        },
      },
    },
  },
};
