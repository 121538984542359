import { camelCase } from 'change-case';
import axios from '~/utilities/axios';
// import operatorStore from '~/store/modules/current-operator';

class AccreditationCpApi {
  constructor() {
    this.configuration = {
      apiKey: '',
      basePath: '/api/v2',
    };

    this.authorizationHeader = () => ({});


    this.getAccreditationsList = ({ 'order-field': order, ...rest }, csv = false) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/accreditations?zip-file=true&kyc-status=verified`;
      let localVarHeaderParams;
      let params;
      if (csv) {
        localVarHeaderParams = { ...this.authorizationHeader(), ...{ accept: 'text/csv' } };
      } else {
        localVarHeaderParams = { ...this.authorizationHeader() };
      }
      if (order) {
        params = {
          'order-by': camelCase(order),
          ...rest,
        };
      } else {
        params = {
          ...rest,
        };
      }
      return axios.get(localVarPath, {
        params,
        headers: localVarHeaderParams,
      });
    };

    this.getAccreditationDetail = (accreditationId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/accreditations/${accreditationId}`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.getS3ZipUrl = (accreditationId) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/accreditations/${accreditationId}/zip-file`;
      const localVarHeadersParams = { ...this.authorizationHeader() };
      return axios.get(localVarPath, {
        headers: localVarHeadersParams,
      });
    };

    this.updateAccreditation = (accreditationId, status, rejectReason, note) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/accreditations/${accreditationId}`;
      return axios.patch(localVarPath, { status, rejectReason, note }, {
        headers: this.authorizationHeader(),
      });
    };

    this.getAttorneys = () => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/attorneys`;
      return axios.get(localVarPath, {
        headers: this.authorizationHeader(),
      });
    };

    this.putAttorney = (accreditationId, attorney) => {
      const localVarPath = `${this.configuration.basePath}/broker-dealer/accreditations/${accreditationId}/attorney`;
      return axios.put(localVarPath, attorney, {
        headers: this.authorizationHeader(),
      });
    };
  }
}

export const {
  getAccreditationsList,
  getS3ZipUrl,
  getAccreditationDetail,
  updateAccreditation,
  getAttorneys,
  putAttorney,
} = new AccreditationCpApi();
