export default {
  issuerTexts: {
    title: 'テキスト',
    label: {
      table: {
        name: 'テキスト名',
        type: 'タイプ',
        isHtml: 'HTML',
        active: 'アクティブ',
        value: '値',
      },
    },
    status: {
      system: 'システム',
      content: 'コンテンツ',
    },
    // modal
    affiliateModal: {
      title: 'テキスト編集',
      input: {
        name: 'テキスト名',
        type: 'タイプ',
        isHtml: 'HTML',
        active: 'アクティブ',
        value: '値',
      },
      label: {
        createdAt: '作成日',
        updatedAt: '更新日',
      },
    },
  },
};
