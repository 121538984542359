export default {
  signatures: {
    label: {
      signTransaction: '{0}件のトランザクションに署名',
      signAllTransaction: '全ての保留中トランザクションに署名',
      filter: 'ステータスフィルタ:',
      table: {
        id: 'ID',
        type: 'タイプ',
        owner: 'Owner', // TODO ADD Japanese translation
        description: '説明',
        signaturesRequired: '必要な\n署名数',
        status: 'ステータス',
        date: '日付',
      },
      tokenInitialStateMessage: 'Token is not deployed to the network. Signatures can only be created and displayed once the token is ready', // TODO ADD Japanese translation
    },
    status: {
      type: {
        issueTokens: 'トークン発行',
        issueTokensToTBE: 'IssueTokensToTBE',
        registerWallet: 'ウォレット登録',
        holdTrading: '取引停止',
        releaseTrading: '取引再開',
        lockTokens: 'トークンロック',
        unlockTokens: 'Unlock Tokens', // TODO ADD Japanese translation
        burnTokens: 'トークン焼却',
        burnTBETokens: 'BurnTBETokens', // TODO ADD Japanese translation
        releaseLock: ' ReleaseLock',
        updateComplianceRules: 'UpdateComplianceRules',
        updateRole: 'UpdateRole',
        updateCountryComplianceStatus: 'UpdateCountryComplianceStatus',
        internalTBETransfer: 'InternalTBETransfer',
        transferTBE: 'TransferTBE',
        createAssetAlgorand: 'CreateAssetAlgorand',
        reallocateTokens: 'ReallocateTokens',
        investorLock: 'InvestorLock',
        investorUnlock: 'InvestorUnlock',
        bulkIssueTokens: 'BulkIssueTokens', // TODO ADD Japanese translation
        bulkBurnTokens: 'BulkBurnTokens', // TODO ADD Japanese translation
        bulkRegister: 'BulkRegister', // TODO ADD Japanese translation
      },
      txStatus: {
        pending: '保留中',
        signed: '署名済',
        sent: '送信済',
        success: '成功',
        failure: '失敗',
        mempool: 'mempool',
        canceled: 'canceled',
        expired: 'expired',
      },
    },
    option: {
      txStatus: {
        all: '全て',
        pending: '保留中',
        signed: '署名済',
        sent: '送信済',
        success: '成功',
        failure: '失敗',
        mempool: 'Mempool',
        canceled: 'Canceled', // TODO ADD Japanese translation
        expired: 'Expired', // TODO ADD Japanese translation
      },
      menu: {
        sign: '署名',
        view: '見る',
        remove: '削除',
        speedUp: 'Speed Up',
        clearSignatures: 'Remove signature',
        cancelTransaction: 'Cancel transaction',
      },
    },
    field: {
      id: 'ID: ',
      type: 'タイプ: ',
      owner: 'Owner: ',
      description: '説明: ',
      status: 'ステータス: ',
      date: '作成日: ',
      transaction: 'トランザクション: ',
      tx: 'Tx: ',
      signatureCount: 'signatureCount',
    },
    // modals
    speedUpTransactionModal: {
      title: 'Speed Up Transaction(s)',
      button: {
        sign: {
          now: 'Sign now',
          ledgerDevice: 'Sign with a ledger device',
        },
      },
      input: {
        currentGasPrice: {
          text: 'Current Price:',
        },
        newGasPrice: {
          text: 'New Price:',
        },
        gwei: 'GWEI',
      },
      message: {
        signTransactionsIds: 'You are about to Speed Up transactions ids: {0}',
        newGasPrice: 'New gas price cannot be lower than current price',
        gasPriceTooHigh: 'New gas price is too high and it\'s recommended to wait and not to Speed Up transaction',
      },
    },
    cancelTransactionModal: {
      title: 'Cancel Transaction(s)',
      button: {
        sign: {
          now: 'Confirm',
          ledgerDevice: 'Cancel with a ledger device',
        },
      },
      input: {
        currentGasPrice: {
          text: 'Current Price:',
        },
        newGasPrice: {
          text: 'New Price:',
        },
        gwei: 'GWEI',
      },
      message: {
        signTransactionsIds: 'You are about to Cancel transactions ids: {0}',
        newGasPrice: 'New gas price cannot be lower than current price',
        disclaimer: 'Warning: Canceling a transaction is not guaranteed to succeed, and will also depend on specified gas fees and previous transactions status', // TODO ADD Japanese translation
      },
    },
    signTransactionModal: {
      title: 'トランザクションに署名',
      label: {
        signingTransactions: 'トランザクションに署名',
        signing: '{total} 件のうち {signed} 件に署名',
      },
      input: {
        walletAddress: {
          text: 'ウォレットアドレス',
          placeholder: 'あなたのウォレットアドレス...',
        },
        privateKey: {
          text: 'プライベートキー',
          placeholder: 'あなたのプライベートキー...',
        },
      },
      select: {
        signatureType: {
          text: 'Signature Type',
        },
        multiSigWallet: {
          text: 'MutiSig Wallet',
        },
        pendingSignatures: {
          text: 'Pending signatures',
        },
      },
      signatureMethod: {
        text: 'Signature Method',
        method: {
          walletAddressPrivateKey: 'Wallet Address & Private Key',
          usbLedgerDevice: 'USB Ledger Device',
          package: 'Securitize Wallet Integration Manager',
          hsm: 'Hardware Security Module Signature',
          hsmDisabledMessage: 'Ask an administrator to enable an HSM address for you', // TODO ADD Japanese translation
        },
      },
      button: {
        sign: {
          now: '署名する',
          ledgerDevice: 'レッジャーデバイスで署名する',
        },
      },
      message: {
        signTransactionsIds: '以下のトランザクションIDに署名しようとしています: {0}',
        privateKeyCaution: 'この鍵はブラウザ上でのみ利用されます',
        ledgerConnectionError: 'レッジャーデバイスへの接続エラー',
        ledgerInvalidError: '無効なレッジャーデバイスデータ',
        commonError: '何らかの問題が発生したため、再度トライをお願いします',
        multiSigFeatureNotImplementedBulkTransactions: 'Multi-Sig wallet signatures are not supported for Bulk Transactions',
        blockchainError: 'ブロックチェーン関連エラーのため、サポートに連絡をお願いします',
        unauthorizedWalletError: '未確認ウォレットのため、サポートに連絡をお願いします',
        notEnoughBalanceError: '残高が不足しているため、サポートに連絡をお願いします',
        signFailure: 'トランザクション{0}:({1})に署名できないため、プライベートキーを確認して下さい',
        settlementFailed: 'Unable to sign transaction {0} with code {1}: ({2}). Please make sure that the signing wallet has enough funds to execute the transaction.',
        cannotDetectLedgerError: 'レッジャーデバイスを認識できません。正常に接続され、ロックが解除されているかどうかご確認下さい',
        couldNotEstablishConnectionError: 'レッジャーデバイスを認識しましたが、接続を確立できませんでした',
        lastSignatureMessage: 'You are now signing the last transaction',
        hsmMessage: 'You can use HSM enabled addresses to sign transactions without a private key', // TODO ADD Japanese translation
        multipleMultiSigTransactionsSelected: 'Cannot bulk sign with MultiSignature wallets',
        blockchainTypeNotSupportedError: 'Blockchain type {0} is not supported to sign this transaction',
      },
      signatureTypes: {
        singleSignature: 'Single Signature',
        multiSignature: 'Multi Signature',
      },
    },
    viewTransactionModal: {
      title: 'ブロックチェーントランザクション #{0}',
    },
    removeTransaction: {
      title: 'トランザクションの削除',
      message: {
        deleteCofrimation: 'トランザクション {0} を削除しますがよいですか？',
      },
    },
    clearSignatures: {
      title: 'Remove signature',
      message: {
        clearSignaturesMessage: 'Transaction {0} was signed with a Multi-Signature wallet and can only be removed by the owner. This operation will not delete the transaction, it will remove the signatures.',
        clearSignaturesConfirmationMessage: 'Are you sure you want to remove the signatures of the transaction {0}?',
      },
    },
  },
};
