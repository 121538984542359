export default {
  clientConfiguration: {
    title: 'Global Client Configuration',
    button: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
    },
    label: {
      filter: {
        domain: 'Domain',
        name: 'Name',
      },
    },
    confirmModal: {
      title: 'Delete Client Configuration',
      bodyText: 'Deleting a Client Configuration will have an impact on the behavior of the {0} domain. Are you sure you want to delete it?',
    },
    addEditModal: {
      addTitle: 'Add Client Configuration',
      editTitle: 'Edit Client Configuration',
      labels: {
        domain: 'Domain',
        name: 'Name',
        value: 'Value',
      },
    },
  },
};
