<template>
  <div>
    <div class="row mt-1">
      <div class="ml-3 ip-range-title">
        {{ $t('operators.organizationModal.label.ipRange') }}
      </div>
    </div>
    <div class="row">
      <cp-input
        v-model="fromVal"
        :validate="validate"
        :error="errors['fromIp']"
        name="fromIp"
        class="col-md-6"
        :placeholder="$t('operators.organizationModal.label.fromIp')"
        @input="handleInput"
      />
      <cp-input
        v-model="toVal"
        :validate="validate"
        :error="errors['toIp']"
        name="toIp"
        class="col-md-6"
        :placeholder="$t('operators.organizationModal.label.toIp')"
        @input="handleInput"
      />
      <span
        v-if="error"
        class="ml-2 invalid-feedback d-block"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script>
    import CpInput from '~/components/common/standalone-components/inputs/cp-input';

    export default {
        name: 'CpIpRange',
        components: {
            CpInput,
        },
        props: {
            name: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            inputClass: {
                type: [String, Object, Array],
                default: '',
            },
            value: {
                type: Array,
                default: () => ([null, null]),
            },
            validate: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                fromVal: '',
                toVal: '',
                controlledValue: this.value,
                controlledError: '',
                error: '',
            };
        },
        computed: {
            errors() {
                return this.validationErrors.items.reduce(
                    (res, error) => {
                        res[error.field] = error.msg;
                        return res;
                    },
                    {},
                );
            },
        },
        watch: { // clear local data
            controlledValue(val) {
              if (val === null) {
                    this.fromVal = '';
                    this.toVal = '';
                    this.controlledError = '';
                }
            },
            value: {
              immediate: true,
              handler(newVal) {
                if (newVal && newVal.length) {
                  const [fromVal, toVal] = newVal;
                  this.fromVal = fromVal || '';
                  this.toVal = toVal || '';
                }
              },
            },
        },
        methods: {
            handleInput() {
              if (!this.isValidIpRange(this.fromVal, this.toVal)) {
                this.error = 'IP\'s must conform a valid range.';
              } else {
                this.error = null;
              }
              this.$emit('input', [this.fromVal, this.toVal, this.error]);
            },
            isValidIpRange(fromIp, toIp) {
              if ((fromIp && !toIp) || (!fromIp && toIp)) {
                return false;
              }

              const fromIpNumber = this.ipToNumber(fromIp);
              const toIpNumber = this.ipToNumber(toIp);
              if (fromIpNumber > toIpNumber) {
                return false;
              }
              return true;
            },
            ipToNumber(ip) {
              return Number(
                ip.split('.')
                  .map(d => (`000${ d}`).substr(-3))
                  .join(''),
            );
          },
        },
    };
</script>

<style lang="scss" scoped>
  .ip-range-title {
    font-weight: 500;
  }
</style>
