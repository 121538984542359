/* eslint-disable import/prefer-default-export */
import axios from '@/utilities/axios';

class BlockchainGwApi {
    constructor() {
        this.configuration = {
            basePath: '/api/v2/blockchain-gw',
        };

        this.authorizationHeader = () => {
            const localVarHeaderParams = {};
            return localVarHeaderParams;
          };

        this.getStableCoinsByProvider = (providerId) => {
            const path = `/providers/${providerId}/stable-coins`;

            return this.get(path, { headers: this.authorizationHeader() });
        };

        this.fetchDeploymentLogs = deploymentId => this.get(`/deployments/${deploymentId}/deployment-logs`, { headers: this.authorizationHeader() });

        this.getDeployerWallet = provider => this.get(`/providers/${provider}/deployer-wallet`, { headers: this.authorizationHeader() });

        this.getDeploymentGasFee = provider => this.get(`/providers/${provider}/gas-fees`, { headers: this.authorizationHeader() });

        this.getDeploymentGasPrice = provider => this.get(`/providers/${provider}/gas-price`, { headers: this.authorizationHeader() });

        this.get = (path, config = {}) => axios.get(this.configuration.basePath + path, config);
    }
}

export const { getStableCoinsByProvider, fetchDeploymentLogs, getDeployerWallet, getDeploymentGasFee, getDeploymentGasPrice } = new BlockchainGwApi();
