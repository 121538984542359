import Vue from 'vue';// eslint-disable-line import/no-extraneous-dependencies
import { i18n } from '~/i18n';

export default {
  SET_LABELS: (state, labels) => {
    state.labels = labels;
  },
  SET_ISSUER_LABELS: (state, labels) => {
    state.issuerLabels = labels;
  },
  DELETE_LABEL: (state, deletedLabel) => {
    state.labels = state.labels.filter(label => label !== deletedLabel);
  },
  DELETE_ISSUER_LABEL: (state, deletedLabel) => {
    state.issuerLabels = state.issuerLabels.filter(label => label !== deletedLabel);
    Vue.$toast.open({
      message: `"${deletedLabel}" ${ i18n.t('store.toaster.issuerLabel.successfullyDelete')}`,
      type: 'default',
      duration: 5000,
      position: 'bottom',
    });
  },
};
