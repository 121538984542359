export default {
  dtl: {
    title: 'トランザクション',
    label: {
      investorId: '投資家ID:',
      investorFullName: '投資家:',
      investorCountry: '居住国:',
      investorUsState: '居住州:',
      investorWallet: '投資家ウォレット:',
      investorBalanceAfterTransaction: '投資家に関する取引後残高:',
      walletBalanceAfterTransaction: 'ウォレットの取引後残高:',
      walletType: 'ウォレットタイプ:',
      wallet: 'ウォレット:',
      accountType: 'アカウントタイプ:',
    },
    button: {
      transaction: {
        add: 'トランザクションを追加',
        edit: '編集',
        delete: '削除',
        exportList: 'リストをエクスポート',
        exportMonthly: 'リストをエクスポート（月毎）',
      },
    },
    input: {
      txId: 'トランザクションID',
      initialTimestamp: '開始時タイムスタンプ',
      closedTimestamp: '完了時タイムスタンプ',
      blockchainTxId: '{0}トランザクションID',
      type: 'トランザクションタイプ',
      processType: 'プロセスタイプ',
      sender: '送付者',
      senderInvestorId: '送付者 投資家ID',
      receiver: '受領者',
      receiverInvestorId: '受領者 投資家ID',
      accountType: 'アカウントタイプ',
      walletAddress: 'ウォレットアドレス',
      manuallyModified: '手動編集済み',
      operator: '担当者',
      amount: '数量',
      notes: '備考',
      table: {
        txId: 'トランザクション\nID',
        initialTimestamp: '開始時\nタイムスタンプ',
        closedTimestamp: '完了時\nタイムスタンプ',
        blockchainTxId: {
          base: 'トランザクション\nID',
          eth: 'イーサリアム トランザクションID',
          tezos: 'テゾス トランザクションID',
        },
        type: 'トランザクション\nタイプ',
        processType: 'プロセス\nタイプ',
        sender: '送付者',
        receiver: '受領者',
        manuallyModified: '手動編集',
        operator: '担当者',
        amount: '数量',
        notes: '備考',
      },
    },
    option: {
      typeSelect: {
        issuance: '発行',
        reallocation: '再配分',
        transferOk: '送金完了',
        transferRejected: '送金拒否',
        reclaim: '再発行',
        destroy: '破棄',
        freeze: '凍結',
        unfreeze: '解凍',
        redemption: 'Redemption', // TODO: translate to japanese
      },
      processType: {
        empty: 'その他',
        routine: 'ルーチン',
        nonRoutine: '非ルーチン',
        amendment: '修正',
      },
      senderReceiver: {
        investor: '投資家',
        issuer: '発行体',
      },
      wallet: {
        drs: 'トレジャリー',
        onchain: 'ウォレット',
      },
    },
    filter: {
      country: '国',
      type: 'トランザクションタイプ',
      processType: 'プロセスタイプ',
      date: '日付',
    },
    // modals
    exportMonthlyModal: {
      title: '月毎のリストをエクスポート',
      input: {
        selectYear: '年を選択',
        selectMonth: '月を選択',
      },
    },
    transactionAddModal: {
      title: 'トランザクションを追加',
      button: {
        ok: '追加',
      },
    },
    transactionEditModal: {
      button: {
        ok: '保存',
      },
    },
    transactionDeleteModal: {
      title: 'トランザクションを削除',
      message: 'トランザクションID {0} をトランザクションログから削除します。本当に削除しますか？',
    },
  },
};
