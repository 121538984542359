export default {
  investorsFundraise: {
    title: 'Fundraise',
    titleWithRoundName: 'Fundraise Round-{0}',
    label: {
      allRounds: 'All Rounds',
      filter: {
        country: 'Country',
        investorType: 'Individual/Entity',
        investorStatus: 'Investor Status',
        amountPledged: 'Amount Pledged',
        amountFunded: 'Amount funded',
        tokensAssigned: 'Assigned Tokens',
        subscriptionAgreementStatus: 'Subscription agreement status',
      },
      table: {
        investorName: 'Name / Entity',
        investorStatus: 'Investor Status',
        lastAmountPledged: 'Last Pledged',
        pledgeDate: 'Pledge Date',
        lastAmountFunded: 'Last Funded',
        tokensAssigned: 'Assigned Tokens',
        subscriptionAgreementStatus: 'SA Status',
        roundIds: 'Round',
        labels: 'Labels',
      },
    },
    status: {
      investor: {
        ready: 'ready for issuance',
        pendingKyc: 'pending kyc',
        pendingQualification: 'pending qualification',
        pendingAccreditation: 'pending accreditation',
        pendingFunding: 'pending funding',
        pendingInvestorSignature: 'pending investor signature',
        pendingIssuerSignature: 'pending issuer signature',
        pendingFunded: 'pending funded',
        issued: 'issued',
      },
      subscriptionAgreement: {
        none: 'none',
        requested: 'requested',
        sent: 'sent',
        signed: 'signed',
        confirmed: 'confirmed',
        rejected: 'rejected',
        preSigned: 'pre signed',
      },
      tokenStatus: {
        issued: 'issued',
        notIssued: 'not issued',
      },
    },
    loading: 'Loading Investor Data...',
    option: {
      investorStatus: {
        ready: 'Ready',
        inProgress: 'In progress',
      },
      subscriptionAgreement: {
        none: 'None',
        requested: 'Requested',
        sent: 'Sent',
        signed: 'Signed',
        confirmed: 'Confirmed',
        rejected: 'Rejected',
        preSigned: 'Pre Signed',
      },
      tokenStatus: {
        issued: 'Issued',
        notIssued: 'Not-issued',
      },
      operations: {
        issueTokens: 'Issue Tokens',
      },
    },
    bulkActions: {
      issuance: {
        name: 'Issue Tokens',
        confirmModal: {
          title: 'Run Issuance',
          okText: 'Run',
          confirmMessage: 'When running an issuance to the selected {0} investor/s, the assigned tokens in the current round will be issued to the first wallet or to TBE in absence of a wallet.',
          confirmMessageBulk: 'Your issuance will be running in the background. When finished, you will receive an email.',
          errorMessage: 'Please set the round\'s issuance date before running an issuance',
        },
      },
      sendFundingInstructions: {
        name: 'Send Funding Instructions',
        confirmModal: {
          title: 'Action Confirmation',
          okText: 'Confirm',
          confirmMessage: 'Please confirm that you wish to send funding instructions to the custodian for {0} investors, according to their pledge amount in the current active round: {1}.',
          confirmMessageNonFbo: 'We will not send to {0} of the selected investors as they are not assigned to a custodian.',
        },
        summaryModal: {
          message: 'Total of {0} investors were included in the funding instructions sent to the custodian/s.',
        },
      },
      sendDocuSignEmail: {
        name: 'Send DocuSign Email',
        confirmModal: {
          title: 'Action Confirmation',
          okText: 'Confirm',
          cancelText: 'Close',
          confirmMessageAllFbo: 'Please confirm that you wish to trigger a DocuSign signature email to be sent to {0} investors, '
            + 'according to their pledge amount in the current active round: {1}.',
          confirmMessageSomeFbo: 'We will not send to {0} selected investors as they are not assigned to a custodian',
          confirmMessageNonFbo: 'The action cannot be completed as you had selected only non-custodian investors. Please select at least one custodian investor in order to Send {0} Email',
        },
        summaryModal: {
          messageFullSuccess: 'Total of {0} subscription agreements were successfully sent',
          messagePartialSuccess: 'Total of {0} out of {1} subscription agreements were successfully sent. Total of {2} agreements were not sent due to missing pledge amount for the active round',
        },
      },
    },
  },
};
