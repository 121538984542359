import MarketsOverviewRoutesWrapper from '../pages/markets/overview/MarketsOverviewRoutesWrapper';
import sidRouteWrapper from '../pages/securitize-id/securitizeIdRoutesWrapper';

export default [
  {
    path: 'overview',
    name: 'brokerDealerOverview',
    component: () => MarketsOverviewRoutesWrapper(),
    async beforeEnter(to, from, next) {
        return next();
    },
  },
  {
    path: 'investors',
    name: 'brokerDealerInvestors',
    component: () => import('../pages/broker-dealer/investors/index'),
  },
  {
    path: 'investors/:investorId',
    name: 'brokerDealerInvestorDetails',
    component: () => import('../pages/broker-dealer/investors/investor-details'),
  },
  {
    path: 'mfe/investors/:investorId',
    name: 'brokerDealerInvestorDetailsMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'investors/:investorId/legal-signer/:legalSignerId',
    name: 'brokerDealerLegalSigner',
    component: () => import('../pages/broker-dealer/investors/legal-signer'),
  },
  {
    path: 'opportunities',
    name: 'brokerDealerOpportunities',
    component: () => import('../pages/broker-dealer/opportunities/index'),
  },
  {
    path: 'opportunities/:opportunityId',
    name: 'brokerDealerEditOpportunity',
    component: () => import('../pages/broker-dealer/opportunities/opportunity-details'),
  },
  {
    path: 'config',
    name: 'brokerDealerSystemConfig',
    component: () => import('../pages/broker-dealer/system-config/index'),
  },
  {
    path: 'accreditations',
    name: 'brokerDealerAccreditation',
    component: () => import('../pages/broker-dealer/accreditations/index'),
  },
];
