export default {
  twoFactorAuth: {
    title: {
      auth: 'Two-factor Authentication (2FA)',
      set: 'Set Two-factor Authentication (2FA)',
    },
    subTitle: 'Open your two-factor authentication app and insert the 6 digit code that appears for Securitize',
    button: {
      signIn: 'Sign in',
      set: 'Set 2FA',
    },
    login: {
      title: 'Sign in to access your Securitize account',
    },
    input: {
      digists: 'Insert your 6-digit 2FA code for Securitize:',
    },
    message: {
      error: 'Something went wrong! Please contact customer support.',
      wrong: 'Wrong 2fa code.',
      lock: 'This account has been locked. You can sign in again after 10 minututes.',
      expire: 'Session has expired. Please sign in again.',
      lost: 'Have you lost access to the code? {0}',
      contactUs: 'Contact us',
      loginProcessFailed: 'The login process has failed. Please try again',
    },
  },
  home: {
    button: {
      signIn: 'Sign in',
      google: 'SIGN IN WITH GOOGLE',
    },
    input: {
      email: 'Email',
      password: 'Password',
    },
    message: {
      error: 'There was a problem signing in. Please try again or contact',
      wrongEmailOrPassword: 'Wrong email or password',
      lock: 'This account has been locked. You can sign in again after 10 minututes.',
      noAccount: 'You don\'t have an account yet?',
      invalidRequest: {
        title: 'INVALID REQUEST',
        body: '400: invalid_request Permission denied to generate login',
      },
    },
    link: {
      register: 'Register now!',
    },
  },
};
