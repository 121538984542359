export default {
    signatures: {
        label: {
            signTransaction: 'Sign {0} transaction',
            signAllTransaction: 'Sign all pending transaction',
            filter: 'Filter by status:',
            table: {
                id: 'ID',
                type: 'Type',
                owner: 'Owner',
                description: 'Description',
                signaturesRequired: 'Signature Count',
                status: 'Status',
                date: 'Date',
            },
            tokenInitialStateMessage: 'Token is not deployed to the network. Signatures can only be created and displayed once the token is ready',
        },
        status: {
            type: {
                issueTokens: 'IssueTokens',
                issueTokensToTBE: 'IssueTokensToTBE',
                registerWallet: 'RegisterWallet',
                holdTrading: 'HoldTrading',
                releaseTrading: 'ReleaseTrading',
                lockTokens: 'LockTokens',
                unlockTokens: 'UnlockTokens',
                burnTokens: 'BurnTokens',
                burnTBETokens: 'BurnTBETokens',
                releaseLock: 'ReleaseLock',
                updateComplianceRules: 'UpdateComplianceRules',
                updateRole: 'UpdateRole',
                updateCountryComplianceStatus: 'UpdateCountryComplianceStatus',
                internalTBETransfer: 'InternalTBETransfer',
                transferTBE: 'TransferTBE',
                createAssetAlgorand: 'CreateAssetAlgorand',
                reallocateTokens: 'ReallocateTokens',
                investorLock: 'InvestorLock',
                investorUnlock: 'InvestorUnlock',
                bulkIssueTokens: 'BulkIssueTokens',
                bulkBurnTokens: 'BulkBurnTokens',
                bulkRegister: 'BulkRegister',
            },
            txStatus: {
                pending: 'pending',
                signed: 'signed',
                sent: 'sent',
                success: 'success',
                failure: 'failure',
                mempool: 'mempool',
                canceled: 'canceled',
                expired: 'expired',
            },
        },
        option: {
            txStatus: {
                all: 'All',
                pending: 'Pending',
                signed: 'Signed',
                sent: 'Sent',
                success: 'Success',
                failure: 'Failure',
                mempool: 'Mempool',
                canceled: 'Canceled',
                expired: 'Expired',
            },
            menu: {
                sign: 'Sign',
                view: 'View',
                remove: 'Remove',
                clearSignatures: 'Remove signature',
                speedUp: 'Speed Up',
                cancelTransaction: 'Cancel transaction',
            },
        },
        field: {
            id: 'ID: ',
            owner: 'Owner: ',
            type: 'Type: ',
            description: 'Description: ',
            status: 'Status: ',
            date: 'Created: ',
            transaction: 'Transaction: ',
            tx: 'Tx: ',
            signatureCount: 'Signature Count',
        },
        // modals
        speedUpTransactionModal: {
            title: 'Speed Up Transaction(s)',
            button: {
                sign: {
                    now: 'Speed Up now',
                    ledgerDevice: 'Speed Up with a ledger device',
                },
            },
            input: {
                currentGasPrice: {
                    text: 'Current Price:',
                },
                newGasPrice: {
                    text: 'New Price:',
                },
                gwei: 'GWEI',
            },
            message: {
                signTransactionsIds: 'You are about to Speed Up transactions ids: {0}',
                newGasPrice: 'New gas price cannot be lower than current price',
                gasPriceTooHigh: 'New gas price is too high and it\'s recommended to wait and not to Speed Up transaction',
            },
        },
        cancelTransactionModal: {
            title: 'Cancel Transaction(s)',
            button: {
                sign: {
                    now: 'Confirm',
                    ledgerDevice: 'Cancel with a ledger device',
                },
            },
            input: {
                currentGasPrice: {
                    text: 'Current Price:',
                },
                newGasPrice: {
                    text: 'New Price:',
                },
                gwei: 'GWEI',
            },
            message: {
                signTransactionsIds: 'You are about to Cancel transactions ids: {0}',
                newGasPrice: 'New gas price cannot be lower than current price',
                disclaimer: 'Warning: Canceling a transaction is not guaranteed to succeed, and will also depend on specified gas fees and previous transactions status',
            },
        },
        signTransactionModal: {
            title: 'Sign Transaction(s)',
            label: {
                signingTransactions: 'Signing Transactions',
                signing: 'Signing {signed} of {total}',
            },
            input: {
                walletAddress: {
                    text: 'Wallet Address',
                    placeholder: 'Your wallet address...',
                },
                privateKey: {
                    text: 'Private Key',
                    placeholder: 'Your private key...',
                },
            },
            select: {
                signatureType: {
                    text: 'Signature Type',
                },
                multiSigWallet: {
                    text: 'MutiSig Wallet',
                },
                pendingSignatures: {
                    text: 'Pending signatures',
                },
            },
            signatureMethod: {
                text: 'Signature Method',
                method: {
                    walletAddressPrivateKey: 'Wallet Address & Private Key',
                    usbLedgerDevice: 'USB Ledger Device',
                    package: 'Securitize Wallet Integration Manager',
                    hsm: 'Hardware Security Module Signature',
                    hsmDisabledMessage: 'Ask an administrator to enable an HSM address for you',
                },
            },
            button: {
                sign: {
                    now: 'Sign now',
                    ledgerDevice: 'Sign with a ledger device',
                },
            },
            message: {
                signTransactionsIds: 'You are about to sign transactions ids: {0}',
                privateKeyCaution: 'This key will not leave your local browser.',
                ledgerConnectionError: 'Error connecting with Ledger.',
                ledgerInvalidError: 'Invalid Ledger data',
                commonError: 'Something went wrong. Please try again',
                multiSigFeatureNotImplementedBulkTransactions: 'Multi-Sig wallet signatures are not supported for Bulk Transactions',
                blockchainError: 'Blockchain error. Please contact support.',
                unauthorizedWalletError: 'Unauthorized wallet. Please contact support.',
                notEnoughBalanceError: 'Not enough balance. Please contact support.',
                signFailure: 'Unable to sign transaction {0}: ({1}). Please check your private key!',
                settlementFailed: 'Unable to sign transaction {0} with code {1}: ({2}). Please make sure that the signing wallet has enough funds to execute the transaction.',
                cannotDetectLedgerError: 'Cannot detect a Ledger device. If it is connected, make sure it is unlocked.',
                couldNotEstablishConnectionError: 'Ledger device was detected but could not establish connection.',
                lastSignatureMessage: 'You are now signing the last transaction',
                hsmMessage: 'You can use HSM enabled addresses to sign transactions without a private key',
                multipleMultiSigTransactionsSelected: 'Cannot bulk sign with MultiSignature wallets',
                blockchainTypeNotSupportedError: 'Blockchain type {0} is not supported to sign this transaction',
            },
            signatureTypes: {
                singleSignature: 'Single Signature',
                multiSignature: 'Multi Signature',
            },
        },
        viewTransactionModal: {
            title: 'Blockchain Transaction #{0}',
        },
        removeTransaction: {
            title: 'Remove transaction',
            message: {
                deleteCofrimation: 'Are you sure you want to delete transaction {0}?',
            },
        },
        clearSignatures: {
            title: 'Remove signature',
            message: {
                clearSignaturesMessage: 'Transaction {0} was signed with a Multi-Signature wallet and can only be removed by the owner. This operation will not delete the transaction, it will remove the signatures.',
                clearSignaturesConfirmationMessage: 'Are you sure you want to remove the signatures of the transaction {0}?',
            },
        },
    },
};
