import { getWalletConnectConfig } from '~/utilities/wallet-connect';

export default {
    getWalletConnectProjectId() {
        return getWalletConnectConfig().then(({ data }) => data.projectId).catch((err) => {
            this.$log.error('Error getting WalletConnect project ID', err);
            throw err;
        });
    },
};
