export default {
  issueDetails: {
    loading: 'Loading Issue Details Data...',
    prependTitle: 'Issue',
    title: 'Detail',
    label: {
      tab: {
        controlBook: 'Control Book',
        transactions: 'Transactions',
      },

    },
    token: {
      securitiesAuthorized: 'Securities Authorized',
      securitiesOutstanding: 'Securities Outstanding',
      securitiesAvailableToIssue: 'Securities Available to Issue',
    },
    holding: {
      title: 'Holding Type',
      bookSecurities: 'Book Securities',
      digitalSecurities: 'Digital Securities',
    },
    table: {
      title: 'Control Book Updates and Issuances',
      date: 'Date',
      authorizer: 'Authorizer',
      operator: 'Operator',
      securitiesAuthorizedUpdate: 'Authorized Securities Update',
      securitiesIssuedUpdate: 'Issued Securities Update',
      updateType: 'Update Type',
      closedTimestamp: 'Date',
      type: 'Transaction Type',
      quantity: 'Quantity',
      sender: 'Sender',
      receiver: 'Receiver',
      ethTransactionId: 'ETH Transaction ID',
    },
    typeSelect: {
      issuance: 'Issuance',
      reallocation: 'Reallocation',
      transferOk: 'Transfer ok',
      transferRejected: 'Transfer rejected',
      reclaim: 'Reclaim',
      destroy: 'Destroy',
      freeze: 'Freeze',
      unfreeze: 'Unfreeze',
      redemption: 'Redemption',
    },
    updateType: {
      issuedAndAuthorized: 'Issued & Authorized',
      authorized: 'Securities Authorized',
      issued: 'Securities Issued',
    },
  },
};
