import EthereumConstants, { AlgorandConstants } from './constants';

export default {
  getComplianceConfiguration: ({ deploymentToken }) => ({
    complianceRules: deploymentToken.complianceRules || {},
    countriesComplianceStatuses:
        deploymentToken.countriesComplianceStatuses || '',
  } || { complianceRules: {}, countriesComplianceStatuses: '' }),
  getTransactions: ({ deploymentTransactions }) => ({
    data: deploymentTransactions.data,
    totalItems: deploymentTransactions.totalItems,
  } || { data: [], totalItems: 1 }),
  getDeploymentLockStatus: ({ deploymentTransactions }) => ({
    isLocked: deploymentTransactions.isLocked,
  }),
  getTokenDescription: ({ deploymentToken }) => ({
    decimals: deploymentToken.decimals,
    symbol: deploymentToken.symbol,
    name: deploymentToken.name,
    address: deploymentToken.tokenAddress,
    status: deploymentToken.status,
    provider: deploymentToken.provider,
    id: deploymentToken.id,
    url: deploymentToken.url,
    metadataHash: deploymentToken.metadataHash,
    totalSupply: deploymentToken.totalSupply,
  }),
  getOwners: ({ deploymentToken }) => (deploymentToken.owners
    ? {
      omnibusTBEAddress: deploymentToken.owners.omnibusTBEAddress,
      redemptionAddress: deploymentToken.owners.redemptionAddress,
      masterWalletAddress: deploymentToken.owners.tokenOwner,
      registrarOwnerAddress: deploymentToken.owners.walletRegistrarOwner,
      registrarOwnerPk: deploymentToken.owners.walletRegistrarPrivateKey,
      registrarOwnerBalance: deploymentToken.provider === 'algorand' ? (
          deploymentToken.owners.walletRegistrarOwnerBalance
          / AlgorandConstants.algo
      ).toFixed(AlgorandConstants.decimalPlaces) : (
        deploymentToken.owners.walletRegistrarOwnerBalance
            / EthereumConstants.wei
      ).toFixed(EthereumConstants.decimalPlaces),
    }
    : {}),
  getRoles: ({ deploymentToken }) => deploymentToken.roles || [],
  getMultiSigWallets: ({ deploymentMultiSig }) => deploymentMultiSig || [],
  getAvailableNetworks: ({ deploymentAvailableNetworks }) => deploymentAvailableNetworks || [],
  getSwapContractModel: state => state.swapContract,
  tokenDeployed: ({ deploymentToken }) => !deploymentToken.status || deploymentToken.status !== 'initial',
  hasOwners: ({ deploymentToken }) => deploymentToken.owners !== undefined
    && Object.keys(deploymentToken.owners).length > 0,
  hasRoles: ({ deploymentToken }) => deploymentToken.roles !== undefined && deploymentToken.roles.length > 0,
  hasCompliance: state => () => (state.deploymentToken.provider && state.deploymentToken.provider.includes('algorand'))
  || (state.deploymentToken.complianceRules !== undefined && Object.keys(state.deploymentToken.complianceRules).length > 0),
  // We transform this specific getter into a function to avoid caching the result (known to have caused bugs)
};
