<template>
  <div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4">
    <div
      class="ui-bg-overlay bg-dark ui-bg-cover login-bg"
      :style="{'background-image':`url(${getImagePath('login-background.jpg','theme')}`}"
    />
    <div class="authentication-inner py-5">
      <b-card
        no-body
      >
        <div class="p-4 p-sm-5">
          <!-- Logo -->
          <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
            <div class="ui-w-60">
              <div class="w-100 position-relative">
                <img
                  class="d-block w-100"
                  :src="getImagePath('securitize.png', 'logo')"
                  alt="Securitize"
                >
              </div>
            </div>
          </div>
          <div v-if="!errorView">
            <cp-input-container ref="cpInputContainer">
              <p class="font-weight-light text-center">
                {{ $t('twoFactorAuth.login.title') }}
              </p>
              <div
                class="form-group"
              >
                <cp-input
                  ref="emailInput"
                  v-model.trim="email"
                  name="email"
                  :label="$t('home.input.email')"
                  input-type="email"
                  placeholder="e.g. jamie@gmail.com"
                  validate="required|email"
                />
              </div>
              <div class="form-group pb-4">
                <cp-input
                  id="issuer-name"
                  v-model.trim="password"
                  name="password"
                  :label="$t('home.input.password')"
                  input-type="password"
                  placeholder="password"
                  validate="required"
                />
              </div>

              <button
                type="button"
                class="btn btn-outline-primary btn-block mb-3"
                :disabled="isLoading || googleIsLoading"
                @click="singInWithPassword"
              >
                <span v-if="!isLoading && !googleIsLoading">{{ $t('home.button.signIn') }}</span>
                <b-spinner
                  v-if="isLoading || googleIsLoading"
                  class="align-middle"
                  small
                />
              </button>
              <div
                id="google-button"
                class="d-flex justify-content-center"
              />
              <vue-recaptcha
                v-if="sitekey"
                ref="invisibleRecaptcha"
                size="invisible"
                :sitekey="sitekey"
                :load-recaptcha-script="true"
                @verify="onVerifyRecaptcha"
              />
            </cp-input-container>
            <div
              v-if="loginError"
              class="alert alert-danger mt-3 mb-0"
            >
              <small>
                {{ errorMessage }}
                <a
                  v-if="errorMessage"
                  href="mailto:issuersupport@securitize.io"
                >
                  issuersupport@securitize.io
                </a>
              </small>
            </div>
          </div>
          <div v-if="errorView">
            <h2>
              {{ $t('home.message.invalidRequest.title') }}
            </h2>
            <p class="line-separator" />
            <p> {{ $t('home.message.invalidRequest.body') }}</p>
          </div>
        </div>
      </b-card>
    </div>
    <twofa-modal
      ref="twofaModal"
      :is-button-active="twofaIsButtonActive"
      :twofa-token="twofaToken"
      @verifyTwofa="verifyTwofaAndLogin"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import Cookies from 'js-cookie';
import twofaModal from '~/components/common/twofa-modal';
import staticPathGenerationMixin from '@/mixins/static-path-generation';
import { CpInput } from '@/components/common/standalone-components/inputs';
import cpInputContainer from '@/components/common/cpInputContainer';

export default {
  metaInfo: {
    title: 'Authorize',
    name: 'authorizeFrom',
  },
  components: {
    twofaModal,
    VueRecaptcha,
    CpInput,
    cpInputContainer,
  },
  mixins: [
    staticPathGenerationMixin,
  ],
  data() {
    return {
      email: '',
      password: '',
      loginError: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      isRecaptchaEnabled: process.env.VUE_APP_RECAPTCHA_ENABLED === 'true',
      isLoading: false,
      redirectUrl: this.$route.query.redirectUrl || '',
      clientId: this.$route.query.clientId || '',
      idToken: this.getIdTokenFromHash(),
      twofaToken: '',
      twofaIsButtonActive: false,
      errorView: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('authorize', ['tokensAuthentication', 'googleIsLoading']),
    ...mapGetters('authorize', ['getTokensAuthentication']),
  },
  watch: {
  // eslint-disable-next-line func-names
  'tokensAuthentication.accessToken': function () {
      return this.redirectToPage(this.getTokensAuthentication);
    },
  },
  created() {
    const { clientId, redirectUrl } = this.$route.query;
    const authToken = Cookies.get('auth_token');

    Cookies.set('clientId', clientId);
    Cookies.set('redirectUrl', redirectUrl);

    if (authToken) {
      this.validateCpBearer({ clientId, redirectUrl, authToken }).then((token) => {
        this.redirectToPage(token);
      }).catch((error) => {
        this.handleErrors(error.response.data.message);
      });
    }

    this.validateClientConfig({ clientId, redirectUrl }).then(() => {
      this.clientId = clientId;
      this.redirectUrl = redirectUrl;
    }).catch((error) => {
      this.handleErrors(error.response.data.message, error.response.data.data);
    });
  },
  mounted() {
    this.initGoogleAuthetication();
  },
  methods: {
    ...mapActions('authorize', ['oauthLogin', 'validateClientConfig', 'validateCpBearer', 'initGoogleAuthetication', 'toggleGoogleLoading']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    ...mapActions('currentOperator', ['login', 'googleLogin']),
    ...mapMutations('currentOperator', ['SET_VIEW_DATA']),
    verifyTwofaAndLogin(tfaToken) {
      this.loginError = false;
      this.isLoading = true;
      this.twofaIsButtonActive = false;
      this.oauthLogin({
        email: this.email,
        password: this.password,
        tfaToken,
        clientId: this.clientId,
        redirectUrl: this.redirectUrl,
      }).then((token) => {
        this.redirectToPage(token);
      })
        .catch((error) => {
          if (error.response.data.statusCode === 403) {
            this.twofaToken = '';
            this.twofaIsButtonActive = true;
          } else {
            this.isLoading = false;
            this.handleErrors(error.response.data.message);
          }
          return error;
        });
    },
    singInWithPassword() {
      if (this.sitekey) {
        this.$refs.cpInputContainer.validateForm().then(() => {
          if (this.$refs.cpInputContainer.isValid()) {
            this.$refs.invisibleRecaptcha.execute();
          }
        });
      } else {
        this.onVerifyRecaptcha();
      }
    },
    onVerifyRecaptcha(recaptchaToken) {
      this.isLoading = true;
      this.loginError = false;
      this.oauthLogin({
        email: this.email,
        password: this.password,
        recaptchaToken,
        clientId: this.clientId,
        redirectUrl: this.redirectUrl,
      }).then((token) => {
        this.redirectToPage(token);
        return token;
      })
        .catch((error) => {
          if (this.isRecaptchaEnabled) {
            this.$refs.invisibleRecaptcha.reset();
          }
          this.handleErrors(error.response.data.message);
          return error;
        });
    },
    handleErrors(errorMessage, errorData = undefined) {
      switch (errorMessage) {
        case 'INVALID_REQUEST':
          this.errorView = true;
          break;
        case 'UNAUTHORIZED':
          this.CALL_ERROR_TOASTER(this.$t('twoFactorAuth.message.loginProcessFailed'));
          this.isLoading = false;
          break;
        case 'FORBIDDEN':
          this.isLoading = false;
          this.$refs.twofaModal.show();
          break;
        default:
          this.handleValidationError(errorData);
      }
    },
    handleValidationError(errorData) {
      if (!Array.isArray(errorData)) {
        this.loginError = true;
        this.isLoading = false;
        this.errorMessage = this.$t('home.message.error');
        return;
      }
      if (errorData.some(({ property }) => (property === 'clientId'))) this.errorView = true;
    },
    redirectToPage(token) {
      const { refreshTokenExpiredAt, accessTokenExpiredAt, refreshToken, accessToken } = token;
      const url = `${decodeURIComponent(this.redirectUrl)}?accessToken=${accessToken}&refreshToken=${refreshToken}&refreshExpire=${refreshTokenExpiredAt}&accessExpire=${accessTokenExpiredAt}`;
      window.location.href = url;
    },
    getIdTokenFromHash() {
      const { hash } = this.$route;
      const regex = new RegExp('id_token=(.+?)&', 'gm');
      const hashArr = regex.exec(hash);
      if (hashArr) return hashArr[1];
      return '';
    },
  },
};
</script>

<style src="~/vendor/styles/pages/authentication.scss" lang="scss"></style>
<style lang="scss">
    .btn-google.blue{
        background-color: #3275e4;
        &:disabled {
            background: #04bec4!important;
        }
    }
  .line-separator{
    border-bottom: 1px solid #0a17270f;
  }
</style>
