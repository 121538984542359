export default {
  component: {
    filterBooleanField: {
      label: {
        show: 'Filter by  {0}',
      },
      options: {
        true: 'Has a payout wallet',
        false: 'Does not have payout wallet',
      },
    },
  },
};
