export default {
  dtl: {
    title: 'Securities Transactions',
    label: {
      investorId: 'Investor ID:',
      investorFullName: 'Investor Full Name:',
      investorCountry: 'Investor Country:',
      investorUsState: 'Investor US State:',
      investorWallet: 'Investor wallet:',
      investorBalanceAfterTransaction: 'Investor balance after transaction:',
      walletBalanceAfterTransaction: 'Wallet balance after transaction:',
      walletType: 'Wallet Type:',
      wallet: 'Wallet:',
      accountType: 'Account Type:',
    },
    button: {
      transaction: {
        add: 'Add Manual Transaction',
        edit: 'Edit',
        delete: 'Delete',
        exportList: '  Export list',
        exportMonthly: ' Export monthly list',
      },
    },
    input: {
      txId: 'Transaction ID',
      initialTimestamp: 'Initial Timestamp',
      closedTimestamp: 'Closed Timestamp',
      blockchainTxId: '{0} Transaction ID',
      type: 'Type',
      processType: 'Process type',
      sender: 'Sender',
      senderInvestorId: 'Sender Investor ID',
      receiver: 'Receiver',
      receiverInvestorId: 'Receiver Investor ID',
      accountType: 'Account Type',
      walletAddress: 'Wallet Address',
      manuallyModified: 'Manually modified',
      operator: 'Operator',
      amount: 'Amount',
      notes: 'Notes',
      table: {
        txId: 'Transaction ID',
        initialTimestamp: 'Initial Timestamp',
        closedTimestamp: 'Closed Timestamp',
        blockchainTxId: {
          base: 'transaction ID',
          eth: 'ETH Transaction ID',
          tezos: 'TZ Transaction ID',
        },
        type: 'Type',
        processType: 'Process type',
        sender: 'Sender',
        receiver: 'Receiver',
        manuallyModified: 'Manually modified',
        operator: 'Operator',
        amount: 'Amount',
        notes: 'Notes',
      },
    },
    option: {
      typeSelect: {
        issuance: 'Issuance',
        reallocation: 'Reallocation',
        transferOk: 'Transfer ok',
        transferRejected: 'Transfer rejected',
        reclaim: 'Reclaim',
        destroy: 'Destroy',
        freeze: 'Freeze',
        unfreeze: 'Unfreeze',
        redemption: 'Redemption',
      },
      processType: {
        empty: 'Empty',
        routine: 'Routine',
        nonRoutine: 'Non routine',
        amendment: 'Amendment',
      },
      senderReceiver: {
        investor: 'Investor',
        issuer: 'Issuer',
      },
      wallet: {
        drs: 'TBE',
        onchain: 'Wallet',
      },
    },
    filter: {
      country: 'Country',
      type: 'Type',
      processType: 'Process-Type',
      date: 'Date',
    },
    // modals
    exportMonthlyModal: {
      title: 'Export monthly list',
      input: {
        selectYear: 'Select Year',
        selectMonth: 'Select Month',
      },
    },
    transactionAddModal: {
      title: 'Add Manual Transaction',
      button: {
        ok: 'Add Transaction',
      },
    },
    transactionEditModal: {
      button: {
        ok: 'Save',
      },
    },
    transactionDeleteModal: {
      title: 'Delete Transaction',
      message: 'Are you sure you want to delete Transaction ID {0} from Securities Transaction log?',
    },
  },
};
