export default {
  issuerPrerenderedAddresses: {
    title: '手動アドレス設定',
    button: {
      address: {
        add: 'アドレスの追加',
      },
    },
    label: {
      table: {
        address: 'アドレス',
        addressSignature: 'Address Signature',
        derivativeId: 'Derivative Id',
        currencyId: '通貨',
        createdAt: '作成日',
        updatedAt: '更新日',
      },
    },
    loading: 'データ読み込み中...',
    // modal
    prerenderedAddressModal: {
      title: 'アドレス{0}',
      input: {
        address: 'アドレス',
        derivativeId: 'Derivative ID',
        currencyId: '通貨',
      },
      label: {
        createdAt: '作成日',
        updatedAt: '更新日',
      },
    },
  },
};
