export default {
  twoFactorAuth: {
    title: {
      auth: 'Two-factor Authentication (2FA)',
      set: 'Set Two-factor Authentication (2FA)',
    },
    subTitle: 'Open your two-factor authentication app and insert the 6 digit code that appears for Securitize',
    button: {
      signIn: 'Sign in',
      set: 'Set 2FA',
    },
    input: {
      digists: 'Insert your 6-digit 2FA code for Securitize:',
    },
    message: {
      error: 'Something went wrong! Please contact customer support.',
      wrong: 'Wrong 2fa code.',
      lock: 'This account has been locked. You can sign in again after 10 minututes.',
      expire: 'Session has expired. Please sign in again.',
      lost: 'Have you lost access to the code? {0}',
      contactUs: 'Contact us',
    },
    label: {
      googleAuthDetail: 'E.g. Google Authenticator app on the Appstore or Google Play',
      authyDetail: 'E.g. Authy app on the Appstore or Google Play',
      step1: {
        title: 'Step 1. ',
        text: 'Download a two factor authentication app',
      },
      step2: {
        title: 'Step 2. ',
        text: 'Open the app and scan the QR code',
      },
    },
  },
};
