export default {
  controlBook: {
    title: 'Control Book',
    label: {
      me: 'me',
      table: {
        createdAt: 'Date & time',
        authorizer: 'Authorizer',
        operatorName: 'Operator',
        previousAuthorizedSecurities: 'Prv. Auth. Securities',
        authorizedSecurities: 'New Auth. Securities',
        authorizedSecuritiesDebt: 'New Principal Amount Auth. Securities',
        previousCbSecurities: 'Prv. CB Securities',
        cbSecurities: 'New CB Securities',
        description: 'Description',
        documentationId: 'Documentation ID',
        previousPrincipalAmountAuthorizedSecurities: 'Prv. Principal Amount Auth. Securities',
        principalAmountauthorizedSecurities: ' New Principal Amount Auth. Securities',
        previousCbPrincipalAmountSecurities: 'Prv. Principal Amount Issued Securities (CB)',
        cbPrincipalAmountSecurities: 'New Principal Amount Issued Securities (CB)',
      },
    },
    button: {
      controlBook: {
        update: 'Update Control Book',
      },
    },
    // modals
    controlBookCreateModal: {
      title: 'Update Control Book',
      input: {
        authorizer: 'Authorizer',
        authorizedSecurities: 'New Auth. Securities',
        cbSecurities: 'New Issued Securities (CB)',
        authorizedSecuritiesDebt: 'New Principal Amount Auth. Securities',
        cbSecuritiesDebt: 'New Principal Amount Issued Securities (CB)',
        description: 'Description',
        documentationId: 'Documentation ID',
      },
    },
    controlBookEditModal: {
      title: 'Edit',
      input: {
        createdAt: 'Date & time',
        authorizer: 'Authorizer',
        operatorName: 'Operator',
        previousAuthorizedSecurities: 'Previous Auth. Securities',
        authorizedSecurities: 'New Auth. Securities',
        previousCbSecurities: 'Previous Issued Securities (CB)',
        cbSecurities: 'New Issued Securities (CB)',
        authorizedSecuritiesDebt: 'New Principal Amount Auth. Securities',
        cbSecuritiesDebt: 'New Principal Amount Issued Securities (CB)',
        description: 'Description',
        documentationId: 'Documentation ID',
      },
    },
  },
};
