export default [
  {
    path: 'countries',
    name: 'systemCountries',
    component: () => import('../pages/system-config/countries'),
  }, {
    path: 'agreements',
    name: 'systemAgreements',
    component: () => import('../pages/system-config/agreements'),
  }, {
    path: 'questions',
    name: 'systemQuestions',
    component: () => import('../pages/system-config/questions'),
  }, {
    path: 'email-templates',
    name: 'systemEmailTemplates',
    component: () => import('../pages/system-config/email-templates'),
  }, {
    path: 'system-texts',
    name: 'systemTexts',
    component: () => import(/* webpackChunkName: "about" */ '../pages/system-config/system-texts'),
  }, {
    path: 'client-configuration',
    name: 'clientConfiguration',
    component: () => import('../pages/system-config/client-configuration'),
  }];
