export default {
  brokerDealer: {
    accreditation: {
      prependTitle: 'Markets',
      title: 'Accreditation',
      filters: {
        accreditationStatus: 'Accreditation Status',
        investorType: 'Investor Type',
        assignees: 'Assignees',
        date: 'Updated Date',
      },
      verificationTab: {
        accreditationStatuses: {
          expired: 'Expired',
          processing: 'Processing',
          verified: 'Verified',
          rejected: 'Rejected',
          'updates-required': 'Updates required',
        },
        investorTypes: {
          individual: 'Individual',
          entity: 'Entity',
        },
      },
      table: {
        labels: {
          name: 'Name',
          investorType: 'Investor Type',
          accreditationMethod: 'Accreditation Method',
          documentOrigin: 'Document Type',
          createdAt: 'Date Submitted',
          status: 'Status',
          view: 'View',
          assignee: 'Assignee',
        },
        data: {
          'equity-owners': 'Equity Owners',
          'net-worth': 'Net Worth',
          income: 'Income',
          'professional-license': 'Professional License',
          'assets-and-investments': 'Assets And Investments',
          personal: 'Personal',
          entity: 'Entity',
          external: 'External',
          individual: 'Individual',
          'verification-letter': 'Verification Letter',
        },
      },
      modal: {
        title: 'Accreditation details',
        investorName: 'Investor Name',
        investorType: 'Investor Type',
        method: 'Method',
        dateSubmitted: 'Date Submitted',
        documentType: 'Document Type',
        individualIncome: 'Individual Income',
        jointIncome: 'Joint Income',
        income: 'Income',
        repType: 'Rep. Type',
        repNumber: 'Rep. Number',
        proLicenseType: 'Pro. License Type',
        CRDNumber: 'CRD Number',
        documents: 'Documents',
        reviewResults: 'Review results',
        status: 'Status',
        rejectReason: 'Message for investor',
        notes: 'Internal note',
        notesPlaceholder: 'Write you comments here...',
        changeReview: 'Submit review',
        downloadInfo: 'Accreditation documents',
        download: 'Download',
        assignee: 'Assignee',
        email: 'Email',
        assignWarning: 'You are not assigned to this applicant so you cannot submit a review',
        reasonWarning: 'Please introduce a message for investor',
      },
    },
    opportunitiesPage: {
      assetContentTitle: 'Asset Content',
      title: 'Opportunities',
      subtitle: 'Create and manage opportunities',
      buttons: {
        createNewOpportunity: 'Create new opportunity',
        viewDetails: 'View details',
        createOpportunity: 'Create opportunity',
        cancel: 'Cancel',
        backToList: 'Back to list',
      },
      published: 'Published',
      draft: 'Draft',
      shareValue: 'Share value',
      typeOfAsset: 'Type of asset',
      modal: {
        title: 'Provide some information about your opportunity',
        buttons: {
          createOpportunity: 'Create opportunity',
          cancel: 'Cancel',
        },
      },
      deleteConfirmModal: {
        title: 'Confirm',
        description: 'Are you sure you want to delete this opportunity?',
        ok: 'Delete',
      },
      opportunityPublished: 'Opportunity published',
      opportunityUnpublished: 'Opportunity unpublished',
      public: 'Public',
      private: 'Private',
      featured: 'Featured',
      typeOfAssetOptions: {
        eg: 'e.g. Equity',
        realState: 'Real estate',
        equity: 'Equity',
        debt: 'Debt',
        other: 'Other',
      },
      publishConfirmModal: {
        description: 'As soon as it is published, your investors will have access to it. You will be able to unpublish it if you want later.',
        ok: 'Publish',
        title: 'Do you want to publish {0}?',
      },
      filters: {
        searchPlaceholder: 'Search by title',
        issuerPlaceholder: 'Issuer',
        typeOfAssetPlaceholder: 'Category',
      },
      sortWarningModal: {
        title: 'Warning',
        description: 'Please clear all filters before trying to change opportunities order',
      },
    },
    investorDetails: {
      verificationTab: {
        identityCheckTitle: 'Identity check from Securitize iD',
        identityCheckInfo: 'This identity verification is done by Securitize iD, so it is not editable',
        ssnCheckResults: 'SSN check results',
        suitabilityTitle: 'US Suitability',
        suitabilityEuTitle: 'EU Suitability',
        suitabilityInfo: 'Current US suitability status:',
        suitabilityEuInfo: 'Current EU suitability status:',
        download: 'Download',
        suitabilityStatuses: {
          none: 'None',
          processing: 'Processing',
          verified: 'Verified',
          notApproved: 'Not approved',
        },
        accountTitle: 'US Broker Dealer Account',
        accountEuTitle: 'EU Broker Dealer Account',
        accountInfo: 'Current status:',
        accountStatuses: {
          none: 'None',
          approved: 'Approved',
          rejected: 'Rejected',
        },
        brokerDealerAmlTitle: 'Broker dealer verification (AML)',
        brokerDealerAmlInfo: 'Current AML status:',
        brokerDealerAmlStatuses: {
          none: 'None',
          processing: 'Processing',
          verified: 'Verified',
          notApproved: 'Not approved',
        },
        accreditationStatusTitle: 'Accreditation status',
        accreditationStatusInfo: 'Current status:',
        accreditationStatuses: {
          none: 'None',
          processing: 'Processing',
          verified: 'Verified',
          rejected: 'Rejected',
          notAccredited: 'Not accredited',
          pending: 'Pending',
          updatesRequired: 'Updates required',
          expired: 'Expired',
        },
        lastAccreditationChange: 'Last accreditation change',
        accreditationExpiryDate: 'Accreditation Expiry Date',
      },
      filters: {
        suitability: 'Suitability',
        accountStatus: 'Account status',
        brokerDealerVerificationStatus: 'B/D Verification',
        accreditationStatus: 'Accreditation',
        verificationStatus: 'Identity checks',
      },
    },
  },
};
