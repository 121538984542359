export default {
  labelsList: ({ labelsList }) => labelsList,
  getInvestorById: ({ list }) => id => list.find(el => el.id === Number.parseInt(id)),
  getDetailGeneral: ({ details }) => details.general,
  getDetailTokens: ({ details }) => details.tokens,
  supportedCurrencies: ({ supportedCurrencies }) => supportedCurrencies || [],
  isDripActive: ({ details }) => details.tokens.isDripActive,
  tokensWalletsSection: ({ details }) => details.tokensWalletsSection,
  getTokenPreferencesIsDrip: ({ details }) => details.tokensWalletsSection.method === 'drip',
  getDetailInvestment: ({ details }) => details.investment,
  getInvestmentForDisplay: ({ details }) => details.investmentForDisplay,
  getDetailKyc: ({ details }) => details.kyc,
  isDetailsEditableBlock: ({ details }) => blockName => details.editableBlock === blockName,
  getDetailsEditableBlock: ({ details }) => details.editableBlock,
  getSorting: ({ sorting }) => type => sorting[type],
  getLegalSignerGeneralInfo: state => state.legalSigner.general,
  subscriptionAgreementStatusLogs: ({ details }) => details.subscriptionAgreementStatusLogs,

};
