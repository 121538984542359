export default {
  issuerPlatformDocuments: {
    title: 'ドキュメント',
    label: {
      table: {
        name: '名前',
        title: 'タイトル',
        url: 'URL',
        type: ' タイプ',
        category: 'カテゴリー',
      },
    },
    button: {
      document: {
        add: 'ドキュメントの追加',
      },
    },
    status: {
      category: {
        basic: 'ベーシック',
        investorOnly: '投資家のみ',
      },
    },
  },
  issuerDocumentModal: {
    title: 'ドキュメント{0}',
    input: {
      name: '名前',
      title: 'タイトル',
      url: 'URL',
      type: ' タイプ',
      category: 'カテゴリー',
      created: '作成日',
      updated: '更新日',
    },
    option: {
      category: {
        basic: 'ベーシック',
        investorOnly: '投資家のみ',
      },
    },
  },
};
