export default {
  affiliatesCampaigns: {
    title: 'Issuer Affiliates Campaigns',
    button: {
      campaign: {
        add: 'Add campaign',
      },
    },
    label: {
      table: {
        name: 'Name',
        externalId: 'External',
        affiliateName: 'Affiliate',
        status: 'Status',
        updatedAt: 'Updated',
        createdAt: 'Created',
      },
    },
    status: {
      planned: 'planned',
      active: 'active',
      finished: 'finished',
    },
    // modal
    affiliatesCampaignsModal: {
      title: '{0} Affiliate Campaign',
      input: {
        name: 'Name',
        externalId: 'External id',
        affiliateId: 'Affiliate id',
        status: 'Status',
      },
      label: {
        createdAt: 'Created',
        updatedAt: 'Updated',
      },
    },
  },
};
