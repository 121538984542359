export default {
  issuerList: {
    title: 'Issuers',
    button: {
      issuer: {
        create: 'Create Issuer',
      },
    },
    searchByName: 'Search by name',
    filterByLabel: 'Filter by label',
    message: {
      error: 'There was an error loading Issuers.',
      noIssuers: 'No Issuers Found',
      loading: 'Loading issuer list, please wait...',
    },
    deleteIssuerModal: {
      title: 'Delete issuer',
      message: 'To validate the issuer removal, please type the issuer name {name} in the below field:',
    },
  },
};
