import { camelCase } from 'change-case';

function kebabToCamel(obj) {
  return Object.entries(obj).reduce((newObj, [key, value]) => ({
    ...newObj,
    [camelCase(key)]: value,
  }), {});
}

export default kebabToCamel;
