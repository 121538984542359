export default {
  configurationFundraiseToken: {
    title: 'Token',
    // general section
    general: {
      title: 'General',
      input: {
        fundraise: 'Activate Fundraise / Publish Opportunity',
        tokenLifecycleManagement: 'Token Lifecycle Management',
      },
    },
    addTokenButtonText: 'Add Token',
    addTokenModal: {
      title: 'Add Token',
      newTokenHeader: 'New Token',
      modalMessage: 'Please note that once a token is added - it cannot be removed',
      input: {
        name: 'Token Name *',
      },
    },
    generalConfirmModal: {
      message: {
        confirm: 'Are you sure you want to save changes?',
      },
    },
    // token details section
    tokenDetails: {
      title: 'Token Details',
      label: {
        tokenIcon: 'Token Icon',
        recommendedSize: 'Recommended size:',
        recommendedSizeValue: '500px * 500px',
        recommendedFormat: 'Recommended Format:',
        recommendedFormatValue: 'transparent PNG',
        tokenName: 'Token Name',
        currentTokenValue: 'Current Token Value',
        isin: 'ISIN',
        saft: 'Child (SAFT) Token',
        tokenRepresentsDebt: 'Token Represents Debt',
        allowDepositWithdraw: 'Allow Deposit / Withdraw',
        investorPays: 'Investor Pays',
        evergreenRound: 'Evergreen Round',
        decimal: 'Token Decimals',
        blockchain: 'Blockchain',
        documents: 'Documents',
        isDripActive: 'Dividend Reinvestment Program',
        isScheduledIssuanceEnabled: 'Scheduled Issuance Enabled',
        isAssetPerformanceActive: 'Enable Asset Performance',
        table: {
          type: 'Type',
          category: 'Category',
          name: 'Name',
          title: 'Title',
        },
      },
      saftInputDisplayChild: 'This token is a child (SAFT) under {0} token',
      saftEmptyDdl: 'There isn’t any available tokens to connect',
      error: {
        tokenRepresentsDebt: 'Set token value to represent debt',
        isin: 'Set a correct format of International Securities ID. Example: US0378331005 or AU0000XVGZA3',
      },
      status: {
        category: {
          basic: 'Basic',
          investorOnly: 'Investor Only',
        },
      },
      button: {
        document: {
          download: 'Download',
          add: 'Add document',
        },
        manageToken: 'Manage Token',
      },
      message: {
        tokenNameValidation: 'Token Name is required field',
      },
      tokenStatus: 'Token Status',
      tokenDeploymentStatus: {
        initial: 'None',
        pending: 'Pending',
        inProgress: 'In Progress',
        success: 'Deployed',
        failure: 'Failed',
      },
      confirmModal: {
        title: 'Change Token decimals',
        attention: 'Attention - ',
        message: 'changing the Token Decimal will have a direct impact on the value of currently issued bonus tokens, TBE (treasury), Assigned Token and the Control Book. \n'
          + 'Are you sure you wish to change the value from {0} to {1}?',
      },
      confirmModalEverGreen: {
        title: 'Evergreen Round',
        attention: 'Attention - ',
        message: 'You had enabled an evergreen round, please set round details in Fundraise configuration page',
        okBtnText: 'Go to fundraise',
      },
      historicalTokenValues: {
        title: 'Token Historical Values',
        table: {
          tokenValueDate: 'Token Value Date',
          modificationDate: 'Modification Date',
          tokenValue: 'Token Value',
          addedBy: 'Added By',
          actions: '',
        },
        actions: {
          addTokenValue: 'Add Token Value',
        },
        modal: {
          create: {
            title: 'Add Token Value',
          },
          edit: {
            title: 'Edit Token Value',
          },
          overwrite: {
            title: 'Token Value Exists',
            message: 'For the {0} we already have a value of {1}. Do you want to overwrite it with {2}?',
            confirm: 'Overwrite',
          },
          fields: {
            tokenValue: 'Token Value',
            tokenValueDate: 'Token Value Date',
          },
        },
      },
    },
  },
};
