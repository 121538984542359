export default {
  SET_CONTROL_BOOK_RECORDS(state, data) {
    state.controlBookRecords = data;
  },
  SET_CONTROL_BOOK_RECORDS_TOTAL(state, data) {
    state.controlBookRecordsTotal = data;
  },
  SET_CONTROL_BOOK_INFO(state, data) {
    state.controlBookInfo = data;
  },
  SET_TOKEN_TRANSACTIONS(state, data) {
    state.tokenTransactions = data;
  },
  SET_TOKEN_TRANSACTIONS_TOTAL(state, data) {
    state.tokenTransactionsTotal = data;
  },
};
