export default { // TODO: translate to jp
  configurationJurisdictionsPerToken: {
    pageTitle: 'Asset Configuration',
    title: '対象国・免責事項',
    loading: '対象国・免責事項データ読み込み中...',
    // Per Jurisdiction Disclaimers and Definitions
    perCountryTable: {
      addStateAndRegion: '州または地域の追加',
      title: '国別の免責事項および定義',
      label: {
        table: {
          country: '国名',
          regionState: '州・地域',
          qualificationType: '適格性',
          tokenSpecific: 'Asset Specific',
        },
        filterByTokenSpecific: 'Filter by Asset Specific',
      },
      assetSpecificOptions: {
        yes: 'Yes',
        no: 'No',
      },
      status: {
        qualTypes: {
          standard: '標準',
          specific: '特定',
        },
      },
    },
    // modal
    jurisdictionsEditModal: {
      title: '{0}を編集',
      label: {
        qualification: '適格性について',
        placeholder: 'テキストを入力...',
        additionalAccreditation: 'Additional Accreditation',
        reverseSolicitation: 'Reverse Solicitation',
        allowSkipAccreditation: 'Skip Accreditation', // TODO: translate to jp
      },
      input: {
        initialAgreement: 'Initial Agreement',
        accreditedAgreements: 'Accredited agreements',
        qualification: '適格性が必要ですか？',
        qualificationTitle: '適格性の種別',
      },
      option: {
        qualification: {
          standard: '標準',
          specific: '特定',
        },
        qualificationTitle: {
          accredited: '認定済',
          qualification: '資格有',
          professional: 'プロ',
          other: 'その他',
        },
      },
      message: {
        qualification: '適格性の基準に合致しない投資家は投資することができません。',
      },
    },
    confirmDeleteModal: {
      title: 'Delete Jurisdiction per asset settings',
      message: 'Are you sure you want to delete this Jurisdiction per asset settings?',
    },
  },
};
