import FileSaver from 'file-saver';
import { tokenTransactionsApi, getArgumentsForApi } from '~/utilities/mainApiClient';
import { i18n } from '~/i18n';

const {
  createTokenTransaction,
  deleteTokenTransactionById,
  getTokenTransactions,
  monthlyTransactionsLog,
  updateTokenTransactionById,
} = tokenTransactionsApi;
const tokenTransactionsArguments = {
  issuerId: undefined,
  tokenId: undefined,
  startDate: undefined,
  endDate: undefined,
  q: undefined,
  type: undefined,
  processType: undefined,
  investorCountry: undefined,
  ethTxId: undefined,
  page: undefined,
  limit: undefined,
};
const monthlyTransactionsLogArguments = {
  month: undefined,
  year: undefined,
};
const options = {
  headers: {
    accept: 'text/csv',
  },
};

export default {
  exportMonthlyList(ctx, { issuerId, tokenId, params }) {
    const args = getArgumentsForApi(monthlyTransactionsLogArguments, params);
    return monthlyTransactionsLog(issuerId, tokenId, ...args, options)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, 'Monthly Transaction Log.csv');
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },
  exportList(ctx, { ...params }) {
    const args = getArgumentsForApi(tokenTransactionsArguments, params);
    return getTokenTransactions(...args, options)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, 'Daily Transaction Log.csv');
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },

  setTransactionData({ commit }, { issuerId, tokenId, body, tokenTransactionId }) {
    return updateTokenTransactionById(issuerId, tokenId, tokenTransactionId, body)
      .then((res) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.dailyTransactionLog.setTransactionData'), { root: true });
        return res;
      });
  },
  addManualTransaction({ commit }, { issuerId, tokenId, body }) {
    return createTokenTransaction(issuerId, tokenId, body)
      .then((res) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.dailyTransactionLog.addManualTransaction'), { root: true });
        return res;
      });
  },
  deleteManualTransaction({ commit }, { issuerId, tokenId, txId }) {
    return deleteTokenTransactionById(issuerId, tokenId, txId)
      .then((res) => {
        commit('global/CALL_TOASTER', i18n.t('store.toaster.dailyTransactionLog.deleteManualTransaction'), { root: true });
        return res;
      });
  },
  getIssuerTokenTransactions(ctx, { params }) {
    const args = getArgumentsForApi(tokenTransactionsArguments, params);
    return getTokenTransactions(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Token Transactions get error:', err);
        return err;
      });
  },
};
