<template>
  <div>
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
    </label>
    <b-select
      v-validate.initial="validate"
      :class="[ inputClass, { 'border border-danger': error || controlledError }, injectedClasses] "
      :value="currentValue"
      :options="getList"
      :name="name"
      :multiple="multiple"
      :disabled="disabled"
      :required="required"
      @input="onChangeSelected"
      @focus="isInvalid = false"
    />
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CpSelect',
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    injectedClasses: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    inputClass: {
      type: [String, Object, Array],
      default: 'form-group',
    },
    value: {
      type: [String, Number, Array, Object],
      default: null,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: [String, Object],
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isInvalid: false,
      controlledValue: this.value,
      controlledError: '',
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
    getList() {
      const geTranslatedOptions = () => (this.options.map((item) => {
        if (item.i18nKey) {
          const option = {
            ...item,
            text: this.$t(item.i18nKey),
          };
          delete option.i18nKey;
          return option;
        }
        return item;
      }));
      const list = [...geTranslatedOptions()];
      if (this.placeholder) {
        list.unshift({ value: null, text: this.$t('component.cpSelect.label.defaultText'), disabled: true });
      }
      return list;
    },
  },
  methods: {
    onChangeSelected(val) {
      if (this.required && !val) {
        this.isInvalid = true;
        return;
      }
      const previousValue = this.value;
      if (previousValue !== val) {
        this.$emit('input', val, previousValue);
      } else if (!val) {
        this.$emit('input', val);
      }
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
