export default {
  affiliatesCampaigns: {
    title: 'アフィリエイトキャンペーン',
    button: {
      campaign: {
        add: 'キャンペーンの追加',
      },
    },
    label: {
      table: {
        name: '名前',
        externalId: '外部ID',
        affiliateName: 'アフィリエイター',
        status: 'ステータス',
        updatedAt: '更新日',
        createdAt: '作成日',
      },
    },
    status: {
      planned: '企画済み',
      active: 'アクティブ',
      finished: '終了',
    },
    // modal
    affiliatesCampaignsModal: {
      title: 'アフィリエイトキャンペーン{0}',
      input: {
        name: '名前',
        externalId: '外部ID',
        affiliateId: 'アフィリエイター',
        status: 'ステータス',
      },
      label: {
        createdAt: '作成日',
        updatedAt: '更新日',
      },
    },
  },
};
