import * as _ from 'lodash';
import ComplianceRulesVars from './compliance-rules-variables';

const comparer = newChanges => current => newChanges.filter(change => change.countryName === current.countryName && change.complianceStatus === current.complianceStatus && change.comment === current.comment).length === 0;

export const toTableData = (compliance) => {
  const map = Object.keys(compliance).map(v => ({ variable: v, ...compliance[v] }), {});
  return Object.assign({}, map);
};

export const defaultCountryCompliance = async () => {
  const euCountryCodes = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'IS', 'LI', 'NO', 'CH'];
  const usCountryCodes = ['US'];
  const forbiddenCountryCodes = ['CN', 'IR', 'SY', 'KP', 'CU', 'UA'];
  const defaultCompliance = [];

  euCountryCodes
    .forEach(country => defaultCompliance.push({ countryName: country, complianceStatus: 'eu', comment: '' }));
  usCountryCodes
    .forEach(country => defaultCompliance.push({ countryName: country, complianceStatus: 'us', comment: '' }));
  forbiddenCountryCodes
    .forEach(country => defaultCompliance.push({ countryName: country, complianceStatus: 'forbidden', comment: '' }));
  return defaultCompliance;
};

export const defaultComplianceRules = async () => {
  const defaultCompliance = [];

  ComplianceRulesVars.forEach((rule) => {
    switch (rule) {
      case 'usLockPeriod':
        defaultCompliance.push({ variable: rule, type: 'number', value: 31536000, comment: 'One Year' });
        break;
      case 'euRetailInvestorsLimit':
        defaultCompliance.push({ variable: rule, type: 'number', value: 150, comment: '' });
        break;
      case 'forceAccredited':
      case 'forceFullTransfer':
      case 'worldWideForceFullTransfer':
      case 'forceAccreditedUS':
      case 'disallowBackDating':
        defaultCompliance.push({ variable: rule, type: 'boolean', value: 'false', comment: '' });
        break;
      case 'blockFlowbackEndTime':
        defaultCompliance.push({ variable: rule, type: 'date', value: '', comment: '' });
        break;
      default:
        defaultCompliance.push({ variable: rule, type: 'number', value: 0, comment: '' });
        break;
    }
  });

  return toTableData(defaultCompliance);
};

export const deltaCountryComplianceChanges = (currentCompliance, newCompliance) => newCompliance.filter(comparer(currentCompliance));

export const getRowFields = data => Object.keys(data).map(v => ({ variable: v, ...data[v] }));

export const sortComplianceRules = rules => _.sortBy(getRowFields(rules), row => ComplianceRulesVars.indexOf(row.variable));
