<template>
  <span>
    {{ countryName }}
  </span>
</template>

<script>
import { getCountryName } from '~/utilities/i18n-util';

export default {
  name: 'CpCountryName',
  props: {
    countryCode: {
      type: String,
      default: '',
      required: false,
    },
    locale: {
      type: String,
      default: 'en',
      required: false,
    },
  },
  computed: {
    countryName() {
      if (!this.countryCode) return 'N/A';
      return getCountryName(this.countryCode, this.locale);
    },
  },
  getCountryName(countryCode, locale = 'en') {
    return getCountryName(countryCode, locale);
  },
};
</script>
