export default {
  deploymentToken: {
    status: 'initial',
    complianceRules: {},
    countriesComplianceStatuses: {},
    provider: '',
  },
  deploymentTransactions: { isLocked: false },
  deploymentMultiSig: [],
  deploymentExists: false,
  deploymentAvailableNetworks: [],
  swapContract: {
    custodianAddress: '',
    stableCoin: '',
    swapContractAddress: '',
  },
  submitButtonDisabled: false,
  processingActivate: false,
};
