<template>
  <b-form-group>
    <b-form-checkbox-group
      v-model="controlledValue"
      v-validate.initial="validate"
      :class="{ 'border border-danger': error || controlledError }"
      :options="options"
      :name="name"
      @input="onChangeCheckBox"
    />
    <span
      v-if="error || controlledError"
      class="invalid-feedback d-block"
    >
      {{ error || controlledError }}
    </span>
  </b-form-group>
</template>

<script>
export default {
  name: 'CpCheckboxGroup',
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, String],
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      controlledValue: this.value,
      controlledError: '',
    };
  },
  computed: {
    currentValue() {
      return this.value !== null ? this.value : this.controlledValue;
    },
  },
  methods: {
    onChangeCheckBox() {
      this.$emit('input', this.controlledValue);
    },
    isCpInput() {
      return true;
    },
  },
};
</script>
