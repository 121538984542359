export default {
  msf: {
    title: '保有者名簿',
    label: {
      holdersOfRecord: '保有者',
      beneficialOwners: '受益者',
      table: {
        id: '投資家ID',
        beneficialOwners: '受益者',
        recordHolder: '記録保有者',
        countryResidence: '居住国',
        stateCode: '州コード',
        email: 'Eメール',
        walletAddress: 'ウォレットアドレス',
        numberOfSecurities: '保有証券数',
        principalAmount: '債券合計額（{0}）',
      },
    },
    filter: {
      countryCode: 'カントリーコード',
      investorType: '投資家タイプ',
      fromTotalTokens: '保有証券数',
    },
    button: {
      list: {
        export: 'リストをエクスポート',
      },
    },
  },
};
