export default {
  investorDetails: {
    label: {
      securitizeId: 'Securitize Id',
      lastUpdate: '最終更新: ',
      externalId: 'External ID: ',
      securitizeIdMarkets: 'Securitize Id Markets', // TODO: translate japanese
    },
    button: {
      back: 'リストに戻る',
      invite: '招待を送る',
      reset: 'パスワードリセット',
      disable: '2FA 無効化',
      delete: '削除',
      syncSecuritizeId: 'Securitize ID Sync', // TODO: translate japanese
      securitizeIdUnLink: 'Securitize ID UnLink', // TODO
    },
    tooltip: {
      edit: {
        message: 'Personal Information cannot be changed for this investor. Please contact support',
      },
    },
    option: {
      transactionType: {
        deposit: '預け入れ',
        withdraw: '引き出し',
        liquidation: '精算',
        refund: '払い戻し',
      },
    },
    loading: '投資家データを読込中...',
    // common modals
    addInvestmentModal: {
      title: '投資家を追加',
      label: {
        rounds: 'ラウンド',
        currency: '通貨',
      },
    },
    subscriptionAgreementStatusLogsModal: {
      title: 'Show Signature Log', // TODO: translate japanese
      tableColumns: {
        date: 'Date', // TODO: translate japanese
        signedBy: 'Signed by', // TODO: translate japanese
        signerDetails: 'Signer details', // TODO: translate japanese
        status: 'New status', // TODO: translate japanese
      },
    },

    addNextInvestmentModal: {
      title: 'Add Investment to round: {0}', // TODO: translate japanese
      label: {
        rounds: 'Rounds', // TODO: translate japanese
        currency: 'Currency', // TODO: translate japanese
      },
    },
    removeModal: {
      title: '{0}削除',
      message: 'こちらの{0}を削除してもよいですか？',
    },
    sendInviteModal: {
      title: 'Eメールで招待を送る',
      input: {
        emailContent: {
          placeholder: 'Eメールの招待テキスト',
        },
      },
    },
    disable2faModal: {
      title: '2FA 無効化',
      message: `二段階認証(2FA)は投資家のアカウントをより強固に保護します。\n
        無効化を実行するためには本人確認を先に済ませる必要があります。`,
      button: {
        disable: '無効化',
      },
    },
    confirmSyncSecuritizeIdModal: {
      title: 'Securitize ID Sync', // TODO: translate japanese
      okText: 'Confirm',
      message: 'Are you sure you want to synchronize the personal information and KYC / Accreditation status from Securitize ID to this issuer investor account?',
    },
    confirmForceSyncSecuritizeIdModal: {
      title: 'SYNC Failed', // TODO: translate japanese
      okText: 'Force Sync',
      message: 'You can force the syncing process by clicking on ‘Force Sync’ button',
    },
    confirmUnlinkSecuritizeIdModal: {
      title: 'Securitize ID Un-Link', // TODO: translate japanese
      okText: 'Confirm', // TODO: translate japanese
      message: 'Are you sure you want to un-link the investor from his SecuritizeID account?', // TODO: translate japanese
    },
    // general section
    general: {
      title: '一般情報',
      input: {
        firstName: 'ファーストネーム（名）',
        middleName: 'ミドルネーム',
        lastName: 'ラストネーム（氏）',
        email: 'Eメール',
        fboEmail: 'FBO Eメール:',
        phone: '電話',
        phonePrefix: 'Phone Prefix:',
        birthday: '生年月日',
        gender: '性別',
        investorType: '投資家タイプ',
        entityName: '会社名',
        entityDba: 'Entity DBA',
        entityLeiCode: 'LEI Code', // TODO: translate japanese
        address: '市区町村・町域・番地',
        postalCode: '郵便番号',
        city: '都道府県',
        country: '居住国',
        state: '州',
        taxId: '納税者番号・ID',
        taxId2: '納税者番号・ID 2:',
        taxId3: '納税者番号・ID 3:',
        taxCountryCode: '納税国 (FATCA)',
        taxCountryCode2: '納税国 2 (FATCA):',
        taxCountryCode3: '納税国 3 (FATCA):',
        identityDocumentNumber: '身分証明書番号',
        countryOfBirth: '出生地 - 国',
        cityOfBirth: '出生地 - 都道府県',
        additionalInfo: '建物名など',
        creationDate: '作成日',
        incorporationDate: '設立日:',
        custodian: 'カストディアン',
        jsonTextareaOpen: {
          label: 'Additional info', // TODO add translation to japanese
        },
        jsonTextareaPrivate: {
          label: 'Additional Private Info', // TODO add translation to japanese
        },
      },
      option: {
        gender: {
          female: '女性',
          male: '男性',
        },
      },
    },
    generalPropertiesModal: {
      title: 'KYCレポート - プレビュー',
      button: {
        download: '直近の{0}レポートのダウンロード',
      },
    },
    // legal signers section
    legalSigners: {
      title: 'Legal Signers',
      logoLabel: 'Legal Signers are managed by SecuritizeID',
      addLegalSignerButton: 'Legal Signer',
      confirmDeleteLegalSignerModal: 'Please confirm you would like to delete this legal signer', // TODO: translate to jp
      addLegalSignerModal: {
        title: 'Add Legal Signer',
        okText: 'Add Legal Signer',
        signerType: 'Legal Signer Type',
        ultimateBeneficialOwner: 'Ultimate beneficial owner (25% or more)',
        individual: {
          input: {
            firstName: 'First Name',
            middleName: 'Middle Name',
            lastName: 'Last Name',
            email: 'Email',
            birthDate: 'Birthdate',
            state: 'State',
            country: 'Country',
            city: 'City',
            streetName: 'Street Name',
            streetNumber: 'Street Number',
            postalCode: 'Postal Code',
            taxCountry: 'Tax Country ',
            taxId: 'Tax ID',
          },
        },
        entity: {
          input: {
            name: 'Name',
            email: 'Email',
            dba: 'DBA',
            type: 'Type',
            taxId: 'Tax ID',
            docType: 'Document Type',
          },
        },
      },
    },
    // kyc section
    kyc: {
      title: '{0} & 適格性',
      label: {
        currentRegulationStatus: '{0}ステータス',
        performRegulationCheck: '{0}チェックを実行',
        sendRegulationEmail: '{0} Eメール送信',
        rerunRegulationCheck: '{0}チェックを依頼する',
        viewRegulationReport: '{0}レポートを見る',
        lastRegulationChange: '直近の更新日',
        expirationDate: '期限',
        transactionId: '外部のKYCトランザクションID',
        currentAccreditationStatus: '適格性ステータス',
        qualificationStatusFor: '{0} 投資許可ステータス',
        lastAccreditationChange: '直近の更新日',
        accreditedExpiryDate: '適格性評価有効期限',
        kycProvider: '{0}プロバイダー',
        kycStatusLog: '{0}ステータスログ',
        errors: 'Verification and SSN check results',
      },
      button: {
        sendAccreditationEmail: '適格性評価メール送信',
        fboTooltipText: 'この機能はカストディアンを利用している投資家では使用できません',
      },
      message: {
        documents: '書類(表面は必須)',
        missingField: 'フィールドに入力してください: "{0}"',
        inProgress: '{0} チェックを実行しています。もし時間がかかりすぎる場合は一度ステータスを"無し"にしてやりなおして下さい',
      },
      option: {
        status: {
          none: 'なし',
          processing: '処理中',
          updatesRequired: '要更新',
          manualReview: '個別レビュー',
          verified: '承認済み',
          rejected: '拒否',
          expired: '期限切れ',
        },
        accreditation: {
          no: '非適格',
          noAccepted: '非適格だが投資可能',
          pending: '保留中',
          confirmed: '確認済み',
          rejected: '却下',
          none: 'なし',
          expired: 'Expired',
          updatesRequired: 'Updates Required',
          processing: 'Processing',
          notAccredited: 'Not Accredited',
        },
        tokenQualification: { // TODO: translate to jp
          none: 'None',
          pending: 'Pending',
          confirmed: 'Confirmed',
          rejected: 'Rejected',
          no: 'No',
          noAccepted: 'No Accepted',
          expired: 'Expired',
          updatesRequired: 'Updates Required',
          processing: 'Processing',
          notAccredited: 'Not Accredited',
        },
        kycProvider: {
          internal: '内部',
          'securitize-id': 'SecuritizeID',
          brokerdealer: 'BrokerDealer',
        },
      },
      field: {
        general: {
          gender: '性別',
          birthdate: '生年月日',
          address1: '住所',
          zipCode: '郵便番号',
          city: '市',
        },
      },
    },
    // investment section
    investment: {
      title: '投資',
      subTitle: {
        transactions: 'トランザクション',
        depositAddresses: '入金アドレス',
        investments: 'Investments', // TODO add translate
      },
      label: {
        investment: '投資',
        round: 'ラウンド:',
        status: 'ステータス: ',
        investmentStatus: '投資ステータス:',
        pledgedAmount: '申込額:',
        pledgedDate: '申込日:',
        pledgedInitiator: '申込登録方法:',
        totalFunded: '払込額:',
        subscriptionAgreement: '契約ステータス:',
        subscriptionAgreementSignedAt: '契約署名日:',
        transaction: 'トランザクション',
        token: 'トークン',
        table: {
          transactionTime: '日時',
          tokenName: 'トークン名',
          roundName: 'ラウンド名',
          direction: '処理種別',
          amount: '数量',
          currencyId: '通貨',
          worth: '{0} 換算額',
          source: '登録方法',
          externalTransactionConfirmation: '備考',
          type: 'タイプ',
          address: 'アドレス',
          investorData: '投資家データ',
        },
      },
      status: {
        round: {
          active: '募集中',
          planned: '企画済み',
          done: '終了',
        },
        transactionType: {
          deposit: '預け入れ',
          withdraw: '引き出し',
          liquidation: '精算',
          refund: '払い戻し',
        },
        source: {
          manual: '手動',
          automated: '自動',
        },
      },
      button: {
        investment: {
          toolTip: '投資をするトークンを選択します',
          loading: '投資の作成中',
          add: '投資を追加',
          sendInstructionsEmail: {
            sendInstructions: '指示のメールを送信',
            toolTip: 'カストディアンに指示を送信するための投資を追加してください',
          },
          sendAgreementEmail: {
            sendAgreement: '契約書を送信',
          },
        },
        transaction: {
          add: 'トランザクション追加',
          toolTip: '先に投資を追加してください',
        },
      },
      message: {
        noData: '投資はまだ行われていません',
        selectRound: '投資ラウンドを選択して下さい',
        noRound: '投資ラウンドを選択して下さい',
      },
      option: {
        subscriptionAgreement: {
          none: 'なし',
          requested: 'リクエスト済',
          sent: '送信済',
          signed: '署名済',
          confirmed: '確認済',
          rejected: '拒否',
          preSigned: '署名待ち',
        },
        investmentStatus: {
          none: 'なし',
          inProgress: '進行中',
          pending: '保留中',
          confirmed: '確認済',
          na: '-',
        },
      },
    },
    investmentTableFields: {
      date: 'Date', // TODO add translation to japanese;
      pledge: 'Pledge', // TODO add translation to japanese;
      funded: 'Funded', // TODO add translation to japanese;
      subscriptionAgreement: 'Subscription Agreement', // TODO add translation to japanese;
      subscriptionAgreementSignedAt: 'Signature Date', // TODO add translation to japanese;
      investmentStatus: 'Investment Status', // TODO add translation to japanese;
    },
    addTransactionModal: {
      title: 'トランザクション追加',
      input: {
        date: '日付',
        direction: '処理種別',
        amount: {
          text: '数量',
          placeholder: '例）500',
        },
        currency: '通貨',
        worth: '{0} 換算額',
        txid: '備考 (オプション)',
      },
    },
    editTransactionModal: {
      title: 'トランザクション編集',
      input: {
        txid: '備考',
        direction: '処理種別',
        worth: {
          text: '{0}　換算額',
          placeholder: '例）500',
        },
      },
    },
    editIssuanceModal: {
      title: 'Edit Issuance', // TODO add translation to japanese;
      input: {
        status: 'Status', // TODO add translation to japanese;
        issuanceTime: 'Issuance date', // TODO add translation to japanese;
      },
    },
    editDepositWalletModal: {
      title: '入金アドレス編集',
      input: {
        investorData: {
          text: '投資家データ',
          placeholder: 'テキストエリア',
        },
      },
    },
    // tokens & wallets section
    tokensWallets: {
      title: 'トークンとウォレット',
      subTitle: {
        general: '一般',
        forRound: '対象ラウンド: {0}',
        wallets: 'ウォレット',
        issuances: '発行',
      },
      label: {
        wallet: 'ウォレット',
        issuance: '発行',
        blockchainId: 'ブロックチェーンID',
        totalTokensHeld: '保有トークン合計',
        totalTreasuryTokens: 'トレジャリートークン合計',
        isAnAffiliate: '会社関係者',
        totalBonusTokens: 'ボーナストークン合計',
        totalLockedTokens: 'ロック中トークン合計',
        bonusTokens: 'ボーナストークン',
        assignedBonusTokens: '発行予定ボーナストークン',
        assignedTokens: '発行予定トークン',
        sourceTypeWallet: 'ウォレット',
        sourceTypeTreasuryTBE: 'TBE',
        dripParticipant: 'DRIP Participant', // Todo: translate to jp
        table: {
          wallets: {
            name: 'ラベル',
            address: 'アドレス',
            walletType: 'ウォレット\nタイプ',
            owner: 'オーナー',
            status: 'ステータス',
            tokensPending: '保留中\nトークン',
            tokensHeld: '保有\nトークン',
            tokensLocked: 'ロック中\nトークン',
            creationDate: '作成日',
            tokenName: 'トークン名',
            blockchainType: 'ブロックチェーン',
          },
          issuances: {
            id: 'ID',
            creationDate: '作成日',
            executionDate: '実行日',
            issueAmount: '発行額',
            source: 'ソース',
            target: '発行先',
            status: 'ステータス',
            issuanceTime: '発行日',
            description: '理由',
            tokenName: 'Token:',
          },
        },
      },
      info: {
        aboutTokensRefertTo: '「トークン」はトークン化された証券を指します',
        aboutTbeStandsFor: '「トレジャリー」はトークン化していない発行済証券を意味しています',
        hasActiveIssuance: 'Assigned Tokens are pending signature', // Todo: translate to jp
      },
      status: {
        walletType: {
          normal: 'ノーマル',
          omnibus: 'オムニバス',
        },
        source: {
          manual: '手動',
          automated: '自動',
        },
        walletsList: {
          treasury: 'トレジャリー',
          wallet: 'ウォレット',
        },
      },
      button: {
        bonus: {
          move: 'ボーナス移動',
        },
        wallets: {
          add: 'ウォレット追加',
          transferFromTreasury: 'トレジャリーからウォレットに転送',
        },
        issuance: {
          add: '追加発行',
        },
      },
      option: {
        walletStatuses: {
          syncing: '同期中',
          syncingInvestorPays: 'syncing investor pays', // TODO: translate to jp
          pending: '処理中',
          ready: '準備完了',
          rejected: 'rejected',
          cancelled: 'cancelled', // TODO: translate to jp
        },
        issuanceStatuses: {
          pending: '保留中',
          processing: '処理中',
          success: '成功',
          failure: '失敗',
          amended: 'amended', // TODO add translation to japanese;
        },
      },
      transactionHistory: {
        options: {
          transactionHistoryTypes: {
            issuance: 'Issuance', // Todo: translate to jp
            transferOk: 'Transfer Ok', // Todo: translate to jp
            destroy: 'Destroy', // Todo: translate to jp
            redemption: 'Redemption', // Todo: translate to jp
          },
        },
      },
      autoIssuanceConfirmationModal: {
        title: 'Assign Tokens Auto Issuance', // Todo: translate to jp
        message: 'Note that when changing the assigned tokens for this investor, while auto-issuance is enabled, you will trigger an issuance record pending to signature', // Todo: translate to jp
      },
    },
    moveBonusModal: {
      title: 'ボーナストークン移動',
      message: '発行予定ボーナストークン（{0}）をボーナストークンに移動しますか？',
    },
    addInvestorWalletModal: {
      title: 'ウォレット追加',
      input: {
        name: 'ラベル',
        address: 'アドレス',
      },
      blockchainType: 'ブロックチェーン',
    },
    addIssuanceModal: {
      title: 'トークン発行',
      label: {
        roundIssuanceDate: 'ラウンド発行日:',
        clearAssignedTokens: 'Clear assigned tokens after successful issuance', // TODO add translation to japanese
        costBasisCalculation: 'Cost Basis Calculation', // TODO add translation to japanese
        totalAmountFunded: 'Total Amount Funded', // TODO add translation to japanese
        pricePerUnit: 'Price Per Unit', // TODO add translation to japanese
      },
      input: {
        issueTo: '発行先:',
        reason: {
          text: 'Description:', // TODO: translate to jp
          placeholder: 'Type your description here...', // TODO: translate to jp
        },
        tokenAmount: 'トークン発行数:',
        numberOfTokens: {
          text: '発行トークン数:',
          placeholder: '例）500',
        },
      },
      message: {
        confirm: '{amount}トークンを{name}に対して発行しますがよろしいですか？',
      },
      option: {
        tokenAmount: {
          roundsAssignedTokens: '発行予定トークンと同量',
          customTokenAmount: 'カスタム値を指定',
        },
        walletsList: {
          treasury: 'トレジャリー',
        },
      },
    },
    editTokenTransaction: { // TODO: translate to JP
      title: 'EditTransaction',
      label: {
        roundIssuanceDate: 'Round issuance date:',
        clearAssignedTokens: 'Clear assigned tokens after issuance',
      },
      input: {
        transactionDate: 'Date',
        transactionType: 'Transaction Type',
        tokensAmount: 'Units',
        pricePerUnit: 'Price Per Unit',
        transactionAmount: 'Transaction Amount',
      },
    },
    editInvestorWalletModal: {
      title: 'ウォレット編集',
      input: {
        name: 'ラベル',
        address: 'アドレス',
        status: 'ステータス',
      },
    },
    transferTreasuryModal: {
      loading: 'loading...', // TODO add translation to japanese;
      amountToWithdraw: 'Withdrawable Balance: {0}', // TODO add translation to japanese;
      amountToWithdrawInputLabel: 'Amount to Withdraw', // TODO add translation to japanese;
      title: 'トレジャリーからウォレットに転送',
      input: {
        issueTo: '発行先:',
      },
    },
    // transaction history section
    transactionHistory: { // TODO add translation to japanese;
      title: 'Transaction History',
      label: {
        table: {
          transactionDate: 'Date',
          transactionType: 'Transaction Type',
          units: 'Units',
          price: 'Price Per Unit',
          transactionAmount: 'Transaction Amount',
        },
      },
      option: {
        sell: 'Sell',
        buy: 'Buy',
        redemption: 'Redemption',
        issuance: 'Issuance',
        reallocation: 'Reallocation',
        destroy: 'Destroy',
        p2p: 'P2P',
      },
    },
    // payouts section
    payouts: {
      title: 'Payout History', // TODO add translation to japanese;
      label: {
        table: {
          distributionType: 'Distribution Type', // TODO add translation to japanese;
          confirmationDate: 'Payout Date', // TODO add translation to japanese;
          payoutMethod: 'Payout Method', // TODO add translation to japanese;
          units: 'Units', // TODO add translation to japanese;
          withholding: 'Withholding', // TODO add translation to japanese;
          netAmount: 'Net Amount', // TODO add translation to japanese;
        },
      },
      button: {
        payout: {
          add: '払い出し追加',
        },
      },
      option: {
        statusesList: { // TODO add translation to japanese;
          paid: 'paid',
          notPaid: 'not paid',
          rejected: 'rejected',
        },
        payoutTypeOptions: {
          dividend: '配当',
          buyback: '買い戻し',
          profitSharing: '分配',
          other: 'その他',
        },
      },
    },
    createPayoutModal: {
      title: '払い出し追加',
      input: {
        payoutDate: '払い出し日:',
        payoutAmount: {
          text: '払い出し額:',
          placeholder: '例）500',
        },
        tokensAmount: {
          text: 'トークン量:',
          placeholder: '例）500',
        },
        status: 'ステータス:',
        payoutType: '払い出しタイプ:',
        payoutDescription: {
          text: '払い出しの説明:',
          placeholder: 'こちらに説明を入力して下さい',
        },
      },
    },
    // affiliate section
    affiliate: {
      title: '会社関係者',
      label: {
        table: {
          status: 'ステータス',
          createdAt: '日付',
          comment: 'コメント',
        },
      },
      status: {
        yes: 'yes', // TODO
        no: 'no', // TODO
        pending: 'pending', // TODO
      },
      message: {
        issuerSupportLink: '会社体関係者のステータス変更がありましたら、セキュリタイズ発行サポートにEメールでお知らせください。（米国でSecuritizeが名義書換代理人を担っている場合のみ。）',
        issuerSupportMail: '{0}: 会社関係者変更リクエスト',
        setAffiliatePendingToolTip: 'Investor cannot be set as an affiliate when Pending', // TODO
        setAffiliateYesToolTip: 'Investor is already an affiliate', // TODO
      },
      button: {
        affiliate: {
          set: '会社関係者として登録',
        },
      },
    },
    setAffiliateModal: {
      title: '会社関係者として登録',
      input: {
        date: '日付:',
        comment: {
          text: 'コメント:',
          placeholder: 'テキストエリア',
        },
      },
    },
    // documents section
    documents: {
      title: 'ドキュメント',
      label: {
        document: 'ドキュメント',
        table: {
          image: '画像',
          documentTitle: '名称',
          type: '種類',
          documentFace: '裏表',
          createdAt: '登録日',
          origin: '登録元',
          token: 'トークン',
        },
      },
      status: {
        type: {
          passport: 'パスポート',
          other: 'その他',
          driverLicense: '運転免許証',
          nationalIdentityCard: '国民識別カード',
          residencePermit: '居住許可証',
          visa: 'ビザ',
          passportCard: 'パスポートカード',
          workPermit: '労働許可証',
          stateId: '州ID',
          ukBiometricResidencePermit: 'UK 生体居住許可証',
          taxId: '納税者番号・ID',
          voterId: '投票者番号・ID',
          certificateOfNaturalisation: '帰化証明書',
          homeOfficeLetter: 'Home office letter', // TODO add translation to japanese
          immigrationStatusDocument: '在留資格認定証明書',
          birthCertificate: '出生証明書',
        },
        face: {
          front: '表面',
          back: '裏面',
        },
        origin: {
          internal: 'Internal',
          securitizeId: 'SecuritizeID', // TODO add translation to japanese
        },
      },
      button: {
        document: {
          download: 'ダウンロード',
          add: 'ドキュメント追加',
        },
      },
      message: {
        downloadFailure: 'サーバーからダウンロードに失敗しました',
      },
      option: {
        type: {
          passport: 'パスポート',
          other: 'その他',
          driverLicense: '運転免許証',
          nationalIdentityCard: '国民識別カード',
          residencePermit: '居住許可証',
          visa: 'ビザ',
          passportCard: 'パスポートカード',
          workPermit: '労働許可証',
          stateId: '州ID',
          ukBiometricResidencePermit: 'UK 生体居住許可証',
          taxId: '納税者番号・ID',
          voterId: '投票者番号・ID',
          certificateOfNaturalisation: '帰化証明書',
          homeOfficeLetter: 'Home office letter',
          immigrationStatusDocument: '在留資格認定証明書',
          birthCertificate: '出生証明書',
          selfie: 'セルフィー',
          certificateOfFormation: 'Certificate of formation',
          articlesOfOrganization: 'Articles of organization',
          operatingAgreement: 'Operating Agreement',
          addressProof: 'Proof of address',
          shareholdersListAndAuthorisedSignersList: 'Members list and authorized signer list',
          byLaws: 'By-laws', // TODO add translation to japanese
          cashCard: 'キャッシュカード',
          healthInsuranceCard: '健康保険証',
          driverHistoryCertificate: '運転経歴証明書',
          myNumberCard: 'マイナンバーカード',
          specialPermanentResidentCertificate: '特別永住者証明書',
          residentRecordCard: '在留カード',
        },
        face: {
          front: '表面',
          back: '裏面',
        },
      },
    },
    addDocumentModal: {
      title: 'ドキュメント追加',
      input: {
        category: '種類',
        documentFace: '裏表',
        showToInvestor: '投資家に表示',
        title: '名称',
        token: 'トークン',
      },
      message: {
        noFileFailure: 'ドキュメントをアップロードして下さい',
        fileSizeFailure: 'ファイルサイズは50KBから10MBの間としてください',
      },
    },
    previewDocumentModal: {
      title: 'ドキュメントプレビュー',
      label: {
        imageAlt: 'ドキュメントプレビュー',
      },
    },
    confirmInvestorTypeModal: { // TODO add translation to japanese
      title: 'Change investor type',
      text: 'Note that when changing the investor type, some of the information might be lost after you save your changes',
    },
    confirmKycStatusModal: { // TODO add translation to japanese
      title: 'KYC Ownership',
      text: "Note that when changing a KYC status for a Securitize ID investor you are taking full responsibility for the KYC process. You can click on 'Cancel' to undo your selection.",
    },
    confirmPIIModel: { // TODO add translation to japanese
      title: 'KYC Ownership',
      /* eslint-disable max-len */
      text: "Note that when changing a PII for a Securitize ID investor you are taking full responsibility for the KYC process. Additional Info, Additional Private Info and Comment are excluded. You can click on 'Cancel' to undo your selection.",
    },
    anchors: { // TODO add translation to japanese
      generalInformation: 'General Information',
      kycAccreditation: 'KYC & Accreditation',
      investment: 'Investment',
      tokenWallets: 'Token & Wallets',
      documents: 'Documents',
    },
  },
};
