export default {
  investorsCommon: {
    title: 'Investors',
    button: {
      table: {
        export: 'Export List',
        edit: 'Edit labels',
        show: 'Show Labels',
        exportToolTip: 'You don’t have permission to export',
      },
      investor: {
        dropdown: 'Add',
        addCustodian: 'Add Custodian',
        add: 'Add Investor',
        omnibus: 'Add Omnibus Wallet Owner',
        import: 'Import investors',
        importBulkMsg: '{0} investors are being generated in the background. You will receive an email once done. In case there would be errors you will see them in the email',
        delete: 'Delete investor',
      },
    },
    message: {
      deleteConfirmation: 'Are you sure you want to delete "{0}"?',
    },
  },
};
