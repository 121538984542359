import baseAxios from 'axios';
import axios from '~/utilities/axios';
// import operatorStore from '@/store/modules/current-operator';

const axiosInstance = baseAxios.create({
  withCredentials: true,
});

const basePath = '/api/v2';

const DEFAULT_DEPLOYMENT_ESTIMATED_GAS = '12810301';
const DEFAULT_GAS_PRICE = '100000000000';

export default class DeploymentsManagementService {
  static getAuthorizationHeader = () => {
    const that = this;
    that.configuration = {
      apiKey: '',
      basePath: '/api/v2',
    };

    // const _store = operatorStore;
    // that.configuration.apiKey = _store.state.accessToken;
    const localVarHeaderParams = {};
    return {
      ...localVarHeaderParams,
    };
  }

  static getIssuerDeployments(issuerId) {
    return this.get(`/issuers/${issuerId}/deployments`, { headers: this.getAuthorizationHeader() }).then(res => res.data);
  }

  static getIssuerDeployment(issuerId, deploymentId) {
    return this.get(`/issuers/${issuerId}/deployments/${deploymentId}`, { headers: this.getAuthorizationHeader() }).then(res => res.data);
  }

  static getIssuerDeploymentBasicInfo(issuerId, deploymentId) {
    return this.get(`/issuers/${issuerId}/deployments/${deploymentId}/basic-info`, { headers: this.getAuthorizationHeader() }).then(res => res.data);
  }

  static getIssuerClientConfigurations(issuerId) {
    return this.get(`/issuers/${issuerId}/configurations/client-configs`, { headers: this.getAuthorizationHeader() }).then(res => res.data);
  }

  static addDeploymentForIssuer(issuerId, body = {}) {
    return this.post(`/issuers/${issuerId}/deployments`, body, { headers: this.getAuthorizationHeader() }).then(res => res.data.id);
  }

  static updateDeploymentDescription(deploymentId, body = {}) {
    return this.put(`/deployments/${deploymentId}/description`, body, { headers: this.getAuthorizationHeader() });
  }

  static getComplianceRules(deploymentId) {
    return this.get(`/deployments/${deploymentId}/compliance`, { headers: this.getAuthorizationHeader() });
  }

  static getComplianceCounters(deploymentId) {
    return this.get(`/deployments/${deploymentId}/compliance/counters`, { headers: this.getAuthorizationHeader() });
  }

  static addComplianceConfiguration(deploymentId, body = {}) {
    const updatedBody = {
      complianceRules: body.complianceRules,
      countriesComplianceStatuses: body.countriesComplianceStatuses,
    };

    return this.post(`/deployments/${deploymentId}/compliance`, updatedBody, { headers: this.getAuthorizationHeader() });
  }

  static updateComplianceConfiguration(deploymentId, body) {
    const updatedBody = { complianceRules: body.complianceRules };
    if (body.countriesComplianceStatuses && body.countriesComplianceStatuses.length && body.countriesComplianceStatuses.length > 0) {
      updatedBody.countriesComplianceStatuses = body.countriesComplianceStatuses;
    }

    return this.put(`/deployments/${deploymentId}/compliance`, updatedBody, { headers: this.getAuthorizationHeader() });
  }

  static updateComplianceType(deploymentId, complianceType) {
    return this.put(`/deployments/${deploymentId}/compliance-type`, complianceType, { headers: this.getAuthorizationHeader() });
  }

  static addPermissions(deploymentId, body) {
    const {
      hsmUsed,
      owners: {
        omnibusTBEAddress,
        redemptionAddress,
        masterWalletAddress,
        registrarOwnerAddress,
        registrarOwnerPk,
      },
      roles,
    } = body;

    const walletRegistrar = {
      walletRegistrarOwner: registrarOwnerAddress,
      walletRegistrarPrivateKey: registrarOwnerPk,
    };

    return this.post(`/deployments/${deploymentId}/permissions`, {
      walletRegistrarType: hsmUsed ? 'hsm' : 'issuer',
      owners: {
        omnibusTBEAddress,
        redemptionAddress,
        tokenOwner: masterWalletAddress,
        ...walletRegistrar,
      },
      roles,
    }, { headers: this.getAuthorizationHeader() });
  }

  static updatePermissions(deploymentId, body) {
    return this.put(`/deployments/${deploymentId}/permissions`, {
      roles: body.roles,
    }, { headers: this.getAuthorizationHeader() });
  }

  static deployToken(deploymentId, gasPrice, provider) {
    // Only Ethereum is supported
    return this.post(`/deployments/${deploymentId}/deploy`, {
      provider,
      priority: 'medium',
      gasPrice,
    }, { headers: this.getAuthorizationHeader() });
  }

  static retryDeploy(deploymentId) {
    return this.post(`/deployments/${deploymentId}/retry`, {},
      { headers: this.getAuthorizationHeader() });
  }

  static async getDeploymentGasCostEstimation(networkId, complianceType) {
    const estimation = {
      gasPrice: DEFAULT_GAS_PRICE,
      estimatedDeploymentGas: DEFAULT_DEPLOYMENT_ESTIMATED_GAS,
    };
    try {
      const response = await this.get(`/deployments/deployment-cost-estimation/${networkId}`,
        { headers: this.getAuthorizationHeader() });
      if (response.data) {
        const { deploymentEstimations } = response.data;
        estimation.gasPrice = response.data.gasPrice || DEFAULT_GAS_PRICE;
        estimation.estimatedDeploymentGas = DEFAULT_DEPLOYMENT_ESTIMATED_GAS;
        if (deploymentEstimations && complianceType === 'whitelisted') {
          estimation.estimatedDeploymentGas = deploymentEstimations.complianceServiceWhitelisted;
        }
        if (deploymentEstimations && complianceType === 'partitioned') {
          estimation.estimatedDeploymentGas = deploymentEstimations.complianceServicePartitioned;
        }
        if (deploymentEstimations && complianceType === 'regulated') {
          estimation.estimatedDeploymentGas = deploymentEstimations.complianceServiceRegulated;
        }
        if (deploymentEstimations && complianceType === 'notRegulated') {
          estimation.estimatedDeploymentGas = deploymentEstimations.complianceServiceNotRegulated;
        }
      }
      return estimation;
    } catch (error) {
      return estimation;
    }
  }

  static getTransactions(deploymentId, filters) {
    if (!filters) {
      return this.get(`/deployments/${deploymentId}/transactions`, { headers: this.getAuthorizationHeader() });
    }
    return this.get(`/deployments/${deploymentId}/transactions?status=${filters.transactionsStatus}&type=${filters.transactionsType}`, { headers: this.getAuthorizationHeader() });
  }

  static getWalletRegistrarOwnerBalance(deploymentId, address) {
    return this.get(`/deployments/${deploymentId}/wallet/${address}/balance`, { headers: this.getAuthorizationHeader() });
  }

  static getAvailableNetworks() {
    return this.get('/deployments/available-networks', { headers: this.getAuthorizationHeader() });
  }

  static addMultiSigWallet(deploymentId, wallet) {
    return this.post(`/deployments/${deploymentId}/multisig_wallets`, wallet, { headers: this.getAuthorizationHeader() });
  }

  static updateMultiSigWallet(deploymentId, wallet) {
    return this.put(`/deployments/${deploymentId}/multisig_wallets`,
      wallet,
      { headers: this.getAuthorizationHeader() });
  }

  static getAllMultiSigWallets(deploymentId) {
    return this.get(`/deployments/${deploymentId}/multisig_wallets`, { headers: this.getAuthorizationHeader() });
  }

  static postDeployMultiSigWallets(deploymentId) {
    return this.post(`/deployments/${deploymentId}/multisig_wallets/post-deployment`, {}, { headers: this.getAuthorizationHeader() });
  }

  static get(path, config = {}) {
    return axios.get(basePath + path, config);
  }

  static getTokenFromDeploymentsManagement(path, config = {}) {
    return axiosInstance.get(basePath + path, config);
  }

  static post(path, body = {}, config = {}) {
    return axios.post(basePath + path, body, config);
  }

  static put(path, body = {}, config = {}) {
    return axios.put(basePath + path, body, config);
  }
}
