export default {
  operationalProcedures: {
    title: 'オペレーション',
    input: {
      chooseProcedure: 'オペレーションを選択:',
      sourceWalletId: {
        text: '送信元ウォレットID:',
        placeholder: '例）7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      numberOfSecurities: {
        text: '数量:',
        placeholder: '例）50',
      },
      numberOfLockedTokens: { // TODO: translate
        text: 'Number of Locked Tokens:',
        placeholder: 'e.g. 50',
      },
      investorName: '投資家:',
      destinationWalletId: {
        text: '送信先ウォレットID:',
        placeholder: '例）7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      issuanceDate: '発行日:',
      reason: {
        text: '理由:',
        placeholder: '理由を入力...',
      },
      txId: {
        text: 'トランザクションID:',
        placeholder: {
          tezos: '例）op9TReQgmLUXouucihDwddRtimnaNcXQDNoyAGe6qYQq8JkJSPd',
          ethereum: '例）0x48b33f6c6d825fea0e7c26ade694c1c9b22168cdfe7574fdefece956310e2075',
          algorand: '例）X4HPM7O3CWNBUZ7AI3IDYJYYE4537GFVR5635GFLPLM7IHBL3FMA',
        },
      },
      documentId: {
        text: 'ドキュメントID:',
        placeholder: 'ドキュメントIDを入力...',
      },
      walletId: {
        text: 'ウォレットID:',
        placeholder: '例）7a6ffed9-4252-427e-af7d-3dcaaf2df2df',
      },
      investorId: {
        text: 'Investor ID:', // TODO: add japanese translations
        placeholder: 'e.g. 127923531',
      },
      senderId: {
        text: 'Sender ID:',
        placeholder: 'e.g. 127923531',
      },
      receiverId: {
        text: 'Receiver ID:',
        placeholder: 'e.g. 127923531',
      },
      tbeAmount: {
        text: 'TBE Amount:',
        placeholder: 'e.g. 50',
      },
      contractStatus: 'コントラクトステータス:',
    },
    button: {
      execute: '実行',
      holdTrading: '取引停止',
      resumeTrading: '取引再開',
    },
    option: {
      procedures: {
        lostShares: '紛失対応',
        clawback: '巻戻し',
        destroy: '破棄',
        holdTrading: '取引停止',
        destroyTbe: 'Destroy TBE', // TODO Add Japanese translation
        transferTbe: 'Transfer TBE', // TODO Add Japanese translation
        internalTbeTransfer: 'Internal Transfer TBE',
      },
    },
    message: {
      lostShareFormDescription: `指定した数のトークンを登録済みのウォレットから破棄し、
        新たに同数のトークンを新しいウォレットに発行します。
        投資家が既存のウォレットにアクセス出来なくなった場合などに使用します。`,
      clawbackFormDescription: `指定した数のトークンを送信先のウォレットから破棄し、
        新たに同じ量を送信者のウォレットに再発行します。
        発行体がトークン発行を無効にしたり、やり直す場合などに使用します。`,
      destroyFormDescription: `指定した数のトークンを指定したウォレットから破棄します。
        この操作は発行体から割り当てられたトークンに対してのみ行うことができます。`,
      holdTradingDescription: `ブロックチェーンのコントラクトを停止（または停止解除）します。
        コントラクトを停止すると、そのトークンに関するすべての取引(セカンダリーマーケットでの取引を含む)が停止されます。`,
      destroyTbeFormDescription: `Destroy a number of securities from the investor TBE balance.
      Can only be achieved for tokens that were assigned by the issuer.`, // TODO Add Japanese translation
      transferFormTbeDescription: 'Transfer a number of securities from a certain investor to another. Can only be achieved for TBE Balance',
    },
    deleteConfirmationModal: {
      title: '最終確認',
      message: '投資家：{investorName} のウォレットから{tokensAmount}トークンを破棄しますがよろしいですか？',
    },
    deleteTbeConfirmationModal: { // TODO Add Japanese translation
      title: 'Please confirm',
      message: 'Are you sure you want to destroy {tokensAmount} TBE tokens for {investorName} ?',
    },
    lockTokensConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to lock {tokensAmount} tokens for {investorName} ?',
    },
    unlockTokensConfirmationModal: {
      title: 'Please confirm',
      message: 'Are you sure you want to unlock {tokensAmount} tokens for {investorName} ?',
    },
    proceduresConfirmationModal: {
      title: '最終確認',
      message: '入力した内容で{0}のオペレーションを実行しますがよろしいですか？',
      label: {
        lostShare: '紛失対応',
        clawback: '巻戻し',
      },
    },
    resumeConfirmationModal: {
      title: '最終確認',
      message: 'トークンステータスを{tokenStatus}から{altStatus}に変更するには、以下に発行体名称　{currentIssuer}　をタイプしてください。',
      input: {
        name: {
          text: '発行体名称',
          placeholder: '発行体名称を入力してください...',
        },
      },
    },
  },
};
