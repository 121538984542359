import moment from 'moment';
import { alertsApi, selfServiceApi } from '~/utilities/mainApiClient';
import { login, refresh, googleLogin, updatePassword, getTwoFactorAuth, verifyTwoFactorAuth, verifyRegisterToken, logout } from '~/utilities/sessionCpApi';

const Cookie = require('js-cookie');

const setLoginData = (commit, loginType, data) => {
  if (window.Intercom) {
    // eslint-disable-next-line camelcase
    const { app_id } = window.intercomSettings;
    window.intercomSettings = {
      app_id,
      email: data.user.email,
      name: data.user.name,
    };
    window.Intercom('update');
  }

  moment.locale(data.user.languageCode);
  Cookie.set('auth_type', loginType);
  commit('SET_AUTHENTICATED', true);
  commit('SET_OPERATOR', data.user);
  commit('global/SET_LOCALE', data.user.languageCode, { root: true });

  if (loginType === 'local') {
    commit('SET_VIEW_DATA', {});
    commit('SET_TWO_FA_URI', '');
    commit('SET_REGISTER_TOKEN', '');
  }
};

export default {
  register({ commit }, { ...params }) {
    return selfServiceApi.createSelfServiceIssuer(params)
      .then(({ data }) => {
        this.$i18n.locale = data.user.languageCode;
        setLoginData(commit, 'google', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        return err;
      });
  },
  async logout({ commit }) {
    const logoutRes = await logout();
    if (window.Intercom) {
      // eslint-disable-next-line camelcase
      const { app_id } = window.intercomSettings;
      window.intercomSettings = {
        app_id,
      };
      window.Intercom('shutdown');
      window.Intercom('boot', {
        app_id,
      });
    }

    Cookie.remove('auth_type');
    commit('SET_AUTHENTICATED', false);
    commit('global/SET_GLOBAL_ISSUERS', [], { root: true });
    return logoutRes;
  },
  login({ commit }, { email, password, recaptchaToken, tfaToken }) {
    return new Promise((resolve, reject) => login(email, password, recaptchaToken, tfaToken)
      .then(({ data }) => {
        commit('SET_REGISTER_TOKEN', data);
        this.$i18n.locale = data.user.languageCode;
        setLoginData(commit, 'local', data);
        resolve(data);
      })
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        reject(err);
      }));
  },
  refresh({ commit }) {
    return new Promise((resolve, reject) => refresh()
      .then(({ data }) => {
        this.$i18n.locale = data.user.languageCode;
        setLoginData(commit, 'local', data);
        resolve(data);
      })
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        reject(err);
      }));
  },
  googleLogin({ commit }, googleAccessToken) {
    return new Promise((resolve, reject) => {
      googleLogin(googleAccessToken)
        .then(({ data }) => {
          this.$i18n.locale = data.user.languageCode;
          setLoginData(commit, 'google', data);
          resolve(data);
        })
        .catch((err) => {
          this.$log.error('Authorize error:', err);
          reject(err);
        });
    });
  },
  signup({ state }, { password, recaptchaToken }) {
    return new Promise((resolve, reject) => updatePassword(password, recaptchaToken, state.registerToken)
      .then(() => resolve())
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        reject(err);
      }));
  },
  getTwoFactorAuth({ commit, state }) {
    return new Promise((resolve, reject) => getTwoFactorAuth(state.registerToken)
      .then(({ data }) => {
        commit('SET_TWO_FA_URI', data.uri);
        resolve();
      })
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        reject(err);
      }));
  },
  verifyTwoFactorAuth({ commit, state }, digits) {
    return new Promise((resolve, reject) => verifyTwoFactorAuth(digits, state.registerToken)
      .then((data) => {
        this.$i18n.locale = data.user.languageCode;
        setLoginData(commit, 'local', data);
        resolve(data);
      })
      .catch((err) => {
        this.$log.error('Authorize error:', err);
        reject(err);
      }));
  },
  verifyRegisterToken({ commit }, registerToken) {
    return new Promise((resolve, reject) => verifyRegisterToken(registerToken)
      .then(({ data }) => {
        commit('SET_REGISTER_TOKEN', registerToken);
        resolve(data);
      })
      .catch((err) => {
        commit('SET_REGISTER_TOKEN', '');
        reject(err);
      }));
  },
  getNotifications(ctx, issuerId) {
    alertsApi.getAlertsCount(issuerId)
      .catch(error => this.$log.error('Issuer alerts count get error:', error));

    alertsApi.getAlerts(issuerId)
      .catch(error => this.$log.error('Issuer alerts get error:', error));
  },

  getSecuritizeIdNotifications({ commit }) {
    alertsApi.getSecuritizeIdAlertsCount()
      .then(({ data }) => commit('SET_SECURITIZE_ID_ALERTS_COUNT', data))
      .catch(error => this.$log.error('Securitize-id alerts count get error:', error));

    alertsApi.getSecuritizeIdAlerts()
      .then(({ data }) => commit('SET_SECURITIZE_ID_NOTIFICATIONS', data))
      .catch(error => this.$log.error('Securitize-id alerts get error:', error));
  },
};
