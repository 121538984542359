import sidRouteWrapper from '../pages/securitize-id/securitizeIdRoutesWrapper';

export default [
  {
    path: '/',
    name: 'securitizeId',
    component: () => import('../pages/securitize-id/index'),
  },
  {
    path: 'translations',
    name: 'securitizeIdTranslations',
    component: () => import('../pages/securitize-id/translations'),
  },
  {
    path: 'wallets',
    name: 'securitizeIdWallets',
    component: () => import('../pages/securitize-id/wallets'),
  },
  {
    path: ':investorId',
    name: 'securitizeIdInvestor',
    component: () => import('../pages/securitize-id/investor'),
  },
  {
    path: ':investorId/legal-signer/:legalSignerId',
    name: 'securitizeIdLegalSignerId',
    component: () => import('../pages/securitize-id/legal-signer'),
  },
  // MFE routes
  {
    path: 'mfe/investors',
    name: 'securitizeIdMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/investors/:investorId',
    name: 'securitizeIdInvestorMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/translations',
    name: 'securitizeIdTranslationsMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/wallets',
    name: 'securitizeIdWalletsMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/:investorId/legal- signer/:legalSignerId',
    name: 'securitizeIdLegalSignerIdMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/promotions',
    name: 'securitizeIdPromotionsMfe',
    component: () => sidRouteWrapper(),
  },
  {
    path: 'mfe/promotions/:promotionId',
    name: 'securitizeIdPromotionEditMfe',
    component: () => sidRouteWrapper(),
  },
];
