export default {
  holders: {
    title: 'Holders',
    label: {
      investor: 'Investor',
      tokenSynced: '{0} Synced',
      tokenPendingSync: '{0} Pending Sync',
      tab: {
        holdersOfRecord: 'Holders of Record',
        beneficialOwners: 'Beneficial Owners',
        custodianManagement: 'Custodian Management',
      },
      filter: {
        country: 'Country',
        investorType: 'Investor Type',
        tokenOwned: 'Token Owned',
        walletOwner: 'Wallet Owner',
        custodian: 'Custodian',
      },
      table: {
        beneficialOwners: 'Ben. Owner Name/Entity',
        recordHolder: 'Record Holder',
        country: 'Country',
        name: 'Name',
        friendlyName: 'Friendly Name',
        investors: 'Investors',
        email: 'Email',
        investorType: 'Type',
        totalTokens: 'Tokens',
        principalAmount: 'Principal Amount, {0}',
        percentage: 'Percentage',
        walletAddress: 'Wallet Address',
        walletRegistrationStatus: 'Wallet Status',
        labels: 'Labels',
      },
    },
    status: {
      walletRegistration: {
        none: 'none',
        pending: 'pending',
        syncing: 'syncing',
        ready: 'ready',
        rejected: 'rejected',
        cancelled: 'cancelled',
      },
    },
    loading: 'Loading Investor Data...',
    importIssuance: {
      button: 'Import Issuance Records',
      processStartedMessage: 'A total of {0} issuance records were found and they will be processed in the background. '
          + 'You will receive an email once the process is completes including any error that may occur.',
      processStartedMessageWithErrors: 'A total of {0} issuance records were found, {1} records will be processed in the background, '
          + '{2} records contained validation errors and will not be processed. '
          + 'You will receive an email once the process is completes including any error that may occur.',
      processCouldNotBeStartedMessage: 'An error occurred and the import cannot continue. Please try again or contact an administrator. {0}',
    },
  },
};
