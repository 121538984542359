export default {
  getOutreachIsEnabled: state => state.outreachIsEnabled,
  getPreviewContactList: ({ previewContactList }) => () => (previewContactList),
  getSavedContactListNames: ({ savedContactLists }) => () => savedContactLists.map(list => ({ text: list.name, value: list.id })),
  getSavedContactListById: ({ savedContactLists }) => (ids) => {
    const filters = savedContactLists
      .filter(list => ids.includes(list.id))
      .map(list => list.filters);
    return filters.flat();
  },
  getInvestorsOutreachList: state => state.investorsOutreachList,
  getTotalItems: state => state.totalItems,
  getCustomEmails: state => state.customEmails,
  getSavedDomain: state => state.domain,
  getIsAuthenticated: state => state.isAuthenticated,
  getEmailActivityData: ({ emailActivity }) => () => (emailActivity),
  getEmailActivityTotal: state => state.emailActivityTotal,
  getSelectedIdDetailsData: state => state.selectedIdDetailsData,
  getSelectedIdDetailsTotal: state => state.selectedIdDetailsTotal,
  getEmailActivityChart: state => state.emailActivityChart,
};
