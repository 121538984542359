export default {
  component: {
    cpDualListBox: {
      label: {
        instances: 'Instances',
        selectedInstances: 'Selected Instances',
        showingAll: 'Showing all {0}',
        emptyList: 'Empty list',
        moveAll: 'Move all',
        moveSelected: 'Move selected',
        removeAll: 'Remove all',
        removeSelected: 'Remove selected',
        noResultsFound: 'No results found',
        clearSearch: 'Clear Search',
      },
      input: {
        search: {
          placeholder: 'Search...',
        },
      },
    },
    cpDateRange: {
      to: 'to',
    },
    cpHtmlTextarea: {
      button: {
        preview: 'Preview',
        view: 'View Source',
      },
    },
    cpSelect: {
      label: {
        defaultText: 'Please select an option',
      },
    },
    cpTextArea: {
      label: {
        placeholder: 'Write your text here...',
      },
    },
    cpMultiSelect: {
      button: {
        clear: 'clear {0}',
      },
    },
  },
};
